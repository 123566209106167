import user1 from "./images/user1.png"
import inquryImg from "./images/icon7.png"
import question3 from "./images/question3.png"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getInquiry, listInquiryStatusAction, updateInquiryAction } from "../../../actions/inquiryActions"
import { useEffect, useLayoutEffect, useState } from "react"
import { listEmployees } from "../../../actions/employeeActions"
import { listHearusOptionsAction } from "../../../actions/globalActions"
import Select from "react-select";

const ViewInquiry = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const inquiry = useSelector(state => state.inquiry.inquiry)
    const { id } = useParams('id');

    const hearusOptions = useSelector(state => state.global.hearusOptions);
    const inquiryStatuses = useSelector(state => state.inquiry.inquiryStatuses);
    const employees = useSelector(state => state.employee.employees);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [newBuyer, setNewBuyer] = useState("FACEBOOK");
    const [message, setMessage] = useState("");
    const [inquiryStatus, setInquiryStatus] = useState("new");
    const [employee, setEmployee] = useState("");

    const [employeeOptions, setEmployeeOptions] = useState([]);

    useLayoutEffect(() => {
        dispatch(listEmployees());
        dispatch(listInquiryStatusAction());
        dispatch(listHearusOptionsAction());
    }, []);

    useEffect(() => {
        if (inquiry) {
            console.log('inquiry', inquiry);
            setName(inquiry.name);
            setEmail(inquiry.email);
            setPhone(inquiry.phone);
            setMessage(inquiry.message);
            setInquiryStatus(inquiry.inquiryStatus);
            inquiry?.assignedTo?.firstName && setEmployee({ obj: inquiry.assignedTo, value: inquiry.assignedTo._id, label: inquiry.assignedTo.firstName + ' ' + inquiry.assignedTo.lastName });
        }
    }, [inquiry]);

    useEffect(() => {
        const opts = [];
        employees && employees.map(employee => {
            if (!employee.isDeleted) {
                opts.push({ obj: employee, value: employee._id, label: employee.firstName + ' ' + employee.lastName });
            }
        });
        setEmployeeOptions(opts);
    }, [employees]);

    const updateInquiry = (e) => {
        e.preventDefault();

        const inquiry = {
            name,
            email,
            message,
            assignedTo: employee.obj,
            newBuyer,
            inquiryStatus,
            phone
        }

        dispatch(updateInquiryAction(id, inquiry));
        navigate('/admin/inquiries');
    }

    useEffect(() => {
        if (id) {
            dispatch(getInquiry(id))
        }
    }, [id])

    return (

        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <nav aria-label="breadcrumb" className="">
                    <ol className="breadcrumb p-1">
                        <li className="breadcrumb-item">
                            <Link to="/admin">Admin</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/admin/inquiries">Inquiries</Link>
                        </li>
                    </ol>
                </nav>
                <div className="d-sm-flex d-block justify-content-between align-items-center">
                    <h3 className="content-header-title">
                        Inquiry # {inquiry?.ticket}
                    </h3>
                    {/* <div className="d-sm-flex">
                        <a href="#" className="btn btn-primary d-sm-flex d-block align-items-center">

                            <span>&nbsp;View Tickets</span>
                        </a>
                        &nbsp;

                        <button type="button" href="#" className=" d-sm-flex d-block border-0 dropdown-toggle align-items-center" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class='bx bx-cog'></i> &nbsp;&nbsp;Action&nbsp;&nbsp;
                        </button>
                        <ul className="dropdown-menu">
                            <li><a className="dropdown-item" href="#">
                                Export as Pdf
                            </a>
                            </li>
                        </ul>


                    </div> */}
                </div>
                <div className="row">

                    <div className="col-md-8 col-lg-8  mb-4 order-lg-0">
                        <div className="border border-dark px-1 py-1 h-100">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center me-3">
                                    <img src={user1} alt="Avatar" className="rounded-circle me-3" width={54} />
                                    <div className="card-title mb-0 mx-1">
                                        <h5 className="mb-0">Customer Information</h5>
                                    </div>
                                </div>
                            </div>
                            <hr className="" />
                            <div className="card-body mx-2 p-0">
                                <a href="javascript:void(0);">{inquiry?.name}</a>
                                <span className="d-block my-1">
                                    <strong>Mobile:</strong> {inquiry?.phone}
                                </span>
                                <span className="d-block my-1">
                                    <strong>Email:</strong> {inquiry?.email}
                                </span>
                                <span className="d-block my-1">
                                    <strong>Message:</strong> {inquiry?.message}
                                </span>
                                {/* <div className="my-2">
                                    <span>
                                        Store Credits: £0.00 | Loyalty:
                                    </span>
                                </div> */}
                                {/* <div className="">
                                    <a href="#" className="btn btn-primary align-items-center rounded-0">
                                        <span><i className='bx bx-edit'></i>&nbsp;Edit</span>
                                    </a>
                                </div> */}
                            </div>

                        </div>
                    </div>
                    <div className="col-md-4 col-lg-4 mb-4 order-lg-0 mb-4 ">
                        <div className="border border-dark py-1 h-100">
                            <div className="d-flex align-items-center me-3 mb-2 px-1 pt-2">
                                <img src={inquryImg} alt="Avatar" className="rounded-circle me-3" width={54} />
                                <div className="card-title mb-0 mx-1">
                                    <h5 className="mb-0">Inquiry Summary</h5>
                                </div>
                            </div>
                            <div className="px-1">
                                <hr />
                            </div>
                            <div className="card-body  jusitify-content-center  px-2">
                                <table className="table table-bordered mx-auto bg-white">
                                    <tr >
                                        <td >Inquiry Number</td>
                                        <td>{inquiry?.ticket}</td>
                                    </tr>
                                    <tr>
                                        <td>Reference</td>
                                        <td>{inquiry?.newBuyer} </td>
                                    </tr>
                                    <tr>
                                        <td>Creation Date</td>
                                        <td>{new Date(inquiry?.createdAt)?.toDateString()}</td>
                                    </tr>
                                    <tr>
                                        <td>Inquiry Status</td>
                                        <td>
                                            <a href="javascript:void(0);" className="btn btn-success bg-success"> {inquiry?.inquiryStatus}</a></td>
                                    </tr>
                                    <tr>
                                        <td>Assigned To</td>
                                        <td>{inquiry?.assignedTo?.firstName && inquiry?.assignedTo?.firstName + ' ' + inquiry?.assignedTo?.lastName}</td>
                                    </tr>
                                    <tr>
                                        <td>Updated By</td>
                                        <td>{inquiry?.updatedBy?.firstName && inquiry?.updatedBy?.firstName + ' ' + inquiry?.updatedBy?.lastName}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="card-body">
                        <div className="bg-white px-1 py-1">
                            <h5>Update Inquiry <img src={question3} alt="" title="Select inquiry" /></h5>
                            <form onSubmit={updateInquiry}>
                                <div className="row">
                                    <div className="col mb-0">
                                        <label className="form-label">Name</label>
                                        <input type="text" className="form-control" placeholder="Enter Name" required
                                            value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <div className="col mb-0">
                                        <label className="form-label">Email</label>
                                        <input type="email" className="form-control" placeholder="Enter Email" required
                                            value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                    <div className="col mb-0">
                                        <label className="form-label">Mobile</label>
                                        <input type="text" className="form-control" placeholder="Enter Mobile Number" required
                                            value={phone} onChange={(e) => setPhone(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col mb-0">
                                        <label className="form-label">assigned To</label>
                                        <Select
                                            required
                                            value={employee}
                                            onChange={(e) => setEmployee(e)}
                                            options={employeeOptions}
                                        />
                                    </div>
                                    <div className="col mb-0">
                                        <label className="form-label">Status</label>
                                        <select className="form-control" required
                                            value={inquiryStatus} onChange={(e) => setInquiryStatus(e.target.value)}>
                                            {inquiryStatuses?.map(status => <option value={status[1]}>{status[0]}</option>)}
                                        </select>
                                    </div>
                                    <div className="col mb-0">
                                        <label className="form-label">Reference</label>
                                        <select className="form-control" required
                                            value={newBuyer} onChange={(e) => setNewBuyer(e.target.value)}>
                                            {hearusOptions?.map(option => <option value={option[1]}>{option[0]}</option>)}

                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col mb-0">
                                        <label className="form-label">Message</label>
                                        <textarea className="form-control" required
                                            value={message} onChange={(e) => setMessage(e.target.value)}>
                                        </textarea>
                                    </div>
                                </div>
                                <div class="col-sm-12 d-flex justify-content-end my-2">
                                    <button type="button" class="btn btn-primary" onClick={() => navigate('/admin/inquiries')}>Cancel</button>
                                    <button type="submit" class="btn btn-primary mx-1">Update</button>
                                </div>
                            </form>
                        </div>
                        {/* <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Inquiry Type	</th>
                                        <th>Product/Service	</th>
                                        <th>Task Type</th>
                                        <th>Expected Visit Time	</th>
                                        <th>Notes</th>
                                        <th>Qty</th>
                                        <th>Price</th>
                                        <th>Tax</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Repairs</td>
                                        <td>
                                            <span className="d-block">
                                                Mobile Repair - iPhone 11 PRO MAX
                                            </span>
                                            <span className="d-block">
                                                <i class='bx bxs-wrench'></i> Device Issue: <a href="#">10.</a>
                                            </span>
                                            <span className="d-block">
                                                <a href="#">Rear Camera Lens Replacement</a>
                                            </span>

                                        </td>
                                        <td>
                                            On-Site
                                        </td>

                                        <td>
                                            26 Jan, 2023 (01:02 PM)
                                        </td>
                                        <td>
                                            Sufyan
                                        </td>
                                        <td>
                                            1
                                        </td>
                                        <td>£39.99	 </td>
                                        <td>£0.00	 </td>
                                        <td>£39.99	 </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> */}
                        {/* Pagination */}
                    </div>
                </div>
            </div >

        </>
    )
}
export default ViewInquiry