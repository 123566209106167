import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { addUserRoleAction, getUserRole, updateUserRoleAction } from "../../../actions/userRoleActions";
import { PERMISSIONS } from "../../../variables/permissions";

const UpdateRole = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    let { id } = useParams();

    const userRole = useSelector(state => state.userRole.userRole);

    const [name, setName] = useState("");
    const [permissions, setPermissions] = useState(PERMISSIONS);
    const [superUser, setSuperUser] = useState(false);

    useEffect(() => {
        if (userRole) {
            setName(userRole?.name);
            userRole?.permissions && setPermissions(userRole?.permissions)
        }
    }, [userRole]);
    
    useEffect(() => {
        id && dispatch(getUserRole(id));
    }, [id]);

    const updateAll = () => {
        if (superUser) {
            setSuperUser(false);
            const updatedPermissions = Object.keys(permissions).reduce((newPermission, key) => {
                newPermission[key] = false;
                return newPermission;
            }, {});
            setPermissions(updatedPermissions);
        } else {
            setSuperUser(true);
            const updatedPermissions = Object.keys(permissions).reduce((newPermission, key) => {
                newPermission[key] = true;
                return newPermission;
            }, {});
            setPermissions(updatedPermissions);
        }
    }

    const updatePermissions = (permission) => {
        if (permissions[permission]) {
            setPermissions({ ...permissions, [permission]: false });
        } else {
            setPermissions({ ...permissions, [permission]: true });
        }
    }

    const UpdateRole = (e) => {
        e.preventDefault();
        const role = {
            name,
            permissions
        }
        dispatch(updateUserRoleAction(id, role));
        navigate('/admin/roles');
    }

    return (
        <>
            <div className="container-xxl flex-grow-1 cotainer-p-y">
                <div className="breadcrumbs-top py-4">
                    <div className="breadcrumb-wrapper d-none d-sm-block">
                        <ol className="breadcrumb p-0 mb-0 pl-1">
                            <li className="breadcrumb-item">
                                <a href="javascript:void(0);">
                                    <i className="bx bx-home-alt" />
                                </a>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/admin/roles">Roles</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <a href="javascript:void(0);">Update</a>
                            </li>
                        </ol>
                    </div>
                </div>

                <section id="basic-horizontal-layouts">
                    <div className="row match-height">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <form className="row g-3" onSubmit={UpdateRole}>
                                        <div className="col-6 mb-4">
                                            <label className="form-label" htmlFor="modalRoleName">Role Name</label>
                                            <input type="text" className="form-control" required placeholder="Enter a role name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)} />
                                        </div>
                                        <div className="col-12">
                                            <h5>Role Permissions</h5>
                                            <div className="table-responsive">
                                                <table className="table table-flush-spacing">
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-nowrap">
                                                                Administrator Access
                                                                <i className="bx bx-info-circle bx-xs" data-bs-toggle="tooltip" data-bs-placement="top" title="Allows a full access to the system" />
                                                            </td>
                                                            <td>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" id="selectAll"
                                                                        checked={superUser}
                                                                        onChange={() => updateAll()} />
                                                                    <label className="form-check-label" htmlFor="selectAll"> Select All </label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-nowrap">Manage Leads</td>
                                                            <td>
                                                                <div className="d-flex">
                                                                    <div className="form-check me-3 me-lg-5">
                                                                        <input className="form-check-input" type="checkbox" id="listLeads"
                                                                            checked={permissions.list_leads}
                                                                            onChange={() => updatePermissions('list_leads')}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="listLeads"> List </label>
                                                                    </div>
                                                                    <div className="form-check me-3 me-lg-5">
                                                                        <input className="form-check-input" type="checkbox" id="updateLead"
                                                                            checked={permissions.update_lead}
                                                                            onChange={() => updatePermissions('update_lead')}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="updateLead"> Update </label>
                                                                    </div>
                                                                    <div className="form-check me-3 me-lg-5">
                                                                        <input className="form-check-input" type="checkbox" id="addLead"
                                                                            checked={permissions.add_lead}
                                                                            onChange={() => updatePermissions('add_lead')}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="addLead"> Create </label>
                                                                    </div>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" id="deleteLead"
                                                                            checked={permissions.delete_lead}
                                                                            onChange={() => updatePermissions('delete_lead')}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="deleteLead"> Delete </label>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-nowrap">Manage Inquiries</td>
                                                            <td>
                                                                <div className="d-flex">
                                                                    <div className="form-check me-3 me-lg-5">
                                                                        <input className="form-check-input" type="checkbox" id="listInquiries"
                                                                            checked={permissions.list_inquiries}
                                                                            onChange={() => updatePermissions('list_inquiries')}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="listInquiries"> List </label>
                                                                    </div>
                                                                    <div className="form-check me-3 me-lg-5">
                                                                        <input className="form-check-input" type="checkbox" id="updateInquiry"
                                                                            checked={permissions.update_inquiry}
                                                                            onChange={() => updatePermissions('update_inquiry')}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="updateInquiry"> Update </label>
                                                                    </div>
                                                                    <div className="form-check me-3 me-lg-5">
                                                                        <input className="form-check-input" type="checkbox" id="addInquiry"
                                                                            checked={permissions.add_inquiry}
                                                                            onChange={() => updatePermissions('add_inquiry')}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="addInquiry"> Create </label>
                                                                    </div>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" id="deleteInquiry"
                                                                            checked={permissions.delete_inquiry}
                                                                            onChange={() => updatePermissions('delete_inquiry')}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="deleteInquiry"> Delete </label>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <button type="submit" className="btn btn-primary me-sm-3 me-1">Update</button>
                                            <Link to="/admin/roles" className="btn btn-label-secondary">
                                                Cancel
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default UpdateRole;