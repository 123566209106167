//auth types
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const ERRORS_GET = "ERRORS_GET";
export const SERVER_ERROR = "SERVER_ERROR";


//global types
export const ALERT_MESSAGE_EMPTY = "ALERT_MESSAGE_EMPTY";
export const ERRORS_EMPTY = "ERRORS_EMPTY";
export const UPDATED = "UPDATED";
export const ALERT_MESSAGE = "ALERT_MESSAGE";
export const SET_COUNT = "SET_COUNT";
export const LIST_HEARUS_OPTIONS = "LIST_HEARUS_OPTIONS";

//profile types
export const PROFILE_GET = "PROFILE_GET";
export const ROLES_GET = "ROLES_GET";
export const USER_ERRORS = "USER_ERRORS";
export const USER_ERRORS_EMPTY = "USER_ERRORS_EMPTY";
export const USER_MESSAGE = "USER_MESSAGE";

//Repair Categories types
export const REPAIR_CATEGORIES_COUNT =  "REPAIR_CATEGORIES_COUNT";
export const LIST_REPAIR_CATEGORIES =  "LIST_REPAIR_CATEGORISS";
export const ADD_REPAIR_CATEGORY =  "ADD_REPAIR_CATEGORY";
export const UPDATE_REPAIR_CATEGORY =  "UPDATE_REPAIR_CATEGORY";
export const GET_REPAIR_CATEGORY =  "GET_REPAIR_CATEGORY";
export const DELETE_REPAIR_CATEGORY =  "DELETE_REPAIR_CATEGORY";

//Devices types
export const LIST_DEVICES =  "LIST_DEVICES";
export const ADD_DEVICE =  "ADD_DEVICE";
export const UPDATE_DEVICE =  "UPDATE_DEVICE";
export const GET_DEVICE =  "GET_DEVICE";
export const DELETE_DEVICE =  "DELETE_DEVICE";

//Manufacturers types
export const LIST_MANUFACTURERS =  "LIST_MANUFACTURERS";
export const ADD_MANUFACTURER =  "ADD_MANUFACTURER";
export const UPDATE_MANUFACTURER =  "UPDATE_MANUFACTURER";
export const GET_MANUFACTURER =  "GET_MANUFACTURER";
export const DELETE_MANUFACTURER =  "DELETE_MANUFACTURER";

//Device Colors types
export const LIST_DEVICE_COLORS =  "LIST_DEVICE_COLORS";
export const ADD_DEVICE_COLOR =  "ADD_DEVICE_COLOR";
export const UPDATE_DEVICE_COLOR =  "UPDATE_DEVICE_COLOR";
export const GET_DEVICE_COLOR =  "GET_DEVICE_COLOR";
export const DELETE_DEVICE_COLOR =  "DELETE_DEVICE_COLOR";

//Problems types
export const LIST_PROBLEMS =  "LIST_PROBLEMS";
export const ADD_PROBLEM =  "ADD_PROBLEM";
export const UPDATE_PROBLEM =  "UPDATE_PROBLEM";
export const GET_PROBLEM =  "GET_PROBLEM";
export const DELETE_PROBLEM =  "DELETE_PROBLEM";

//Inquiry types
export const LIST_INQUIRIES =  "LIST_INQUIRIES";
export const ADD_INQUIRY =  "ADD_INQUIRY";
export const UPDATE_INQUIRY =  "UPDATE_INQUIRY";
export const GET_INQUIRY =  "GET_INQUIRY";
export const DELETE_INQUIRY =  "DELETE_INQUIRY";
export const LIST_INQUIRY_STATUS =  "LIST_INQUIRY_STATUS";


//Devices orders
export const ORDERS_COUNT =  "ORDERS_COUNT";
export const LIST_ORDERS =  "LIST_ORDERS";
export const ADD_ORDER =  "ADD_ORDER";
export const UPDATE_ORDER =  "UPDATE_ORDER";
export const GET_ORDER =  "GET_ORDER";
export const DELETE_ORDER =  "DELETE_ORDER";
export const LIST_ORDER_STATUS =  "LIST_ORDER_STATUS";
export const LIST_INVOICE_STATUS =  "LIST_INVOICE_STATUS";

//Devices orders
export const SELL_ORDERS_COUNT =  "SELL_ORDERS_COUNT";
export const LIST_SELL_ORDERS =  "LIST_SELL_ORDERS";
export const ADD_SELL_ORDER =  "ADD_SELL_ORDER";
export const UPDATE_SELL_ORDER =  "UPDATE_SELL_ORDER";
export const GET_SELL_ORDER =  "GET_SELL_ORDER";
export const DELETE_SELL_ORDER =  "DELETE_SELL_ORDER";
export const BUY_ORDERS_COUNT =  "BUY_ORDERS_COUNT";
export const LIST_BUY_ORDERS =  "LIST_BUY_ORDERS";
export const ADD_BUY_ORDER =  "ADD_BUY_ORDER";
export const UPDATE_BUY_ORDER =  "UPDATE_BUY_ORDER";
export const GET_BUY_ORDER =  "GET_BUY_ORDER";
export const DELETE_BUY_ORDER =  "DELETE_BUY_ORDER";
export const LIST_BUY_DEVICES = "LIST_BUY_DEVICES"
export const GET_BUY_DEVICE = "GET_BUY_DEVICE"
// export const LIST_ORDER_STATUS =  "LIST_ORDER_STATUS";
// export const LIST_INVOICE_STATUS =  "LIST_INVOICE_STATUS";


//Tax Classes types
export const LIST_TAX_CLASSES =  "LIST_TAX_CLASSES";
export const ADD_TAX_CLASS =  "ADD_TAX_CLASS";
export const UPDATE_TAX_CLASS =  "UPDATE_TAX_CLASS";
export const GET_TAX_CLASS =  "GET_TAX_CLASS";
export const DELETE_TAX_CLASS =  "DELETE_TAX_CLASS";

//Repair Service Types
export const REPAIR_SERVICES_COUNT = "REPAIR_SERVICES_COUNT";
export const LIST_REPAIR_SERVICES =  "LIST_REPAIR_SERVICES";
export const ADD_REPAIR_SERVICE =  "ADD_REPAIR_SERVICE";
export const UPDATE_REPAIR_SERVICE =  "UPDATE_REPAIR_SERVICE";
export const GET_REPAIR_SERVICE =  "GET_REPAIR_SERVICE";
export const DELETE_REPAIR_SERVICE =  "DELETE_REPAIR_SERVICE";
export const GET_WARRANTY_TYPES = "GET_WARRANTY_TYPES";
export const GET_LOYALTY_TYPES = "GET_LOYALTY_TYPES";

export const UPDATE_PROGRESS = "UPDATE_PROGRESS";
export const MEDIA_UPDATE = "MEDIA_UPDATE";
export const MEDIA_EMPTY = "MEDIA_EMPTY";

//blog types
export const LIST_BLOGS =  "LIST_BLOGS";
export const ADD_BLOG =  "ADD_BLOG";
export const UPDATE_BLOG =  "UPDATE_BLOG";
export const GET_BLOG =  "GET_BLOG";
export const DELETE_BLOG =  "DELETE_BLOG";

//blog types
export const LIST_SEO_PAGES =  "LIST_SEO_PAGES";
export const ADD_SEO_PAGE =  "ADD_SEO_PAGE";
export const UPDATE_SEO_PAGE =  "UPDATE_SEO_PAGE";
export const GET_SEO_PAGE =  "GET_SEO_PAGE";
export const DELETE_SEO_PAGE =  "DELETE_SEO_PAGE";

//blog types
export const LIST_STORES =  "LIST_STORES";
export const ADD_STORE =  "ADD_STORE";
export const UPDATE_STORE =  "UPDATE_STORE";
export const GET_STORE =  "GET_STORE";
export const DELETE_STORE =  "DELETE_STORE";

//product Categories types
export const LIST_PRODUCT_CATEGORIES =  "LIST_PRODUCT_CATEGORIES";
export const ADD_PRODUCT_CATEGORY =  "ADD_PRODUCT_CATEGORY";
export const UPDATE_PRODUCT_CATEGORY =  "UPDATE_PRODUCT_CATEGORY";
export const GET_PRODUCT_CATEGORY =  "GET_PRODUCT_CATEGORY";
export const DELETE_PRODUCT_CATEGORY =  "DELETE_PRODUCT_CATEGORY";

//product Conditions types
export const LIST_PRODUCT_CONDITIONS =  "LIST_PRODUCT_CONDITIONS";
export const ADD_PRODUCT_CONDITION =  "ADD_PRODUCT_CONDITION";
export const UPDATE_PRODUCT_CONDITION =  "UPDATE_PRODUCT_CONDITION";
export const GET_PRODUCT_CONDITION =  "GET_PRODUCT_CONDITION";
export const DELETE_PRODUCT_CONDITION =  "DELETE_PRODUCT_CONDITION";

//product Conditions types
export const LIST_DEVICE_SERIESES =  "LIST_DEVICE_SERIESES";
export const ADD_DEVICE_SERIES =  "ADD_DEVICE_SERIES";
export const UPDATE_DEVICE_SERIES =  "UPDATE_DEVICE_SERIES";
export const GET_DEVICE_SERIES =  "GET_DEVICE_SERIES";
export const DELETE_DEVICE_SERIES =  "DELETE_DEVICE_SERIES";

// User Roles Types
export const LIST_USER_ROLES = "LIST_USER_ROLES"
export const ADD_USER_ROLE =  "ADD_USER_ROLE";
export const UPDATE_USER_ROLE =  "UPDATE_USER_ROLE";
export const GET_USER_ROLE =  "GET_USER_ROLE";
export const DELETE_USER_ROLE =  "DELETE_USER_ROLE";

//product Network types
export const LIST_PRODUCT_NETWORKS =  "LIST_PRODUCT_NETWORKS";
export const ADD_PRODUCT_NETWORK =  "ADD_PRODUCT_NETWORK";
export const UPDATE_PRODUCT_NETWORK =  "UPDATE_PRODUCT_NETWORK";
export const GET_PRODUCT_NETWORK =  "GET_PRODUCT_NETWORK";
export const DELETE_PRODUCT_NETWORK =  "DELETE_PRODUCT_NETWORK";

//product physical location types
export const LIST_PRODUCT_PHYSICAL_LOCATIONS =  "LIST_PRODUCT_PHYSICAL_LOCATIONS";
export const ADD_PRODUCT_PHYSICAL_LOCATION =  "ADD_PRODUCT_PHYSICAL_LOCATION";
export const UPDATE_PRODUCT_PHYSICAL_LOCATION =  "UPDATE_PRODUCT_PHYSICAL_LOCATION";
export const GET_PRODUCT_PHYSICAL_LOCATION =  "GET_PRODUCT_PHYSICAL_LOCATION";
export const DELETE_PRODUCT_PHYSICAL_LOCATION =  "DELETE_PRODUCT_PHYSICAL_LOCATION";

//product product Suppliers types
export const LIST_PRODUCT_SUPPLIERS =  "LIST_PRODUCT_SUPPLIERS";
export const ADD_PRODUCT_SUPPLIER =  "ADD_PRODUCT_SUPPLIER";
export const UPDATE_PRODUCT_SUPPLIER =  "UPDATE_PRODUCT_SUPPLIER";
export const GET_PRODUCT_SUPPLIER =  "GET_PRODUCT_SUPPLIER";
export const DELETE_PRODUCT_SUPPLIER =  "DELETE_PRODUCT_SUPPLIER";


// Employees Types
export const LIST_EMPLOYEES =  "LIST_EMPLOYEES";
export const ADD_EMPLOYEE =  "ADD_EMPLOYEE";
export const UPDATE_EMPLOYEE =  "UPDATE_EMPLOYEE";
export const GET_EMPLOYEE =  "GET_EMPLOYEE";
export const DELETE_EMPLOYEE =  "DELETE_EMPLOYEE";


// Employees Types
export const LIST_PRODUCTS =  "LIST_PRODUCTS";
export const ADD_PRODUCT =  "ADD_PRODUCT";
export const UPDATE_PRODUCT =  "UPDATE_PRODUCT";
export const GET_PRODUCT =  "GET_PRODUCT";
export const DELETE_PRODUCT =  "DELETE_PRODUCT";
export const PRODUCTS_COUNT = "PRODUCTS_COUNT"
export const GET_VALUATION_METHOD = "GET_VALUATION_METHOD";


//Trade In types
export const LIST_TRADE_IN =  "LIST_TRADE_IN";
export const ADD_TRADE_IN =  "ADD_TRADE_IN";
export const UPDATE_TRADE_IN =  "UPDATE_TRADE_IN";
export const GET_TRADE_IN =  "GET_TRADE_IN";
export const DELETE_TRADE_IN =  "DELETE_TRADE_IN";
export const LIST_SALE_STATUS =  "LIST_SALE_STATUS";

// Products Sizes Types
export const LIST_PRODUCT_SIZES =  "LIST_PRODUCT_SIZES";
export const ADD_PRODUCT_SIZE =  "ADD_PRODUCT_SIZE";
export const UPDATE_PRODUCT_SIZE =  "UPDATE_PRODUCT_SIZE";
export const GET_PRODUCT_SIZE =  "GET_PRODUCT_SIZE";
export const DELETE_PRODUCT_SIZE =  "DELETE_PRODUCT_SIZE";

// ProductType Types
export const LIST_PRODUCT_TYPES =  "LIST_PRODUCT_TYPES";
export const ADD_PRODUCT_TYPE =  "ADD_PRODUCT_TYPE";
export const UPDATE_PRODUCT_TYPE =  "UPDATE_PRODUCT_TYPE";
export const GET_PRODUCT_TYPE =  "GET_PRODUCT_TYPE";
export const DELETE_PRODUCT_TYPE =  "DELETE_PRODUCT_TYPE";


export const LIST_TICKETS = "LIST_TICKETS"
export const ADD_TICKET = "ADD_TICKET"
export const UPDATE_TICKET = "UPDATE_TICKET"
export const GET_TICKET = "GET_TICKET"
export const DELETE_TICKET = "DELETE_TICKET"
export const ADD_CUSTOMER = "ADD_CUSTOMER"