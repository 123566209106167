import { useLayoutEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { deleteUserRoleAction, listUserRoles } from "../../../actions/userRoleActions";

const ListRoles = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userRoles = useSelector((state) => state.userRole.userRoles);
    const [modal, setModal] = useState(false);
    const [delId, setDelId] = useState(null);

    useLayoutEffect(() => {
        dispatch(listUserRoles());
    }, []);

    const styleShow = {
        display: 'block',
        paddingRight: '17px'
    }

    const styleHide = {
        display: 'none',
        paddingRight: '17px'
    }

    const deleteUserRole = () => {
        // console.log(delId);
        delId && dispatch(deleteUserRoleAction(delId));
        setModal(false);
    }
    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="py-3 breadcrumb-wrapper mb-2">Roles List</h4>
                <div className="row g-4">
                    {userRoles?.map((role, key) => {
                        return !role.isDeleted && <div key={key} className="col-xl-4 col-lg-6 col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between mb-2" style={{ visibility: 'hidden' }}>
                                        <h6 className="fw-normal">Total 4 users</h6>
                                        <ul className="list-unstyled d-flex align-items-center avatar-group mb-0">
                                            <li data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="top" title="Vinnie Mostowy" className="avatar avatar-sm pull-up">
                                                <img className="rounded-circle" src="/../../assets/img/avatars/5.png" alt="Avatar" />
                                            </li>
                                            <li data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="top" title="Allen Rieske" className="avatar avatar-sm pull-up">
                                                <img className="rounded-circle" src="/../../assets/img/avatars/12.png" alt="Avatar" />
                                            </li>
                                            <li data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="top" title="Julee Rossignol" className="avatar avatar-sm pull-up">
                                                <img className="rounded-circle" src="/../../assets/img/avatars/6.png" alt="Avatar" />
                                            </li>
                                            <li data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="top" title="Kaith D'souza" className="avatar avatar-sm pull-up">
                                                <img className="rounded-circle" src="/../../assets/img/avatars/15.png" alt="Avatar" />
                                            </li>
                                            <li data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="top" title="John Doe" className="avatar avatar-sm pull-up">
                                                <img className="rounded-circle" src="/../../assets/img/avatars/1.png" alt="Avatar" />
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-end">
                                        <div className="role-heading">
                                            <h4 className="mb-1">{role.name}</h4>
                                            <Link to={`/admin/roles/${role._id}`} className="role-edit-modal"><small>Edit Role</small></Link>
                                        </div>
                                        <a href="javascript:void(0);" className="text-muted"><i className="bx bx-comment-x"
                                            onClick={() => { setModal(true); setDelId(role._id) }} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                    <div className="col-xl-4 col-lg-6 col-md-6">
                        <div className="card h-100">
                            <div className="row h-100">
                                <div className="col-sm-5">
                                    <div className="d-flex align-items-end h-100 justify-content-center mt-sm-0 mt-3">
                                        <img src="/../../assets/img/illustrations/lady-with-laptop-light.png" className="img-fluid" alt="Image" width={100} data-app-light-img="illustrations/lady-with-laptop-light.png" data-app-dark-img="illustrations/lady-with-laptop-dark.png" />
                                    </div>
                                </div>
                                <div className="col-sm-7">
                                    <div className="card-body text-sm-end text-center ps-sm-0">
                                        <Link to="/admin/roles/add" className="btn btn-primary mb-3 text-nowrap add-new-role">
                                            Add New Role
                                        </Link>
                                        <p className="mb-0">Add role, if it does not exist</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal card fade text-left ${modal ? 'show' : ''}`} id="danger" tabIndex={-1} aria-labelledby="myModalLabel120" style={modal ? styleShow : styleHide} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-primary py-2">
                            <h5 className="modal-title text-white" id="myModalLabel120">Danger!</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={() => { setModal(false) }}>
                                <i className="bx bx-x" />
                            </button>
                        </div>
                        <div className="modal-body">
                            Are you sure! You want to delete this Role?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light-secondary" data-dismiss="modal"
                                onClick={() => { setModal(false) }}>
                                <i className="bx bx-x d-block d-sm-none" />
                                <span className="d-none d-sm-block">Cancel</span>
                            </button>
                            <button type="button" className="btn btn-danger ms-1" data-dismiss="modal"
                                onClick={() => { deleteUserRole(delId) }}>
                                <i className="bx bx-check d-block d-sm-none" />
                                <span className="d-none d-sm-block">Accept</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ListRoles;