import axios from "axios";
import { alertMessage, base_url, check_response_status } from "../utils";
import { ALERT_MESSAGE_EMPTY, ERRORS_EMPTY, MEDIA_EMPTY, MEDIA_UPDATE, UPDATE_PROGRESS, SET_COUNT, LIST_HEARUS_OPTIONS } from "./types"

// Empty Alert Message
export const emptyAlertMessage = () => async dispatch => {
    dispatch({
        type: ALERT_MESSAGE_EMPTY,
        payload: null
    });
}

// Empty Errors
export const emptyErrors = () => async dispatch => {
    dispatch({
        type: ERRORS_EMPTY,
        payload: null
    });
}

// Empty Media
export const emptyMedia = () => async dispatch => {
    dispatch({
        type: MEDIA_EMPTY,
        payload: null
    });
}

//upload Image
export const uploadImage = (file) => async dispatch => {
    const options = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total)
            if (percent <= 100) {
                dispatch({
                    type: UPDATE_PROGRESS,
                    payload: percent
                });
            }
        },
        headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${localStorage.usertoken}` }
    }
    axios.post(`${base_url}media`,
        file, options)
        .then(response => {
            // console.log(response);
            if (response.status == 201) {
                dispatch({
                    type: MEDIA_UPDATE,
                    payload: response.data
                });
            } else {
                dispatch(check_response_status(response));
            }
        })
        .catch(err => {
            dispatch(check_response_status(err.response));
        });
}

// Get Records Count
export const GetRecordsCount = (url) => async dispatch => {

    axios.get(`${base_url + url}/count`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: SET_COUNT,
                    payload: response?.data?.totalCount
                });
            }
            else if (response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            dispatch(check_response_status(err.response));
        });
}

//list hearus options
export const listHearusOptionsAction = () => async dispatch => {
    axios.get(`${base_url}orders/getHearUsOptions`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                dispatch({
                    type: LIST_HEARUS_OPTIONS,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

