import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { emptyMedia, uploadImage } from "../../../actions/globalActions";
import { addProductCategoryAction, addProductSubCategoryAction, listProductCategories } from "../../../actions/productCategoriesActions";
import { base_url } from "../../../utils";
import Select from 'react-select';

const AddProductCategory = () => {
    const imageRef = useRef();
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const media = useSelector(state => state.global.media);
    const productCategories = useSelector(state => state.productCategory.productCategories);

    const [name, setName] = useState("");
    const [image, setImage] = useState("");
    const [button, setButton] = useState(false);
    const [parrent, setParrent] = useState({});
    const [productCategoryOptions, setProductCategoryOptions] = useState([]);

    useLayoutEffect(() => {
        dispatch(emptyMedia());
        dispatch(listProductCategories());
    }, []);

    useEffect(() => {
        const opts = [{ obj: null, value: null, label: 'No Parent' }];
        productCategories && productCategories.map(productCategory => {
            if (!productCategory.isDeleted) {
                opts.push({ obj: productCategory, value: productCategory._id, label: productCategory.name });
            }
        });
        setProductCategoryOptions(opts);
    }, [productCategories]);

    useEffect(() => {
        media && setButton(true);
    }, [media])

    const addProductCategory = (e) => {
        e.preventDefault();
        const productCategory = {
            name,
            media
        }
        if (parrent?.value) {
            dispatch(addProductSubCategoryAction(parrent.value, productCategory));
        } else {
            dispatch(addProductCategoryAction(productCategory));
        }
        navigate('/admin/product-Categories');
    }

    const updateImage = (e) => {
        if (e.files && e.files[0]) {
            setImage(e.files[0]);

            const data = new FormData()
            data.append('file', e.files[0], e.files[0].name);

            dispatch(uploadImage(data));
        }
    }

    const profileStyle = {
        width: '150px',
        height: '150px'
    }
    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="breadcrumbs py-4 rounded">
                    <div className="breadcrumb-wrapper d-none d-sm-block">
                        <ol className="breadcrumb breadcrumb-style1 p-0 mb-0 pl-1">
                            <li className="breadcrumb-item">
                                <Link to="/admin">
                                    <i className="bx bx-home-alt" />
                                </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/admin/product-categories">Product Categories</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <a href="javascript:void(0);">Add New</a>
                            </li>
                        </ol>
                    </div>
                </div>
                <section id="basic-horizontal-layouts">
                    <div className="row match-height">
                        <div className="col-md-6 col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">Add New Product Category</h5>
                                </div>
                                <div className="card-body">
                                    <form className="form form-horizontal" onSubmit={addProductCategory}>
                                        <div className="form-body">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label>
                                                        Parrent
                                                    </label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <Select
                                                        value={parrent}
                                                        onChange={(e) => setParrent(e)}
                                                        options={productCategoryOptions}
                                                        className="mb-4"
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <label>
                                                        Name
                                                    </label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <input
                                                        type="text"
                                                        id="name"
                                                        className="form-control mb-4"
                                                        name="name"
                                                        placeholder="Name"
                                                        required
                                                        value={name}
                                                        onChange={(e) => { setName(e.target.value) }}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Picture</label>
                                                </div>
                                                <div className="col-md-8 mb-2">
                                                    <img style={profileStyle} src={media ? base_url + "media/" + media.filename : "/app-assets/images/dummy.jpg"} alt={name} />
                                                </div>
                                                <div className="col-md-4">
                                                    <label></label>
                                                </div>
                                                <div className="col-md-8">
                                                    <div class="button-wrapper">
                                                        <label for="upload" class="btn btn-primary me-2 mb-4" tabindex="0">
                                                            <span class="d-none d-sm-block">Upload new photo</span>
                                                            <i class="bx bx-upload d-block d-sm-none"></i>
                                                            <input
                                                                type="file"
                                                                id="upload"
                                                                class="account-file-input"
                                                                value={image.value} required
                                                                hidden
                                                                accept="image/png, image/jpeg"
                                                                onChange={(e) => { updateImage(e.target); setButton(false) }}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-1"
                                                        onClick={() => navigate(-1)}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary "
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default AddProductCategory;
