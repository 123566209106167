import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { listDevices } from "../../actions/deviceActions";
import { listManufacturers } from "../../actions/manufacturerActions";
import { listRepairCategories } from "../../actions/repairCategoriesActions";
import { addRepairServiceAction, getLoyaltyTypes, getWarrantyTypes, listRepairServices } from "../../actions/repairServiceActions";
import { listTaxClasses } from "../../actions/taxClassActions";
import { listProblems } from "../../actions/problemActions";

import { base_url } from "../../utils";
import Select from 'react-select';

const AddRepairService = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const repairCategories = useSelector(state => state.repairCategory.repairCategories);
    const manufacturers = useSelector(state => state.manufacturer.manufacturers);
    const devices = useSelector(state => state.device.devices);
    const repairServices = useSelector(state => state.repairService.repairServices);
    const taxClasses = useSelector(state => state.taxClass.taxClasses);
    const problems = useSelector(state => state.problem.problems);
    const loyaltyTypes = useSelector(state => state.repairService.loyaltyTypes);
    const warrantyTypes = useSelector(state => state.repairService.warrantyTypes);

    const [repairCategoryOptions, setRepairCategoryOptions] = useState([]);
    const [manufacturerOptions, setManufacturerOptions] = useState([]);
    const [deviceOptions, setDeviceOptions] = useState([]);
    const [repairServiceOptions, setRepairServiceOptions] = useState([]);
    const [taxClassOptions, setTaxClassOptions] = useState([]);
    const [problemOptions, setProblemOptions] = useState([]);


    const [warrantyType, setWarrantyType] = useState("");
    const [warrantyNumber, setWarrantyNumber] = useState("");
    const [days, setDays] = useState("");
    const [hours, setHours] = useState("");
    const [minuts, setMinutes] = useState("");
    const [description, setDescription] = useState("");
    const [retailPrice, setRetailPrice] = useState("");
    const [costPrice, setCostPrice] = useState("");
    const [wholePrice, setWholePrice] = useState("");
    const [isCommission, setIsCommission] = useState(false);
    const [isTaxInclusive, setIsTaxInclusive] = useState(false);
    const [commissionPercentage, setCommissionPercentage] = useState("");
    const [commissionAmount, setCommissionAmount] = useState("");
    const [enableLoyalty, setEnableLoyalty] = useState(false);
    const [loyaltyType, setLoyaltyType] = useState("default");
    const [loyaltyAmount, setLoyaltyAmount] = useState("");

    const [name, setName] = useState("");
    const [button, setButton] = useState(false);
    const [manufacturer, setManufacturer] = useState("");
    const [deviceColor, setDeviceColor] = useState("");
    const [repairCategory, setRepairCategory] = useState("");
    const [problem, setProblem] = useState("");
    const [device, setDevice] = useState("");
    const [repairService, setRepairService] = useState("");
    const [taxClass, setTaxClass] = useState("");

    useLayoutEffect(() => {
        dispatch(listManufacturers());
        dispatch(listRepairCategories());
        dispatch(listDevices());
        dispatch(listRepairServices());
        dispatch(listTaxClasses());
        dispatch(listProblems());
        dispatch(getWarrantyTypes());
        dispatch(getLoyaltyTypes());
    }, []);

    useEffect(() => {
        const opts = [];
        taxClasses && taxClasses.map(taxClass => {
            if (!taxClass.isDeleted) {
                opts.push({ obj: taxClass, value: taxClass._id, label: taxClass.class });
            }
        });
        setTaxClassOptions(opts);
    }, [taxClasses]);

    useEffect(() => {
        const opts = [];
        repairServices && repairServices.map(repairService => {
            if (!repairService.isDeleted) {
                opts.push({ obj: repairService, value: repairService._id, label: repairService.problem });
            }
        });
        setRepairServiceOptions(opts);
    }, [repairServices]);

    useEffect(() => {
        const opts = [];
        devices && devices.map(device => {
            if (!device.isDeleted) {
                opts.push({ obj: device, value: device._id, label: device.name });
            }
        });
        setDeviceOptions(opts);
    }, [devices]);

    useEffect(() => {
        const opts = [];
        repairCategories && repairCategories.map(repairCategory => {
            if (!repairCategory.isDeleted) {
                opts.push({ obj: repairCategory, value: repairCategory._id, label: repairCategory.name });
            }
        });
        setRepairCategoryOptions(opts);
    }, [repairCategories]);
    
    useEffect(() => {
        const opts = [];
        problems && problems.map(problem => {
            if (!problem.isDeleted) {
                opts.push({ obj: problem, value: problem._id, label: problem.name });
            }
        });
        setProblemOptions(opts);
    }, [problems]);

    useEffect(() => {
        const opts = [];
        manufacturers && manufacturers.map(manufacturer => {
            if (!manufacturer.isDeleted) {
                opts.push({ obj: manufacturer, value: manufacturer._id, label: manufacturer.name });
            }
        });
        setManufacturerOptions(opts);
    }, [manufacturers]);


    const addService = (e) => {
        e.preventDefault();
        const service = {
            manufactures: manufacturer.obj,
            repairCategories: repairCategory.obj,
            devices: device.obj,
            problem : problem.obj,
            warrantyNumber,
            warrantyType,
            description,
            averageJobTime: `${days}d ${hours}h ${minuts}m`,
            retailPrice,
            costPrice,
            wholePrice,
            taxes: taxClass.obj,
            isTaxInclusive,
            isCommission,
            commissionPercentage,
            commissionAmount,
            enableLoyalty,
            loyaltyType,
            loyaltyAmount
        }
        console.log(service);
        dispatch(addRepairServiceAction(service));
        // navigate('/admin/repair-services');
    }

    return (
        <>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="breadcrumbs-top py-4">
                {/* <h5 className="content-header-title float-left pr-1 mb-0">
                    Add New Repair Service
                </h5> */}
                <div className="breadcrumb-wrapper d-none d-sm-block">
                    <ol className="breadcrumb breadcrumb-style1 p-0 mb-0 pl-1">
                        <li className="breadcrumb-item">
                            <Link to="/admin">
                                <i className="bx bx-home-alt" />
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/admin/repair-services">Repair Services</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <a href="javascript:void(0);">Add New</a>
                        </li>
                    </ol>
                </div>
            </div>
            <section id="basic-horizontal-layouts">
                <form className="form form-horizontal" onSubmit={addService}>
                    <div className="form-body">
                        <div className="row match-height">
                            <div className="col-md-12 col-12">
                                <div className="card">
                                    <div className="card-header">
                                    <h5 className="card-title">
                                        Add New Repair Service
                                    </h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label>
                                                            Category
                                                        </label>
                                                    </div>
                                                    <div className="col-md-8 form-group">
                                                        <Select
                                                            required
                                                            value={repairCategory}
                                                            onChange={(e) => setRepairCategory(e)}
                                                            options={repairCategoryOptions}
                                                            className ="mb-4"
                                                        />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label>
                                                            Manufacturer
                                                        </label>
                                                    </div>
                                                    <div className="col-md-8 form-group">
                                                        <Select 
                                                            required
                                                            value={manufacturer}
                                                            onChange={(e) => setManufacturer(e)}
                                                            options={manufacturerOptions}
                                                            className="mb-4"
                                                        />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label>
                                                            Device / Model
                                                        </label>
                                                    </div>
                                                    <div className="col-md-8 form-group">
                                                        <Select
                                                            required
                                                            value={device}
                                                            onChange={(e) => setDevice(e)}
                                                            options={deviceOptions}
                                                            className="mb-4"
                                                        />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label>
                                                            Problem
                                                        </label>
                                                    </div>
                                                    <div className="col-md-8 form-group">
                                                    <Select
                                                            required
                                                            value={problem}
                                                            onChange={(e) => setProblem(e)}
                                                            options={problemOptions}
                                                            className="mb-4"
                                                        />
                                                    </div>
                                                    {/* <div className="col-md-4">
                                                        <label>
                                                            Create Bundle
                                                        </label>
                                                    </div>
                                                    <div className="col-md-8 form-group">
                                                        <Select
                                                            isMulti={true}
                                                            required
                                                            value={repairService}
                                                            onChange={(e) => setRepairService(e)}
                                                            options={repairServiceOptions}
                                                        />
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label>
                                                            Warranty
                                                        </label>
                                                    </div>
                                                    <div className="col-md-8 form-group">
                                                        <input
                                                            type="number"
                                                            style={{ width: '30%', display: 'inline' }}
                                                            className="mb-4 form-control me-2"
                                                            required
                                                            value={warrantyNumber}
                                                            onChange={(e) => { setWarrantyNumber(e.target.value) }}
                                                        />
                                                        <select
                                                            value={warrantyType}
                                                            onChange={(e) => setWarrantyType(e.target.value)}
                                                            style={{ width: '60%', display: 'inline' }}
                                                            className="mb-4 form-control">
                                                            {/* {warrantyTypes && warrantyTypes.map(warrantyType => <option value={warrantyType}>{warrantyType}</option>)} */}
                                                            <option value="no_warranty">No Warranty</option>
                                                            <option value="days">Days</option>
                                                            <option value="months">Months</option>
                                                            <option value="years">Years</option>
                                                            <option value="lifetime">Lifetime</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label>
                                                            Average Job Time
                                                        </label>
                                                    </div>
                                                    <div className="col-md-8 form-group">
                                                        <input
                                                            style={{ width: '29%', display: 'inline' }}
                                                            type="number"
                                                            className="mb-4 form-control mr-1"
                                                            placeholder="Days"
                                                            required
                                                            value={days}
                                                            onChange={(e) => { setDays(e.target.value) }}
                                                        />
                                                        <input
                                                            style={{ width: '29%', display: 'inline' }}
                                                            type="number"
                                                            className="mb-4 form-control mr-1"
                                                            placeholder="Hours"
                                                            required
                                                            value={hours}
                                                            onChange={(e) => { setHours(e.target.value) }}
                                                        />
                                                        <input
                                                            style={{ width: '29%', display: 'inline' }}
                                                            type="number"
                                                            className="mb-4 form-control mr-1"
                                                            placeholder="Minutes"
                                                            required
                                                            value={minuts}
                                                            onChange={(e) => { setMinutes(e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label>
                                                            Description
                                                        </label>
                                                    </div>
                                                    <div className="col-md-8 form-group">
                                                        <textarea
                                                            className="mb-4 form-control"
                                                            rows="3"
                                                            placeholder="Description"
                                                            required
                                                            value={description}
                                                            onChange={(e) => { setDescription(e.target.value) }}
                                                        >
                                                        </textarea>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-12 my-2">

                                <div className="card">
                                    <div className="card-header">
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label>
                                                            Retail Price
                                                        </label>
                                                    </div>
                                                    <div className="col-md-8 form-group">
                                                        <input
                                                            type="number"
                                                            className="mb-4 form-control"
                                                            required
                                                            value={retailPrice}
                                                            onChange={(e) => setRetailPrice(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="col-md-4">
                                                        <label>
                                                            Cost Price
                                                        </label>
                                                    </div>
                                                    <div className="col-md-8 form-group">
                                                        <input
                                                            type="number"
                                                            className="mb-4 form-control"
                                                            required
                                                            value={costPrice}
                                                            onChange={(e) => setCostPrice(e.target.value)}
                                                        />
                                                    </div>

                                                    <div className="col-md-4">
                                                        <label>
                                                            Whole Price
                                                        </label>
                                                    </div>
                                                    <div className="col-md-8 form-group">
                                                        <input
                                                            type="number"
                                                            className="mb-4 form-control"
                                                            required
                                                            value={wholePrice}
                                                            onChange={(e) => setWholePrice(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label>
                                                            Tax Class
                                                        </label>
                                                    </div>
                                                    <div className="col-md-8 form-group mb-4">
                                                        <Select
                                                            required
                                                            value={taxClass}
                                                            onChange={(e) => setTaxClass(e)}
                                                            options={taxClassOptions}
                                                        />
                                                    </div>

                                                    <div className="col-md-4">
                                                    </div>
                                                    <div className="col-md-8 form-group">
                                                        <fieldset>
                                                            <div class="checkbox me-2">
                                                                <input type="checkbox" class="form-check-input checkbox-input me-1" id="checkbox2" defaultChecked={isTaxInclusive}
                                                                    onChange={() => setIsTaxInclusive(!isTaxInclusive)} />
                                                                <label for="checkbox2">Tax Inclusive</label>
                                                            </div>

                                                            <div class="checkbox me-2">
                                                                <input type="checkbox" class="form-check-input checkbox-input me-1" id="checkbox1" defaultChecked={isCommission}
                                                                    onChange={() => setIsCommission(!isCommission)} />
                                                                <label for="checkbox1">Commission</label>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                    <div style={isCommission ? { display: 'block' } : { display: 'none' }} className="col-md-4">
                                                        <label>
                                                            Percentage (%)
                                                        </label>
                                                    </div>
                                                    <div style={isCommission ? { display: 'block' } : { display: 'none' }} className="col-md-8 form-group">
                                                        <input
                                                            type="number"
                                                            className="mb-4 form-control"
                                                            required
                                                            value={commissionPercentage}
                                                            onChange={(e) => setCommissionPercentage(e.target.value)}
                                                        />
                                                    </div>
                                                    <div style={isCommission ? { display: 'block' } : { display: 'none' }} className="col-md-4">
                                                        <label>
                                                            Amount (£)
                                                        </label>
                                                    </div>
                                                    <div style={isCommission ? { display: 'block' } : { display: 'none' }} className="col-md-8 form-group">
                                                        <input
                                                            type="number"
                                                            className="mb-4 form-control"
                                                            required
                                                            value={commissionAmount}
                                                            onChange={(e) => setCommissionAmount(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-12">
                                <div className="card">
                                    <div className="card-header">
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6 form-group">
                                                <div className="custom-control custom-switch custom-control-inline mb-1">
                                                    <input type="checkbox" className="form-check-input custom-control-input" defaultChecked={enableLoyalty} id="customSwitch1"
                                                        onChange={() => setEnableLoyalty(!enableLoyalty)} />
                                                    <label className="mx-1 custom-control-label me-1" htmlFor="customSwitch1">
                                                    </label>
                                                    <span>Enable Loyalty</span>
                                                </div>
                                            </div>
                                            <div className="col-md-6"></div>
                                            <div className="col-md-6 form-group">
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" className="form-check-input custom-control-input" name="customRadio" id="customRadio1"
                                                        value={loyaltyType} checked={loyaltyType == 'default'}
                                                        onChange={() => setLoyaltyType('default')} />
                                                    <label className="mx-1 custom-control-label" htmlFor="customRadio1">Default loyalty</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6"></div>
                                            <div className="col-md-6 form-group">
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" className="form-check-input custom-control-input" name="customRadio" id="customRadio2"
                                                        value={loyaltyType} checked={loyaltyType == 'custom'}
                                                        onChange={() => setLoyaltyType('custom')} />
                                                    <label className="mx-1 custom-control-label" htmlFor="customRadio2">Custom loyalty</label>
                                                </div>
                                                <div className="col-md-6"></div>
                                                <div style={loyaltyType == 'custom' ? { display: 'block' } : { display: 'none' }} className="col-md-10 form-group">
                                                    <span>This product will earn £ </span>
                                                    <input type="number" value={loyaltyAmount}
                                                        onChange={(e) => setLoyaltyAmount(e.target.value)} />
                                                    <span> loyalty when sold</span>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 d-flex justify-content-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary me-1"
                                                    onClick={() => navigate(-1)}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary "
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </section>
        </div>

        </>
    );
};

export default AddRepairService;
