import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { emptyMedia, uploadImage } from "../../actions/globalActions";
import { addTaxClassAction } from "../../actions/taxClassActions";
import { base_url } from "../../utils";
const AddTaxClass = () => {
    const imageRef = useRef();
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const media = useSelector(state => state.global.media);

    const [classs, setClasss] = useState("");
    const [percentage, setPercentage] = useState("");
    const [image, setImage] = useState("");
    const [button, setButton] = useState(false);

    useLayoutEffect(() => {
        dispatch(emptyMedia());
    }, []);

    useEffect(() => {
        media && setButton(true);
    }, [media])

    const AddTaxClass = (e) => {
        e.preventDefault();
        const taxClass = {
            classs,
            percentage
            // media: media.id
        }
        dispatch(addTaxClassAction(classs, percentage));
        navigate('/admin/tax-classes');
    }

    const updateImage = (e) => {
        if (e.files && e.files[0]) {
            setImage(e.files[0]);

            const data = new FormData()
            data.append('file', e.files[0], e.files[0].name);
            console.log('data', data);

            dispatch(uploadImage(data));
        }
    }

    const profileStyle = {
        width: '150px',
        height: '150px'
    }
    return (
        <>
<div className="container-xxl flex-grow-1 container-p-y">
    <div className="breadcrumbs-top">
        <div className="breadcrumb-wrapper d-none d-sm-block mb-4">
            <ol className="breadcrumb p-0 mb-0 pl-1">
                <li className="breadcrumb-item">
                    <a href="index.html">
                        <i className="bx bx-home-alt" />
                    </a>
                </li>
                <li className="breadcrumb-item">
                    <Link to="/admin/tax-classes">Tax Classes</Link>
                </li>
                <li className="breadcrumb-item active">
                    <a href="javascript:void(0);">Add New</a>
                </li>
            </ol>
        </div>
        <h5 className=" mb-1">
            Add New Tax Class
        </h5>
    </div>
    <section id="basic-horizontal-layouts">
        <div className="row match-height">
            <div className="col-md-6 col-12">
                <div className="card">
                    <div className="card-header">
                        {/* <h4 className="card-title">
                            Horizontal Form
                        </h4> */}
                    </div>
                    <div className="card-body">
                        <form className="form form-horizontal" onSubmit={AddTaxClass}>
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label>
                                            Tax Class
                                        </label>
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <input
                                            type="text"
                                            id="class"
                                            className="mb-4 form-control"
                                            name="class"
                                            placeholder="Tax Class"
                                            required
                                            value={classs}
                                            onChange={(e) => { setClasss(e.target.value) }}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label>
                                            Tax Percentage
                                        </label>
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <input
                                            type="text"
                                            id="percentage"
                                            className="mb-4 form-control"
                                            name="percentage"
                                            placeholder="Tax Percentage"
                                            required
                                            value={percentage}
                                            onChange={(e) => { setPercentage(e.target.value) }}
                                        />
                                    </div>
                                    {/* <div className="col-md-4">
                                        <label>Picture</label>
                                    </div>
                                    <div className="col-md-8">
                                        <img style={profileStyle} src={media ? base_url + "media/" + media.filename : "/app-assets/images/dummy.jpg"} />
                                            </div>
                                    <div className="col-md-4">
                                        <label></label>
                                    </div>
                                    <div className="col-md-8">
                                        <fieldset class="form-group">
                                            <input type="file" class="mb-4 form-control-file" value={image.value} required
                                            onChange={(e) => {updateImage(e.target); setButton(false) }} />
                                        </fieldset>
                                    </div> */}
                                    <div className="col-sm-12 d-flex justify-content-end">
                                        <button
                                            type="button"
                                            className="btn btn-primary me-1"
                                            onClick={() => navigate(-1)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
    
        </>
    );
};

export default AddTaxClass;
