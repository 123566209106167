import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { GetRecordsCount } from "../../actions/globalActions";
import { listOrdersAction } from "../../actions/orderActions";
import { deleteRepairCategoryAction, listRepairCategories } from "../../actions/repairCategoriesActions";
import Pagination from "../elements/Pagination";

const ListOrders = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const orders = useSelector((state) => state.order.orders);
    const [modal, setModal] = useState(false);
    const [delId, setDelId] = useState(null);

    useEffect(() => {
        dispatch(GetRecordsCount('orders'));
        dispatch(listOrdersAction());


    }, []);

    const updateRecord = (val) => {
        dispatch(listOrdersAction(val));
    }

    const styleShow = {
        display: 'block',
        paddingRight: '17px'
    }

    const styleHide = {
        display: 'none',
        paddingRight: '17px'
    }

    const deleteRepairCategory = () => {
        delId && dispatch(deleteRepairCategoryAction(delId));
        setModal(false);
    }

    console.log('..', orders);

    return (
        <>

            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="breadcrumbs-top ">
                    <div className="breadcrumb-wrapper d-none d-sm-block mb-4">
                        <ol className="breadcrumb p-0 mb-0 pl-1">
                            <li className="breadcrumb-item">
                                <Link to="/admin">
                                    <i className="bx bx-home-alt" />admin
                                </Link>
                            </li>
                            <li className="breadcrumb-item active">
                                List All
                            </li>
                        </ol>
                    </div>
                    <div class="col-12 d-flex justify-content-end" style={{ top: '-40px' }}>
                        {/* <button onClick={() => { navigate('add') }} type="button" class="btn btn-primary mr-1">Add New</button> */}
                    </div>
                </div>
                <h5 className="">
                        Repair Orders
                </h5>
                <div className="row" id="basic-table">
                            <div className="col-12">
                                <div className="card">
                                    {/* <div className="card-header">
                                        <h4 className="card-title">
                                            Basic Tables
                                        </h4>
                                    </div> */}
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        {/* <th>
                                                            <input type="checkbox" />
                                                        </th> */}
                                                        {/* <th>Category</th>
                                                        <th>Manufacturer</th> */}
                                                        <th>Device</th>
                                                        <th>Service</th>
                                                        <th>Price</th>
                                                        <th>User</th>
                                                        <th>Date</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {orders.length > 0 && orders.map((order, key) => {
                                                        return  !order.isDeleted && <tr key={key}>
                                                            {/* <td className="text-bold-500">
                                                                <input type="checkbox" />
                                                            </td> */}
                                                            {/* <td>{order?.repairCategories?.name}</td>
                                                            <td>{order?.manufactures?.name}</td> */}
                                                            <td>{order?.devices?.name}</td>
                                                            <td>{order?.repairServices?.map(service => service.problem.name).toString()}</td>
                                                            <td>£{order?.repairServices?.reduce((previousValue, currentValue) => previousValue + Number(currentValue.retailPrice), 0)}</td>
                                                            <td className="text-bold-500">
                                                                {order?.orderDetails?.firstName + " " + order?.orderDetails?.lastName}
                                                            </td>
                                                            <td>
                                                                {(new Date(order?.createdAt))?.toDateString()}
                                                            </td>
                                                            <td>{order?.orderStatus}</td>
                                                            {/* <td className="text-bold-500">
                                                                <input type="checkbox" />
                                                            </td>
                                                            <td className="text-bold-500">
                                                                <input type="checkbox" />
                                                            </td> */}
                                                            <td>
                                                                <Link to={`/admin/orders/${order._id}`}>
                                                                    <i className="badge-circle badge-circle-light-secondary bx bx-show font-medium-1" />
                                                                </Link>
                                                                {/* <a href="javascript:void(0);" onClick={() => { setModal(true); setDelId(order._id)}}>
                                                                    <i className="badge-circle badge-circle-light-secondary bx bx-trash font-medium-1" />
                                                                </a> */}
                                                            </td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        
                                    </div>
        
                                    <div className="card-footer">
                                    <Pagination currentItems={orders} updateRecord={updateRecord} />
                                    </div>
                                </div>
                            </div>
                </div>
            </div>
            <div className={`modal fade text-left ${modal ? 'show' : ''}`} id="danger" tabIndex={-1} aria-labelledby="myModalLabel120" style={modal ? styleShow : styleHide} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-danger">
                            <h5 className="modal-title white" id="myModalLabel120">Danger!</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                            onClick={() => {setModal(false)}}>
                                <i className="bx bx-x" />
                            </button>
                        </div>
                        <div className="modal-body">
                            Are you sure! You want to delete this Repair Category?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light-secondary" data-dismiss="modal"
                            onClick={() => {setModal(false)}}>
                                <i className="bx bx-x d-block d-sm-none" />
                                <span className="d-none d-sm-block">Cancel</span>
                            </button>
                            <button type="button" className="btn btn-danger ml-1" data-dismiss="modal"
                            onClick={() => {deleteRepairCategory(delId)}}>
                                <i className="bx bx-check d-block d-sm-none" />
                                <span className="d-none d-sm-block">Accept</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ListOrders;
