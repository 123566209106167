import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link, useNavigate } from "react-router-dom";
import { loginUser } from "../../actions/authActions";
import { emptyAlertMessage, emptyErrors } from "../../actions/globalActions";
import Alert from "../elements/Alert";

const Signin = () => {
    let dispatch = useDispatch();
    let history = useNavigate ();
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const login = useSelector(state => state.auth.login);

    useLayoutEffect(() => {
        if (localStorage.usertoken) {
            history("/admin")
        }
        dispatch(emptyAlertMessage());
    }, []);

    useEffect(() => {
        if (localStorage.usertoken) {
            history("/admin")
        }
    }, [login]);
    

    const submitForm = (e) => {
        e.preventDefault();
        dispatch(emptyErrors());
        dispatch(emptyAlertMessage());

        const login_user = {
            password,
            email
        };
        dispatch(loginUser(login_user));

    };

    return (
        <>
        <div className="authentication-wrapper authentication-cover">
        <div className="authentication-inner row m-0">
           <div className="container my-5 position-absolute">
           <Alert />
           </div>
            <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center">
            <div className="flex-row text-center mx-auto">
                <img src="../../assets/img/pages/login-light.png" alt="Auth Cover Bg color" width={520} className="img-fluid authentication-cover-img" data-app-light-img="pages/login-light.png" data-app-dark-img="pages/login-dark.png" />
                <div className="mx-auto">
                {/* <h3>Discover the powerful admin template 🥳</h3> */}
                {/* <h3>Mobile phone repair shop in Edinburgh, Scotland</h3> */}
                <h3>Mr fix: iPhone|iPad|Screen Repair|Unlocking</h3>
                {/* <p>
                    Perfectly suited for all level of developers which helps you to <br />
                    kick start your next big projects &amp; Applications.
                </p> */}
                <p>
                Mr. Fixs is the smart and secure way to get your iPhone, ipad, tablets screen repaired and unlocking by Certified Technicians in Edinburgh, Milton Keynes.
                </p>
                </div>
            </div>
            </div>
          
            <div className="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg p-sm-5 p-4">
            <div className="w-px-400 mx-auto">
               
                <div className="app-brand mb-4">
                <a href="index.html" className="app-brand-link gap-2 mb-2">
                    <span className="app-brand-logo demo">
                    <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <title>icon</title>
                        <defs>
                        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
                            <stop stopColor="#5A8DEE" offset="0%" />
                            <stop stopColor="#699AF9" offset="100%" />
                        </linearGradient>
                        <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="linearGradient-2">
                            <stop stopColor="#FDAC41" offset="0%" />
                            <stop stopColor="#E38100" offset="100%" />
                        </linearGradient>
                        </defs>
                        <g id="Pages" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Login---V2" transform="translate(-667.000000, -290.000000)">
                            <g id="Login" transform="translate(519.000000, 244.000000)">
                            <g id="Logo" transform="translate(148.000000, 42.000000)">
                                <g id="icon" transform="translate(0.000000, 4.000000)">
                                <path d="M13.8863636,4.72727273 C18.9447899,4.72727273 23.0454545,8.82793741 23.0454545,13.8863636 C23.0454545,18.9447899 18.9447899,23.0454545 13.8863636,23.0454545 C8.82793741,23.0454545 4.72727273,18.9447899 4.72727273,13.8863636 C4.72727273,13.5423509 4.74623858,13.2027679 4.78318172,12.8686032 L8.54810407,12.8689442 C8.48567157,13.19852 8.45300462,13.5386269 8.45300462,13.8863636 C8.45300462,16.887125 10.8856023,19.3197227 13.8863636,19.3197227 C16.887125,19.3197227 19.3197227,16.887125 19.3197227,13.8863636 C19.3197227,10.8856023 16.887125,8.45300462 13.8863636,8.45300462 C13.5386269,8.45300462 13.19852,8.48567157 12.8689442,8.54810407 L12.8686032,4.78318172 C13.2027679,4.74623858 13.5423509,4.72727273 13.8863636,4.72727273 Z" id="Combined-Shape" fill="#4880EA" />
                                <path d="M13.5909091,1.77272727 C20.4442608,1.77272727 26,7.19618701 26,13.8863636 C26,20.5765403 20.4442608,26 13.5909091,26 C6.73755742,26 1.18181818,20.5765403 1.18181818,13.8863636 C1.18181818,13.540626 1.19665566,13.1982714 1.22574292,12.8598734 L6.30410592,12.859962 C6.25499466,13.1951893 6.22958398,13.5378796 6.22958398,13.8863636 C6.22958398,17.8551125 9.52536149,21.0724191 13.5909091,21.0724191 C17.6564567,21.0724191 20.9522342,17.8551125 20.9522342,13.8863636 C20.9522342,9.91761479 17.6564567,6.70030817 13.5909091,6.70030817 C13.2336969,6.70030817 12.8824272,6.72514561 12.5388136,6.77314791 L12.5392575,1.81561642 C12.8859498,1.78721495 13.2366963,1.77272727 13.5909091,1.77272727 Z" id="Combined-Shape2" fill="url(#linearGradient-1)" />
                                <rect id="Rectangle" fill="url(#linearGradient-2)" x={0} y={0} width="7.68181818" height="7.68181818" />
                                </g>
                            </g>
                            </g>
                        </g>
                        </g>
                    </svg>
                    </span>
                    <span className="app-brand-text demo h3 mb-0 fw-bold">MrFix</span>
                </a>
                </div>
               
                <h4 className="mb-2">Welcome to MrFix! 👋</h4>
                <p className="mb-4">Please sign-in to your account and start the adventure</p>
                
                <form onSubmit={submitForm}>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email or Username</label>
                     {/* <input type="text" className="form-control" id="email" name="email-username" placeholder="Enter your email or username" autofocus /> */}
                    <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Email address" value={email}onChange={(e) =>setEmail(e.target.value
                     )}/>
                </div>
                <div className="mb-3 form-password-toggle">
                    <div className="d-flex justify-content-between">
                    <label className="form-label" htmlFor="password">Password</label>
                    {/* <a href="auth-forgot-password-cover.html">
                        <small>Forgot Password?</small>
                    </a> */}
                    </div>
                    <div className="input-group input-group-merge">
                     {/* <input type="password" id="password" className="form-control" name="password" placeholder="············" aria-describedby="password" />  */}
                    <input type="password"  className="form-control"   id="exampleInputPassword1"  placeholder="Password" value={password} onChange={(e) => setPassword( e.target.value)} />
                    <span className="input-group-text cursor-pointer"><i className="bx bx-hide" /></span>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="remember-me" />
                    <label className="form-check-label" htmlFor="remember-me"> Remember Me </label>
                    </div>
                </div>
                <button className="btn btn-primary d-grid w-100" type="submit">Sign in</button>
                </form>

                {/* <p className="text-center">
                <span>New on our platform?</span>
                <Link to="/signup">
                     <span>Create an account</span>
                </Link>
                </p> */}
                {/* <div className="divider my-4">
                <div className="divider-text">or</div>
                </div>
                <div className="d-flex justify-content-center">
                <a href="javascript:;" className="btn btn-icon btn-label-facebook me-3">
                    <i className="tf-icons bx bxl-facebook" />
                </a>
                <a href="javascript:;" className="btn btn-icon btn-label-google-plus me-3">
                    <i className="tf-icons bx bxl-google-plus" />
                </a>
                <a href="javascript:;" className="btn btn-icon btn-label-twitter">
                    <i className="tf-icons bx bxl-twitter" />
                </a>
                </div> */}
            </div>
            </div>
           
        </div>
        </div>
        </>
    );
};

export default Signin;
