import axios from "axios";
import { alertMessage, base_url, check_response_status } from "../utils";
import { GET_STORE, LIST_STORES, UPDATED } from "./types";

// list  stores
export const listStores = () => async dispatch => {

    axios.get(`${base_url}stores`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                // console.log(' stores', response.data);
                dispatch({
                    type: LIST_STORES,
                    payload: response.data
                });
            }
            else if (response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//get  store
export const getStore = (id) => async dispatch => {
    axios.get(`${base_url}stores/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                // console.log(' stores', response.data);
                dispatch({
                    type: GET_STORE,
                    payload: response.data
                });
            }
            else if (response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

// add  store
export const addStoreAction = (store) => async dispatch => {

    axios.post(`${base_url}stores`,
        store,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log(' store', response);
            if (response.status == 201) {
                dispatch(alertMessage(response.data));
                dispatch(listStores());
                dispatch({
                    type: UPDATED,
                    payload: true
                });
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//update Device Color
export const updateStoreAction = (id, store) => async dispatch => {
    axios.put(`${base_url}stores/${id}`,
        store,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log(' store', response);
            if (response.status == 200) {
                dispatch(alertMessage({ flag: 'success', message: "Store Upadated Successfully." }));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

export const deleteStoreAction = (id) => async dispatch => {
    // console.log(' store', id)
    axios.delete(`${base_url}stores/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log('delete', response);
            if (response.status == 200) {
                dispatch(alertMessage({ flag: 'success', message: response.data.message }));
                dispatch(listStores());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}