import { useEffect, useLayoutEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { listTaxClasses } from "../../../../actions/taxClassActions";

import { addCustomerAction, addEmployeeAction } from "../../../../actions/employeeActions";

const AddCustomer = () => { 
    const dispatch = useDispatch();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [address,setAddress] = useState("")
    const [zip,setZip] = useState("")
    const [city,setCity] = useState("")
    const [state,setState] = useState("")
    const [hearUs,setHearUs] = useState("")


    const handleCustomer = (e) => {
        e.preventDefault()
        const newCustomer = {
            email,
            firstName,
            lastName,
            mobile,
            address,
            city,
            postCode:zip,
            hearUs,
            password: mobile,
            userType: 'CUSTOMER'
        };
        console.log(newCustomer, "new customer");
        dispatch(addCustomerAction(newCustomer));
    }

    return (
      <div className="modal modal-center fade" id="modalTop" tabIndex={-1}>
        <div className="modal-dialog">
          <form className="modal-content" >
            <div className="modal-header">
              <h5 className="modal-title fw-900" id="modalTopTitle">
                <a href="javascript:viod(0)">
                    Add New Customer    
                </a>
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
                <div className="row mb-4"> 
                    <div className="col-lg-6"> 
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" placeholder="First Name" value={firstName} onChange={(e)=>setFirstName(e.target.value)} />
                    </div>
                    <div className="col-lg-6"> 
                    <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    </div>
                    <div className="col-lg-6">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="name@example.com" value={email} onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                    <div className="col-lg-6">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control type="tel" placeholder="Phone" value={mobile} onChange={(e) => setMobile(e.target.value)}/>
                    </div>
                </div>
                <Accordion  defaultActiveKey="0">
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            <h5>
                                <a href="javascript:viod(0)">More Details</a>
                            </h5>
                        </Accordion.Header>
                        <Accordion.Body>    
                            <div className="row"> 
                                <div className="col-lg-6"> 
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Address" value={address} onChange={(e) => setAddress(e.target.value)}/>
                                </div>
                                <div className="col-lg-6"> 
                                    <Form.Label>Postcode</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Postcode" value={zip} onChange={(e) => setZip(e.target.value)}/>
                                </div>
                                <div className="col-lg-6"> 
                                    <Form.Label>City</Form.Label>
                                    <Form.Control type="text" placeholder="Enter City Name" value={city} onChange={(e) => setCity(e.target.value)}/>
                                </div>
                                <div className="col-lg-6"> 
                                    <Form.Label>State</Form.Label>
                                    <Form.Control type="text" placeholder="Enter State Name" value={state} onChange={(e) => setState(e.target.value)}/>
                                </div>      
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <div className="text-center">
                <input type="submit" value="Add Customer" className="btn btn-primary text-center" onClick={handleCustomer} />
                </div>
            </div>
          </form>
        </div>
      </div>
    );
}

export default AddCustomer;