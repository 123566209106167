import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select';
import { emptyMedia, uploadImage } from "../../actions/globalActions";
import { addManufacturerAction } from "../../actions/manufacturerActions";
import { listRepairCategories } from "../../actions/repairCategoriesActions";
import { base_url } from "../../utils";

const AddManufacturer = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const media = useSelector(state => state.global.media);
    const repairCategories = useSelector(state => state.repairCategory.repairCategories);

    const [repairCategoryOptions, setRepairCategoryOptions] = useState([]);
    const [repairCategory, setRepairCategory] = useState("");

    const [name, setName] = useState("");
    const [slug, setSlug] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState("");
    const [button, setButton] = useState(false);

    useLayoutEffect(() => {
        dispatch(listRepairCategories());
        dispatch(emptyMedia());
    }, []);

    useEffect(() => {
        const opts = [];
        repairCategories && repairCategories.map(repairCategory => {
            if (!repairCategory.isDeleted) {
                opts.push({ obj: repairCategory, value: repairCategory._id, label: repairCategory.name });
            }
        });
        setRepairCategoryOptions(opts);
    }, [repairCategories]);

    useEffect(() => {
        media && setButton(true);
    }, [media])

    const addManufacturer = (e) => {
        e.preventDefault();
        const manufacturer = {
            repairCategories: repairCategory.obj,
            name,
            slug,
            description,
            media
        }
        dispatch(addManufacturerAction(manufacturer));
        navigate('/admin/manufacturers');
    }

    const updateImage = (e) => {
        if (e.files && e.files[0]) {
            setImage(e.files[0]);
            const data = new FormData()
            data.append('file', e.files[0], e.files[0].name);

            dispatch(uploadImage(data));
        }
    }

    const profileStyle = {
        width: '150px',
        height: '150px'
    }
    return (
        <>
<div className="container-xxl flex-grow-1 container-p-y">
    <div className="breadcrumbs-top">
        <div className="breadcrumb-wrapper d-none d-sm-block mb-4">
            <ol className="breadcrumb p-0 mb-0 pl-1">
                <li className="breadcrumb-item">
                    <Link to="/admin">
                        <i className="bx bx-home-alt" />
                    </Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to="/admin/manufacturers">Manufacturers</Link>
                </li>
                <li className="breadcrumb-item active">
                    <a href="javascript:void(0);">Add New</a>
                </li>
            </ol>
        </div>
        <h5 className="mb-2">
            Add New Manufacturer
        </h5>
    </div>

    <section id="basic-horizontal-layouts">
        <div className="row match-height">
            <div className="col-md-6 col-12">
                <div className="card">
                    <div className="card-header">
                        {/* <h4 className="card-title">
                            Horizontal Form
                        </h4> */}
                    </div>
                    <div className="card-body">
                        <form className="form form-horizontal" onSubmit={addManufacturer}>
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label>
                                            Category
                                        </label>
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <Select
                                            required
                                            value={repairCategory}
                                            onChange={(e) => {setRepairCategory(e); console.log(e)}}
                                            options={repairCategoryOptions} 
                                            className="mb-4"
                                            
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label>
                                            Name
                                        </label>
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <input
                                            type="text"
                                            id="name"
                                            className="mb-4 form-control"
                                            name="name"
                                            placeholder="Name"
                                            required
                                            value={name}
                                            onChange={(e) => { setName(e.target.value) }}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label>
                                            Slug
                                        </label>
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <input
                                            type="text"
                                            className="mb-4 form-control"
                                            placeholder="Slug"
                                            required
                                            value={slug}
                                            onChange={(e) => { setSlug(e.target.value) }}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label>
                                            Description
                                        </label>
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <textarea
                                            type="text"
                                            className="mb-4 form-control"
                                            placeholder="Description"
                                            rows={4}
                                            required
                                            value={description}
                                            onChange={(e) => { setDescription(e.target.value) }}
                                        ></textarea>
                                    </div>
                                    <div className="col-md-4">
                                        <label>Picture</label>
                                    </div>
                                    <div className="col-md-8 mb-1">
                                        <img style={profileStyle} src={media ? base_url + "media/" + media.filename : "/app-assets/images/dummy.jpg"} alt={name} />
                                    </div>
                                    <div className="col-md-4">
                                        <label></label>
                                    </div>
                                    <div className="col-md-8">
                                        <div class="button-wrapper">
                                            <label for="upload" class="btn btn-primary me-2 mb-4" tabindex="0">
                                                <span class="d-none d-sm-block">Upload new photo</span>
                                                <i class="bx bx-upload d-block d-sm-none"></i>
                                                <input
                                                type="file"
                                                id="upload"
                                                class="account-file-input"
                                                value={image.value} required
                                                hidden
                                                accept="image/png, image/jpeg"
                                                onChange={(e) => { updateImage(e.target); setButton(false) }}
                                                />
                                            </label>
                                            {/* <button type="button" class="btn btn-label-secondary account-image-reset mb-4">
                                                <i class="bx bx-reset d-block d-sm-none"></i>
                                                <span class="d-none d-sm-block">Reset</span>
                                            </button> */}

                                            {/* <p class="mb-0">Allowed JPG, GIF or PNG. Max size of 800K</p> */}
                                            </div>
                                    </div>
                                    <div className="col-sm-12 d-flex justify-content-end">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => navigate(-1)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary mx-1"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>

        </>
    );
};

export default AddManufacturer;
