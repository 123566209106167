import { GET_PROBLEM, LIST_PROBLEMS } from "../actions/types";

const initialState = {
    problems: [],
    error: null,
    problem: null
};
export default (state = initialState, { type, payload }) => {

    switch (type) {
        case LIST_PROBLEMS: 
        return {
            ...state,
            problems: payload
        }
        case GET_PROBLEM:
            return {
                ...state,
                problem: payload
            }
        default:
            return state;
    }
}
