import "./inquiry.css"
import img1 from "./images/inq-img1.png"
import img2 from "./images/inq-img2.png"
import img3 from "./images/inq-img3.png"
import img4 from "./images/inq-img4.png"
import img5 from "./images/inq-img5.png"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { deleteInquiryAction, listInquiries } from "../../../actions/inquiryActions"
import { useEffect, useState } from "react"
import { GetRecordsCount } from "../../../actions/globalActions"
import Pagination from "../../elements/Pagination"
import AddInquiry from "./AddInquiry"
import axios from "axios"
import { base_url } from "../../../utils"

const ListInquiries = () => {
    const dispatch = useDispatch();
    // const navigate = useNavigate();

    const inquiries = useSelector((state) => state.inquiry.inquiries);
    const [statuses, setStatuses] = useState({ open: 0, new: 0, closed: 0, cancelled: 0 });
    const [modal, setModal] = useState(false);
    const [delId, setDelId] = useState(null);

    useEffect(() => {
        dispatch(GetRecordsCount('inquiries'));
        dispatch(listInquiries());

        axios.get(`${base_url}inquiries/chartData`,
            {
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            })
            .then(response => {
                console.log("Social Status", response.data.inquiryStatus)
                const statuses1 = { open: 0, new: 0, closed: 0, cancelled: 0 }
                response.data.inquiryStatus.map(status => {
                    statuses1[status._id] = status.count;
                })
                setStatuses(statuses1);

            })
            .catch(err => {
                console.log(err)
            });
    }, []);

    const updateRecord = (val) => {
        dispatch(listInquiries(val));
    }


    const deleteInquiry = () => {
        console.log(delId);
        delId && dispatch(deleteInquiryAction(delId));
        setModal(false);
    }

    const styleShow = {
        display: 'block',
        paddingRight: '17px'
    }

    const styleHide = {
        display: 'none',
        paddingRight: '17px'
    }

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y ">
                <nav aria-label="breadcrumb" className="">
                    <ol className="breadcrumb p-1">
                        <li className="breadcrumb-item">
                            <Link to="/admin">Admin</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/admin/inquiries">Inquiries</Link>
                        </li>
                    </ol>
                </nav>
                <div className="d-sm-flex d-block justify-content-end mb-1">
                    {/* <h4 className="">
                        Manage Inquiries
                    </h4> */}
                    <div className="d-sm-flex">
                        {/* <a href="#" className="btn btn-primary d-sm-flex d-block align-items-center">
                            <span><i className='bx bx-spreadsheet'></i>&nbsp;Export</span>
                        </a>
                        &nbsp; */}
                        <a href="#" className="btn btn-success d-sm-flex d-block align-items-center" data-bs-toggle="modal" data-bs-target="#modalTop">

                            <span> <i className='bx bxs-plus-circle'></i>&nbsp;Create Inquiry</span>
                        </a>
                        &nbsp;
                        <AddInquiry />


                        {/* <a href="#" className="btn d-sm-flex d-block btn-primary dropdown-toggle align-items-center" data-bs-toggle="dropdown" aria-expanded="false">
                            Action&nbsp;
                        </a>
                        <ul className="dropdown-menu">
                            <li><a className="dropdown-item" href="#">Action</a>
                            </li>
                        </ul> */}

                    </div>
                </div>
                {/* <div className="row bg-white my-2 py-2">
                    <div className="col-sm-8">
                        <form>
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="basic-default-fullname">Inquiry ID
                                        </label>
                                        <input type="text" className="form-control" id="basic-default-fullname" placeholder="Inquiry Id" />
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="basic-default-fullname">Customer Name
                                        </label>
                                        <input type="text" className="form-control" id="basic-default-fullname" placeholder="Customer Name" />
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div>
                                        <label className="form-label" htmlFor="formtabs-birthdate">Created Date</label>
                                        <input type="date" id="formtabs-birthdate" className="form-control dob-picker flatpickr-input active" placeholder="YYYY-MM-DD" />
                                    </div>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="select2Basic" className="form-label">Inquiry Status</label>
                                    <select id="select2Basic" className="form-control" placeholder="Select inquiry status">
                                        <option value="">Select inquiry</option>
                                        <option value="new">New</option>
                                        <option value="open">Open</option>
                                        <option value="close">Closed</option>
                                        <option value="cancelled">Cancelled</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                    <div className="col-md-3 mb-4">
                        <label htmlFor="select2Basic" className="form-label">Select Criteria</label>
                        <select id="select2Basic" className="select2 form-select form-select-lg form-control"  placeholder="Select inquiry status">
                        <option value="">Select Criteria</option>
                        <option value="createdBy">Created By.</option>
                        </select>
                        </div>
                    </div>

                        </form>
                    </div>
                    <div className="col-sm-4 d-flex justify-content-center align-items-center">
                        <div className="">
                            <a href="#" className="btn btn-outline-primary  px-5">
                                Search
                            </a>
                            <a href="#" className="btn btn-outline-primary mx-2">
                                <i class='bx bx-refresh '></i>
                            </a>
                            <div className="my-2">
                                <input type="checkbox" name="" id="" className="form-controle" /><a href="#"> Hide Closed Inquiries</a>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-6">
                        <div className="card border bg-white">
                            <div className="card-body text-center">
                                <div className="mb-1">
                                    <img src={img1} alt="" />
                                </div>
                                <span className="d-block mb-1">New Inquiries </span>
                                <h2 className="mb-0">{statuses?.new}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-6">
                        <div className="card border bg-white">
                            <div className="card-body text-center">
                                <div className="mb-1">
                                    <img src={img2} alt="" />
                                </div>
                                <span className="d-block mb-1">Open Inquiries </span>
                                <h2 className="mb-0">{statuses?.open}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-6">
                        <div className="card border bg-white">
                            <div className="card-body text-center">
                                <div className="mb-1">
                                    <img src={img4} alt="" />
                                </div>
                                <span className="d-block mb-1">Closed Inquiries </span>
                                <h2 className="mb-0">{statuses?.closed}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-6">
                        <div className="card border bg-white">
                            <div className="card-body text-center">
                                <div className="mb-1">
                                    <img src={img3} alt="" />
                                </div>
                                <span className="d-block mb-1">Cancelled Inquiries</span>
                                <h2 className="mb-0">{statuses?.cancelled}</h2>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6">
                        <div className="inquiry-box card border bg-white">
                            <div className="card-body text-center px-0   ">
                                <div className="mb-1">
                                    <img src={img5} alt="" />
                                </div>
                                <span className="d-block">Total Value of All Inquiries</span>
                                <span className="d-block small">Inquiries Created (1)</span>
                                <h3 className="mb-0">£39.99</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6">
                        <div className="inquiry-box card border bg-white">
                            <div className="card-body text-center">
                                <div className="mb-1">
                                    <img src={img1} alt="" />
                                </div>
                                <span className="d-block">Inquiries Closed</span>
                                <span className="d-block small">Inquiries Closed (1)</span>
                                <h3 className="mb-0">£39.99</h3>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="row my-2" >
                    <div className="card">
                        <div className="card-header">
                            <div className="d-sm-flex justify-content-between">
                                <h4>Manage Inquiries</h4>
                                {/* <div>
                    <a href="#">Today&nbsp;-</a> &nbsp;&nbsp;&nbsp;&nbsp;
                    <a href="#">Yesterday&nbsp;-</a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href="#">Last 7 Days&nbsp;-</a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href="#">This Month&nbsp;-</a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href="#">Last Month&nbsp;-</a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href="#">This Year&nbsp;-</a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href="#">  All</a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <select name="" id="" className="btn">
                        <option value="">10</option>
                        <option value="">25</option>
                        <option value="">50</option>
                    </select>
                        </div> */}
                            </div>
                        </div>
                        <div className="card-body">

                            <div className="table-responsive">
                                <table className="table table-bordered d-p">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Customer</th>
                                            <th>Reference</th>
                                            {/* <th>Inquiry Value</th> */}
                                            <th>Assigned To	</th>
                                            <th>Created Date</th>
                                            <th>Status</th>
                                            <th>Message</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {inquiries.length > 0 && inquiries.map((inquiry, key) => {
                                            return !inquiry.isDeleted && <tr key={key}>
                                                <td>
                                                    <Link to={`/admin/inquiries/${inquiry._id}`} className="text-decoration-none">{inquiry.ticket ? inquiry.ticket : inquiry._id}</Link></td>
                                                <td>
                                                    <span className="d-block">
                                                        {inquiry?.name}
                                                    </span>
                                                    <small className="d-block">
                                                        <strong> Mobile:</strong> {inquiry?.phone}
                                                    </small>
                                                    <small className="d-block">
                                                        Email:{inquiry?.email}
                                                    </small>
                                                    {/* <small className="d-block">
                                                        Organization: mrfix
                                                    </small> */}
                                                </td>
                                                <td>
                                                    <span>{inquiry?.newBuyer}</span>
                                                </td>
                                                {/* <td>
                                                    <span>£39.99</span>
                                                </td> */}
                                                <td>
                                                    <span>{inquiry?.assignedTo?.firstName && inquiry?.assignedTo?.firstName + ' ' + inquiry?.assignedTo?.lastName}</span>
                                                </td>
                                                <td>
                                                    {new Date(inquiry?.createdAt)?.toDateString()}
                                                </td>

                                                <td>
                                                    <span>{inquiry?.inquiryStatus}</span>
                                                </td>
                                                <td title={inquiry?.message}>
                                                    <small>{inquiry?.message?.substring(0, 40)}...</small>
                                                </td>
                                                <td>

                                                    <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="bx bx-dots-vertical-rounded"></i>
                                                    </button>
                                                    <ul className="dropdown-menu bg-white">
                                                        <li><Link to={`/admin/inquiries/${inquiry._id}`} className="dropdown-item">View/Edit</Link>
                                                        </li>
                                                        <li><a className="dropdown-item" href="javascript:void(0);"
                                                            onClick={() => { setModal(true); setDelId(inquiry._id) }}>Delete</a>
                                                        </li>
                                                        {/* <li><Link className="dropdown-item" to={`/admin/leads/${order._id}`}>View/Add Comment</Link>
                                                                </li> */}
                                                    </ul>
                                                    {/* <ul className="dropdown-menu bg-white">
                                                        <li><a className="dropdown-item" href="#">View</a>
                                                        </li>
                                                        <li><a className="dropdown-item" href="#">Delete</a>
                                                        </li>
                                                        <li><a className="dropdown-item" href="#">Export as Pdf</a>
                                                        </li>
                                                    </ul> */}
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="my-2 text-end">
                                <Pagination currentItems={inquiries} updateRecord={updateRecord} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className={`modal fade text-left ${modal ? 'show' : ''}`} id="danger" tabIndex={-1} aria-labelledby="myModalLabel120" style={modal ? styleShow : styleHide} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-danger">
                            <h5 className="modal-title white" id="myModalLabel120">Danger!</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={() => { setModal(false) }}>
                                <i className="bx bx-x" />
                            </button>
                        </div>
                        <div className="modal-body">
                            Are you sure! You want to delete this Inquiry?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light-secondary" data-dismiss="modal"
                                onClick={() => { setModal(false) }}>
                                <i className="bx bx-x d-block d-sm-none" />
                                <span className="d-none d-sm-block">Cancel</span>
                            </button>
                            <button type="button" className="btn btn-danger ml-1" data-dismiss="modal"
                                onClick={() => { deleteInquiry(delId) }}>
                                <i className="bx bx-check d-block d-sm-none" />
                                <span className="d-none d-sm-block">Accept</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ListInquiries