import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { emptyAlertMessage, uploadImage } from "../../actions/globalActions";
import { updateDeviceColorAction, getDeviceColor } from "../../actions/deviceColorActions";
import { MEDIA_UPDATE } from "../../actions/types";
import { base_url } from "../../utils";
import Alert from "../elements/Alert";


const UpdateDeviceColor = () => {
    let dispatch = useDispatch();
    let media = useSelector(state => state.global.media);
    let navigate = useNavigate();
    const deviceColor = useSelector(state => state.deviceColor.deviceColor);

    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [image, setImage] = useState({ value: "" });
    const [button, setButton] = useState(false);
    let { id } = useParams();

    useLayoutEffect(() => {
        dispatch(emptyAlertMessage());
    }, []);

    useEffect(() => {
        if (deviceColor) {
            // dispatch({
            //     type: MEDIA_UPDATE,
            //     payload: { id: deviceColor.media._id, filename: deviceColor.media.filename }
            // });
            // console.log('hahaha', deviceColor.name);
            setName(deviceColor.name);
            setCode(deviceColor.code);
            // setImage({value: manufacturer.media.originalname})
        }
    }, [deviceColor])

    useEffect(() => {
        id && dispatch(getDeviceColor(id));
    }, [id]);

    useEffect(() => {
        media && setButton(true);
    }, [media])

    const UpdateDeviceColor = (e) => {
        e.preventDefault();
        const deviceColorObj = {
            name,
            code
            // media: media?.id
        }
        dispatch(updateDeviceColorAction(id, deviceColorObj));
    }

    const updateImage = (e) => {
        if (e.files && e.files[0]) {
            setImage(e.files[0]);

            const data = new FormData()
            data.append('file', e.files[0], e.files[0].name);
            console.log('data', data);

            dispatch(uploadImage(data));
        }
    }

    const profileStyle = {
        width: '150px',
        height: '150px'
    }
    return (
        <>
        <div className="container-xxl flex-grow-1 container-p-y">
        <div className="breadcrumbs-top py-4">
            {/* <h5 className="content-header-title float-left pr-1 mb-0">
                Update Device Color
            </h5> */}
            <div className="breadcrumb-wrapper d-none d-sm-block">
                <ol className="breadcrumb breadcrumb-style1 p-0 mb-0 pl-1">
                    <li className="breadcrumb-item">
                        <a href="index.html">
                            <i className="bx bx-home-alt" />
                        </a>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/admin/device-colors">Device Colors</Link>
                    </li>
                    <li className="breadcrumb-item active">
                        <a href="javascript:void(0);">Update</a>
                    </li>
                </ol>
            </div>
        </div>
        <section id="basic-horizontal-layouts">
                            <div className="row match-height">
                                <div className="col-md-6 col-12">
                                    <div className="card">
                                        <div className="card-header">
                                        <h5 className="card-title">
                                            Update Device Color
                                        </h5>
                                            <Alert />
                                        </div>
                                        <div className="card-body">
                                            <form className="form form-horizontal" onSubmit={UpdateDeviceColor}>
                                                <div className="form-body">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <label>
                                                                Name
                                                            </label>
                                                        </div>
                                                        <div className="col-md-8 form-group">
                                                            <input
                                                                type="text"
                                                                id="name"
                                                                className="mb-4 form-control"
                                                                name="name"
                                                                placeholder="Name"
                                                                required
                                                                value={name}
                                                                onChange={(e) => { setName(e.target.value) }}
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label>
                                                                Code
                                                            </label>
                                                        </div>
                                                        <div className="col-md-8 form-group">
                                                            <input
                                                                type="color"
                                                                id="code"
                                                                className="mb-4 form-control"
                                                                name="code"
                                                                placeholder="Code"
                                                                required
                                                                value={code}
                                                                onChange={(e) => { setCode(e.target.value) }}
                                                            />
                                                        </div>
                                                        {/* <div className="col-md-4">
                                                            <label>Picture</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <img style={profileStyle} src={media ? base_url + "media/" + media.filename : "/app-assets/images/dummy.jpg"} />
                                                            </div>
                                                        <div className="col-md-4">
                                                            <label></label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <fieldset class="form-group">
                                                                <input type="file" class="mb-4 form-control-file" value={image.value}
                                                                onChange={(e) => {updateImage(e.target); setButton(false) }} />
                                                            </fieldset>
                                                        </div> */}
                                                        {/* <div class="edit"><a onClick={() => imageRef.current.click()} href="javascript:void(0);"><i class="fa fa-edit fa-lg"></i></a></div> */}
                                                        {/* {progress > 0 && <ProgressBar labelSize="10px" height="10px" bgcolor="#175759" completed={progress} />} */}
                                                        {/* <input type="file" value={Image} hidden="true" className="mb-4 form-control" placeholder="Name" required
                                                            onChange={(e) => updateImage(e.target)} /> */}
                                                        {/* <p className="error">{errors && errors.image}</p> */}
                                                        {/* <div className="col-md-8">
                                                            <fieldset className="form-group">
                                                                <div className="custom-file">
                                                                    <input type="file" className="custom-file-input" />
                                                                    <label className="custom-file-label" htmlFor="inputGroupFile01">WhatsApp Image 2022-06-15 at 9.32.27 AM.jpeg</label>
                                                                </div>
                                                            </fieldset>
                                                        </div> */}

                                                        {/* <div className="col-md-4">
                                                            <label>
                                                                Mobile
                                                            </label>
                                                        </div> */}
                                                        {/* <div className="col-md-8 form-group">
                                                            <input
                                                                type="number"
                                                                id="contact-info"
                                                                className="mb-4 form-control"
                                                                name="contact"
                                                                placeholder="Mobile"
                                                            />
                                                        </div> */}
                                                        {/* <div className="col-md-4">
                                                            <label>
                                                                Password
                                                            </label>
                                                        </div>
                                                        <div className="col-md-8 form-group">
                                                            <input
                                                                type="password"
                                                                id="password"
                                                                className="mb-4 form-control"
                                                                name="password"
                                                                placeholder="Password"
                                                            />
                                                        </div> */}
                                                        {/* <div className="col-12 col-md-8 offset-md-4 form-group">
                                                            <fieldset>
                                                                <div className="checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="checkbox__input"
                                                                        id="checkbox1"
                                                                        defaultChecked
                                                                    />
                                                                    <label htmlFor="checkbox1">
                                                                        Remember
                                                                        me
                                                                    </label>
                                                                </div>
                                                            </fieldset>
                                                        </div> */}
                                                        <div className="col-sm-12 d-flex justify-content-end">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary me-1"
                                                                onClick={() => navigate(-1)}
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary "
                                                            >
                                                                Submit
                                                            </button>
                                                            {/* <button
                                                                type="reset"
                                                                className="btn btn-light-secondary"
                                                                onClick={() => setImage("")}
                                                            >
                                                                Reset
                                                            </button> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
        </div>
            <div className="app-content content">
                <div className="content-overlay" />
                <div className="content-wrapper">
                    <div className="content-header row">
                        <div className="content-header-left col-12 mb-2 mt-1">

                        </div>
                    </div>
                    <div className="content-body">
                        {/* Basic Horizontal form layout section start */}
             
                        {/* Basic Horizontal form layout section end */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default UpdateDeviceColor;
