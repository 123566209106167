import inquryImg from "../images/icon7.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useLayoutEffect, useState } from "react";
import { emptyAlertMessage } from "../../../actions/globalActions";
import { listStores } from "../../../actions/storeActions";
import { listRepairCategories } from "../../../actions/repairCategoriesActions";
import Select from "react-select";
import { listManufacturers } from "../../../actions/manufacturerActions";
import { listDevices } from "../../../actions/deviceActions";
import {
    MOBILE_CONDITIONS,
    MOBILE_NETWORKS,
    MOBILE_SIZES,
} from "../../../constants";
import SellCustomerDetail from "./SellCustomerDetail";
import {
    addSellOrderAction,
    getSellOrderAction,
    listInvoiceStatusAction,
    listOrderStatusAction,
    moveToBuyAction,
} from "../../../actions/buySellOrderActions";
import SellOrderSpecifications from "./SellOrderSpecifications";
import { listProductSizes } from "../../../actions/productSizeActions";
import { listProductNetworks } from "../../../actions/productNetworkActions";
import Alert from "../../elements/Alert";

const SellOrder = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const stores = useSelector((state) => state.store.stores);
    const { repairCategories } = useSelector((state) => state.repairCategory);
    const { manufacturers } = useSelector((state) => state.manufacturer);
    const { devices } = useSelector((state) => state.device);
    const { sellOrder } = useSelector((state) => state.buySellOrder);
    const { productNetworks } = useSelector((state) => state.productNetwork);
    const { productSizes } = useSelector((state) => state.productSize);
    const orderStatuses = useSelector((state) => state.order.orderStatus);

    const [deviceSize, setDeviceSize] = useState("");
    const [deviceNetwork, setDeviceNetwork] = useState("");
    const [deviceCondition, setDeviceCondition] = useState("");
    const [orderStatus, setOrderStatus] = useState("");
    const [invoiceStatus, setInvoiceStatus] = useState("");
    const [images, setImages] = useState("");

    const [devicePrices, setDevicePrices] = useState({
        purchasePrice: 0,
        costPrice: 0,
        retailPrice: 0,
    });
    const [specifications, setSpecifications] = useState({
        grade: "",
        color: "",
        memory: "",
        network: "",
        size: "",
        type: "",
        resolution: "",
        protection: "",
        rear: "",
        video: "",
        features: "",
        front: "",
        build: "",
        weight: "",
        dimensions: "",
        sim: "",
        os: "",
        chipset: "",
        cpu: "",
        romRam: "",
    });
    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        address: "",
        city: "",
        postCode: "",
        hearUs: "",
    });

    const condition = {
        excellent: 0,
        good: 1,
        faulty: 2,
        recycle: 4,
    };

    const [repairCategory, setRepairCategory] = useState("");
    const [manufacturer, setManufacturer] = useState("");
    const [device, setDevice] = useState("");
    const [store, setStore] = useState("");

    const [repairCategoryOptions, setRepairCategoryOptions] = useState([]);
    const [manufacturerOptions, setManufacturerOptions] = useState([]);
    const [deviceOptions, setDeviceOptions] = useState([]);
    const [storeOptions, setStoreOptions] = useState([]);

    const handleInput = (e) => {
        setSpecifications((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handlePriceInput = (e) => {
        setDevicePrices((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    useLayoutEffect(() => {
        dispatch(listRepairCategories());
        dispatch(listManufacturers());
        dispatch(listStores());
        dispatch(listProductNetworks());
        dispatch(listProductSizes());
        dispatch(emptyAlertMessage());
    }, []);

    useEffect(() => {
        if (id) {
            dispatch(getSellOrderAction(id));
            dispatch(listOrderStatusAction());
            dispatch(listInvoiceStatusAction());
        }
    }, [id]);

    useEffect(() => {
        if (Object.keys(sellOrder).length) {
            setUser(sellOrder.customer);
            setOrderStatus(sellOrder?.orderStatus);
            setInvoiceStatus(sellOrder?.invoiceStatus);
            setDeviceSize(sellOrder?.deviceSize);
            setDeviceCondition(sellOrder?.deviceCondition);
            setDeviceNetwork(sellOrder.deviceNetwork);
            setRepairCategory({
                obj: sellOrder?.devices?.manufactures?.repairCategories,
                value: sellOrder?.devices?.manufactures?.repairCategories?._id,
                label: sellOrder?.devices?.manufactures?.repairCategories?.name,
            });
            setManufacturer({
                obj: sellOrder?.devices?.manufactures,
                value: sellOrder?.devices?.manufactures?._id,
                label: sellOrder?.devices?.manufactures?.name,
            });
            setDevice({
                obj: sellOrder?.devices,
                value: sellOrder?.devices?._id,
                label: sellOrder?.devices?.name,
            });
            if (sellOrder?.store) {
                setStore({
                    obj: sellOrder?.store,
                    value: sellOrder?.store?._id,
                    label: sellOrder?.store?.name,
                });
            }
            if (
                sellOrder?.devices?.prices?.length > 0 &&
                sellOrder.deviceCondition !== "recycle"
            ) {
                const parse = JSON.parse(sellOrder?.devices?.prices);
                setDevicePrices((prev) => ({
                    ...prev,
                    purchasePrice:
                        parse[sellOrder.deviceSize][
                            condition[sellOrder.deviceCondition]
                        ],
                }));
            } else {
                setDevicePrices((prev) => ({
                    ...prev,
                    purchasePrice: 0,
                }));
            }
            setSpecifications((prev) => ({
                ...prev,
                memory: sellOrder.deviceSize,
                network: sellOrder.deviceNetwork,
            }));
            // setCreatedAt(dateWithoutZone(new Date(sellOrder?.createdAt)));
            // setUpdatedAt(dateWithoutZone(new Date(sellOrder?.updatedAt)));
        }
    }, [sellOrder]);

    useEffect(() => {
        const opts = [];
        stores &&
            stores.map((store) => {
                if (!store.isDeleted) {
                    opts.push({
                        obj: store,
                        value: store._id,
                        label: store.name,
                    });
                }
            });
        setStoreOptions(opts);
    }, [stores]);

    useEffect(() => {
        const opts = [];
        repairCategories &&
            repairCategories.map((repairCategory) => {
                if (!repairCategory.isDeleted) {
                    opts.push({
                        obj: repairCategory,
                        value: repairCategory._id,
                        label: repairCategory.name,
                    });
                }
            });
        setRepairCategoryOptions(opts);
    }, [repairCategories]);

    useEffect(() => {
        if (repairCategory) {
            const opts = [];
            manufacturers &&
                manufacturers.map((manufacturer) => {
                    if (
                        !manufacturer.isDeleted &&
                        manufacturer.repairCategories._id ==
                            repairCategory?.value
                    ) {
                        opts.push({
                            obj: manufacturer,
                            value: manufacturer._id,
                            label: manufacturer.name,
                        });
                    }
                });
            setManufacturerOptions(opts);
        }
    }, [repairCategory, manufacturers]);

    useEffect(() => {
        const opts = [];
        devices &&
            devices.map((device) => {
                if (!device.isDeleted) {
                    opts.push({
                        obj: device,
                        value: device._id,
                        label: device.name,
                    });
                }
            });
        setDeviceOptions(opts);
    }, [devices]);

    useEffect(() => {
        if (manufacturer) {
            dispatch(listDevices(manufacturer?.value));
        }
    }, [manufacturer]);

    const addLead = (e) => {
        e.preventDefault();
        if (
            specifications?.color?.name &&
            device?.obj?.name &&
            store?.obj?.name
        ) {
            const order = {
                devices: device.obj,
                store: store.obj,
                deviceNetwork,
                deviceSize,
                deviceCondition,
                deviceColor: specifications.color,
                deviceGrade: specifications.grade,
                customer: user,
                devicePrices,
                orderStatus,
                specifications,
            };
            dispatch(moveToBuyAction(order));
        } else {
            alert('Kidly select Device, Store and Color')
        }
    };

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <nav aria-label="breadcrumb" className="">
                    <ol className="breadcrumb p-1">
                        <li className="breadcrumb-item">
                            <Link to="/admin">Admin</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/admin/sell-orders">Sell Orders</Link>
                        </li>
                    </ol>
                </nav>
                <Alert />
                <form onSubmit={addLead}>
                    <div className="row">
                        <div className="col-md-6 col-lg-6  mb-4 order-lg-0">
                            <SellCustomerDetail user={user} setUser={setUser} />
                        </div>
                        <div className="col-md-6 col-lg-6 mb-4 order-lg-0 mb-4 ">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-sm-flex align-items-center justify-content-between">
                                        <div>
                                            <div className="d-sm-flex">
                                                <img src={inquryImg} alt="" />
                                                <h4 className="fw-bold mx-2 mt-4">
                                                    <a
                                                        href="javascript:void(0);"
                                                        className=""
                                                    >
                                                        Order Summary
                                                    </a>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="" />
                                <div className="card-body">
                                    <div className="row">
                                        <div class="col-md-6 mb-4">
                                            <label class="form-label">
                                                Select Category *
                                            </label>
                                            <Select
                                                required
                                                value={repairCategory}
                                                onChange={(e) => {
                                                    setRepairCategory(e);
                                                    setManufacturer("");
                                                    setDevice("");
                                                }}
                                                options={repairCategoryOptions}
                                            />
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <label class="form-label">
                                                Select Brand *
                                            </label>
                                            <Select
                                                required
                                                value={manufacturer}
                                                onChange={(e) => {
                                                    setManufacturer(e);
                                                    setDevice("");
                                                }}
                                                options={manufacturerOptions}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <label class="form-label">
                                                Select Device *
                                            </label>
                                            <Select
                                                required
                                                value={device}
                                                onChange={(e) => setDevice(e)}
                                                options={deviceOptions}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <label class="form-label">
                                                Condition *
                                            </label>
                                            <select
                                                required
                                                className="form-control"
                                                value={deviceCondition}
                                                onChange={(e) =>
                                                    setDeviceCondition(
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <option value="">
                                                    Select...
                                                </option>
                                                {MOBILE_CONDITIONS.map(
                                                    (condition) => (
                                                        <option
                                                            key={condition.name}
                                                            value={
                                                                condition.value
                                                            }
                                                        >
                                                            {condition.name}
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <label class="form-label">
                                                Store *
                                            </label>
                                            <Select
                                                required
                                                value={store}
                                                onChange={(e) => setStore(e)}
                                                options={storeOptions}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <label class="form-label">
                                                Purchase Price (£) *
                                            </label>
                                            <input
                                                required
                                                min="1"
                                                step="0.01"
                                                name="purchasePrice"
                                                type="number"
                                                class="form-control"
                                                value={
                                                    devicePrices.purchasePrice
                                                }
                                                onChange={(e) =>
                                                    handlePriceInput(e)
                                                }
                                            />
                                        </div>
                                        <div className="col-md-4 mb-4">
                                            <label class="form-label">
                                                Memory/Size *
                                            </label>
                                            <select
                                                required
                                                name="memory"
                                                className="form-control"
                                                value={deviceSize}
                                                onChange={(e) => {
                                                    setDeviceSize(
                                                        e.target.value
                                                    );
                                                    handleInput(e);
                                                }}
                                            >
                                                <option value="">
                                                    Select...
                                                </option>
                                                {device.obj?.sizes?.length > 0
                                                    ? device.obj?.sizes.map(
                                                          (size) => (
                                                              <option
                                                                  key={
                                                                      size.name
                                                                  }
                                                                  value={
                                                                      size.name
                                                                  }
                                                              >
                                                                  {size.name}
                                                              </option>
                                                          )
                                                      )
                                                    : productSizes.map(
                                                          (size) =>
                                                              !size.isDeleted && (
                                                                  <option
                                                                      key={
                                                                          size.name
                                                                      }
                                                                      value={
                                                                          size.name
                                                                      }
                                                                  >
                                                                      {
                                                                          size.name
                                                                      }
                                                                  </option>
                                                              )
                                                      )}
                                            </select>
                                        </div>
                                        <div className="col-md-4 mb-4">
                                            <label class="form-label">
                                                Network *
                                            </label>
                                            <select
                                                required
                                                name="network"
                                                className="form-control"
                                                value={deviceNetwork}
                                                onChange={(e) => {
                                                    setDeviceNetwork(
                                                        e.target.value
                                                    );
                                                    handleInput(e);
                                                }}
                                            >
                                                <option value="">
                                                    Select...
                                                </option>
                                                {productNetworks.map(
                                                    (network) =>
                                                        !network.isDeleted && (
                                                            <option
                                                                key={
                                                                    network.name
                                                                }
                                                                value={
                                                                    network.name
                                                                }
                                                            >
                                                                {network.name}
                                                            </option>
                                                        )
                                                )}
                                            </select>
                                        </div>
                                        <div className="col-md-4 mb-4">
                                            <label class="form-label">
                                                Order Status *
                                            </label>
                                            <select
                                                required
                                                className="form-control"
                                                value={orderStatus}
                                                onChange={(e) =>
                                                    setOrderStatus(
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <option value="">
                                                    Select...
                                                </option>
                                                {orderStatuses.map((status) => (
                                                    <option value={status[1]}>
                                                        {status[0]}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SellOrderSpecifications
                        device={device.obj}
                        specifications={specifications}
                        setSpecifications={setSpecifications}
                        productNetworks={productNetworks}
                        productSizes={productSizes}
                        devicePrices={devicePrices}
                        setDevicePrices={setDevicePrices}
                        handleInput={handleInput}
                        handlePriceInput={handlePriceInput}
                    />
                    <div class="col-sm-12 d-flex justify-content-end">
                        <button
                            type="button"
                            class="btn btn-primary"
                            onClick={() => navigate(-1)}
                        >
                            Cancel
                        </button>
                        <button type="submit" class="btn btn-primary mx-1">
                            Move to Buy
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};
export default SellOrder;
