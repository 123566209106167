import axios from "axios";
import { alertMessage, base_url, check_response_status } from "../utils";
import { GET_INQUIRY, LIST_INQUIRIES, LIST_INQUIRY_STATUS } from "./types";

// list  inquiries
export const listInquiries = (from=0, limit=20) => async dispatch => {

    axios.get(`${base_url}inquiries?from=${from}&limit=${limit}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                // console.log(' inquiries', response.data);
                dispatch({
                    type: LIST_INQUIRIES,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//get  inquiry
export const getInquiry = (id) => async dispatch => {
    axios.get(`${base_url}inquiries/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                // console.log(' inquiries', response.data);
                dispatch({
                    type: GET_INQUIRY,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

// add  inquiry
export const addInquiryAction = (inquiry) => async dispatch => {

    axios.post(`${base_url}inquiries`,
    inquiry,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log(' inquiry', response);
            if(response.status == 201) {
                dispatch(alertMessage(response.data));
                dispatch(listInquiries());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//update Device Color
export const updateInquiryAction = (id, inquiry) => async dispatch => {
    axios.put(`${base_url}inquiries/${id}`,
    inquiry,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log(' inquiry', response);
            if(response.status == 200 || response.status == 201) {
                dispatch(alertMessage({flag: 'success', message: "Inquiry Upadated Successfully."}));
                dispatch(listInquiries());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

export const deleteInquiryAction = (id) => async dispatch => {
    // console.log(' inquiry', id)
    axios.delete(`${base_url}inquiries/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log('delete', response);
            if(response.status == 200) {
                dispatch(alertMessage({flag: 'success', message: response.data.message}));
                dispatch(listInquiries());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

export const listInquiryStatusAction = () => async dispatch => {
    axios.get(`${base_url}inquiries/getInquiryStatus`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                dispatch({
                    type: LIST_INQUIRY_STATUS,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}