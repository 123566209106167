import user1 from "./images/user1.png"
import inquryImg from "./images/icon7.png"
import question3 from "./images/question3.png"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useLayoutEffect, useState } from "react"
import { getOrderAction, listInvoiceStatusAction, listOrderStatusAction, updateOrderAction } from "../../../actions/orderActions"
import { emptyAlertMessage } from "../../../actions/globalActions"
import { listStores } from "../../../actions/storeActions"
import { listRepairCategories } from "../../../actions/repairCategoriesActions"
import Select from 'react-select';
import { listManufacturers } from "../../../actions/manufacturerActions"
import { listDevices } from "../../../actions/deviceActions"
import { listRepairServicesById } from "../../../actions/repairServiceActions"
import { listEmployees } from "../../../actions/employeeActions"
import { dateWithoutZone, getDate } from "../../../utils"

const UpdateLead = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams('id');

    const lead = useSelector(state => state.order.order);
    const leadStatuses = useSelector(state => state.order.orderStatus);
    const invoiceStatuses = useSelector(state => state.order.invoiceStatus);
    const stores = useSelector(state => state.store.stores);
    const repairCategories = useSelector(state => state.repairCategory.repairCategories);
    const manufacturers = useSelector(state => state.manufacturer.manufacturers);
    const devices = useSelector(state => state.device.devices);
    const repairServices = useSelector(state => state.repairService.repairServices);
    const employees = useSelector(state => state.employee.employees);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [postCode, setPostCode] = useState("");
    const [hearUs, setHearUs] = useState("");
    const [message, setMessage] = useState("");
    const [leadStatus, setLeadStatus] = useState();
    const [timeTo, setTimeTo] = useState("");
    const [timeFrom, setTimeFrom] = useState("");
    const [orderType, setOrderType] = useState("");
    const [storeTiming, setStoreTiming] = useState("");
    const [createdAt, setCreatedAt] = useState("");
    const [updatedAt, setUpdatedAt] = useState("");
    const [returnDate, setReturnDate] = useState("");
    const [invoiceStatus, setInvoiceStatus] = useState("partial");

    const [repairCategory, setRepairCategory] = useState("");
    const [manufacturer, setManufacturer] = useState("");
    const [device, setDevice] = useState("");
    const [repairService, setRepairService] = useState("");
    const [store, setStore] = useState("");
    const [employee, setEmployee] = useState("");

    const [repairCategoryOptions, setRepairCategoryOptions] = useState([]);
    const [manufacturerOptions, setManufacturerOptions] = useState([]);
    const [deviceOptions, setDeviceOptions] = useState([]);
    const [repairServiceOptions, setRepairServiceOptions] = useState([]);
    const [storeOptions, setStoreOptions] = useState([]);
    const [employeeOptions, setEmployeeOptions] = useState([]);

    useEffect(() => {
        if (lead) {
            setFirstName(lead?.orderDetails?.firstName);
            setLastName(lead?.orderDetails?.lastName);
            setEmail(lead?.orderDetails?.email);
            setMobile(lead?.orderDetails?.mobile);
            setCity(lead?.orderDetails?.city);
            setAddress(lead?.orderDetails?.address);
            setHearUs(lead?.orderDetails?.hearUs);
            setMessage(lead?.orderDetails?.message);
            setPostCode(lead?.orderDetails?.postCode);
            setLeadStatus(lead?.orderStatus);
            setInvoiceStatus(lead?.invoiceStatus);
            setReturnDate(lead?.returnDate?.slice(0, 16));
            setOrderType(lead?.orderType);
            if (lead.storeTiming) {
                const { startAt, endAt } = lead?.storeTiming;
                setTimeFrom(`${startAt?.year}-${startAt?.month < 10 ? '0' + startAt?.month : startAt?.month}-${startAt?.day < 10 ? '0' + startAt?.day : startAt?.day}T${startAt?.hour < 10 ? '0' + startAt?.hour : startAt?.hour}:${startAt?.minute < 10 ? '0' + startAt?.minute : startAt?.minute}`);
                setTimeTo(`${endAt?.year}-${endAt?.month < 10 ? '0' + endAt?.month : endAt?.month}-${endAt?.day < 10 ? '0' + endAt?.day : endAt?.day}T${endAt?.hour < 10 ? '0' + endAt?.hour : endAt?.hour}:${endAt?.minute < 10 ? '0' + endAt?.minute : endAt?.minute}`);
            }
            setRepairCategory({
                obj: lead?.repairCategories, value: lead?.repairCategories?._id, label: lead?.repairCategories?.name
            });
            setManufacturer({
                obj: lead?.manufactures, value: lead?.manufactures?._id, label: lead?.manufactures?.name
            });
            setDevice({
                obj: lead?.devices, value: lead?.devices?._id, label: lead?.devices?.name
            });
            setRepairService(
                lead?.repairServices.map(service => { return { obj: service, value: service._id, label: service?.problem?.name } })
            );
            setStore({ obj: lead?.store, value: lead?.store?._id, label: lead?.store?.name })
            lead?.assignedTo?.firstName && setEmployee({ obj: lead?.assignedTo, value: lead?.assignedTo?._id, label: lead?.assignedTo?.firstName + ' ' + lead?.assignedTo?.lastName })
            setCreatedAt(dateWithoutZone(new Date(lead?.createdAt)));
            setUpdatedAt(dateWithoutZone(new Date(lead?.updatedAt)));
        }
    }, [lead]);

    useLayoutEffect(() => {
        dispatch(listRepairCategories());
        dispatch(listManufacturers());
        dispatch(listOrderStatusAction());
        dispatch(listInvoiceStatusAction());
        dispatch(listStores());
        dispatch(emptyAlertMessage());
        dispatch(listEmployees());
    }, []);

    useEffect(() => {
        if (id) {
            dispatch(getOrderAction(id));
            dispatch(listOrderStatusAction());
            dispatch(listInvoiceStatusAction());
            // dispatch(listRepairServices());
        }
    }, [id]);

    useEffect(() => {
        const opts = [];
        employees && employees.map(employee => {
            if (!employee.isDeleted) {
                opts.push({ obj: employee, value: employee._id, label: employee.firstName + ' ' + employee.lastName });
            }
        });
        setEmployeeOptions(opts);
    }, [employees]);

    useEffect(() => {
        const opts = [];
        stores && stores.map(store => {
            if (!store.isDeleted) {
                opts.push({ obj: store, value: store._id, label: store.name });
            }
        });
        setStoreOptions(opts);
    }, [stores]);

    useEffect(() => {
        const opts = [];
        repairCategories && repairCategories.map(repairCategory => {
            if (!repairCategory.isDeleted) {
                opts.push({ obj: repairCategory, value: repairCategory._id, label: repairCategory.name });
            }
        });
        setRepairCategoryOptions(opts);
    }, [repairCategories]);

    useEffect(() => {
        if (repairCategory) {
            const opts = [];
            manufacturers && manufacturers.map(manufacturer => {
                if (!manufacturer.isDeleted && manufacturer.repairCategories._id == repairCategory?.value) {
                    opts.push({ obj: manufacturer, value: manufacturer._id, label: manufacturer.name });
                }
            });
            setManufacturerOptions(opts);
        }
    }, [repairCategory, manufacturers]);

    useEffect(() => {
        const opts = [];
        devices && devices.map(device => {
            if (!device.isDeleted) {
                opts.push({ obj: device, value: device._id, label: device.name });
            }
        });
        setDeviceOptions(opts);
    }, [devices]);

    useEffect(() => {
        const opts = [];
        repairServices && repairServices.map(service => {
            if (!service.isDeleted) {
                opts.push({ obj: service, value: service._id, label: service.problem.name });
            }
        });
        setRepairServiceOptions(opts);
    }, [repairServices]);

    useEffect(() => {
        if (manufacturer) {
            dispatch(listDevices(manufacturer?.value))
        }
    }, [manufacturer])

    useEffect(() => {
        if (device) {
            dispatch(listRepairServicesById(device.value))
        }
    }, [device])

    const addLead = (e) => {
        e.preventDefault();


        let date = new Date(timeFrom);
        const startAt = {
            year: date.getUTCFullYear(),
            month: date.getUTCMonth() + 1,
            day: date.getUTCDate(),
            hour: date.getUTCHours(),
            minute: date.getUTCMinutes()
        }
        date = new Date(timeTo);
        const endAt = {
            year: date.getUTCFullYear(),
            month: date.getUTCMonth() + 1,
            day: date.getUTCDate(),
            hour: date.getUTCHours(),
            minute: date.getUTCMinutes()
        }

        const lead = {
            repairCategories: repairCategory.obj,
            manufactures: manufacturer.obj,
            devices: device.obj,
            repairServices: repairService.map(service => service.obj),
            store: store.obj,
            orderType,
            orderStatus: leadStatus,
            assignedTo: employee.obj,
            returnDate,
            invoiceStatus,
            orderDetails: {
                firstName,
                lastName,
                email,
                mobile,
                address,
                city,
                postCode,
                hearUs,
                message,
                gdprCompliance: true
            },
            storeTiming: { startAt, endAt }
        }
        dispatch(updateOrderAction(id, lead))
        navigate('/admin/leads')

    }

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <nav aria-label="breadcrumb" className="">
                    <ol className="breadcrumb p-1">
                        <li className="breadcrumb-item">
                            <Link to="/admin">Admin</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/admin/leads">Leads</Link>
                        </li>
                    </ol>
                </nav>
                <form onSubmit={addLead}>
                    <div className="row">
                        <div className="col-md-6 col-lg-6  mb-4 order-lg-0">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-sm-flex align-items-center justify-content-between">
                                        <div>
                                            <div className="d-sm-flex">
                                                <img src={user1} alt="" />
                                                <h4 className="fw-bold mx-2 mt-4">
                                                    <a href="javascript:void(0);" className="">Customer Details</a>
                                                </h4>
                                            </div>
                                        </div>
                                        {/* <div>
                                        <div className="d-sm-flex d-block justify-content-end">
                                            <input type="search" className="form-control w-50 mx-1" placeholder="Search Customer" />
                                            <a href="#" className="btn btn-primary  rounded-0">
                                                <i className='bx bxs-plus-circle'></i>&nbsp;New</a>
                                        </div>
                                    </div> */}
                                    </div>
                                </div>
                                <hr className="" />
                                <div className="card-body">
                                    <div className="row">
                                        <div class="col-md-6 mb-4">
                                            <label class="form-label">First Name*</label>
                                            <input type="text" className="form-control" required
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)} />
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <label class="form-label">Last Name*</label>
                                            <input type="text" className="form-control" required
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)} />
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <label class="form-label">Email*</label>
                                            <input type="email" className="form-control" required
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)} />
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <label class="form-label">Mobile*</label>
                                            <input type="text" className="form-control" required
                                                value={mobile}
                                                onChange={(e) => setMobile(e.target.value)} />
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <label class="form-label">Address</label>
                                            <input type="text" className="form-control"
                                                value={address}
                                                onChange={(e) => setAddress(e.target.value)} />
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <label class="form-label">City</label>
                                            <input type="text" className="form-control"
                                                value={city}
                                                onChange={(e) => setCity(e.target.value)} />
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <label class="form-label">Post Code</label>
                                            <input type="text" className="form-control"
                                                value={postCode}
                                                onChange={(e) => setPostCode(e.target.value)} />
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <label class="form-label">How did you hear about us*</label>
                                            <select class="select2 form-select form-select" data-allow-clear="true"
                                                value={hearUs} required
                                                onChange={e => setHearUs(e.target.value)}>
                                                <option value="">Select an option</option>
                                                <option value="FACEBOOK">Facebook</option>
                                                <option value="BING">Bing</option>
                                                <option value="SEARCH_ENGINE">Search Engine</option>
                                                <option value="REFERRAL">Referral</option>
                                                <option value="GOOGLE_ADS">Google Ads</option>
                                                <option value="OTHER">Other</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 mb-4 order-lg-0 mb-4 ">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-sm-flex align-items-center justify-content-between">
                                        <div>
                                            <div className="d-sm-flex">
                                                <img src={inquryImg} alt="" />
                                                <h4 className="fw-bold mx-2 mt-4"><a href="javascript:void(0);" className="">Lead Summary</a></h4>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <hr className="" />
                                <div className="card-body">
                                    <div className="row">
                                        <div class="col-md-6 mb-4">
                                            <label class="form-label">Lead Status</label>
                                            <select className="form-control" value={leadStatus} onChange={(e) => setLeadStatus(e.target.value)}>
                                                {leadStatuses.map(status => <option value={status[1]}>{status[0]}</option>)}
                                            </select>
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <label class="form-label">Assigned To</label>
                                            <Select
                                                required
                                                value={employee}
                                                onChange={(e) => setEmployee(e)}
                                                options={employeeOptions}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <label className="form-label" htmlFor="multicol-birthdate">Created On</label>
                                            <input type="text" readOnly value={createdAt} className="form-control" />
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <label class="form-label">Last Modified</label>
                                            <input type="text" readOnly value={updatedAt} className="form-control" />
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <label class="form-label">Store</label>
                                            <Select
                                                required
                                                value={store}
                                                onChange={(e) => setStore(e)}
                                                options={storeOptions}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <label class="form-label">Repair Type</label>
                                            <select class="select2 form-select form-select" data-allow-clear="true"
                                                value={orderType} onChange={e => setOrderType(e.target.value)}>
                                                <option value="walk_in">Walk In</option>
                                                <option value="mail_in">Mail In</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <label class="form-label">Invoice Status</label><select className="form-control"
                                                value={invoiceStatus} onChange={(e) => setInvoiceStatus(e.target.value)}>
                                                {invoiceStatuses.map(status => <option value={status[1]}>{status[0]}</option>)}
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <label class="form-label">Return Date</label>
                                            <input type="datetime-local" class="form-control"
                                                value={returnDate} onChange={e => setReturnDate(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 mb-4 order-lg-0 mb-4 ">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-sm-flex align-items-center justify-content-between">
                                        <div>
                                            <div className="d-sm-flex">
                                                {/* <img src={inquryImg} alt="" /> */}
                                                <h4 className="fw-bold mx-2 mt-4"><a href="#" className="">Reair Details</a></h4>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <hr className="" />
                                <div className="card-body">
                                    <div className="row">
                                        <div class="col-md-4 mb-4">
                                            <label class="form-label">Select Category</label>
                                            <Select
                                                required
                                                value={repairCategory}
                                                onChange={(e) => {
                                                    setRepairCategory(e);
                                                    setManufacturer("");
                                                    setDevice("");
                                                    setRepairService("");
                                                }}
                                                options={repairCategoryOptions}
                                            />
                                        </div>
                                        <div class="col-md-4 mb-4">
                                            <label class="form-label">Select Brand</label>
                                            <Select
                                                required
                                                value={manufacturer}
                                                onChange={(e) => {
                                                    setManufacturer(e);
                                                    setDevice("");
                                                    setRepairService("");
                                                }}
                                                options={manufacturerOptions}
                                            />
                                        </div>
                                        <div class="col-md-4 mb-4">
                                            <label class="form-label">Select Phone</label>
                                            <Select
                                                required
                                                value={device}
                                                onChange={(e) => {
                                                    setDevice(e);
                                                    setRepairService("");
                                                }}
                                                options={deviceOptions}
                                            />
                                        </div>
                                        <div class="col-md-4 mb-4">
                                            <label class="form-label">Select Repair Service</label>
                                            <Select
                                                required
                                                isMulti={true}
                                                value={repairService}
                                                onChange={(e) => setRepairService(e)}
                                                options={repairServiceOptions}
                                            />
                                        </div>
                                        <div class="col-md-4 mb-4">
                                            <label class="form-label">Time Slot From</label>
                                            <input type="datetime-local" className="form-control"
                                                value={timeFrom}
                                                onChange={(e) => setTimeFrom(e.target.value)} />
                                        </div>
                                        <div class="col-md-4 mb-4">
                                            <label class="form-label">Time Slot To</label>
                                            <input type="datetime-local" className="form-control"
                                                value={timeTo}
                                                onChange={(e) => setTimeTo(e.target.value)} />
                                        </div>
                                        <div class="col-md-12 mb-4">
                                            <label class="form-label">Customer Comments</label>
                                            <textarea className="form-control"
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}>
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><div class="col-sm-12 d-flex justify-content-end">
                        <button type="button" class="btn btn-primary" onClick={() => navigate('/admin/leads')}>Cancel</button>
                        <button type="submit" class="btn btn-primary mx-1">Submit</button>
                    </div>
                </form>
                <div className="col-md-12 my-4 order-lg-0">
                    <div className="card">
                        {/* <div className="card-header">
                            <div className="d-sm-flex align-items-center justify-content-between">
                                <div>
                                    <div className="d-sm-flex">
                                        <img src={inquryImg} alt="" />
                                        <h4 className="fw-bold mx-2 mt-4"><a href="#" className="">Reair Details</a></h4>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <hr className="" /> */}
                        {/* <div className="card-body">
                            <div>
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Home</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Profile</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Contact</button>
                                    </li>
                                </ul>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex={0}>Home</div>
                                    <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex={0}>Profile</div>
                                    <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabIndex={0}>Contact</div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>


                {/* <div className="row">
                    <div className="card-body">
                        <div className="bg-white px-1 py-1">
                            <h5>Leads Details <img src={question3} alt="" title="Select Leads" /></h5>

                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Leads Type	</th>
                                        <th>Product/Service	</th>
                                        <th>Task Type</th>
                                        <th>Expected Visit Time	</th>
                                        <th>Notes</th>
                                        <th>Qty</th>
                                        <th>Price</th>
                                        <th>Tax</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Repairs</td>
                                        <td>
                                            <span className="d-block">
                                                Mobile Repair - iPhone 11 PRO MAX
                                            </span>
                                            <span className="d-block">
                                                <i class='bx bxs-wrench'></i> Device Issue: <a href="#">10.</a>
                                            </span>
                                            <span className="d-block">
                                                <a href="#">Rear Camera Lens Replacement</a>
                                            </span>

                                        </td>
                                        <td>
                                            On-Site
                                        </td>

                                        <td>
                                            26 Jan, 2023 (01:02 PM)
                                        </td>
                                        <td>
                                            Sufyan
                                        </td>
                                        <td>
                                            1
                                        </td>
                                        <td>£39.99	 </td>
                                        <td>£0.00	 </td>
                                        <td>£39.99	 </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> */}


            </div>
        </>
    )
}
export default UpdateLead