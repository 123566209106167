import { GET_DEVICE_SERIES, LIST_DEVICE_SERIESES } from "../actions/types";

const initialState = {
    deviceSerieses: [],
    error: null,
    deviceSeries: null
};
export default (state = initialState, { type, payload }) => {

    switch (type) {
        case LIST_DEVICE_SERIESES:
            return {
                ...state,
                deviceSerieses: payload
            }
        case GET_DEVICE_SERIES:
            return {
                ...state,
                deviceSeries: payload
            }
        default:
            return state;
    }
}
