import userImage from "../images/user1.png";
import { HEAR_US } from "../../../constants";

const SellCustomerDetail = ({ user, setUser }) => {
    const handleInput = (e) => {
        setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };
    return (
        <div className="card">
            <div className="card-header">
                <div className="d-sm-flex align-items-center justify-content-between">
                    <div>
                        <div className="d-sm-flex">
                            <img src={userImage} alt="" />
                            <h4 className="fw-bold mx-2 mt-4">
                                <a href="javascript:void(0);" className="">
                                    Customer Details
                                </a>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="" />
            <div className="card-body">
                <div className="row">
                    <div class="col-md-6 mb-4">
                        <label class="form-label">First Name*</label>
                        <input
                            type="text"
                            name="firstName"
                            className="form-control"
                            required
                            value={user?.firstName}
                            onChange={handleInput}
                        />
                    </div>
                    <div class="col-md-6 mb-4">
                        <label class="form-label">Last Name*</label>
                        <input
                            type="text"
                            name="lastName"
                            className="form-control"
                            required
                            value={user?.lastName}
                            onChange={handleInput}
                        />
                    </div>
                    <div class="col-md-6 mb-4">
                        <label class="form-label">Email*</label>
                        <input
                            type="email"
                            name="email"
                            className="form-control"
                            required
                            value={user?.email}
                            onChange={handleInput}
                        />
                    </div>
                    <div class="col-md-6 mb-4">
                        <label class="form-label">Mobile*</label>
                        <input
                            type="text"
                            name="mobile"
                            className="form-control"
                            required
                            value={user?.mobile}
                            onChange={handleInput}
                        />
                    </div>
                    <div class="col-md-6 mb-4">
                        <label class="form-label">Address</label>
                        <input
                            type="text"
                            name="address"
                            className="form-control"
                            value={user?.address}
                            onChange={handleInput}
                        />
                    </div>
                    <div class="col-md-6 mb-4">
                        <label class="form-label">City</label>
                        <input
                            type="text"
                            name="city"
                            className="form-control"
                            value={user?.city}
                            onChange={handleInput}
                        />
                    </div>
                    <div class="col-md-6 mb-4">
                        <label class="form-label">Post Code</label>
                        <input
                            type="text"
                            name="postCode"
                            className="form-control"
                            value={user?.postCode}
                            onChange={handleInput}
                        />
                    </div>
                    <div class="col-md-6 mb-4">
                        <label class="form-label">
                            How did you hear about us*
                        </label>
                        <select
                            class="select2 form-select form-select"
                            data-allow-clear="true"
                            value={user?.hearUs}
                            name="hearUs"
                            required
                            onChange={handleInput}
                        >
                            <option value="">Select an option</option>
                            {HEAR_US.map((item) => (
                                <option key={item.key} value={item.key}>
                                    {item.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SellCustomerDetail;
