import { GET_PRODUCT_NETWORK, LIST_PRODUCT_NETWORKS } from "../actions/types";

const initialState = {
    productNetworks: [],
    error: null,
    productNetwork: null
};
export default (state = initialState, { type, payload }) => {

    switch (type) {
        case LIST_PRODUCT_NETWORKS: 
        return {
            ...state,
            productNetworks: payload
        }
        case GET_PRODUCT_NETWORK:
            return {
                ...state,
                productNetwork: payload
            }
        default:
            return state;
    }
}
