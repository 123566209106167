import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { deleteDeviceSeriesAction, listDeviceSerieses } from "../../actions/deviceSeriesActions";

const ListDeviceSeries = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const deviceSerieses = useSelector((state) => state.deviceSeries.deviceSerieses);
    const [modal, setModal] = useState(false);
    const [delId, setDelId] = useState(null);

    useEffect(() => {
        dispatch(listDeviceSerieses());
    }, []);

    const styleShow = {
        display: 'block',
        paddingRight: '17px'
    }

    const styleHide = {
        display: 'none',
        paddingRight: '17px'
    }

    const deleteDeviceSeries = () => {
        console.log(delId);
        delId && dispatch(deleteDeviceSeriesAction(delId));
        setModal(false);
    }

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="breadcrumbs-top py-4">
                    {/* <h5 className="content-header-title float-left pr-1 mb-0">
                    Product Conditions
                </h5> */}
                    <div class="col-12 d-flex justify-content-between align-items-center">
                        <div className="breadcrumb-wrapper d-none d-sm-block">
                            <ol className="breadcrumb breadcrumb-style1 p-0 mb-0 pl-1">
                                <li className="breadcrumb-item">
                                    {/* <a href="javascript:void(0);">
                                    <i className="bx bx-home-alt" />
                                </a> */}
                                    <Link to="/admin">
                                        <i className="bx bx-home-alt" />
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    List All
                                </li>
                            </ol>
                        </div>
                        <button onClick={() => { navigate('add') }} type="button" class="btn btn-primary mr-1">Add New</button>
                    </div>
                </div>
                <div className="row" id="basic-table">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">
                                    <h5 className="">
                                        Device Series
                                    </h5>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>manufacturer</th>
                                                <th>Series Name</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {deviceSerieses && deviceSerieses.map(deviceSeries => {
                                                return !deviceSeries.isDeleted && <tr>
                                                    <td>{deviceSeries.manufactures?.name}</td>
                                                    <td>{deviceSeries.name}</td>
                                                    {/* <td className="text-bold-500">
                                                    {deviceSeries.createdBy.firstName + " " + deviceSeries.createdBy.lastName}
                                                </td> */}
                                                    <td>
                                                        <Link to={`/admin/device-series/${deviceSeries._id}`}>
                                                            <i className="badge-circle badge-circle-light-secondary bx bx-edit font-medium-1" />
                                                        </Link>
                                                        <a href="javascript:void(0);" onClick={() => { setModal(true); setDelId(deviceSeries._id) }}>
                                                            <i className="badge-circle badge-circle-light-secondary bx bx-trash font-medium-1" />
                                                        </a>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className={`modal fade text-left ${modal ? 'show' : ''}`} id="danger" tabIndex={-1} aria-labelledby="myModalLabel120" style={modal ? styleShow : styleHide} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-danger">
                            <h5 className="modal-title white" id="myModalLabel120">Danger!</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={() => { setModal(false) }}>
                                <i className="bx bx-x" />
                            </button>
                        </div>
                        <div className="modal-body">
                            Are you sure! You want to delete this Product Condition?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light-secondary" data-dismiss="modal"
                                onClick={() => { setModal(false) }}>
                                <i className="bx bx-x d-block d-sm-none" />
                                <span className="d-none d-sm-block">Cancel</span>
                            </button>
                            <button type="button" className="btn btn-danger ml-1" data-dismiss="modal"
                                onClick={() => { deleteDeviceSeries(delId) }}>
                                <i className="bx bx-check d-block d-sm-none" />
                                <span className="d-none d-sm-block">Accept</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ListDeviceSeries;
