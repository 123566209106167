import { GET_PRODUCT_PHYSICAL_LOCATION, LIST_PRODUCT_PHYSICAL_LOCATIONS } from "../actions/types";

const initialState = {
    productPhysicalLocations: [],
    error: null,
    productPhysicalLocation: null
};
export default (state = initialState, { type, payload }) => {

    switch (type) {
        case LIST_PRODUCT_PHYSICAL_LOCATIONS: 
        return {
            ...state,
            productPhysicalLocations: payload
        }
        case GET_PRODUCT_PHYSICAL_LOCATION:
            return {
                ...state,
                productPhysicalLocation: payload
            }
        default:
            return state;
    }
}
