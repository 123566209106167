import axios from "axios";
import { alertMessage, base_url, check_response_status } from "../utils";
import { GET_ORDER, GET_PROBLEM, LIST_INVOICE_STATUS, LIST_ORDERS, LIST_ORDER_STATUS, LIST_PROBLEMS } from "./types";

// list  orders
export const listOrdersAction = (from=0, limit=20) => async dispatch => {
    axios.get(`${base_url}orders?from=${from}&limit=${limit}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                // console.log(' orders', response.data);
                dispatch({
                    type: LIST_ORDERS,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//add Order Action
export const addOrderAction = (order) => async dispatch => {
    axios.post(`${base_url}orders/repair`,
    order,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200 || response.status == 201) {
                dispatch(alertMessage({flag: 'success', message: "This order has been Upadated Successfully."}));
                dispatch(listOrdersAction());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

// get order
export const getOrderAction = (id) => async dispatch => {

    axios.get(`${base_url}orders/myOrders/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                // console.log(' orders', response.data);
                dispatch({
                    type: GET_ORDER,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

// list  orders
export const listOrderStatusAction = () => async dispatch => {
    axios.get(`${base_url}orders/getOrderStatus`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                dispatch({
                    type: LIST_ORDER_STATUS,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

// list  orders
export const listInvoiceStatusAction = () => async dispatch => {
    axios.get(`${base_url}orders/getInvoiceStatus`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                dispatch({
                    type: LIST_INVOICE_STATUS,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//update Device Color
export const updateOrderAction = (id, order) => async dispatch => {
    axios.put(`${base_url}orders/${id}`,
    order,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200 || response.status == 201) {
                dispatch(alertMessage({flag: 'success', message: "This order has been Upadated Successfully."}));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

export const deleteOrderAction = (id) => async dispatch => {
    // console.log(' problem', id)
    axios.delete(`${base_url}orders/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log('delete', response);
            if(response.status == 200) {
                dispatch(alertMessage({flag: 'success', message: response.data.message}));
                dispatch(listOrdersAction());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}