import { GET_LOYALTY_TYPES, GET_REPAIR_SERVICE, GET_WARRANTY_TYPES, LIST_REPAIR_SERVICES, REPAIR_SERVICES_COUNT } from "../actions/types";

const initialState = {
    repairServices: [],
    error: null,
    repairService: null,
    loyaltyTypes: [],
    warrantyTypes: [],
    count: null
};
export default (state = initialState, { type, payload }) => {

    switch (type) {
        case REPAIR_SERVICES_COUNT:
            return {
                ...state,
                count: payload
            }
        case LIST_REPAIR_SERVICES:
            return {
                ...state,
                repairServices: payload
            }
        case GET_REPAIR_SERVICE:
            return {
                ...state,
                repairService: payload
            }
        case GET_LOYALTY_TYPES:
            return {
                ...state,
                loyaltyTypes: payload
            }
        case GET_WARRANTY_TYPES:
            return {
                ...state,
                warrantyTypes: payload
            }
        default:
            return state;
    }
}
