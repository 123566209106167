import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { deleteProblemAction, listProblems } from "../../actions/problemActions";

const ListProblems = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const problems = useSelector((state) => state.problem.problems);
    const [modal, setModal] = useState(false);
    const [delId, setDelId] = useState(null);

    useEffect(() => {
        dispatch(listProblems());
    }, []);

    const styleShow = {
        display: 'block',
        paddingRight: '17px'
    }

    const styleHide = {
        display: 'none',
        paddingRight: '17px'
    }

    const deleteProblem = () => {
        console.log(delId);
        delId && dispatch(deleteProblemAction(delId));
        setModal(false);
    }

    return (
        <>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="breadcrumbs-top py-4">
                {/* <h5 className="content-header-title float-left pr-1 mb-0">
                    Problems
                </h5> */}
                <div class="col-12 d-flex justify-content-between align-items-center">
                    <div className="breadcrumb-wrapper d-none d-sm-block">
                        <ol className="breadcrumb breadcrumb-style1 p-0 mb-0 pl-1">
                            <li className="breadcrumb-item">
                                <a href="javascript:void(0);">
                                    <i className="bx bx-home-alt" />
                                </a>
                            </li>
                            <li className="breadcrumb-item active">
                                List All
                            </li>
                        </ol>
                    </div>
                        <button  onClick={() => { navigate('add') }} type="button" class="btn btn-primary mr-1">Add New</button>
                </div>
            </div>
            <div className="content-body">
                <div className="row" id="basic-table">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                            <h5 className="card-title">
                                Problems
                            </h5>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Created By</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {problems && problems.map(problem => {
                                                return  !problem.isDeleted && <tr>
                                                    <td>{problem.name}</td>
                                                    <td className="text-bold-500">
                                                        {problem.createdBy.firstName + " " + problem.createdBy.lastName}
                                                    </td>
                                                    <td>
                                                        <Link to={`/admin/problems/${problem._id}`}>
                                                            <i className="badge-circle badge-circle-light-secondary bx bx-edit font-medium-1" />
                                                        </Link>
                                                        <a href="javascript:void(0);" onClick={() => { setModal(true); setDelId(problem._id)}}>
                                                            <i className="badge-circle badge-circle-light-secondary bx bx-trash font-medium-1" />
                                                        </a>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
 
        <div className={`modal fade text-left ${modal ? 'show' : ''}`} id="danger" tabIndex={-1} aria-labelledby="myModalLabel120" style={modal ? styleShow : styleHide} aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header bg-danger">
                        <h5 className="modal-title white" id="myModalLabel120">Danger!</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                        onClick={() => {setModal(false)}}>
                            <i className="bx bx-x" />
                        </button>
                    </div>
                    <div className="modal-body">
                        Are you sure! You want to delete this Problem?
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-light-secondary" data-dismiss="modal"
                        onClick={() => {setModal(false)}}>
                            <i className="bx bx-x d-block d-sm-none" />
                            <span className="d-none d-sm-block">Cancel</span>
                        </button>
                        <button type="button" className="btn btn-danger ml-1" data-dismiss="modal"
                        onClick={() => {deleteProblem(delId)}}>
                            <i className="bx bx-check d-block d-sm-none" />
                            <span className="d-none d-sm-block">Accept</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        </>
    );
};

export default ListProblems;
