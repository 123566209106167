const Forgotpassword = () => {
    
    return (
        <div className="horizontal-layout horizontal-menu navbar-static 1-column   footer-static bg-full-screen-image  blank-page" data-open="hover" data-menu="horizontal-menu" data-col="1-column">
            <div className="app-content content">
                <div className="content-overlay" />
                <div className="content-wrapper">
                    <div className="content-header row"></div>
                    <div className="content-body">
                        {/* forgot password start */}
                        <section className="row flexbox-container">
                            <div className="col-xl-7 col-md-9 col-10  px-0">
                                <div className="card bg-authentication mb-0">
                                    <div className="row m-0">
                                        {/* left section-forgot password */}
                                        <div className="col-md-6 col-12 px-0">
                                            <div className="card disable-rounded-right mb-0 p-2">
                                                <div className="card-header pb-1">
                                                    <div className="card-title">
                                                        <h4 className="text-center mb-2">
                                                            Forgot Password?
                                                        </h4>
                                                    </div>
                                                </div>
                                                <div className="form-group d-flex justify-content-between align-items-center mb-2">
                                                    <div className="text-left">
                                                        <div className="ml-3 ml-md-2 mr-1">
                                                            <a
                                                                href="auth-login.html"
                                                                className="card-link btn btn-outline-primary text-nowrap"
                                                            >
                                                                Sign in
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="mr-3">
                                                        <a
                                                            href="auth-register.html"
                                                            className="card-link btn btn-outline-primary text-nowrap"
                                                        >
                                                            Sign up
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="text-muted text-center mb-2">
                                                        <small>
                                                            Enter the email or
                                                            phone number you
                                                            used when you joined
                                                            and we will send you
                                                            temporary password
                                                        </small>
                                                    </div>
                                                    <form
                                                        className="mb-2"
                                                        action="index.html"
                                                    >
                                                        <div className="form-group mb-2">
                                                            <label
                                                                className="text-bold-600"
                                                                htmlFor="exampleInputEmailPhone1"
                                                            >
                                                                Email or Phone
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputEmailPhone1"
                                                                placeholder="Email or Phone"
                                                            />
                                                        </div>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary glow position-relative w-100"
                                                        >
                                                            SEND PASSWORD
                                                            <i
                                                                id="icon-arrow"
                                                                className="bx bx-right-arrow-alt"
                                                            />
                                                        </button>
                                                    </form>
                                                    <div className="text-center mb-2">
                                                        <a href="auth-login.html">
                                                            <small className="text-muted">
                                                                I remembered my
                                                                password
                                                            </small>
                                                        </a>
                                                    </div>
                                                    <div className="divider mb-2">
                                                        <div className="divider-text">
                                                            Or Sign in as
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-md-row flex-column">
                                                        <a
                                                            href="javascript:void(0);"
                                                            className="btn btn-social btn-google btn-block font-small-3 mb-1 mb-sm-1 mb-md-0 mr-md-1 text-center"
                                                        >
                                                            <i className="bx bxl-google font-medium-3" />
                                                            <span className="pl-1">
                                                                Google
                                                            </span>
                                                        </a>
                                                        <a
                                                            href="javascript:void(0);"
                                                            className="btn btn-social btn-facebook btn-block font-small-3 text-center mt-0"
                                                        >
                                                            <i className="bx bxl-facebook-square font-medium-3" />
                                                            <span className="pl-1">
                                                                Facebook
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* right section image */}
                                        <div className="col-md-6 d-md-block d-none text-center align-self-center">
                                            <img
                                                className="img-fluid"
                                                src="../../../app-assets/images/pages/forgot-password.png"
                                                alt="branding logo"
                                                width={300}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* forgot password ends */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Forgotpassword;
