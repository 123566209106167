import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { GetRecordsCount } from "../../../actions/globalActions";
import { deleteTradeinAction, listTradeinsAction } from "../../../actions/tradeInActions";
import Chart from "../charts/Chart";
import { deleteRepairCategoryAction, listRepairCategories } from "../../../actions/repairCategoriesActions";
import Pagination from "../../elements/Pagination";
import "./tradein.css"
import { base_url, capitalizeFirstChar, dateWithoutZone, getDate, getDateTime } from "../../../utils"
import axios from "axios";
import PiChart from "../charts/PiChart";


const ListLeads = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const tradeins = useSelector((state) => state.tradein.tradeins);
    const [modal, setModal] = useState(false);
    const [delId, setDelId] = useState(null);
    const [socialChart, setSocialChart] = useState([])
    const [leadsChart, setLeadsChart] = useState([])

    useEffect(() => {

        dispatch(GetRecordsCount('tradein'));
        dispatch(listTradeinsAction());

        axios.get(`${base_url}orders/chartData`,
            {
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            })
            .then(response => {
                console.log("Social Status", response.data.socialStatuses)
                const social = []
                const scoialData = response.data.socialStatuses;
                for (let i = 0; i < scoialData.length; i++) {
                    scoialData[i]._id.length > 1 && social.push({ "name": scoialData[i]._id, "y": scoialData[i].count });
                }
                setSocialChart(social);
                const lead = []
                let leadStatusesData = response.data.leadStatuses;
                for (let i = 0; i < leadStatusesData.length; i++) {
                    lead.push([leadStatusesData[i]._id, leadStatusesData[i].count]);

                }
                setLeadsChart(lead)

            })
            .catch(err => {
                console.log(err)
            });

    }, []);

    const updateRecord = (val) => {
        dispatch(listTradeinsAction(val));
    }


    const deleteLead = () => {
        console.log(delId);
        delId && dispatch(deleteTradeinAction(delId));
        setModal(false);
    }

    const styleShow = {
        display: 'block',
        paddingRight: '17px'
    }

    const styleHide = {
        display: 'none',
        paddingRight: '17px'
    }

    // console.log('..', orders);
    return (
        <>
            <div class="container-xxl flex-grow-1 container-p-y ">
                <nav aria-label="breadcrumb" className="">
                    <ol className="breadcrumb breadcrumb-style1 p-1">
                        <li className="breadcrumb-item">
                            <Link to="/admin">Admin</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <Link to="/admin/tradeins">Trade In</Link>
                        </li>
                    </ol>
                </nav>
                <div>
                    <nav className="navbar navbar-expand-lg">
                        {/* <div className="navbar-nav me-auto">
                            <a className="btn btn-primary me-1 collapsed toggle dropdown-toggle" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                Search Filter
                            </a>

                        </div> */}
                        <ul className="navbar-nav ms-lg-auto">
                            <li className="nav-item">
                                <Link to="/admin/tradeins/add" className="nav-link btn mx-1 btn-success">
                                    <span> <i className='bx bxs-plus-circle'></i>&nbsp;Create Trade In</span>
                                </Link>
                            </li>
                            {/* <li className="nav-item">
                                <a href="#" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="tf-icons navbar-icon bx bx-cog" />
                                    &nbsp;&nbsp;Action&nbsp;
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end ">
                                    <li><a className="dropdown-item" href="#">Delete Selected Leads</a>
                                    </li>
                                    <li><a className="dropdown-item" href="#">Export as CSV</a>
                                    </li>
                                </ul>
                            </li> */}
                        </ul>
                    </nav>
                    <div className="collapse" id="collapseExample" style={{}}>
                        <div className="col-12">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="row">

                                        <div className="col-md-6 mb-4">
                                            <label htmlFor="TypeaheadBasic" className="form-label">Search By</label>
                                            <input id="TypeaheadBasic" className="form-control typeahead" type="text" autoComplete="off" placeholder="Name / ID / IMEI" />
                                        </div>

                                        <div className="col-md-6 mb-4">
                                            <div>
                                                <label htmlFor="selectpickerBasic" className="form-label">Assigned To</label>
                                                <select id="selectpickerBasic" className="form-control selectpicker w-100" data-style="btn-default">
                                                    <option value="" disabled selected>Assigned To</option>
                                                    <option>Rocky</option>
                                                    <option>Pulp Fiction</option>
                                                    <option>The Godfather</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <div>
                                                <label htmlFor="selectpickerBasic" className="form-label">Manufacturer</label>
                                                <select id="selectpickerBasic" className="form-control selectpicker w-100" data-style="btn-default">
                                                    <option>Rocky</option>
                                                    <option>Pulp Fiction</option>
                                                    <option>The Godfather</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <div>
                                                <label htmlFor="selectpickerBasic" className="form-label">Referral Source</label>
                                                <select id="selectpickerBasic" className="form-control selectpicker w-100" data-style="btn-default">
                                                    <option>Rocky</option>
                                                    <option>Pulp Fiction</option>
                                                    <option>The Godfather</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <div>
                                                <label htmlFor="selectpickerBasic" className="form-label">Device</label>
                                                <select id="selectpickerBasic" className="form-control selectpicker w-100" data-style="btn-default">
                                                    <option>Rocky</option>
                                                    <option>Pulp Fiction</option>
                                                    <option>The Godfather</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6  mb-4">
                                            <label htmlFor="flatpickr-date" className="form-label">From date (created on)</label>
                                            <input type="text" className="form-control" placeholder="YYYY-MM-DD" id="flatpickr-date" />
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <label htmlFor="TypeaheadBasic" className="form-label">Customer Name</label>
                                            <input id="TypeaheadBasic" className="form-control typeahead" type="text" autoComplete="off" placeholder="Name / ID / IMEI" />
                                        </div>
                                        <div className="col-md-6 col-12 mb-4">
                                            <label htmlFor="flatpickr-date" className="form-label">To date (created on)</label>
                                            <input type="text" className="form-control" placeholder="YYYY-MM-DD" id="flatpickr-date" />
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <div>
                                                <label htmlFor="selectpickerBasic" className="form-label">Status</label>
                                                <select id="selectpickerBasic" className="form-control selectpicker w-100" data-style="btn-default">
                                                    <option>Rocky</option>
                                                    <option>Pulp Fiction</option>
                                                    <option>The Godfather</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12 mb-4">
                                            <label htmlFor="flatpickr-date" className="form-label">From date (Due on)</label>
                                            <input type="text" className="form-control" placeholder="YYYY-MM-DD" id="flatpickr-date" />
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <label htmlFor="TypeaheadBasic" className="form-label">Mobile/Phone</label>
                                            <input id="TypeaheadBasic" className="form-control typeahead" type="text" autoComplete="off" placeholder="Name / ID / IMEI" />
                                        </div>
                                        <div className="col-md-6 col-12 mb-4">
                                            <label htmlFor="flatpickr-date" className="form-label">To date (Due on)</label>
                                            <input type="text" className="form-control" placeholder="YYYY-MM-DD" id="flatpickr-date" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-top">
                                    <a href="#" className="btn btn-primary">
                                        Search
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row gy-2">
                    <div className="col-sm-6">
                        <div className="card ">
                            {/* <div className="card-header">
                        <table className="w-100">
                            {leadsChart?.map((lead, key) => {
                                return <td key={key}>
                                    <td>{capitalizeFirstChar(lead.name)} : {lead.y}</td>
                                </td>
                            })}
                        </table>

                    </div> */}
                            <div className="card-body">
                                <div className="d-sm-flex justify-content-center">
                                    <PiChart chartData={leadsChart} title="Trade In" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="card ">
                            {/* <div className="card-header">
                        <table className="text-center w-100">
                            {socialChart?.map((obj, key) => {
                                return <td key={key}>
                                    <td>{capitalizeFirstChar(obj?.name)} : {obj?.y}</td>
                                </td>
                            })}
                        </table>
                    </div> */}
                            <div className="card-body">
                                {/* <canvas id="polarChart" className="chartjs" data-height={337} height={337} width={588} style={{display: 'block', boxSizing: 'border-box', height: 337, width: 588}} /> */}
                                <div className="d-sm-flex align-items-center justify-content-center">
                                    <PiChart chartData={socialChart} title="Social" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="card my-2">
                    <div className="row py-1">
                        <div className="col-sm-6">
                            <h6 className="lead text-muted my-2 mx-2">TOTAL VALUE OF ALL LEADS:<span className="text-dark"> £17155.86</span></h6>
                        </div>
                        <div className="col-sm-6">
                            <h6 className="lead text-muted my-2 text-end me-2">LEADS WON: <span className="text-dark"> £17155.86</span></h6>
                        </div>
                    </div>
                </div> */}
                <div className="row my-2" id="basic-table">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="d-sm-flex justify-content-between">
                                    <h4>Manage Trade In</h4>
                                    {/* <div>
                    <a href="#">Today&nbsp;-</a> &nbsp;&nbsp;
                    <a href="#">Yesterday&nbsp;-</a>&nbsp;&nbsp;
                    <a href="#">7days&nbsp;-</a>&nbsp;&nbsp;
                    <a href="#">14days&nbsp;-</a>&nbsp;&nbsp;
                    <a href="#">30days&nbsp;-</a>&nbsp;&nbsp;
                    <a href="#">  All</a>&nbsp;&nbsp;
                    <select name="" id="" className="">
                    <option value="">Due Date</option>
                    <option value="">Create Date</option>
                    </select>&nbsp;&nbsp;
                    <select name="" id="" className="">
                    <option value="">10</option>
                    <option value="">25</option>
                    <option value="">50</option>
                    </select>
                    </div> */}
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive text-nowrap">
                                    <table className="table table-bordered d-p">
                                        <thead>
                                            <tr>
                                                {/* <th>
                                    <input type="checkbox" />
                                </th> */}
                                                {/* <th>Category</th>
                                <th>Manufacturer</th> */}
                                                {/* <th>Device</th>
                                <th>Service</th>
                                <th>Price</th>
                                <th>User</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Action</th> */}
                                                {/* <th>ID</th> */}
                                                <th>Item Name</th>
                                                <th>Device</th>
                                                <th>Seller Name</th>
                                                <th>Buyer Name</th>
                                                <th>Retail Price</th>
                                                <th>Cost Price</th>
                                                <th>Status</th>
                                                {/* <th>Assigned To</th> */}
                                                {/* <th>Referral</th> */}
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* {orders.length > 0 && orders.map((order, key) => {
                                
                                return  !order.isDeleted && <tr key={key}>
                                    <td className="text-bold-500">
                                        <input type="checkbox" />
                                    </td>
                                    <td>{order?.repairCategories?.name}</td>
                                    <td>{order?.manufactures?.name}</td>
                                    <td>{order?.devices?.name}</td>
                                    <td>{order?.repairServices?.map(service => service.problem.name).toString()}</td>
                                    <td>£{order?.repairServices?.reduce((previousValue, currentValue) => previousValue + Number(currentValue.retailPrice), 0)}</td>
                                    <td className="text-bold-500">
                                        {order?.orderDetails?.firstName + " " + order?.orderDetails?.lastName}
                                    </td>
                                    <td>
                                        {(new Date(order?.createdAt))?.toDateString()}
                                    </td>
                                    <td>{order?.orderStatus}</td>
                                    <td className="text-bold-500">
                                        <input type="checkbox" />
                                    </td>
                                    <td className="text-bold-500">
                                        <input type="checkbox" />
                                    </td>
                                    <td>
                                        <Link to={`/admin/orders/${order._id}`}>
                                            <i className="badge-circle badge-circle-light-secondary bx bx-show font-medium-1" />
                                        </Link>
                                        <a href="javascript:void(0);" onClick={() => { setModal(true); setDelId(order._id)}}>
                                            <i className="badge-circle badge-circle-light-secondary bx bx-trash font-medium-1" />
                                        </a>
                                    </td>
                                </tr>
                            })} */}
                                            {
                                                tradeins.length > 0 && tradeins.map((tradein, key) => {
                                                    return !tradein.isDeleted && <tr key={key}>
                                                        {/* <td>
                                                            <Link to={`/admin/tradeins/${tradein._id}`}>{tradein?.ticket ? tradein?.ticket : tradein._id}</Link>
                                                        </td> */}
                                                        <td>{tradein?.name} <br /> {tradein?.repairServices?.map(service => <>{service.problem.name.toString()} <br /></>)}</td>
                                                        
                                                        <td>{tradein?.devices?.name} <br /> {tradein?.repairServices?.map(service => <>{service.problem.name.toString()} <br /></>)}</td>
                                                        <td>
                                                            <span className="d-block">
                                                                <a href="javascript:void(0);">{tradein?.seller?.firstName} {tradein?.seller?.lastName}</a>
                                                            </span>
                                                            <small className="d-block">
                                                                <strong> Mobile:</strong> {tradein?.seller?.mobile}
                                                            </small>
                                                            <small className="b-block">
                                                                <strong>Email:</strong> {tradein?.seller?.email}
                                                            </small>
                                                            <small className="d-block">
                                                                <strong> Address:</strong> {tradein?.seller?.address}
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <span className="d-block">
                                                                <a href="javascript:void(0);">{tradein?.buyer?.firstName} {tradein?.buyer?.lastName}</a>
                                                            </span>
                                                            <small className="d-block">
                                                                <strong> Mobile:</strong> {tradein?.buyer?.mobile}
                                                            </small>
                                                            <small className="b-block">
                                                                <strong>Email:</strong> {tradein?.buyer?.email}
                                                            </small>
                                                            <small className="d-block">
                                                                <strong> Address:</strong> {tradein?.buyer?.address}
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <span className="d-block">{tradein?.retailPrice}</span>
                                                        </td>
                                                        <td>
                                                            <span className="d-block">{tradein?.costPrice}</span>
                                                        </td>
                                                       <td>Pending Purchase</td>
                                                        {/* <td><button className="btn bg-success text-white">WON</button></td> */}
                                                        {/* <td>
                                    <select name="" id="" className="btn border-0">
                                        <option value="">Not yet Assigned</option>
                                        <option value="">Sufyan</option>
                                        
                                    </select>
                                </td>
                                
                                <td>
                                Search Engine
                                </td> */}
                                                        <td>
                                                            <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i class="bx bx-dots-vertical-rounded"></i>
                                                            </button>
                                                            <ul className="dropdown-menu bg-white">
                                                                <li><Link to={`/admin/tradeins/${tradein._id}`} className="dropdown-item">Edit</Link>
                                                                </li>
                                                                <li><a className="dropdown-item" href="javascript:void(0);"
                                                                    onClick={() => { setModal(true); setDelId(tradein._id) }}>Delete</a>
                                                                </li>
                                                                {/* <li><Link className="dropdown-item" to={`/admin/leads/${order._id}`}>View/Add Comment</Link>
                                                                </li> */}
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="my-2 text-end">
                                    <Pagination currentItems={tradeins} updateRecord={updateRecord} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className={`modal fade text-left ${modal ? 'show' : ''}`} id="danger" tabIndex={-1} aria-labelledby="myModalLabel120" style={modal ? styleShow : styleHide} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-danger">
                            <h5 className="modal-title white" id="myModalLabel120">Danger!</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={() => { setModal(false) }}>
                                <i className="bx bx-x" />
                            </button>
                        </div>
                        <div className="modal-body">
                            Are you sure! You want to delete this Lead?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light-secondary" data-dismiss="modal"
                                onClick={() => { setModal(false) }}>
                                <i className="bx bx-x d-block d-sm-none" />
                                <span className="d-none d-sm-block">Cancel</span>
                            </button>
                            <button type="button" className="btn btn-danger ml-1" data-dismiss="modal"
                                onClick={() => { deleteLead(delId) }}>
                                <i className="bx bx-check d-block d-sm-none" />
                                <span className="d-none d-sm-block">Accept</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ListLeads;