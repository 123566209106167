import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { deleteDeviceAction, listDevices, listDevicesAction } from "../../actions/deviceActions";
import { GetRecordsCount } from "../../actions/globalActions";
import Pagination from "../elements/Pagination";
import DeviceSerachFilters from "./DeviceSerachFilters";

const ListDevices = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const devices = useSelector((state) => state.device.devices);
    const [modal, setModal] = useState(false);
    const [delId, setDelId] = useState(null);

    useLayoutEffect(() => {
        dispatch(GetRecordsCount('devices'));
        dispatch(listDevicesAction());
    }, []);

    const updateRecord = (val) => {
        dispatch(listDevicesAction(val));
    }

    const styleShow = {
        display: 'block',
        paddingRight: '17px'
    }

    const styleHide = {
        display: 'none',
        paddingRight: '17px'
    }

    const deleteDevice = () => {
        console.log(delId);
        delId && dispatch(deleteDeviceAction(delId));
        setModal(false);
    }

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <nav aria-label="breadcrumb" className="">
                    <ol className="breadcrumb breadcrumb-style1 p-1">
                        <li className="breadcrumb-item">
                            <Link to="/admin">Admin</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <Link to="/admin/devices">Devices</Link>
                        </li>
                    </ol>
                </nav>
                <div>
                    <nav className="navbar navbar-expand-lg">
                        <div className="navbar-nav me-auto">
                            <a className="btn btn-primary me-1 collapsed toggle dropdown-toggle"
                             data-bs-toggle="collapse" href="#collapseExample" role="button" 
                             aria-expanded="false" aria-controls="collapseExample">
                                Search Filter
                            </a>

                        </div>
                        <ul className="navbar-nav ms-lg-auto">
                            <li className="nav-item">
                                <Link to="/admin/devices/add" className="nav-link btn mx-1 btn-success">
                                    <span> <i className='bx bxs-plus-circle'></i>&nbsp;Create Device</span>
                                </Link>
                            </li>
                            {/* <li className="nav-item">
                                <a href="#" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="tf-icons navbar-icon bx bx-cog" />
                                    &nbsp;&nbsp;Action&nbsp;
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end ">
                                    <li><a className="dropdown-item" href="#">Delete Selected Leads</a>
                                    </li>
                                    <li><a className="dropdown-item" href="#">Export as CSV</a>
                                    </li>
                                </ul>
                            </li> */}
                        </ul>
                    </nav>
                    <DeviceSerachFilters />
                </div>
                <div className="row" id="basic-table">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">
                                    Manage Devices
                                </h4>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                {/* <th>
                                        <input type="checkbox" />
                                    </th> */}
                                                <th>Manufacturer</th>
                                                <th>Name</th>
                                                <th>Slug</th>
                                                <th>Colors</th>
                                                {/* <th>Show on POS</th>
                                    <th>Show on widgets</th> */}
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {devices && devices.map(devices => {
                                                return !devices.isDeleted && <tr>
                                                    {/* <td className="text-bold-500">
                                            <input type="checkbox" />
                                        </td> */}
                                                    <td>{devices.manufactures.name}</td>
                                                    <td>{devices.name}</td>
                                                    <td>{devices.slug}</td>
                                                    <td className="text-bold-500">
                                                        {devices.colors.map(color => color.name + ", ")}
                                                    </td>
                                                    {/* <td className="text-bold-500">
                                            <input type="checkbox" />
                                        </td>
                                        <td className="text-bold-500">
                                            <input type="checkbox" />
                                        </td> */}
                                                    <td>
                                                        <Link to={`/admin/devices/${devices._id}`}>
                                                            <i className="badge-circle badge-circle-light-secondary bx bx-edit font-medium-1" />
                                                        </Link>
                                                        <a href="javascript:void(0);" onClick={() => { setModal(true); setDelId(devices._id) }}>
                                                            <i className="badge-circle badge-circle-light-secondary bx bx-trash font-medium-1" />
                                                        </a>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="my-2 text-end">
                                    <Pagination currentItems={devices} updateRecord={updateRecord} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal fade text-left ${modal ? 'show' : ''}`} id="danger" tabIndex={-1} aria-labelledby="myModalLabel120" style={modal ? styleShow : styleHide} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-danger">
                            <h5 className="modal-title white" id="myModalLabel120">Danger!</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={() => { setModal(false) }}>
                                <i className="bx bx-x" />
                            </button>
                        </div>
                        <div className="modal-body">
                            Are you sure! You want to delete this Manufacturer?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light-secondary" data-dismiss="modal"
                                onClick={() => { setModal(false) }}>
                                <i className="bx bx-x d-block d-sm-none" />
                                <span className="d-none d-sm-block">Cancel</span>
                            </button>
                            <button type="button" className="btn btn-danger ml-1" data-dismiss="modal"
                                onClick={() => { deleteDevice(delId) }}>
                                <i className="bx bx-check d-block d-sm-none" />
                                <span className="d-none d-sm-block">Accept</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ListDevices;
