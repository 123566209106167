import axios from "axios";
import { alertMessage, base_url, check_response_status } from "../utils";
import { GET_LOYALTY_TYPES, GET_PRODUCT, GET_VALUATION_METHOD, GET_WARRANTY_TYPES, LIST_PRODUCTS, PRODUCTS_COUNT } from "./types";

const api = "products";

// list Product
export const listProducts = (from = 0, limit = 20) => async dispatch => {

    axios.get(`${base_url + api}?from=${from}&limit=${limit}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                // console.log('products', response.data);
                dispatch({
                    type: LIST_PRODUCTS,
                    payload: response.data
                });
            }
            else if (response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}


// Products Count
export const productsCount = () => async dispatch => {

    axios.get(`${base_url}products/count`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: PRODUCTS_COUNT,
                    payload: response?.data?.totalCount
                });
            }
            else if (response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            dispatch(check_response_status(err.response));
        });
}

//get Product
export const getProduct = (id) => async dispatch => {
    axios.get(`${base_url + api}/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                // console.log('product', response.data);
                dispatch({
                    type: GET_PRODUCT,
                    payload: response.data
                });
            }
            else if (response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

// add Product
export const addProductAction = (product) => async dispatch => {

    axios.post(`${base_url + api}`,
        product,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log('signup', response);
            if (response.status == 201) {
                dispatch(alertMessage(response.data));
                dispatch(listProducts());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//update Product
export const updateProductAction = (id, product) => async dispatch => {
    axios.put(`${base_url + api}/${id}`,
        product,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log('signup', response);
            if (response.status == 200) {
                dispatch(alertMessage({ flag: 'success', message: "Product Upadated Successfully." }));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

export const deleteProductAction = (id) => async dispatch => {
    // console.log('del man', id)
    axios.delete(`${base_url + api}/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log('delete', response);
            if (response.status == 200) {
                dispatch(alertMessage({ flag: 'success', message: response.data.message }));
                dispatch(listProducts());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

export const getLoyaltyTypes = () => async dispatch => {

    axios.get(`${base_url + api}/getLoyaltyTypes`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                // console.log('products', response.data);
                dispatch({
                    type: GET_LOYALTY_TYPES,
                    payload: response.data
                });
            }
            else if (response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

export const getWarrantyTypes = () => async dispatch => {

    axios.get(`${base_url + api}/getWarrantyTypes`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                // console.log('products', response.data);
                dispatch({
                    type: GET_WARRANTY_TYPES,
                    payload: response.data
                });
            }
            else if (response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

export const getValuationMethods = () => async dispatch => {

    axios.get(`${base_url + api}/getValuationMethods`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                // console.log('products', response.data);
                dispatch({
                    type: GET_VALUATION_METHOD,
                    payload: response.data
                });
            }
            else if (response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}
export const listProductsById = (id) => async dispatch => {

    axios.get(`${base_url}products/active/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200 || response.status == 201) {
                dispatch({
                    type: LIST_PRODUCTS,
                    payload: response.data
                });
            }
            else if (response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}