import {
    GET_TRADE_IN,
    LIST_TRADE_IN,
    LIST_SALE_STATUS,
} from "../actions/types";

const initialState = {
    tradeins: [],
    tradein: null,
    error: null,
    saleStatus: [],
    invoiceStatus: []
};
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case LIST_SALE_STATUS:
            return {
                ...state,
                saleStatus: payload
            }
        // case LIST_INVOICE_STATUS:
        //     return {
        //         ...state,
        //         invoiceStatus: payload
        //     }
        case LIST_TRADE_IN:
            return {
                ...state,
                tradeins: payload
            }
        case GET_TRADE_IN:
            return {
                ...state,
                tradein: payload
            }
        default:
            return state;
    }
}
