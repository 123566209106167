import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { Link, useNavigate } from "react-router-dom";
import { base_url } from "../../../utils";
import { addEmployeeAction } from "../../../actions/employeeActions"
import { listStores } from "../../../actions/storeActions"
import Alert from "../../elements/Alert";
import { listUserRoles } from "../../../actions/userRoleActions"

const AddEmployee = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [hourlyRate, setHourlyRate] = useState(0)
  const [accessPin, setAccessPin] = useState(0)
  const [storeOptions, setStoreOptions] = useState([])
  const [roleOptions, setRoleOptions] = useState([])

  const stores = useSelector((state) => state.store.stores);
  const userRoles = useSelector((state) => state.userRole.userRoles);

  const [storeAccess, setStoreAccess] = useState([])

  useLayoutEffect(() => {
    dispatch(listStores());
    dispatch(listUserRoles());
  }, []);

  useEffect(() => {
    if (stores) {
      let accesses = stores.filter(stor => !stor.isDeleted);

      setStoreAccess(accesses.map(stor => {
        return {
          store: { id: stor._id, name: stor.name },
          access: false,
          assignment: false
        };
      }));
    }
  }, [stores]);

  const updateAccess = (id, access, assignment) => {
    const updatedAccesses = storeAccess.map(acc => {
      if (acc.store.id === id) {
        return {
          ...acc,
          access,
          assignment
        };
      }
      return acc;
    });
    setStoreAccess(updatedAccesses);
  };

const addEmployee = (e) => {
  e.preventDefault()
  const new_employee = {
    firstName,
    lastName,
    email,
    password,
    defaultStore: storeOptions,
    role: roleOptions,
    accessPin,
    hourlyRate,
    storeAccess,
    userType: 'EMPLOYEE'
  }
  console.log('employee create', new_employee)
  // dispatch(addEmployeeAction(new_employee));
  // navigate("/admin/employees");
}

return (
  <>

    <div className="container-xxl flex-grow-1 cotainer-p-y">
      <div className="breadcrumbs-top py-4">
        <div className="breadcrumb-wrapper d-none d-sm-block">
          <ol className="breadcrumb p-0 mb-0 pl-1">
            <li className="breadcrumb-item">
              <a href="javascript:void(0);">
                <i className="bx bx-home-alt" />
              </a>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/employees">Employees</Link>
            </li>
            <li className="breadcrumb-item active">
              <a href="javascript:void(0);">Add New</a>
            </li>
          </ol>
        </div>
      </div>
      <section id="basic-horizontal-layouts">
        <div className="row match-height">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <div className="card-title">
                  <h5 className="content-header-title float-left pr-1 mb-0">
                    Add New Employee
                  </h5>
                </div>
              </div>
              <div className="card-body">
                <form className="form form-horizontal" onSubmit={addEmployee}>
                  <div className="form-body">
                    <h4>Basic information</h4>
                    <hr />
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <label >First Name</label>
                        <input
                          type="text"
                          className="mb-4 form-control"
                          placeholder="Enter your first Name"
                          required
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label >Last Name</label>
                        <input
                          type="text"
                          className="mb-4 form-control"
                          placeholder="Enter your Last Name"
                          required
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <label >Email</label>
                        <input
                          type="email"
                          className="mb-4 form-control"
                          placeholder="Enter your Email"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Password</label>
                        <input
                          type="password"
                          className="mb-4 form-control"
                          placeholder="Enter your Password"
                          required
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <h4>Roles & Access</h4>
                      <div className="col-md-6 mb-4 form-group">
                        <select className="form-control" name="name" value={storeOptions} onChange={(e) => { setStoreOptions(e.target.value) }}>
                          <option value="">Select Store</option>{stores.map((store, key) => { return !store.isDeleted ? <option value={store._id} key={key}>{store.name}</option> : "" })}
                        </select>
                      </div>

                      <div className="col-md-6 mb-4 form-group mb-2">
                        <select className="form-control" name="name" value={roleOptions} onChange={(e) => { setRoleOptions(e.target.value) }}>
                          <option value="">Select Role</option>{userRoles && userRoles.map((role, key) => { return !role.isDeleted ? <option value={role._id} key={key}>{role.name}</option> : "" })}
                        </select>
                      </div>

                    </div>
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <label>Hourly Rate</label>
                        <input
                          type="number"
                          className="mb-4 form-control"
                          placeholder="Enter your Password"
                          required
                          value={hourlyRate}
                          onChange={(e) => setHourlyRate(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Access Pin</label>
                        <input
                          type="tel"
                          className="mb-4 form-control"
                          placeholder="Enter your phone number"
                          required
                          value={accessPin}
                          onChange={(e) => setAccessPin(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <h5 className="card-header">Store Access</h5>
                        <div className="table-responsive">
                          <table className="table table-striped table-borderless">
                            <thead>
                              <tr>
                                <th className="text-nowrap">Store Name</th>
                                <th className="text-nowrap text-center">✉️ Access</th>
                                <th className="text-nowrap text-center">🖥 Assignment</th>
                              </tr>
                            </thead>
                            <tbody>
                              {storeAccess && storeAccess.map((stor, key) => {
                                return <tr key={key}>
                                  <td className="text-nowrap">{stor.store.name}</td>
                                  <td>
                                    <div className="form-check d-flex justify-content-center">
                                      <input className="form-check-input" type="checkbox" id="defaultCheck1"
                                        checked={stor.access}
                                        onChange={() => updateAccess(stor.store.id, !stor.access, stor.assignment)} />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-check d-flex justify-content-center">
                                      <input className="form-check-input" type="checkbox" id="defaultCheck2"
                                        checked={stor.assignment} 
                                        onChange={() => updateAccess(stor.store.id, stor.access, !stor.assignment)} />
                                    </div>
                                  </td>
                                </tr>
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>



                    <div className="col-sm-12 d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary mx-1"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </>
);
}

export default AddEmployee;