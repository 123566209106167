import {
    ALERT_MESSAGE,
    ERRORS_GET,
    LOGOUT,
    SERVER_ERROR,
} from "./actions/types";
import { logoutUser } from "./actions/authActions";

export const check_response = (response) => async (dispatch) => {
    if (response && response.status == 422) {
        dispatch({
            type: ERRORS_GET,
            payload: response.data.errors,
        });
    } else if (response && response.status == 400) {
        dispatch(alertMessage(response.data));
    } else if (response && response.status == 203) {
        dispatch(logoutUser());
    } else if (response && (response.status == 500 || response.status == 500)) {
        dispatch({
            type: SERVER_ERROR,
            payload: "Server Error! Please contact Administator",
        });
    } else {
        console.log(response);
    }
};

export const alertMessage = (response) => async (dispatch) => {
    dispatch({
        type: ALERT_MESSAGE,
        payload: {
            message: response.message,
            flag: response.flag,
        }
    });
};

export function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${date}${separator}${month < 10 ? `0${month}` : `${month}`
        }${separator}${year}`;
}

export const has_permission = (id) => {
    if (is_admin()) {
        return true;
    }
    var userPermissions = localStorage.userPermissions;
    if (userPermissions) {
        userPermissions = JSON.parse(localStorage.userPermissions);
        return userPermissions.includes(id);
    } else {
        return false;
    }
};

export const isLogin = () => { };

export const is_admin = () => {
    if (
        localStorage.role == "superAdmin" ||
        localStorage.role == "projectCoordinator"
    )
        return true;
    else return false;
};

export const check_response_status = (res) => async (dispatch) => {
    console.log("res", res.status);
    if (res && res.status == 400 || res.statusCode == 400) {
        dispatch(alertMessage({ flag: 'danger', message: res.data.message.toString() }));
    } else if (res.status == 203) {
        console.log("res203", res);
        localStorage.removeItem("usertoken");
        localStorage.removeItem("role");
        dispatch({
            type: LOGOUT,
            payload: true,
        });
    } else if (res.status == 401) {
        console.log("res401", res);
        localStorage.removeItem("usertoken");
        localStorage.removeItem("role");
        dispatch({
            type: LOGOUT,
            payload: true,
        });
    } else if (res.status == 500) {
        console.log("res500", res);
        dispatch({
            type: SERVER_ERROR,
            payload: {
                flag: "danger",
                message: "Server Error! Please contact Administator",
            },
        });
    } else {
        console.log("res1", res);
        dispatch({
            type: SERVER_ERROR,
            payload: {
                flag: "danger",
                message: "Server Error! Please contact Administator",
            },
        });
    }
};

export const capitalizeFirstChar = (str) => {
    // console.log(str)
    // return string.charAt(0).toUpperCase().slice(1)
    return str?.charAt(0)?.toUpperCase() + str?.slice(1)
}

export const date_time_to_date_format = (datetime) => {
    if (datetime) {
        datetime = datetime.replace("T", " ");
        datetime = datetime.replace(".000000Z", "");
        let dateTimeParts = datetime.split(/[- :]/);
        dateTimeParts[1]--;
        const dateObject = new Date(...dateTimeParts);
        return dateObject.toDateString("en-US");
    } else {
        return "";
    }
};

export const getDate = (datetime) => {
    return (new Date(datetime))?.toDateString()
};
export const getTime = (datetime) => {
    console.log(datetime)
};



export const getDateTime = (time) => {
    const { startAt, endAt } = time;
    var { day, month, year, hour, minute } = startAt;
    let str = day + '/' + month + '/' + year + ' ' + hour + ':' + (minute == 0 ? '00' : minute);
    var { day, month, year, hour, minute } = endAt;
    return str + ' to ' + hour + ':' + (minute == 0 ? '00' : minute);
}

export const AMPMformat = (time) => {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
        time = time.slice(1);  // Remove full string match value
        time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
}

export const dateWithoutZone = (now) => {
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
    const twelveHourFormatHours = hours % 12 || 12;
    const dateTimeString = now.toISOString().slice(0, 10);
    return `${dateTimeString} ${twelveHourFormatHours}:${minutes} ${amOrPm}`;
}

// export const base_url = "http://localhost:3000/";

export const base_url = "https://betaapi.mrfixs.net/";
export const base_url_file = "https://betaapi.mrfixs.net/api/download-file";
export const server_url = "https://betaapi.mrfixs.net";

// export const base_url = 'https://dev.portal.api.ehya.com.pk/api/';
// export const base_url_file = 'https://dev.portal.api.ehya.com.pk/api/download-file';
// export const server_url = 'https://dev.portal.api.ehya.com.pk';
