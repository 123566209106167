import React from "react";
import { Link } from "react-router-dom";

const PurchaseOrdersAdd = () => {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <nav aria-label=" breadcrumb " className="bg-white">
                <ol className="breadcrumb breadcrumb-style1 p-1">
                    <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">
                        <Link to="/admin/inventory-count">Purchase Order</Link>
                    </li>
                    <li className="breadcrumb-item active">
                        Add New Improved Purchase Order
                    </li>
                </ol>
            </nav>

            <div>
                <div className="span9 border-bottom ">
                    <h3 className="txt24 m0">Add New Purchase Order</h3>
                </div>
                <div className="toggle-box-filter border-bottom dropdown hide search-form p-3">
                    <b className="caret2" />
                    <form
                        className="container-fluid py-4"
                        action="/index.php?r=inventoryCount/index"
                        method="get"
                    >
                        <div className="row">
                            <div className="col-12">
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-4 mb-4">
                                                <label
                                                    htmlFor="selectpickerBasic"
                                                    className="form-label"
                                                >
                                                    Supplier
                                                </label>
                                                <select
                                                    id="selectpickerBasic"
                                                    className="form-control selectpicker w-100"
                                                    data-style="btn-default"
                                                >
                                                    <option>Supplier 1</option>
                                                    <option>Supplier 2</option>
                                                    <option>Supplier 3</option>
                                                </select>
                                            </div>
                                            <div className="col-md-4 mb-4">
                                                <label
                                                    htmlFor="TypeaheadBasic"
                                                    className="form-label"
                                                >
                                                    Shipping Via
                                                </label>
                                                <input
                                                    id="TypeaheadBasic"
                                                    className="form-control typeahead"
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="Shipping Via"
                                                />
                                            </div>
                                            <div className="col-md-4 mb-4">
                                                <label
                                                    htmlFor="TypeaheadBasic"
                                                    className="form-label"
                                                >
                                                    Tracking Number
                                                </label>
                                                <input
                                                    id="TypeaheadBasic"
                                                    className="form-control typeahead"
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="Tracking Number"
                                                />
                                            </div>
                                            <div className="col-md-4 mb-4">
                                                <label
                                                    htmlFor="TypeaheadBasic"
                                                    className="form-label"
                                                >
                                                    Ship To
                                                </label>
                                                <textarea
                                                    id="TypeaheadBasic"
                                                    className="form-control typeahead"
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="Ship To"
                                                ></textarea>
                                            </div>
                                            <div className="col-md-4 mb-4">
                                                <label
                                                    htmlFor="TypeaheadBasic"
                                                    className="form-label"
                                                >
                                                    Internal Notes
                                                </label>
                                                <textarea
                                                    id="TypeaheadBasic"
                                                    className="form-control typeahead"
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="Internal Notes"
                                                ></textarea>
                                            </div>

                                            <div className="col-md-4 mb-4">
                                                <div>
                                                    <label
                                                        htmlFor="selectpickerBasic"
                                                        className="form-label"
                                                    >
                                                        Purchase Order Status
                                                    </label>
                                                    <select
                                                        id="selectpickerBasic"
                                                        className="form-control selectpicker w-100"
                                                        data-style="btn-default"
                                                    >
                                                        <option
                                                            value=""
                                                            disabled
                                                            selected
                                                        >
                                                            Select
                                                        </option>
                                                        <option>Pending</option>
                                                        <option>Closed</option>
                                                        <option>
                                                            Completed
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-4">
                                                <label
                                                    htmlFor="flatpickr-date"
                                                    className="form-label"
                                                >
                                                    Date
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="YYYY-MM-DD"
                                                    id="flatpickr-date"
                                                />
                                            </div>
                                            <div className="col-md-4 mb-4">
                                                <label
                                                    htmlFor="flatpickr-date"
                                                    className="form-label"
                                                >
                                                    Delivery Due Date
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="YYYY-MM-DD"
                                                    id="flatpickr-date"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="table-responsive container-full">
                    <table
                        className="table table-bordered w-full"
                        style={{ whiteSpace: "nowrap" }}
                    >
                        <thead>
                            <tr className="bg-light">
                                <th scope="col">
                                    <input type="checkbox" name="" id="" />
                                </th>
                                <th scope="col">SKU/ID</th>
                                <th scope="col">Name</th>
                                <th scope="col">Description</th>
                                <th scope="col">QTY</th>
                                <th scope="col">Price</th>
                                <th scope="col">GST Class</th>
                                <th scope="col">GST</th>
                                <th scope="col">Total</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="odd">
                                <td className=""></td>
                                <td>123</td>
                                <td className=""></td>
                                <td></td>
                                <td className=""></td>
                                <td></td>
                                <td className=""></td>
                                <td></td>
                                <td className=""></td>
                                <td>
                                    <input type="button" value="Add Row" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="d-flex gap-3 flex-row-reverse">
                    <a
                        id="proceedBtn"
                        className="btn-primary p-2  open-semibold cp"
                        href="/index.php?r=inventoryCount/reviewnew&id=&_csrf=9f4c8a9f13a1bc6c28490fcce984ebfc"
                    >
                        <i className="fa fa-check-circle" />
                        &nbsp; Save{" "}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default PurchaseOrdersAdd;
