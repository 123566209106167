import {
    LOGIN,
    LOGOUT,
    PROFILE_GET,
    ROLES_GET,
    USER_ERRORS,
    USER_ERRORS_EMPTY,
    USER_MESSAGE
} from "../actions/types";

const initialState = {
    login: false,
    logout: false,
    errors: null,
    roles: null,
    response: null,
    profile: null
};
export default (state = initialState, { type, payload }) => {
    console.log(type, payload)
    switch (type) {
        case ROLES_GET:
            return {
                ...state,
                roles: payload
            }
        case USER_ERRORS:
            return {
                ...state,
                errors: payload
            }
        case USER_MESSAGE:
            return {
                ...state,
                response: payload
            }
        case USER_ERRORS_EMPTY:
            return {
                ...state,
                errors: [],
                response: null
            }
        case PROFILE_GET:
            return {
                ...state,
                profile: payload
            }
        case LOGIN:
            return {
                ...state,
                login: payload
            }
        case LOGOUT:
            return {
                ...state,
                login: false,
                logout: payload
            }
        default:
            return state;
    }
}