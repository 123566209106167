import { Route, Routes, Outlet } from "react-router-dom";
import AddDeviceColor from "../deviceColors/AddDeviceColor";
import ListDeviceColors from "../deviceColors/ListDeviceColors";
import UpdateDeviceColor from "../deviceColors/UpdateDeviceColor";

import Header from "../elements/Header";
import Menu from "../elements/Menu";
import AddManufacturer from "../manufacturers/AddManufacturer";
import ListManufacturers from "../manufacturers/ListManufacturers";
import UpdateManufacturer from "../manufacturers/UpdateManufacturer";
import AddTaxClass from "../taxClasses/AddTaxClass";
import ListTaxClass from "../taxClasses/ListTaxClass";
import UpdateTaxClass from "../taxClasses/UpdateTaxClass";
import AddDevice from "../devices/AddDevice";
import ListDevices from "../devices/ListDevices";
import UpdateDevice from "../devices/UpdateDevice";
import AddRepairCategory from "../repairCategories/AddRepairCategory";
import ListRepairCategories from "../repairCategories/ListRepairCategories";
import UpdateRepairCategory from "../repairCategories/UpdateRepairCategory";
import AddRepairService from "../repairService/AddRepairService";
import UpdateRepairService from "../repairService/UpdateRepairService";
import ListRepairServices from "../repairService/ListRepairServices";
import AddProblem from "../problems/AddProblem";
import UpdateProblem from "../problems/UpdateProblem";
import ListProblems from "../problems/ListProblems";
import ListOrders from "../orders/ListOrders";
import ViewOrder from "../orders/ViewOrder";
import AddProductCategory from "../productsModule/productCategories/AddProductCategory";
import UpdateProductCategory from "../productsModule/productCategories/UpdateProductCategory";
import ListProductCategories from "../productsModule/productCategories/ListProductCategories";
import AddBlog from "../blogs/AddBlog";
import UpdateBlog from "../blogs/UpdateBlog";
import ListBlogs from "../blogs/ListBlogs";
import AddStore from "../stores/AddStore";
import UpdateStore from "../stores/UpdateStore";
import ListStores from "../stores/ListStores";
import AddSeoPage from "../seopages/AddSeoPage";
import UpdateSeoPage from "../seopages/UpdateSeoPage";
import ListSeoPages from "../seopages/ListSeoPages";
import AddProductCondition from "../productsModule/productConditions/AddProductCondition";
import UpdateProductCondition from "../productsModule/productConditions/UpdateProductCondition";
import ListProductConditons from "../productsModule/productConditions/ListProductConditions";
import AddProductNetwork from "../productsModule/productNetworks/AddProductNetwork";
import UpdateProductNetwork from "../productsModule/productNetworks/UpdateProductNetwork";
import ListProductNetworks from "../productsModule/productNetworks/ListProductNetworks";
import AddProductPhysicalLocation from "../productsModule/productPhysicalLocation/AddProductPhysicalLocation";
import UpdateProductPhysicalLocation from "../productsModule/productPhysicalLocation/UpdateProductPhysicalLocation";
import ListProductPhysicalLocations from "../productsModule/productPhysicalLocation/ListProductPhysicalLocations";
import AddProductSupplier from "../productsModule/productSupplier/AddProductSupplier";
import UpdateProductSupplier from "../productsModule/productSupplier/UpdateProductSupplier";
import ListProductSuppliers from "../productsModule/productSupplier/ListProductSuppliers";
import ListInquiries from "../repairs/inquiries/ListInquiries";
import ViewInquiry from "../repairs/inquiries/ViewInquiry";
import ListLeads from "../repairs/leads/ListLeads";
import ViewLead from "../repairs/leads/ViewLead";
import AddLead from "../repairs/leads/AddLead";
import ListUserRoles from "../Employees/Roles/ListUserRoles"
import AddUserRole from "../Employees/Roles/AddUserRole"
import UpdateUserRole from "../Employees/Roles/UpdateUserRole"

import AddEmployee from "../Employees/ManageEmployees/AddEmployee"
import ListEmployees from "../Employees/ManageEmployees/ListEmployees";
import UpdateEmployee from "../Employees/ManageEmployees/UpdateEmployee"
import UpdateLead from "../repairs/leads/UpdateLead";
import ListRoles from "../Employees/Roles/ListRoles";
import AddRole from "../Employees/Roles/AddRole";
import UpdateRole from "../Employees/Roles/UpdateRole";
import AddCustomer from "../Employees/ManageCustomers/AddCustomer";
import ListCustomers from "../Employees/ManageCustomers/ListCustomers";
import UpdateCustomer from "../Employees/ManageCustomers/UpdateCustomer";
import AddProduct from "../productsModule/products/AddProduct";
import UpdateProduct from "../productsModule/products/UpdateProduct";
import ListProducts from "../productsModule/products/ListProducts";
import ListTradeIn from "../inventory/tradeIn/ListTradeIn";
import AddTradeIn from "../inventory/tradeIn/AddTradeIn";
import ListProductSizes from '../productsModule/productSizes/ListProductSizes';
import AddProductSize from '../productsModule/productSizes/AddProductSize';
import UpdateProductSize from '../productsModule/productSizes/UpdateProductSizes';
import SellOrders from "../buySellOrders/sellOrders/SellOrders";
import SellOrder from "../buySellOrders/sellOrders/SellOrder";
import ListDeviceSeries from "../deviceSeries/ListDeviceSeries";
import UpdateDeviceSeries from "../deviceSeries/UpdateDeviceSeries";
import AddDeviceSeries from "../deviceSeries/AddDeviceSeries";
import SellOrderAdd from "../buySellOrders/sellOrders/SellOrderAdd";
import SellOrderUpdate from "../buySellOrders/sellOrders/SellOrderUpdate";
import BuyDevices from "../buySellOrders/buyDevices/BuyDevices";
import BuyOrders from "../buySellOrders/buyOrders/BuyOrders";
import ListInventoryTransfer from "../inventoryTransfer/ListInventoryTransfer";
import AddProductType from "../productsModule/productType/AddProductType";
import UpdateProductType from "../productsModule/productType/UpdateProductTypes";
import ListProductTypes from "../productsModule/productType/ListProductTypes";
import ListTickets from "../repairs/tickets/ListTickets";
import AddTicket from "../repairs/tickets/AddTicket/AddTickets";
import InventoryTransfer from "../inventory/inventoryTransfer/InventoryTransfer";
import InventoryTransferView from "../inventory/inventoryTransfer/InventoryTransferView";
import InventoryTransferUpdate from "../inventory/inventoryTransfer/InventoryTransferUpdate";
import InventoryCount from "../inventory/inventoryCount/InventoryCount";
import InventoryTransferAdd from "../inventory/inventoryTransfer/InventoryTransferAdd";
import InventoryCountAdd from "../inventory/inventoryCount/InventoryCountAdd";
import InventoryCountUpdate from "../inventory/inventoryCount/InventoryCountUpdate";
import PurchaseOrders from "../orders/purchaseOrders/PurchaseOrders";
import PurchaseOrdersUpdate from "../orders/purchaseOrders/PurchaseOrdersUpdate";
import PurchaseOrdersAdd from "../orders/purchaseOrders/PurchaseOrdersAdd";

const Dashboard = () => {
    return (
        <>
            <Header />
            <div className="layout-page">
                <div className="content-wrapper">
                    <Menu />
                    <Outlet />
                    <Routes>
                        <Route path={"manufacturers/add"} element={<AddManufacturer />}></Route>
                        <Route path={"manufacturers/:id"} element={<UpdateManufacturer />}></Route>
                        <Route path={"manufacturers"} element={<ListManufacturers />}></Route>

                        <Route path={"device-colors/add"} element={<AddDeviceColor />}></Route>
                        <Route path={"device-colors/:id"} element={<UpdateDeviceColor />}></Route>
                        <Route path={"device-colors"} element={<ListDeviceColors />}></Route>

                        <Route path={"tax-classes/add"} element={<AddTaxClass />}></Route>
                        <Route path={"tax-classes/:id"} element={<UpdateTaxClass />}></Route>
                        <Route path={"tax-classes"} element={<ListTaxClass />}></Route>

                        <Route path={"devices/add"} element={<AddDevice />}></Route>
                        <Route path={"devices/:id"} element={<UpdateDevice />}></Route>
                        <Route path={"devices"} element={<ListDevices />}></Route>

                        <Route path={"repair-categories/add"} element={<AddRepairCategory />}></Route>
                        <Route path={"repair-categories/:id"} element={<UpdateRepairCategory />}></Route>
                        <Route path={"repair-categories"} element={<ListRepairCategories />}></Route>

                        <Route path={"repair-services/add"} element={<AddRepairService />}></Route>
                        <Route path={"repair-services/:id"} element={<UpdateRepairService />}></Route>
                        <Route path={"repair-services"} element={<ListRepairServices />}></Route>

                        <Route path={"problems/add"} element={<AddProblem />}></Route>
                        <Route path={"problems/:id"} element={<UpdateProblem />}></Route>
                        <Route path={"problems"} element={<ListProblems />}></Route>

                        <Route path={"product-conditions/add"} element={<AddProductCondition />}></Route>
                        <Route path={"product-conditions/:id"} element={<UpdateProductCondition />}></Route>
                        <Route path={"product-conditions"} element={<ListProductConditons />}></Route>

                        <Route path={"product-networks/add"} element={<AddProductNetwork />}></Route>
                        <Route path={"product-networks/:id"} element={<UpdateProductNetwork />}></Route>
                        <Route path={"product-networks"} element={<ListProductNetworks />}></Route>

                        {/* <Route path={"problems/add"} element={<AddProblem />}></Route> */}
                        <Route path={"orders/:id"} element={<ViewOrder />}></Route>
                        <Route path={"orders"} element={<ListOrders />}></Route>

                        <Route path={"blogs/add"} element={<AddBlog />}></Route>
                        <Route path={"blogs/:id"} element={<UpdateBlog />}></Route>
                        <Route path={"blogs"} element={<ListBlogs />}></Route>

                        <Route path={"pages/add"} element={<AddSeoPage />}></Route>
                        <Route path={"pages/:id"} element={<UpdateSeoPage />}></Route>
                        <Route path={"pages"} element={<ListSeoPages />}></Route>

                        <Route path={"stores/add"} element={<AddStore />}></Route>
                        <Route path={"stores/:id"} element={<UpdateStore />}></Route>
                        <Route path={"stores"} element={<ListStores />}></Route>

                        <Route path={"product-categories/add"} element={<AddProductCategory />}></Route>
                        <Route path={"product-categories/:id"} element={<UpdateProductCategory />}></Route>
                        <Route path={"product-categories"} element={<ListProductCategories />}></Route>

                        <Route path={"product-physical-locations/add"} element={<AddProductPhysicalLocation />}></Route>
                        <Route path={"product-physical-locations/:id"} element={<UpdateProductPhysicalLocation />}></Route>
                        <Route path={"product-physical-locations"} element={<ListProductPhysicalLocations />}></Route>

                        <Route path={"product-suppliers/add"} element={<AddProductSupplier />}></Route>
                        <Route path={"product-suppliers/:id"} element={<UpdateProductSupplier />}></Route>
                        <Route path={"product-suppliers"} element={<ListProductSuppliers />}></Route>
                        {/* Repaire Routes */}
                        <Route path={"inquiries/:id"} element={<ViewInquiry />}></Route>
                        <Route path={"inquiries"} element={<ListInquiries />}></Route>

                        {/* Manage Leads Routes */}
                        <Route path={"leads/add"} element={<AddLead />}></Route>
                        <Route path={"leads/:id"} element={<UpdateLead />}></Route>
                        <Route path={"leads"} element={<ListLeads />}></Route>

                        {/* Sell Orders Routes */}
                        <Route path={"sell-orders/add"} element={<SellOrderAdd />}></Route>
                        <Route path={"sell-orders/:id"} element={<SellOrder />}></Route>
                        <Route path={"sell-orders/edit/:id"} element={<SellOrderUpdate />}></Route>
                        <Route path={"sell-orders"} element={<SellOrders />}></Route>

                        {/* Buy Devices Routes */}
                        <Route path={"sell-orders/add"} element={<SellOrderAdd />}></Route>
                        <Route path={"sell-orders/:id"} element={<SellOrder />}></Route>
                        <Route path={"sell-orders/edit/:id"} element={<SellOrderUpdate />}></Route>
                        <Route path={"buy-devices"} element={<BuyDevices />}></Route>

                        {/* Buy Orders Routes */}
                        <Route path={"buy-orders/add"} element={<AddLead />}></Route>
                        <Route path={"buy-orders/:id"} element={<UpdateLead />}></Route>
                        <Route path={"buy-orders"} element={<BuyOrders />}></Route>


                        {/* Employess Roles Routes */}
                        <Route path={"roles"} element={<ListRoles />}></Route>
                        <Route path={"roles/add"} element={<AddRole />}></Route>
                        <Route path={"roles/:id"} element={<UpdateRole />}></Route>

                        <Route path={"employees/add"} element={<AddEmployee />}></Route>
                        <Route path={"employees"} element={<ListEmployees />}></Route>
                        <Route path={"employees/:id"} element={<UpdateEmployee />}></Route>

                        <Route path={"customers/add"} element={<AddCustomer />}></Route>
                        <Route path={"customers"} element={<ListCustomers />}></Route>
                        <Route path={"customers/:id"} element={<UpdateCustomer />}></Route>

                        <Route path={"products/add"} element={<AddProduct />}></Route>
                        <Route path={"products"} element={<ListProducts />}></Route>
                        <Route path={"products/:id"} element={<UpdateProduct />}></Route>

                        <Route path={"tradeins/add"} element={<AddTradeIn />}></Route>
                        <Route path={"tradeins"} element={<ListTradeIn />}></Route>
                        <Route path={"products/:id"} element={<UpdateProduct />}></Route>

                        <Route path={"product-sizes/add"} element={<AddProductSize />}></Route>
                        <Route path={"product-sizes/:id"} element={<UpdateProductSize />}></Route>
                        <Route path={"product-sizes"} element={<ListProductSizes />}></Route>

                        <Route path={"device-series/add"} element={<AddDeviceSeries />}></Route>
                        <Route path={"device-series/:id"} element={<UpdateDeviceSeries />}></Route>
                        <Route path={"device-series"} element={<ListDeviceSeries />}></Route>

                        <Route path={"product-types/add"} element={<AddProductType />}></Route>
                        <Route path={"product-types/:id"} element={<UpdateProductType />}></Route> 
                        <Route path={"product-types"} element={<ListProductTypes />}></Route>

                        <Route path="manage-tickets" element ={<ListTickets />} /> 
                        <Route path="manage-tickets/add" element ={<AddTicket />} />


                        <Route path={"inventory-transfer"} element={<InventoryTransfer />}></Route>
                        <Route path={"inventory-transfer/view"} element={<InventoryTransferView />}></Route>
                        <Route path={"inventory-transfer/update"} element={<InventoryTransferUpdate />}></Route>
                        <Route path={"inventory-transfer/add"} element={<InventoryTransferAdd />}></Route>
                        
                        <Route path={"inventory-count"} element={<InventoryCount />}></Route>
                        <Route path={"inventory-count/add"} element={<InventoryCountAdd />}></Route>
                        <Route path={"inventory-count/update"} element={<InventoryCountUpdate />}></Route>

                        <Route path={"purchase-orders"} element={<PurchaseOrders />}></Route>
                        <Route path={"purchase-orders/add"} element={<PurchaseOrdersAdd />}></Route>
                        <Route path={"purchase-orders/update"} element={<PurchaseOrdersUpdate />}></Route>

                        

                        {/* 
                        <Route path={"roles/:id"} element={<UpdateUserRole />}></Route> */}



                    </Routes>
                    <div className="content-backdrop fade"></div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
