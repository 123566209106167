import React, { useState } from "react";
import { Link } from "react-router-dom";

const PurchaseOrders = () => {
    const [transfer, setTransfer] = useState(true);
    const [actionDrop, setActionDrop] = useState(false);

    const [serch, setSearch] = useState(false);

    const transferInToogle = () => {
        setTransfer(true);
    };
    const transferOutToogle = () => {
        setTransfer(false);
    };
    const actionDropToogle = () => {
        setActionDrop(!actionDrop);
    };
    const serchDropToogle = () => {
        setSearch(!serch);
    };

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-style1 p-1">
                        <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                        </li>

                        <li className="breadcrumb-item active">
                            <Link to="">Purchase Orders</Link>
                        </li>
                    </ol>
                </nav>

                <nav className="navbar navbar-expand-lg">
                    <div className="navbar-nav me-auto">
                        <button
                            onClick={serchDropToogle}
                            className="btn btn-primary me-1 collapsed toggle dropdown-toggle"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseExample"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                        >
                            Search Filter
                        </button>
                    </div>

                    <ul className="navbar-nav ms-lg-auto">
                        <li className="nav-item">
                            <Link
                                className="nav-link btn mx-1 btn-success"
                                to="add"
                            >
                                <span>
                                    <i className="bx bxs-plus-circle" />
                                    New Purchase Order{" "}
                                </span>
                            </Link>
                        </li>
                    </ul>
                </nav>
                {serch ? (
                    <div className="toggle-box-filter dropdown hide search-form p-3">
                        <b className="caret2" />
                        <form
                            className="container-fluid py-4"
                            action="/index.php?r=inventoryCount/index"
                            method="get"
                        >
                            <div className="row">
                                <div className="col-12">
                                    <div className="card mb-4">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-4 mb-4">
                                                    <label
                                                        htmlFor="TypeaheadBasic"
                                                        className="form-label"
                                                    >
                                                        Purchase Order Id
                                                    </label>
                                                    <input
                                                        id="TypeaheadBasic"
                                                        className="form-control typeahead"
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="Purchase Order Id"
                                                    />
                                                </div>

                                                <div className="col-md-4 mb-4">
                                                    <div>
                                                        <label
                                                            htmlFor="selectpickerBasic"
                                                            className="form-label"
                                                        >
                                                            Purchase Order
                                                            Status
                                                        </label>
                                                        <select
                                                            id="selectpickerBasic"
                                                            className="form-control selectpicker w-100"
                                                            data-style="btn-default"
                                                        >
                                                            <option
                                                                value=""
                                                                disabled
                                                                selected
                                                            >
                                                                Select
                                                            </option>
                                                            <option>
                                                                Pending
                                                            </option>
                                                            <option>
                                                                Closed
                                                            </option>
                                                            <option>
                                                                Completed
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mb-4">
                                                    <label
                                                        htmlFor="selectpickerBasic"
                                                        className="form-label"
                                                    >
                                                        Supplier
                                                    </label>
                                                    <select
                                                        id="selectpickerBasic"
                                                        className="form-control selectpicker w-100"
                                                        data-style="btn-default"
                                                    >
                                                        <option>
                                                            Supplier 1
                                                        </option>
                                                        <option>
                                                            Supplier 2
                                                        </option>
                                                        <option>
                                                            Supplier 3
                                                        </option>
                                                    </select>
                                                </div>
                                                <div className="col-md-4 mb-4">
                                                    <div>
                                                        <label
                                                            htmlFor="selectpickerBasic"
                                                            className="form-label"
                                                        >
                                                            Payment Status
                                                        </label>
                                                        <select
                                                            id="selectpickerBasic"
                                                            className="form-control selectpicker w-100"
                                                            data-style="btn-default"
                                                        >
                                                            <option
                                                                value=""
                                                                disabled
                                                                selected
                                                            >
                                                                Select
                                                            </option>
                                                            <option>
                                                                Pending
                                                            </option>
                                                            <option>
                                                                Closed
                                                            </option>
                                                            <option>
                                                                Completed
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mb-4">
                                                    <label
                                                        htmlFor="flatpickr-date"
                                                        className="form-label"
                                                    >
                                                        Date
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="YYYY-MM-DD"
                                                        id="flatpickr-date"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer border-top">
                                            <a
                                                href="#"
                                                className="btn btn-primary"
                                            >
                                                Search
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                ) : (
                    ""
                )}
            </div>

            <div className="row" id="basic-table">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="border d-flex justify-content-between justify-content-center">
                                <div>
                                    <h5 className="px-2 pb-2 my-2">
                                        Purchase Orders
                                    </h5>
                                </div>

                                <select className="browser-default px-2 py-1 custom-select my-2 mx-2">
                                    <option selected>50</option>
                                    <option value={1}>100</option>
                                    <option value={2}>250</option>
                                    <option value={3}>500</option>
                                </select>
                            </div>

                            <div className="table-responsive container-full">
                                <table
                                    className="table table-bordered w-full"
                                    style={{ whiteSpace: "nowrap" }}
                                >
                                    <thead>
                                        <tr className="bg-light">
                                            <th scope="col">ID</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Product</th>
                                            <th scope="col">Created By</th>
                                            <th scope="col">Special Order</th>
                                            <th scope="col">Payment Status</th>
                                            <th scope="col">Supplier</th>
                                            <th scope="col">Tracking ID</th>
                                            <th scope="col">Purchase Order Status</th>
                                            <th scope="col">Total</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="odd">
                                            <td className="">IC-022</td>
                                            <td> 16 Aug, 2023 (11:19 am)</td>
                                            <td>Mrfix EXTRA</td>
                                            <td>
                                                Mrfix EXTRA - 16 Aug, 2023
                                                (11:19 AM)
                                            </td>
                                            <td
                                                style={{ whiteSpace: "nowrap" }}
                                            >
                                                Sufyan
                                            </td>
                                            <td
                                                style={{ whiteSpace: "nowrap" }}
                                            >
                                                Iphone 5G Mr Fix Premium Screen
                                                Replacement White , Iphone 5G Mr
                                                Fix Premium Screen Replacement
                                                Black
                                            </td>
                                            <td>
                                                <div className="nav-link btn btn-warning">
                                                    <span>Pasued </span>
                                                </div>
                                            </td>
                                            <td>822</td>
                                            <td>822</td>
                                            <td>£0.00</td>

                                            <td>
                                                <button
                                                    type="button"
                                                    className="btn p-0 dropdown-toggle hide-arrow"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <i class="bx bx-dots-vertical-rounded"></i>
                                                </button>
                                                <ul className="dropdown-menu bg-white">
                                                    <li>
                                                        <Link
                                                            to="view"
                                                            className="dropdown-item"
                                                        >
                                                            Update
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="view"
                                                            className="dropdown-item"
                                                        >
                                                            Delete
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="card-footer"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PurchaseOrders;
