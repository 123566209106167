import { GET_SEO_PAGE, LIST_SEO_PAGES } from "../actions/types";

const initialState = {
    pages: [],
    error: null,
    page: null
};
export default (state = initialState, { type, payload }) => {

    switch (type) {
        case LIST_SEO_PAGES: 
        return {
            ...state,
            pages: payload
        }
        case GET_SEO_PAGE:
            return {
                ...state,
                page: payload
            }
        default:
            return state;
    }
}
