import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { addProductSupplierAction } from "../../../actions/productSupplierActions";

const AddProductSupplier = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const [name, setName] = useState("");
    const [supplierCode, setSupplierCode] = useState("");
    const [doubleTax, setDoubleTax] = useState("true");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [website, setWebsite] = useState("");
    const [postCode, setPostCode] = useState("");
    const [address, setAddress] = useState("");

    const AddProductSupplier = (e) => {
        e.preventDefault();
        const productSupplier = {
            name,
            supplierCode,
            doubleTax: doubleTax === 'true',
            email,
            phone,
            website,
            postCode,
            address
        }
        dispatch(addProductSupplierAction(productSupplier));
        navigate('/admin/product-suppliers');
    }
    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="breadcrumbs-top py-4">
                    <div className="breadcrumb-wrapper d-none d-sm-block">
                        <ol className="breadcrumb breadcrumb-style1 p-0 mb-0 pl-1">
                            <li className="breadcrumb-item">
                                <a href="javascript:void(0);">
                                    <i className="bx bx-home-alt" />
                                </a>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/admin/product-suppliers">Product Suppliers</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <a href="javascript:void(0);">Add New</a>
                            </li>
                        </ol>
                    </div>
                </div>
                <section id="basic-horizontal-layouts">
                    <div className="row match-height">
                        <div className="col-md-8 col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h5 className="content-header-title float-left pr-1 mb-0">
                                            Add New Product Supplier
                                        </h5>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <form className="form form-horizontal" onSubmit={AddProductSupplier}>
                                        <div className="form-body">
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label">Full Name *</label>
                                                    <input
                                                        type="text"
                                                        className="mb-4 form-control"
                                                        placeholder="Name"
                                                        required
                                                        value={name}
                                                        onChange={(e) => { setName(e.target.value) }}
                                                    />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label">
                                                        Supplier Code *
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="mb-4 form-control"
                                                        placeholder="Supplier Code"
                                                        required
                                                        value={supplierCode}
                                                        onChange={(e) => { setSupplierCode(e.target.value) }}
                                                    />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label">
                                                        Email
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="mb-4 form-control"
                                                        placeholder="Email"
                                                        value={email}
                                                        onChange={(e) => { setEmail(e.target.value) }}
                                                    />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label">
                                                        Phone
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="mb-4 form-control"
                                                        placeholder="Phone"
                                                        value={phone}
                                                        onChange={(e) => { setPhone(e.target.value) }}
                                                    />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label">
                                                        Website
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="mb-4 form-control"
                                                        placeholder="Website"
                                                        value={website}
                                                        onChange={(e) => { setWebsite(e.target.value) }}
                                                    />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label">
                                                        Post Code
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="mb-4 form-control"
                                                        placeholder="Post Code"
                                                        value={postCode}
                                                        onChange={(e) => { setPostCode(e.target.value) }}
                                                    />
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <label className="form-label">
                                                        Double Tax
                                                    </label>
                                                    <select
                                                        className="mb-4 form-control"
                                                        name="doubleTax"
                                                        value={doubleTax}
                                                        onChange={(e) => { setDoubleTax(e.target.value) }}
                                                    >
                                                        <option value="true">Yes</option>
                                                        <option value="false">No</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-8 mb-3">
                                                    <label class="form-label">Address</label>
                                                    <textarea class="form-control" placeholder="Address"
                                                        value={address}
                                                        onChange={(e) => { setAddress(e.target.value) }}
                                                    ></textarea>
                                                </div>
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-1"
                                                        onClick={() => navigate(-1)}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary "
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default AddProductSupplier;
