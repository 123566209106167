import { GET_USER_ROLE, LIST_USER_ROLES } from "../actions/types";

const initialState = {
    userRoles:[],
    error:null,
    userRole:null
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case LIST_USER_ROLES: 
        return {
            ...state,
            userRoles: payload
        }
        case GET_USER_ROLE:
            return {
                ...state,
                userRole: payload
            }
        default:
            return state;
    }
}