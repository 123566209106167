import { GET_TICKET, LIST_TICKETS , ADD_CUSTOMER} from "../actions/types";

const initialState = {
    tickets: [],
    error: null,
    ticket: null,
    current_customer: null,
};
export default (state = initialState, { type, payload }) => {

    switch (type) {
        case LIST_TICKETS:
        return {
            ...state,
            tickets: payload
        }
        case GET_TICKET:
            return {
                ...state,
                ticket: payload
            }
        case ADD_CUSTOMER:
            return {
                ...state,
                current_customer: payload
            }
        default:
            return state;
    }
}
