import axios from "axios";
import { alertMessage, base_url, check_response_status } from "../utils";
import { GET_BLOG, LIST_BLOGS, UPDATED } from "./types";

// list Blog Categories
export const listBlogs = () => async dispatch => {

    axios.get(`${base_url}blogs`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: LIST_BLOGS,
                    payload: response.data
                });
            }
            else if (response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            dispatch(check_response_status(err.response));
        });
}

//get blog category
export const getBlog = (id) => async dispatch => {
    axios.get(`${base_url}blogs/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: GET_BLOG,
                    payload: response.data
                });
            }
            else if (response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            dispatch(check_response_status(err.response));
        });
}

// add blog category
export const addBlogAction = (blog) => async dispatch => {

    axios.post(`${base_url}blogs`,
        blog,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 201) {
                dispatch(alertMessage(response.data));
                dispatch(listBlogs());
                dispatch({
                    type: UPDATED,
                    payload: true
                });
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            dispatch(check_response_status(err.response));
        });
}

//update Blog Category
export const updateBlogAction = (id, blog) => async dispatch => {
    axios.put(`${base_url}blogs/${id}`,
        blog,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch(alertMessage({ flag: 'success', message: "Blog Upadated Successfully." }));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            dispatch(check_response_status(err.response));
        });
}

export const deleteBlogAction = (id) => async dispatch => {
    axios.delete(`${base_url}blogs/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch(alertMessage({ flag: 'success', message: response.data.message }));
                dispatch(listBlogs());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            dispatch(check_response_status(err.response));
        });
}