import React from 'react'
import { Link } from 'react-router-dom'

const InventoryTransferAdd = () => {
  return (
    <div className="container-xxl flex-grow-1 container-p-y">

            <nav aria-label=" breadcrumb " className='bg-white'>
                <ol className="breadcrumb breadcrumb-style1 p-1">
                    <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">
                        <Link to="/admin/inventory-transfer">Inventory Transfer</Link>
                    </li>
                    <li className="breadcrumb-item active">
                        Inventory Transfer Order
                    </li>
                </ol>
            </nav>

            <div>
                <div className="span9 border-bottom ">
                    <h3 className="txt24 m0">Transfer Order # TO-298
                    </h3>

                </div>
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group row mb-2">
                                <h5 className="col-sm-3 ">Date</h5>
                                <div className="col-sm-9">
                                    <p>25, Jan 2024</p>
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <h5 className="col-sm-3 ">From Store</h5>
                                <div className="col-sm-9">
                                    <p>Mrfix EXTRA <input type="hidden" name="InventoryTransferOrder[store_from]" id="InventoryTransferOrder_store_from" defaultValue={21559} /></p>
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <h5 className="col-sm-3 ">To Store</h5>
                                <div className="col-sm-9">
                                    <p>Mr Fix - Kilmarnock <input type="hidden" id="InventoryTransferOrder_store_to" name="InventoryTransferOrder[store_to]" defaultValue={22972} /></p>
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <h5 className="col-sm-3 ">Created By</h5>
                                <div className="col-sm-9">
                                    <p>Lukasik</p>
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <h5 className="col-sm-3 py-2 ">Received By</h5>
                                <div className="col-sm-5">
                                    <input type="text" className="form-control bg-white" disabled="true" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group row mb-2">
                                <h5 className="col-sm-3 ">Shipped Date</h5>
                                <div className="col-sm-9">
                                    <div className="input-group date">
                                        <input type="date" name="shipped_date" className="form-control datepicker" data-date-allow-clear="yes" data-date-format="DD MMMM, YYYY" data-date-include-clear-icon />

                                    </div>
                                </div>
                            </div>
                            <div className="form-group row mb-2 " >
                                <h5 className="col-sm-3 my-2 " style={{ whiteSpace: "nowrap" }}>Shipping Charges</h5>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" defaultValue={0.00} name="InventoryTransferOrder[shipping_charges]" id="InventoryTransferOrder_shipping_charges" />
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <h5 className="col-sm-3 my-2">Tracking ID</h5>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" defaultValue name="InventoryTransferOrder[tracking_id]" id="InventoryTransferOrder_tracking_id" />
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <h5 className="col-sm-3 my-2 v-top">Notes</h5>
                                <div className="col-sm-9">
                                    <textarea rows={3} className="form-control" name="InventoryTransferOrder[notes]" id="InventoryTransferOrder_notes" defaultValue={""} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="v4input bg-light px-3 py-4">
                    <div className="row my-6">
                        <div className="col-md-9">
                            <label htmlFor="scan_item_input">Search Inventory Items</label>
                            <input className="form-control search-input py-3" id="scan_item_input" placeholder="ID, Serial, SKU or Scan Item" type="text" name="scan_item_input" autoComplete="off" />
                            <span role="status" aria-live="polite" className="ui-helper-hidden-accessible" />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="scan_item_qty">Quantity</label>
                            <div className="input-group">
                                <input type="text" className="form-control py-3" id="scan_item_qty" autoComplete="off" defaultValue={1} style={{ width: 48, marginRight: 8 }} />
                                <div className="input-group-append">
                                    <button className="btn btn-primary open-semibold py-3" id="scan_add_btn" style={{ width: 190 }}>Add</button>
                                </div>
                            </div>
                        </div>
                        <input type="hidden" id="countOfRows" placeholder="It will contain the count of scanned items which will help to decide the validations of store selection" />
                    </div>
                </div>

                <div className="table-responsive container-full">

                    <table className="table table-bordered w-full" style={{ whiteSpace: "nowrap" }}>
                        <thead>
                            <tr className='bg-light'>
                                <th scope="col">SKU/Id</th>
                                <th scope="col">Manufacturer</th>
                                <th scope="col">Device</th>
                                <th scope="col">Item Name</th>
                                <th scope="col">In Stock</th>
                                <th scope="col">Shipped Qty</th>
                                <th scope="col">Cost Price</th>
                                <th scope="col">Retail Price</th>
                                <th scope="col">Discount</th>
                                <th scope="col">Tax</th>
                                <th scope="col">Total</th>
                                <th scope="col">Received Qty</th>
                                <th scope="col">Remaining Qty</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr className="odd"  >
                                <td className=''>5056594114109</td>
                                <td>Ven-Dens</td>
                                <td>SP-TC21A</td>
                                <td >Single USB 1 Amp Plug</td>
                                <td>12</td>
                                <td>
                                    <button className='border px-5 py-1 bg-white'>5</button>
                                </td>
                                <td >£1.50</td>
                                <td>£1.50</td>
                                <td>£0.00</td>
                                <td className='d-flex flex-column '>
                                    <select className="browser-default px-3 py-1 custom-select my-2 mx-2">
                                        <option selected></option>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>5</option>
                                    </select>
                                    <span className='px-3'>£0.00</span>
                                </td>
                                <td>£7.50</td>
                                <td>0</td>
                                <td >5</td>
                                <td>
                                   
                                    <select className="browser-default px-3 py-1 custom-select my-2 mx-2">
                                        <option selected>Pending</option>
                                        <option value={1}>Shipped</option>
                                        
                                    </select>
                                   
                                </td>

                                <td>
                                    <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="bx bx-dots-vertical-rounded"></i>
                                    </button>
                                    <ul className="dropdown-menu bg-white">
                                        <li><Link to='view'
                                            className="dropdown-item">Delete</Link>
                                        </li>

                                    </ul>
                                </td>
                            </tr>
                            

                        </tbody>
                    </table>
                </div>
            </div>


        </div>
  )
}

export default InventoryTransferAdd