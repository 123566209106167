import { GET_STORE, LIST_STORES } from "../actions/types";

const initialState = {
    stores: [],
    error: null,
    store: null
};
export default (state = initialState, { type, payload }) => {

    switch (type) {
        case LIST_STORES: 
        return {
            ...state,
            stores: payload
        }
        case GET_STORE:
            return {
                ...state,
                store: payload
            }
        default:
            return state;
    }
}
