import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const InventoryTransfer = () => {

    const [transfer, setTransfer] = useState(true)
    const [serch, setSearch] = useState(false)
    const [actionDrop, setActionDrop] = useState(false)

    const transferInToogle = () => {
        setTransfer(true)
    }
    const transferOutToogle = () => {
        setTransfer(false)
    }
    const actionDropToogle = () => {
        setActionDrop(!actionDrop)
    }
    const serchDropToogle = () => {
         setSearch(!serch)
    }

    return (

        <>


            <div className="container-xxl flex-grow-1 container-p-y">
                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumb-style1 p-1">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/admin/sell-orders">Inventory</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/admin/sell-orders">Inventory Transfer</Link>
                            </li>
                        </ol>
                    </nav>

                    <nav className="navbar navbar-expand-lg">
                        <div className="navbar-nav me-auto">
                            <button onClick={serchDropToogle} className="btn btn-primary me-1 collapsed toggle dropdown-toggle"
                                data-bs-toggle="collapse" data-bs-target="#collapseExample"
                                aria-expanded="false" aria-controls="collapseExample">
                                Search Filter
                            </button>
                        </div>

                        <ul className="navbar-nav ms-lg-auto">
                            <li className="nav-item">
                                <Link className="nav-link btn mx-1 btn-success" to="add">
                                    <span><i className="bx bxs-plus-circle" />
                                        Create   Transfer Order </span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a onClick={actionDropToogle} className="btn dropdown-toggle btn-secondary" data-toggle="dropdown" href="#">
                                    <i className="icon-setting3" /> Actions <span className="caret" />
                                </a>

                                {
                                    actionDrop ?
                                        <div className=" bg-white py-2 px-1">

                                            In TransitInventory </div>
                                        :
                                        ''
                                }



                            </li>
                        </ul>
                    </nav>
                    {
                      serch ?
                      <div className="toggle-box-filter dropdown hide search-form p-3">
                      <b className="caret2" />
                      <form className="container-fluid py-4" action="/index.php?r=inventoryCount/index" method="get">
                          <div className="row">
                              <div className="col-md-6">
                                  <div className="form-group d-flex  mb-2">
                                      <h5 htmlFor="InventoryCount_id" className="col-sm-3">ID</h5>
                                      <input maxLength={20} placeholder="Id" name="InventoryCount[id]" id="InventoryCount_id" type="text" className="form-control" />
                                  </div>
                                  <div className="form-group d-flex  mb-2">
                                      <h5 htmlFor="InventoryCount_store_id" className="col-sm-3">Freom Store</h5>
                                      {/* Simplified store dropdown */}
                                      <select data-placeholder="Select Store" name="InventoryCount[store_id]" className="form-control">
                                          <option value="">Select a option</option>
                                          <option value="21559">Mrfix EXTRA</option>
                                          <option value="22972">Mr Fix - Kilmarnock</option>
                                          <option value="22973">Mr Fix - Milton Keynes</option>
                                          <option value="23174">Mr Fix - Corstorphine</option>
                                      </select>
                                  </div>
                                  <div className="form-group d-flex  mb-2">
                                      <h5 htmlFor="InventoryCount_employee_id" className="col-sm-3">To Store</h5>
                                      {/* Simplified employee dropdown */}
                                      <select data-placeholder="Select Employee" name="InventoryCount[employee_id]" className="form-control">
                                          <option value="">Select a option</option>
                                          <option value="21559">Mrfix EXTRA</option>
                                          <option value="22972">Mr Fix - Kilmarnock</option>
                                          <option value="22973">Mr Fix - Milton Keynes</option>
                                          <option value="23174">Mr Fix - Corstorphine</option>
                                      </select>
                                  </div>
                                  
                              </div>
                              <div className="col-md-6">
                                  <div className="form-group d-flex  mb-2">
                                      <h5 htmlFor="InventoryCount_status" className="col-sm-3">Status</h5>
                                      {/* Simplified status dropdown */}
                                      <select data-placeholder="Select Status" id="InventoryCount_status" name="InventoryCount[status]" className="form-control">
                                          <option value="">Select Status</option>
                                          <option value="Paused">Paused</option>
                                          <option value="Counted">Counted</option>
                                      </select>
                                  </div>
                                  <div className="form-group d-flex  mb-2">
                                      <h5 htmlFor="InventoryCount_created_on_0" className="col-sm-3">From Date</h5>
                                      <div className="wdp-input-wrap">
                                          <input className="form-control" data-date-include-clear-icon data-date-format="DD MMM, YYYY" data-date-allow-clear="yes" autoComplete="off" name="InventoryCount[created_on][0]" id="InventoryCount_created_on_0" type="date" />
                                          <a href="javascript:void(0)" className="wdp-clear-icon" style={{ display: 'none' }}>×</a>
                                      </div>
                                  </div>
                                  <div className="form-group d-flex  mb-2">
                                      <h5 htmlFor="InventoryCount_created_on_1" className="col-sm-3">To Date</h5>
                                      <div className="wdp-input-wrap">
                                          <input className="form-control" data-date-include-clear-icon data-date-format="DD MMM, YYYY" data-date-allow-clear="yes" autoComplete="off" name="InventoryCount[created_on][1]" id="InventoryCount_created_on_1" type="date" />
                                          <a href="javascript:void(0)" className="wdp-clear-icon" style={{ display: 'none' }}>×</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <hr />
                          <div className="form-group d-flex  mb-2 text-right">
                              <button className="btn btn-primary" type="submit">Search <i className="icon-search icon-white" /></button>
                          </div>
                      </form>
                  </div> :
                  ''
                    }
                   






                </div>



                <div className="row" id="basic-table">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header pt-4">
                                <ul className="nav nav-tabs1">
                                    <li className="nav-item">
                                        <h6 className=' nav-link active hover-border-bottom'><button className={`btn ${transfer ? 'btn-outline-primary' : 'btn-primary'}`} onClick={transferInToogle}>Transfer In </button></h6>
                                    </li>
                                    <li className='nav-item'>
                                        <h6 className='nav-link user-select-pointer'><button className={`btn ${transfer ? 'btn-primary' : 'btn-outline-primary'}`} onClick={transferOutToogle}>Transfer Out</button></h6>
                                    </li>
                                </ul>


                            </div>


                            {
                                transfer ?
                                    <div className="card-body">
                                        <div className='border d-flex justify-content-between justify-content-center'>
                                            <div>
                                                <h5 className='px-2 pb-2 my-2'>Inventory Transfer In</h5>

                                            </div>

                                            <select className="browser-default px-2 py-1 custom-select my-2 mx-2">
                                                <option selected>50</option>
                                                <option value={1}>100</option>
                                                <option value={2}>250</option>
                                                <option value={3}>500</option>
                                            </select>

                                        </div>

                                        <div className="table-responsive container-full">

                                            <table className="table table-bordered w-full" style={{ whiteSpace: "nowrap" }}>
                                                <thead>
                                                    <tr className='bg-light'>
                                                        <th scope="col">ID</th>
                                                        <th scope="col">Date</th>
                                                        <th scope="col">Type</th>
                                                        <th scope="col">Products</th>
                                                        <th scope="col">From Location</th>
                                                        <th scope="col">To Location</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Total Cost</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr className="odd"  >
                                                        <td className=''>TO-275</td>
                                                        <td>2023-12-14 10:37:00</td>
                                                        <td>Transaction In</td>
                                                        <td >Samsung S9 | Screen Replacement</td>
                                                        <td>Mr Fix - Corstorphine</td>
                                                        <td>Mrfix EXTRA</td>
                                                        <td>
                                                            <div className="nav-link btn btn-success" >
                                                                <span>
                                                                    Completed </span>
                                                            </div>
                                                        </td>
                                                        <td >40.00</td>
                                                        <td>
                                                            <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i class="bx bx-dots-vertical-rounded"></i>
                                                            </button>
                                                            <ul className="dropdown-menu bg-white">
                                                                <li><Link to='view'
                                                                    className="dropdown-item">View</Link>
                                                                </li>

                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr className="even" >
                                                        <td>TO-275</td>
                                                        <td>2023-12-14 10:37:00</td>
                                                        <td>Transaction In</td>
                                                        <td >Samsung S9 | Screen Replacement</td>
                                                        <td>Mr Fix - Corstorphine</td>
                                                        <td>Mrfix EXTRA</td>
                                                        <td>
                                                            <div className="nav-link btn btn-success" >
                                                                <span>
                                                                    Completed </span>
                                                            </div>
                                                        </td>
                                                        <td style={{ whiteSpace: "nowrap" }}>40.00</td>
                                                        <td>
                                                            <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i class="bx bx-dots-vertical-rounded"></i>
                                                            </button>
                                                            <ul className="dropdown-menu bg-white">
                                                                <li><Link to='view'
                                                                    className="dropdown-item">View</Link>
                                                                </li>

                                                            </ul>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>

                                    </div> :
                                    <div className="card-body">
                                        <div className='border d-flex justify-content-between justify-content-center'>
                                            <div>
                                                <h5 className='px-2 pb-2 my-2'>Inventory Transfer Out</h5>

                                            </div>

                                            <select className="browser-default px-2 py-1 custom-select my-2 mx-2">
                                                <option selected>50</option>
                                                <option value={1}>100</option>
                                                <option value={2}>250</option>
                                                <option value={3}>500</option>
                                            </select>

                                        </div>

                                        <div className="table-responsive container-full">

                                            <table className="table table-bordered w-full" style={{ whiteSpace: "nowrap" }}>
                                                <thead>
                                                    <tr className='bg-light'>
                                                        <th scope="col">ID</th>
                                                        <th scope="col">Date</th>
                                                        <th scope="col">Type</th>
                                                        <th scope="col">Products</th>
                                                        <th scope="col">From Location</th>
                                                        <th scope="col">To Location</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Total Cost</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr className="odd"  >
                                                        <td className=''>TO-275</td>
                                                        <td>2023-12-14 10:37:00</td>
                                                        <td>Transaction Out</td>
                                                        <td >Samsung S9 | Screen Replacement</td>
                                                        <td>Mr Fix - Corstorphine</td>
                                                        <td>Mrfix EXTRA</td>
                                                        <td>
                                                            <div className="nav-link btn btn-primary" >
                                                                <span>
                                                                    Pending </span>
                                                            </div>
                                                        </td>
                                                        <td >40.00</td>
                                                        <td>
                                                            <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i class="bx bx-dots-vertical-rounded"></i>
                                                            </button>
                                                            <ul className="dropdown-menu bg-white">
                                                                <li><Link to='update'
                                                                    className="dropdown-item">Update</Link>
                                                                </li>
                                                                <li><Link className="dropdown-item" to="view"
                                                                >View</Link>
                                                                </li>

                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr className="even" >
                                                        <td>TO-275</td>
                                                        <td>2023-12-14 10:37:00</td>
                                                        <td>Transaction Out</td>
                                                        <td >Samsung S9 | Screen Replacement</td>
                                                        <td>Mr Fix - Corstorphine</td>
                                                        <td>Mrfix EXTRA</td>
                                                        <td>
                                                            <div className="nav-link btn btn-success" >
                                                                <span>
                                                                    Completed </span>
                                                            </div>
                                                        </td>
                                                        <td >40.00</td>
                                                        <td>
                                                            <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i class="bx bx-dots-vertical-rounded"></i>
                                                            </button>
                                                            <ul className="dropdown-menu bg-white">
                                                                <li><Link to='view'
                                                                    className="dropdown-item">View</Link>
                                                                </li>


                                                            </ul>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                            }


                            <div className="card-footer">

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>


    )
}

export default InventoryTransfer