import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useEffect, useState } from "react";
const PiChart = ({ chartData, title }) => {
    const [options, setOptions] = useState({})
    // console.log('chart', chartData)
    useEffect(() => {
        // console.log('chartData', chartData)
        if (chartData.length) {
            setOptions( {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie'
                    },
                    title: {
                        text: title,
                        align: 'center',
                        verticalAlign: 'middle',
                        y: 60
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    accessibility: {
                        point: {
                            valueSuffix: '%'
                        }
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                        }
                    },
                    series: [{
                        // colorByPoint: true,

                        type: 'pie',
                        name: title,
                        innerSize: '50%',
                        data: chartData
                    }]
                }
            )
        }
    }, [chartData])
    return (
        <>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </>
    );
}

export default PiChart;