import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select';
import { listDevices } from "../../../actions/deviceActions";
import { uploadImage } from "../../../actions/globalActions";
import { listManufacturers } from "../../../actions/manufacturerActions";
import { listProblems } from "../../../actions/problemActions";
import { listProductCategories } from "../../../actions/productCategoriesActions";
import { listProductConditions } from "../../../actions/productConditionActions";
import { listProductPhysicalLocations } from "../../../actions/productPhysicalLocationActions";
import { addProductAction, getValuationMethods, listProducts } from "../../../actions/productsActions";
import { listProductSuppliers } from "../../../actions/productSupplierActions";
import { listRepairCategories } from "../../../actions/repairCategoriesActions";
import { getLoyaltyTypes, getWarrantyTypes } from "../../../actions/repairServiceActions";
import { listStores } from "../../../actions/storeActions";
import { listTaxClasses } from "../../../actions/taxClassActions";
import { base_url } from "../../../utils";

const AddProduct = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const media = useSelector(state => state.global.media);
    const productCategories = useSelector(state => state.productCategory.productCategories);
    const manufacturers = useSelector(state => state.manufacturer.manufacturers);
    const devices = useSelector(state => state.device.devices);
    const taxClasses = useSelector(state => state.taxClass.taxClasses);
    const stores = useSelector(state => state.store.stores);
    const productConditions = useSelector(state => state.productCondition.productConditions);
    const physicalLocations = useSelector(state => state.productPhysicalLocation.productPhysicalLocations);
    const productSuppliers = useSelector(state => state.productSupplier.productSuppliers);
    const valuationMethods = useSelector(state => state.product.valuationMethods);
    const loyaltyTypes = useSelector(state => state.repairService.loyaltyTypes);
    const warrantyTypes = useSelector(state => state.repairService.warrantyTypes);

    const [productCategoryOptions, setProductCategoryOptions] = useState([]);
    const [productSubCategoryOptions, setProductSubCategoryOptions] = useState([]);
    const [manufacturerOptions, setManufacturerOptions] = useState([]);
    const [deviceOptions, setDeviceOptions] = useState([]);
    const [taxClassOptions, setTaxClassOptions] = useState([]);
    const [productConditionOptions, setProductConditionOptions] = useState([]);
    const [physicalLocationOptions, setPhysicalLocationOptions] = useState([]);
    const [productSuppliersOptions, setProductSuppliersOptions] = useState([]);

    const [displayOnPOS, setDisplayOnPOS] = useState(true);
    const [inventoryLevel, setInventoryLevel] = useState(true);
    const [productType, setProductType] = useState("spare_part")
    const [serialized, setSerialized] = useState(true)
    const [imei, setIMEI] = useState("");
    const [upcCode, setUpcCode] = useState("");
    const [sku, setSku] = useState("");
    const [warrantyType, setWarrantyType] = useState("");
    const [warrantyNumber, setWarrantyNumber] = useState("");
    const [days, setDays] = useState("");
    const [hours, setHours] = useState("");
    const [minuts, setMinutes] = useState("");
    const [description, setDescription] = useState("");
    const [costPrice, setCostPrice] = useState("");
    const [wholePrice, setWholePrice] = useState("");
    const [isCommission, setIsCommission] = useState(false);
    const [isTaxInclusive, setIsTaxInclusive] = useState(false);
    const [commissionPercentage, setCommissionPercentage] = useState("");
    const [commissionAmount, setCommissionAmount] = useState("");
    const [enableLoyalty, setEnableLoyalty] = useState(false);
    const [loyaltyType, setLoyaltyType] = useState("default");
    const [loyaltyAmount, setLoyaltyAmount] = useState("");

    const [profitMargin, setProfitMargin] = useState([]);
    const [valuationMethod, setValuationMethod] = useState("")
    const [minimumQuatity, setMinimumQuatity] = useState("");
    const [warningQuatity, setWarningQuatity] = useState("");
    const [onhandQuatity, setOnhandQuatity] = useState("");
    const [unitCost, setUnitCost] = useState("");
    const [markup, setMarkup] = useState("");
    const [retailPrice, setRetailPrice] = useState("");
    const [minimusPrice, setMinimumPrice] = useState("");
    const [promotionalPrice, setPromotionalPrice] = useState("");
    const [name, setName] = useState("");
    const [button, setButton] = useState(false);
    const [manufacturer, setManufacturer] = useState("");
    const [deviceColor, setDeviceColor] = useState("");
    const [repairCategory, setRepairCategory] = useState("");
    const [productCategory, setProductCategory] = useState("");
    const [productSubCategory, setProductSubCategory] = useState("");
    const [problem, setProblem] = useState("");
    const [device, setDevice] = useState("");
    const [condition, setCondition] = useState("");
    const [physicalLocation, setPhysicalLocation] = useState("");
    const [productSupplier, setProductSupplier] = useState("");
    const [repairService, setProduct] = useState("");
    const [taxClass, setTaxClass] = useState("");
    const [image, setImage] = useState({});

    useLayoutEffect(() => {
        dispatch(listManufacturers());
        dispatch(listRepairCategories());
        // dispatch(listDevices());
        dispatch(listTaxClasses());
        dispatch(listProductConditions());
        dispatch(getWarrantyTypes());
        dispatch(getLoyaltyTypes());
        dispatch(listProductPhysicalLocations());
        dispatch(listStores());
        dispatch(listProductSuppliers());
        dispatch(getValuationMethods());
        dispatch(listProductCategories());
    }, []);

    useEffect(() => {
        if (stores) {
            let storesActive = stores.filter(stor => !stor.isDeleted);
            setProfitMargin(storesActive.map(stor => {
                return {
                    store: { _id: stor._id, name: stor.name },
                    percentage: 0,
                    pounds: 0
                };
            }));
        }
    }, [stores]);

    const updateProfitMargin = (id, percentage, pounds) => {
        const updatedProfitMargin = profitMargin.map(acc => {
            if (acc.store._id === id) {
                return {
                    ...acc,
                    percentage,
                    pounds
                };
            } else {
                return acc;
            }
        });
        setProfitMargin(updatedProfitMargin);
    };

    useEffect(() => {
        if (manufacturer) {
            setDevice("");
            dispatch(listDevices(manufacturer?.value))
        }
    }, [manufacturer])

    useEffect(() => {
        const opts = [];
        taxClasses && taxClasses.map(taxClass => {
            if (!taxClass.isDeleted) {
                opts.push({ obj: taxClass, value: taxClass._id, label: taxClass.class });
            }
        });
        setTaxClassOptions(opts);
    }, [taxClasses]);

    useEffect(() => {
        const opts = [];
        productSuppliers && productSuppliers.map(productSupplier => {
            if (!productSupplier.isDeleted) {
                opts.push({ obj: productSupplier, value: productSupplier._id, label: productSupplier.name });
            }
        });
        setProductSuppliersOptions(opts);
    }, [productSuppliers]);

    useEffect(() => {
        const opts = [];
        physicalLocations && physicalLocations.map(physicalLocation => {
            if (!physicalLocation.isDeleted) {
                opts.push({ obj: physicalLocation, value: physicalLocation._id, label: physicalLocation.name });
            }
        });
        setPhysicalLocationOptions(opts);
    }, [physicalLocations]);

    useEffect(() => {
        const opts = [];
        productConditions && productConditions.map(productCondition => {
            if (!productCondition.isDeleted) {
                opts.push({ obj: productCondition, value: productCondition._id, label: productCondition.name });
            }
        });
        setProductConditionOptions(opts);
    }, [productConditions]);

    useEffect(() => {
        const opts = [];
        devices && devices.map(device => {
            if (!device.isDeleted) {
                opts.push({ obj: device, value: device._id, label: device.name });
            }
        });
        setDeviceOptions(opts);
    }, [devices]);

    useEffect(() => {
        const opts = [];
        productCategories && productCategories.map(productCategory => {
            if (!productCategory.isDeleted) {
                opts.push({ obj: productCategory, value: productCategory._id, label: productCategory.name });
            }
        });
        setProductCategoryOptions(opts);
    }, [productCategories]);

    useEffect(() => {
        const opts = [];
        manufacturers && manufacturers.map(manufacturer => {
            if (!manufacturer.isDeleted) {
                opts.push({ obj: manufacturer, value: manufacturer._id, label: manufacturer.name });
            }
        });
        setManufacturerOptions(opts);
    }, [manufacturers]);

    const makeSubCategoryOptions = (category) => {
        setProductSubCategory({})
        const opts = [];
        category?.subCategories?.length && category?.subCategories?.map(subCat => {
            opts.push({ obj: subCat, value: subCat.id, label: subCat.name });
        });
        setProductSubCategoryOptions(opts);
    }

    const addService = (e) => {
        e.preventDefault();
        const product = {
            serialized,
            name,
            category: productCategory.obj,
            subCategory: productSubCategory.obj,
            manufactures: manufacturer.obj,
            devices: device.obj,
            imei,
            productCondition: condition.obj,
            productPhysicalLocation: physicalLocation.obj,
            productType,
            warrantyNumber,
            warrantyType,
            media,
            description,
            upcCode,
            sku,

            taxes: taxClass.obj,
            isTaxInclusive,
            isCommission,
            commissionPercentage,
            commissionAmount,

            displayOnPOS,
            profitMargin,

            enableLoyalty,
            loyaltyType,
            loyaltyAmount,

            inventoryLevel,
            onhandQuatity,
            warningQuatity,
            minimumQuatity,

            productSuppliers: productSupplier.obj,
            valuationMethod,
            unitCost,
            markup,
            retailPrice,
            promotionalPrice,
            minimusPrice
        }
        dispatch(addProductAction(product));
        navigate('/admin/products');
    }

    const updateImage = (e) => {
        if (e.files && e.files[0]) {
            setImage(e.files[0]);
            const data = new FormData()
            data.append('file', e.files[0], e.files[0].name);

            dispatch(uploadImage(data));
        }
    }

    const profileStyle = {
        width: '150px',
        height: '150px'
    }

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="breadcrumbs-top py-4">
                    {/* <h5 className="content-header-title float-left pr-1 mb-0">
                    Add New Repair Service
                </h5> */}
                    <div className="breadcrumb-wrapper d-none d-sm-block">
                        <ol className="breadcrumb breadcrumb-style1 p-0 mb-0 pl-1">
                            <li className="breadcrumb-item">
                                <Link to="/admin">
                                    <i className="bx bx-home-alt" />
                                </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/admin/products">Products</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <a href="javascript:void(0);">Add New</a>
                            </li>
                        </ol>
                    </div>
                </div>
                <section id="basic-horizontal-layouts">
                    <form className="form form-horizontal" onSubmit={addService}>
                        <div className="form-body">
                            <div className="row match-height">
                                <div className="col-md-12 col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="card-title">
                                                Add New Product
                                            </h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="row mb-3">
                                                        <div className="col-md mb-md-0 mb-2">
                                                            <div className="form-check custom-option custom-option-basic checked">
                                                                <label className="form-check-label custom-option-content" htmlFor="serialized">
                                                                    <input name="serializedCheck" className="form-check-input" type="radio" defaultValue id="serialized" defaultChecked
                                                                        onClick={() => setSerialized(true)} />
                                                                    <span className="custom-option-header">
                                                                        <span className="h6 mb-0">Serialized</span>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md">
                                                            <div className="form-check custom-option custom-option-basic">
                                                                <label className="form-check-label custom-option-content" htmlFor="nonSerialized">
                                                                    <input name="serializedCheck" className="form-check-input" type="radio" defaultValue id="nonSerialized"
                                                                        onClick={() => setSerialized(false)} />
                                                                    <span className="custom-option-header">
                                                                        <span className="h6 mb-0">Non-Serialized</span>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row mb-3">
                                                        <div className="col-md mb-md-0 mb-2">
                                                            <div className="form-check custom-option custom-option-basic checked">
                                                                <label className="form-check-label custom-option-content" htmlFor="sparePart">
                                                                    <input name="productType" className="form-check-input" type="radio" defaultValue id="sparePart" defaultChecked
                                                                        onClick={() => setProductType("spare_part")} />
                                                                    <span className="custom-option-header">
                                                                        <span className="h6 mb-0">Spare Part</span>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md">
                                                            <div className="form-check custom-option custom-option-basic">
                                                                <label className="form-check-label custom-option-content" htmlFor="accessory">
                                                                    <input name="productType" className="form-check-input" type="radio" defaultValue id="accessory"
                                                                        onClick={() => setProductType("accessory")} />
                                                                    <span className="custom-option-header">
                                                                        <span className="h6 mb-0">Accessory</span>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <label>
                                                                Name *
                                                            </label>
                                                        </div>
                                                        <div className="col-md-8 form-group">
                                                            <input
                                                                type="text"
                                                                className="mb-4 form-control"
                                                                required
                                                                value={name}
                                                                onChange={(e) => setName(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label>
                                                                Category *
                                                            </label>
                                                        </div>
                                                        <div className="col-md-8 form-group">
                                                            <Select
                                                                required
                                                                value={productCategory}
                                                                onChange={(e) => { setProductCategory(e); makeSubCategoryOptions(e.obj) }}
                                                                options={productCategoryOptions}
                                                                className="mb-4"
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label>
                                                                Sub Category
                                                            </label>
                                                        </div>
                                                        <div className="col-md-8 form-group">
                                                            <Select
                                                                value={productSubCategory}
                                                                onChange={(e) => setProductSubCategory(e)}
                                                                options={productSubCategoryOptions}
                                                                className="mb-4"
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label>
                                                                Manufacturer
                                                            </label>
                                                        </div>
                                                        <div className="col-md-8 form-group">
                                                            <Select
                                                                value={manufacturer}
                                                                onChange={(e) => setManufacturer(e)}
                                                                options={manufacturerOptions}
                                                                className="mb-4"
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label>
                                                                Device / Model
                                                            </label>
                                                        </div>
                                                        <div className="col-md-8 form-group">
                                                            <Select
                                                                value={device}
                                                                onChange={(e) => setDevice(e)}
                                                                options={deviceOptions}
                                                                className="mb-4"
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label>
                                                                IMEI
                                                            </label>
                                                        </div>
                                                        <div className="col-md-8 form-group">
                                                            <input
                                                                type="text"
                                                                className="mb-4 form-control"
                                                                value={imei}
                                                                onChange={(e) => setIMEI(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label>
                                                                Condition
                                                            </label>
                                                        </div>
                                                        <div className="col-md-8 form-group">
                                                            <Select
                                                                value={condition}
                                                                onChange={(e) => setCondition(e)}
                                                                options={productConditionOptions}
                                                                className="mb-4"
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label>
                                                                Physical Location
                                                            </label>
                                                        </div>
                                                        <div className="col-md-8 form-group">
                                                            <Select
                                                                value={physicalLocation}
                                                                onChange={(e) => setPhysicalLocation(e)}
                                                                options={physicalLocationOptions}
                                                                className="mb-4"
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <label>
                                                                Warranty
                                                            </label>
                                                        </div>
                                                        <div className="col-md-8 form-group">
                                                            <input
                                                                type="number"
                                                                style={{ width: '30%', display: 'inline' }}
                                                                className="mb-4 form-control me-2"
                                                                value={warrantyNumber}
                                                                onChange={(e) => { setWarrantyNumber(e.target.value) }}
                                                            />
                                                            <select
                                                                value={warrantyType}
                                                                onChange={(e) => setWarrantyType(e.target.value)}
                                                                style={{ width: '60%', display: 'inline' }}
                                                                className="mb-4 form-control">
                                                                {/* {warrantyTypes && warrantyTypes.map(warrantyType => <option value={warrantyType}>{warrantyType}</option>)} */}
                                                                <option value="no_warranty">No Warranty</option>
                                                                <option value="days">Days</option>
                                                                <option value="months">Months</option>
                                                                <option value="years">Years</option>
                                                                <option value="lifetime">Lifetime</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label>Picture</label>
                                                        </div>
                                                        <div className="col-md-8 mb-1">
                                                            <img style={profileStyle} src={media ? base_url + "media/" + media.filename : "/app-assets/images/dummy.jpg"} alt={name} />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label></label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div class="button-wrapper">
                                                                <label for="upload" class="btn btn-primary me-2 mb-4" tabindex="0">
                                                                    <span class="d-none d-sm-block">Upload new photo</span>
                                                                    <i class="bx bx-upload d-block d-sm-none"></i>
                                                                    <input
                                                                        type="file"
                                                                        id="upload"
                                                                        class="account-file-input"
                                                                        value={image.value}
                                                                        hidden
                                                                        accept="image/png, image/jpeg"
                                                                        onChange={(e) => { updateImage(e.target); setButton(false) }}
                                                                    />
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label>
                                                                Description
                                                            </label>
                                                        </div>
                                                        <div className="col-md-8 form-group">
                                                            <textarea
                                                                className="mb-4 form-control"
                                                                rows="3"
                                                                placeholder="Description"
                                                                value={description}
                                                                onChange={(e) => { setDescription(e.target.value) }}
                                                            >
                                                            </textarea>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label>
                                                                UPC Code
                                                            </label>
                                                        </div>
                                                        <div className="col-md-8 form-group">
                                                            <input
                                                                className="mb-4 form-control"
                                                                placeholder="UPC Code"
                                                                value={upcCode}
                                                                onChange={(e) => { setUpcCode(e.target.value) }}
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label>
                                                                SKU
                                                            </label>
                                                        </div>
                                                        <div className="col-md-8 form-group">
                                                            <input
                                                                className="mb-4 form-control"
                                                                placeholder="SKU"
                                                                value={sku}
                                                                onChange={(e) => { setSku(e.target.value) }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-12 my-2">

                                    <div className="card">
                                        <div className="card-header">
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <label>
                                                                Tax Class
                                                            </label>
                                                        </div>
                                                        <div className="col-md-8 form-group mb-4">
                                                            <Select
                                                                value={taxClass}
                                                                onChange={(e) => setTaxClass(e)}
                                                                options={taxClassOptions}
                                                            />
                                                        </div>

                                                        <div className="col-md-4">
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div class="checkbox me-2">
                                                                <input type="checkbox" class="form-check-input checkbox-input me-1" id="checkbox2" defaultChecked={isTaxInclusive}
                                                                    onChange={() => setIsTaxInclusive(!isTaxInclusive)} />
                                                                <label for="checkbox2">Tax Inclusive</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-8 form-group mb-3">
                                                            <fieldset>
                                                                <div class="checkbox me-2">
                                                                    <input type="checkbox" class="form-check-input checkbox-input me-1" id="checkbox1" defaultChecked={isCommission}
                                                                        onChange={() => setIsCommission(!isCommission)} />
                                                                    <label for="checkbox1">Commission</label>
                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                        <div className="col-md-4">
                                                        </div>
                                                        <div style={isCommission ? { display: 'block' } : { display: 'none' }} className="col-md-3">
                                                            <label>
                                                                Percentage (%)
                                                            </label>
                                                        </div>
                                                        <div style={isCommission ? { display: 'block' } : { display: 'none' }} className="col-md-3 form-group">
                                                            <input
                                                                type="number"
                                                                className="mb-4 form-control"
                                                                value={commissionPercentage}
                                                                onChange={(e) => setCommissionPercentage(e.target.value)}
                                                            />
                                                        </div>
                                                        <div style={isCommission ? { display: 'block' } : { display: 'none' }} className="col-md-3">
                                                            <label>
                                                                Amount (£)
                                                            </label>
                                                        </div>
                                                        <div style={isCommission ? { display: 'block' } : { display: 'none' }} className="col-md-3 form-group">
                                                            <input
                                                                type="number"
                                                                className="mb-4 form-control"
                                                                value={commissionAmount}
                                                                onChange={(e) => setCommissionAmount(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <hr />
                                                    <div className="row mt-4">
                                                        <div className="col-md-2">
                                                            <label>Display on POS</label>
                                                        </div>
                                                        <div className="col-md-2 form-group">
                                                            <div className="custom-control custom-radio">
                                                                <input type="radio" className="form-check-input custom-control-input" name="showPOS" id="showPOS1"
                                                                    value={displayOnPOS} checked={displayOnPOS === true}
                                                                    onChange={() => setDisplayOnPOS(true)}
                                                                />
                                                                <label className="mx-1 custom-control-label" htmlFor="showPOS1">Yes</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 form-group">
                                                            <div className="custom-control custom-radio">
                                                                <input type="radio" className="form-check-input custom-control-input" name="showPOS" id="showPOS2"
                                                                    value={displayOnPOS} checked={displayOnPOS === false}
                                                                    onChange={() => setDisplayOnPOS(false)}
                                                                />
                                                                <label className="mx-1 custom-control-label" htmlFor="showPOS2">No</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6"></div>
                                                        <div className="col-md-12  mt-4">
                                                            <h6>Franchise Profit Margin Setup</h6>
                                                        </div>

                                                        {profitMargin && profitMargin.map((margin, key) => {
                                                            return <div key={key} className="col-md-2">
                                                                <label className="form-label">{margin?.store?.name}</label>
                                                                <div class="input-group">
                                                                    <button className="btn btn-primary dropdown-toggle show" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        %
                                                                    </button>
                                                                    <ul className="dropdown-menu" style={{}}>
                                                                        {/* <li><a className="dropdown-item" href="javascript:void(0);">%</a></li> */}
                                                                        {/* <li><a className="dropdown-item" href="javascript:void(0);">£</a></li> */}
                                                                    </ul>
                                                                    <input type="number" className="form-control" aria-label="Text input with dropdown button"
                                                                        value={margin?.percentage}
                                                                        onChange={(e) => updateProfitMargin(margin?.store._id, e.target.value, 0)}
                                                                        min={0}
                                                                    />
                                                                </div>
                                                            </div>
                                                        })}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-12">
                                    <div className="card">
                                        <div className="card-header">
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-2 form-group">
                                                    <div className="custom-control custom-switch custom-control-inline mb-1">
                                                        <input type="checkbox" className="form-check-input custom-control-input" defaultChecked={enableLoyalty} id="customSwitch1"
                                                            onChange={() => setEnableLoyalty(!enableLoyalty)} />
                                                        <label className="mx-1 custom-control-label me-1" htmlFor="customSwitch1">
                                                        </label>
                                                        <span>Enable Loyalty</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-2 form-group">
                                                    <div className="custom-control custom-radio">
                                                        <input type="radio" className="form-check-input custom-control-input" name="customRadio" id="customRadio1"
                                                            value={loyaltyType} checked={loyaltyType === 'default'}
                                                            onChange={() => setLoyaltyType('default')} />
                                                        <label className="mx-1 custom-control-label" htmlFor="customRadio1">Default loyalty</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-2 form-group">
                                                    <div className="custom-control custom-radio">
                                                        <input type="radio" className="form-check-input custom-control-input" name="customRadio" id="customRadio2"
                                                            value={loyaltyType} checked={loyaltyType === 'custom'}
                                                            onChange={() => setLoyaltyType('custom')} />
                                                        <label className="mx-1 custom-control-label" htmlFor="customRadio2">Custom loyalty</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div style={loyaltyType === 'custom' ? { display: 'block' } : { display: 'none' }} className="col-md-10 form-group">
                                                        <span>This product will earn £ </span>
                                                        <input type="number" value={loyaltyAmount}
                                                            onChange={(e) => setLoyaltyAmount(e.target.value)} />
                                                        <span> loyalty when sold</span>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <hr />
                                                    <div className="row mt-4">
                                                        <div className="col-md-4">
                                                            <label>Manage inventory Level for this item?</label>
                                                        </div>
                                                        <div className="col-md-2 form-group">
                                                            <div className="custom-control custom-radio">
                                                                <input type="radio" className="form-check-input custom-control-input" name="inventoryLev" id="inventoryLev1"
                                                                    value={inventoryLevel} checked={inventoryLevel === true}
                                                                    onChange={() => setInventoryLevel(true)}
                                                                />
                                                                <label className="mx-1 custom-control-label" htmlFor="inventoryLev1">Yes</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 form-group">
                                                            <div className="custom-control custom-radio">
                                                                <input type="radio" className="form-check-input custom-control-input" name="inventoryLev" id="inventoryLev2"
                                                                    value={inventoryLevel} checked={inventoryLevel == false}
                                                                    onChange={() => setInventoryLevel(false)}
                                                                />
                                                                <label className="mx-1 custom-control-label" htmlFor="inventoryLev2">No</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6"></div>
                                                        <div className="col-md-12  mt-4">
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <label className="form-label">On Hand</label>
                                                                <input type="text" className="form-control"
                                                                    value={onhandQuatity}
                                                                    onChange={e => setOnhandQuatity(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label className="form-label">Stock Warning</label>
                                                                <input type="text" className="form-control"
                                                                    value={warningQuatity}
                                                                    onChange={e => setWarningQuatity(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label className="form-label">Minimum Quatity</label>
                                                                <input type="text" className="form-control"
                                                                    value={minimumQuatity}
                                                                    onChange={e => setMinimumQuatity(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="row mt-4">
                                                            <hr />
                                                            <div className="col-md-2">
                                                                <label>
                                                                    Vendor
                                                                </label>
                                                            </div>
                                                            <div className="col-md-4 form-group mb-4">
                                                                <Select
                                                                    value={productSupplier}
                                                                    onChange={(e) => setProductSupplier(e)}
                                                                    options={productSuppliersOptions}
                                                                />
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label>
                                                                    Valuation Method
                                                                </label>
                                                            </div>
                                                            <div className="col-md-4 form-group mb-4">
                                                                <select
                                                                    className="form-control"
                                                                    value={valuationMethod}
                                                                    onChange={(e) => setValuationMethod(e.target.value)}
                                                                >
                                                                    <option value="">Select Method</option>
                                                                    {valuationMethods.map((valuationMethod, key) => {
                                                                        return <option key={key} value={valuationMethod[1]}>{valuationMethod[0]}</option>
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">

                                                    </div>
                                                </div>
                                                <div className="col-md-12 my-3">

                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            <label className="form-label">Unit Price</label>
                                                            <input type="text" className="form-control"
                                                                value={unitCost}
                                                                onChange={e => setUnitCost(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-md-2">
                                                            <label className="form-label">Mark Up (%)</label>
                                                            <input type="text" className="form-control"
                                                                value={markup}
                                                                onChange={e => setMarkup(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-md-2">
                                                            <label className="form-label">Retail Price</label>
                                                            <input type="text" className="form-control"
                                                                value={retailPrice}
                                                                onChange={e => setRetailPrice(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-md-2">
                                                            <label className="form-label">Promotional Price</label>
                                                            <input type="text" className="form-control"
                                                                value={promotionalPrice}
                                                                onChange={e => setPromotionalPrice(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-md-2">
                                                            <label className="form-label">Minimum Price</label>
                                                            <input type="text" className="form-control"
                                                                value={minimusPrice}
                                                                onChange={e => setMinimumPrice(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-1"
                                                        onClick={() => navigate(-1)}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary "
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </section>
            </div>

        </>
    );
};

export default AddProduct;
