import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { deleteTaxClassAction, listTaxClasses } from "../../actions/taxClassActions";

const ListTaxClass = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const taxClasses = useSelector((state) => state.taxClass.taxClasses);
    const [modal, setModal] = useState(false);
    const [delId, setDelId] = useState(null);

    useEffect(() => {
        dispatch(listTaxClasses());
    }, []);

    const styleShow = {
        display: 'block',
        paddingRight: '17px'
    }

    const styleHide = {
        display: 'none',
        paddingRight: '17px'
    }

    const deleteTaxClass = () => {
        console.log(delId);
        delId && dispatch(deleteTaxClassAction(delId));
        setModal(false);
    }

    return (
        <>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="breadcrumbs-top">
                <div className="breadcrumb-wrapper d-none d-sm-block mb-4 ">
                    <ol className="breadcrumb p-0 mb-0 pl-1">
                        <li className="breadcrumb-item">
                            <a href="index.html">
                                <i className="bx bx-home-alt" />
                            </a>
                        </li>
                        <li className="breadcrumb-item active">
                            List All
                        </li>
                    </ol>
                </div>
                <div class="col-12 d-flex justify-content-between align-items-center mb-2" style={{ top: '-40px' }}>
                <h5 className="">
                    Tax Classes
                </h5>
                    <button onClick={() => { navigate('add') }} type="button" class="btn btn-primary mr-1">Add New</button>
                </div>
            </div>
            <div className="row" id="basic-table">
                <div className="col-12">
                    <div className="card">
                        {/* <div className="card-header">
                            <h4 className="card-title">
                                Basic Tables
                            </h4>
                        </div> */}
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            {/* <th>
                                                <input type="checkbox" />
                                            </th> */}
                                            <th>Tax Class</th>
                                            <th>Tax Percentage</th>
                                            {/* <th>Show on POS</th>
                                            <th>Show on widgets</th> */}
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {taxClasses && taxClasses.map(taxClass => {
                                            return  !taxClass.isDeleted && <tr>
                                                {/* <td className="text-bold-500">
                                                    <input type="checkbox" />
                                                </td> */}
                                                <td>{taxClass.class}</td>
                                                <td className="text-bold-500">
                                                    {taxClass.percentage}
                                                </td>
                                                {/* <td className="text-bold-500">
                                                    <input type="checkbox" />
                                                </td>
                                                <td className="text-bold-500">
                                                    <input type="checkbox" />
                                                </td> */}
                                                <td>
                                                    <Link to={`/admin/tax-classes/${taxClass._id}`}>
                                                        <i className="badge-circle badge-circle-light-secondary bx bx-edit font-medium-1" />
                                                    </Link>
                                                    <a href="javascript:void(0);" onClick={() => { setModal(true); setDelId(taxClass._id)}}>
                                                        <i className="badge-circle badge-circle-light-secondary bx bx-trash font-medium-1" />
                                                    </a>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div className={`modal fade text-left ${modal ? 'show' : ''}`} id="danger" tabIndex={-1} aria-labelledby="myModalLabel120" style={modal ? styleShow : styleHide} aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header bg-danger">
                        <h5 className="modal-title white" id="myModalLabel120">Danger!</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                        onClick={() => {setModal(false)}}>
                            <i className="bx bx-x" />
                        </button>
                    </div>
                    <div className="modal-body">
                        Are you sure! You want to delete this Tax Class?
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-light-secondary" data-dismiss="modal"
                        onClick={() => {setModal(false)}}>
                            <i className="bx bx-x d-block d-sm-none" />
                            <span className="d-none d-sm-block">Cancel</span>
                        </button>
                        <button type="button" className="btn btn-danger ml-1" data-dismiss="modal"
                        onClick={() => {deleteTaxClass(delId)}}>
                            <i className="bx bx-check d-block d-sm-none" />
                            <span className="d-none d-sm-block">Accept</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        </>
    );
};

export default ListTaxClass;
