import { GET_TAX_CLASS, LIST_TAX_CLASSES } from "../actions/types";

const initialState = {
    taxClasses: [],
    error: null,
    taxClass: null
};
export default (state = initialState, { type, payload }) => {

    switch (type) {
        case LIST_TAX_CLASSES: 
        return {
            ...state,
            taxClasses: payload
        }
        case GET_TAX_CLASS:
            return {
                ...state,
                taxClass: payload
            }
        default:
            return state;
    }
}
