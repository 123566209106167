import { Link } from "react-router-dom";
import "./Menu.css"
import { useDispatch, useSelector } from "react-redux";
import { useLayoutEffect } from "react";
import { listStores } from "../../actions/storeActions";
const Menu = () => {
    const dispatch = useDispatch()
    const stores = useSelector(state => state.store.stores);

    useLayoutEffect(() => {
        dispatch(listStores());
    }, [])

    const selectStore = (store) => {
        console.log(store)
    }

    return (
        <aside id="layout-menu" className="layout-menu-horizontal menu-horizontal menu bg-menu-theme flex-grow-0">
            <div className="container-xxl d-flex h-100">
                <ul className="dropdown">
                    {/* <li className="menu-item active" >
                        <a href="javascript:void(0)" className="menu-link menu-toggle"
                            type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="menu-icon tf-icons bx bx-home-circle" />
                            <div data-i18n="Dashboards">Dashboards</div>
                        </a>
                        <ul className="menu-sub dropdown-menu">
                            <li className="menu-item active">
                                <a href="index.html" className="menu-link">
                                    <i className="menu-icon tf-icons bx bx-pie-chart-alt-2" />
                                    <div data-i18n="Analytics">Analytics</div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a href="dashboards-ecommerce.html" className="menu-link">
                                    <i className="menu-icon tf-icons bx bx-analyse" />
                                    <div data-i18n="eCommerce">eCommerce</div>
                                </a>
                            </li>
                        </ul>
                    </li>
             
                    <li className="menu-item">
                        <a href="javascript:void(0)" className="menu-link menu-toggle"
                         data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="menu-icon tf-icons bx bx-layout" />
                            <div data-i18n="Layouts">Layouts</div>
                        </a>
                        <ul className="menu-sub dropdown-menu">
                            <li className="menu-item">
                                <a href="layouts-without-menu.html" className="menu-link">
                                    <i className="menu-icon tf-icons bx bx-menu" />
                                    <div data-i18n="Without menu">Without menu</div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a href="../vertical-menu-template/" className="menu-link" target="_blank">
                                    <i className="menu-icon tf-icons bx bx-vertical-center" />
                                    <div data-i18n="Vertical">Vertical</div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a href="layouts-fluid.html" className="menu-link">
                                    <i className="menu-icon tf-icons bx bx-fullscreen" />
                                    <div data-i18n="Fluid">Fluid</div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a href="layouts-container.html" className="menu-link">
                                    <i className="menu-icon tf-icons bx bx-exit-fullscreen" />
                                    <div data-i18n="Container">Container</div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a href="layouts-blank.html" className="menu-link">
                                    <i className="menu-icon tf-icons bx bx-square-rounded" />
                                    <div data-i18n="Blank">Blank</div>
                                </a>
                            </li>
                        </ul>
                    </li> */}

                    <li className="menu-item">
                        <a href="javascript:void(0)" className="menu-link menu-toggle"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="menu-icon tf-icons bx bx-layout" />
                            <div data-i18n="Layouts">Stores</div>
                        </a>
                        <ul className="menu-sub dropdown-menu">
                            {stores?.map(store => (
                                !store.isDeleted && <li className="menu-item">
                                    <a href="javascript:void(0)" className="menu-link"
                                    onClick={() => selectStore(store)}>
                                        <i className="menu-icon bx bx-right-arrow-alt" />
                                        <div data-i18n="Without menu">{store.name}</div>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </li>

                    <li className="menu-item">
                        <a href="javascript:void(0)" className="menu-link menu-toggle"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="menu-icon tf-icons bx bx-layout" />
                            <div data-i18n="Layouts">Manage Orders</div>
                        </a>
                        <ul className="menu-sub dropdown-menu">
                            <li className="menu-item">
                                <Link to="/admin/leads" className="menu-link">
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    {/* <i className="menu-icon tf-icons bx bx-menu" /> */}
                                    <div data-i18n="Without menu">Repair Orders</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin/sell-orders" className="menu-link">
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Without menu">Sell Orders</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin/buy-orders" className="menu-link">
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Without menu">Buy Orders</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin/buy-devices" className="menu-link">
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Without menu">Buy Devies</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin/inquiries" className="menu-link">
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    {/* <i className="menu-icon tf-icons bx bx-vertical-center" /> */}
                                    <div data-i18n="Vertical">Manage Inquiries</div>
                                </Link>
                            </li>
                            {/* <li className="menu-item">
                                <a href="layouts-fluid.html" className="menu-link">
                                    <i className="menu-icon tf-icons bx bx-fullscreen" />
                                    <div data-i18n="Fluid">Fluid</div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a href="layouts-container.html" className="menu-link">
                                    <i className="menu-icon tf-icons bx bx-exit-fullscreen" />
                                    <div data-i18n="Container">Container</div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a href="layouts-blank.html" className="menu-link">
                                    <i className="menu-icon tf-icons bx bx-square-rounded" />
                                    <div data-i18n="Blank">Blank</div>
                                </a>
                            </li> */}
                        </ul>
                    </li>

                    <li className="menu-item">
                        <a href="javascript:void(0)" className="menu-link menu-toggle"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            {/* <i className="menu-icon tf-icons bx bx-layout" /> */}
                            <i class='menu-icon bx bxs-cog' ></i>
                            <div data-i18n="Layouts">Settings</div>
                        </a>
                        <ul className="menu-sub dropdown-menu">
                            {/* <li className="menu-item">
                                <Link to="/admin/orders" className="menu-link">
                                    <i className="menu-icon tf-icons bx bx-menu" />
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Without menu">Orders</div>
                                </Link>
                            </li> */}
                            <li className="menu-item">
                                <Link to="/admin/manufacturers" className="menu-link">
                                    {/* <i className="menu-icon tf-icons bx bx-vertical-center" /> */}
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Vertical">Manufacturers</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin/device-colors" className="menu-link">
                                    {/* <i className="menu-icon tf-icons bx bx-vertical-center" /> */}
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Vertical">Device Colors</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin/tax-classes" className="menu-link">
                                    {/* <i className="menu-icon tf-icons bx bx-vertical-center" /> */}
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Vertical">Tax Class</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin/devices" className="menu-link">
                                    {/* <i className="menu-icon tf-icons bx bx-vertical-center" /> */}
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Vertical">Devices</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin/repair-categories" className="menu-link">
                                    {/* <i className="menu-icon tf-icons bx bx-vertical-center" /> */}
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Vertical">Repair Categories</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin/repair-services" className="menu-link">
                                    {/* <i className="menu-icon tf-icons bx bx-vertical-center" /> */}
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Vertical">Repair Services</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin/problems" className="menu-link">
                                    {/* <i className="menu-icon tf-icons bx bx-vertical-center" /> */}
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Vertical">Problems</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin/device-series" className="menu-link">
                                    {/* <i className="menu-icon tf-icons bx bx-vertical-center" /> */}
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Vertical">Device Series</div>
                                </Link>
                            </li>
                        </ul>
                    </li>

                    <li className="menu-item">
                        <a href="javascript:void(0)" className="menu-link menu-toggle"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="menu-icon tf-icons bx bx-layout" />
                            {/* <i class='menu-icon bx bxs-cog' ></i> */}
                            <div data-i18n="Layouts">Products</div>
                        </a>
                        <ul className="menu-sub dropdown-menu">
                            <li className="menu-item">
                                <Link to="/admin/products" className="menu-link">
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Without menu">Manage Products</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin/tradeins" className="menu-link">
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Without menu">Manage Trade In</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin/product-sizes" className="menu-link">
                                    {/* <i className="menu-icon tf-icons bx bx-menu" /> */}
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Without menu">Product Sizes</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin/product-categories" className="menu-link">
                                    {/* <i className="menu-icon tf-icons bx bx-menu" /> */}
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Without menu">Product Categories</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin/product-conditions" className="menu-link">
                                    {/* <i className="menu-icon tf-icons bx bx-menu" /> */}
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Without menu">Product Conditions</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin/product-networks" className="menu-link">
                                    {/* <i className="menu-icon tf-icons bx bx-menu" /> */}
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Without menu">Product Networks</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin/product-physical-locations" className="menu-link">
                                    {/* <i className="menu-icon tf-icons bx bx-menu" /> */}
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Without menu">Product Physical Locations</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin/product-suppliers" className="menu-link">
                                    {/* <i className="menu-icon tf-icons bx bx-menu" /> */}
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Without menu">Product Suppliers</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin/blogs" className="menu-link">
                                    {/* <i className="menu-icon tf-icons bx bx-menu" /> */}
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Without menu">Blogs</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin/stores" className="menu-link">
                                    {/* <i className="menu-icon tf-icons bx bx-menu" /> */}
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Without menu">Stores</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin/pages" className="menu-link">
                                    {/* <i className="menu-icon tf-icons bx bx-menu" /> */}
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Without menu">Pages</div>
                                </Link>
                            </li>
                        </ul>

                    </li>

                    <li className="menu-item">
                        <a href="javascript:void(0)" className="menu-link menu-toggle"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            {/* <i class="fa-thin fa-screwdriver-wrench"></i> */}
                            <i className="menu-icon tf-icons bx bx-layout" />
                            <div data-i18n="Layouts">Employees</div>
                        </a>
                        <ul className="menu-sub dropdown-menu">
                            <li className="menu-item">
                                <Link to="/admin/employees" className="menu-link">
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    {/* <i className="menu-icon tf-icons bx bx-menu" //> */}
                                    <div data-i18n="Without menu">Manage Employees</div>
                                </Link>
                            </li>


                            <li className="menu-item">
                                <Link to="/admin/roles" className="menu-link">
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    {/* <i className="menu-icon tf-icons bx bx-menu" /> */}
                                    <div data-i18n="Without menu">Manage Roles</div>
                                </Link>
                            </li>


                        </ul>
                    </li>

                    <li className="menu-item">
                        <a href="javascript:void(0)" className="menu-link menu-toggle"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            {/* <i class="fa-thin fa-screwdriver-wrench"></i> */}
                            <i className="menu-icon tf-icons bx bx-layout" />
                            <div data-i18n="Layouts">Customers</div>
                        </a>
                        <ul className="menu-sub dropdown-menu">
                            <li className="menu-item">
                                <Link to="/admin/customers" className="menu-link">
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Without menu">Manage Customers</div>
                                </Link>
                            </li>


                            {/* <li className="menu-item">
                                <Link to="/admin/roles" className="menu-link">
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Without menu">Manage Roles</div>
                                </Link>
                            </li> */}


                        </ul>
                    </li>

                    <li className="menu-item">
                        <a href="javascript:void(0)" className="menu-link menu-toggle"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            {/* <i class="fa-thin fa-screwdriver-wrench"></i> */}
                            <i className="menu-icon tf-icons bx bx-layout" />
                            <div data-i18n="Layouts">Beta</div>
                        </a>
                        <ul className="menu-sub dropdown-menu">
                            <li className="menu-item">
                                <Link to="/admin/manage-tickets" className="menu-link">
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Without menu">Manage Tickets</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin/inventory-transfer" className="menu-link">
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Without menu">Transfer Inventory</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin/inventory-count" className="menu-link">
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Without menu">Inventory Count</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin/purchase-orders" className="menu-link">
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Without menu">Purchase Orders</div>
                                </Link>
                            </li>



                            {/* <li className="menu-item">
                                <Link to="/admin/roles" className="menu-link">
                                    <i className="menu-icon bx bx-right-arrow-alt" />
                                    <div data-i18n="Without menu">Manage Roles</div>
                                </Link>
                            </li> */}


                        </ul>
                    </li>

                    {/* <li className="menu-item">
                        <a href="javascript:void(0)" className="menu-link menu-toggle">
                            <i className="menu-icon tf-icons bx bx-customize" />
                            <div data-i18n="Apps">Apps</div>
                        </a>
                        <ul className="menu-sub">
                            <li className="menu-item">
                                <a href="app-calendar.html" className="menu-link">
                                    <i className="menu-icon tf-icons bx bx-calendar" />
                                    <div data-i18n="Calendar">Calendar</div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a href="javascript:void(0);" className="menu-link menu-toggle">
                                    <i className="menu-icon tf-icons bx bx-food-menu" />
                                    <div data-i18n="Invoice">Invoice</div>
                                </a>
                                <ul className="menu-sub">
                                    <li className="menu-item">
                                        <a href="app-invoice-list.html" className="menu-link">
                                            <div data-i18n="List">List</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="app-invoice-preview.html" className="menu-link">
                                            <div data-i18n="Preview">Preview</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="app-invoice-edit.html" className="menu-link">
                                            <div data-i18n="Edit">Edit</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="app-invoice-add.html" className="menu-link">
                                            <div data-i18n="Add">Add</div>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="menu-item">
                                <a href="javascript:void(0);" className="menu-link menu-toggle">
                                    <i className="menu-icon tf-icons bx bx-user" />
                                    <div data-i18n="Users">Users</div>
                                </a>
                                <ul className="menu-sub">
                                    <li className="menu-item">
                                        <a href="app-user-list.html" className="menu-link">
                                            <div data-i18n="List">List</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="javascript:void(0);" className="menu-link menu-toggle">
                                            <div data-i18n="View">View</div>
                                        </a>
                                        <ul className="menu-sub">
                                            <li className="menu-item">
                                                <a href="app-user-view-account.html" className="menu-link">
                                                    <div data-i18n="Account">Account</div>
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="app-user-view-security.html" className="menu-link">
                                                    <div data-i18n="Security">Security</div>
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="app-user-view-billing.html" className="menu-link">
                                                    <div data-i18n="Billing & Plans">Billing &amp; Plans</div>
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="app-user-view-notifications.html" className="menu-link">
                                                    <div data-i18n="Notifications">Notifications</div>
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="app-user-view-connections.html" className="menu-link">
                                                    <div data-i18n="Connections">Connections</div>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                             {*<li className="menu-item">
                                <a href="javascript:void(0);" className="menu-link menu-toggle">
                                    <i className="menu-icon tf-icons bx bx-check-shield" />
                                    <div data-i18n="Roles & Permissions">Roles &amp; Permission</div>
                                </a>
                                <ul className="menu-sub">
                                    <li className="menu-item">
                                        <a href="app-access-roles.html" className="menu-link">
                                            <div data-i18n="Roles">Roles</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="app-access-permission.html" className="menu-link">
                                            <div data-i18n="Permission">Permission</div>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                
                    <li className="menu-item">
                        <a href="javascript:void(0)" className="menu-link menu-toggle">
                            <i className="menu-icon tf-icons bx bx-collection" />
                            <div data-i18n="Pages">Pages</div>
                        </a>
                        <ul className="menu-sub">
                            <li className="menu-item">
                                <a href="javascript:void(0);" className="menu-link menu-toggle">
                                    <i className="menu-icon tf-icons bx bx-user-circle" />
                                    <div data-i18n="User Profile">User Profile</div>
                                </a>
                                <ul className="menu-sub">
                                    <li className="menu-item">
                                        <a href="pages-profile-user.html" className="menu-link">
                                            <div data-i18n="Profile">Profile</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="pages-profile-teams.html" className="menu-link">
                                            <div data-i18n="Teams">Teams</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="pages-profile-projects.html" className="menu-link">
                                            <div data-i18n="Projects">Projects</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="pages-profile-connections.html" className="menu-link">
                                            <div data-i18n="Connections">Connections</div>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="menu-item">
                                <a href="javascript:void(0);" className="menu-link menu-toggle">
                                    <i className="menu-icon tf-icons bx bx-cog" />
                                    <div data-i18n="Account Settings">Account Settings</div>
                                </a>
                                <ul className="menu-sub">
                                    <li className="menu-item">
                                        <a href="pages-account-settings-account.html" className="menu-link">
                                            <div data-i18n="Account">Account</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="pages-account-settings-security.html" className="menu-link">
                                            <div data-i18n="Security">Security</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="pages-account-settings-billing.html" className="menu-link">
                                            <div data-i18n="Billing & Plans">Billing &amp; Plans</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="pages-account-settings-notifications.html" className="menu-link">
                                            <div data-i18n="Notifications">Notifications</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="pages-account-settings-connections.html" className="menu-link">
                                            <div data-i18n="Connections">Connections</div>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="menu-item">
                                <a href="pages-faq.html" className="menu-link">
                                    <i className="menu-icon tf-icons bx bx-help-circle" />
                                    <div data-i18n="FAQ">FAQ</div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a href="javascript:void(0);" className="menu-link menu-toggle">
                                    <i className="menu-icon tf-icons bx bxs-buoy" />
                                    <div data-i18n="Help Center">Help Center</div>
                                </a>
                                <ul className="menu-sub">
                                    <li className="menu-item">
                                        <a href="pages-help-center-landing.html" className="menu-link">
                                            <div data-i18n="Landing">Landing</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="pages-help-center-categories.html" className="menu-link">
                                            <div data-i18n="Categories">Categories</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="pages-help-center-article.html" className="menu-link">
                                            <div data-i18n="Article">Article</div>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="menu-item">
                                <a href="pages-pricing.html" className="menu-link">
                                    <i className="menu-icon tf-icons bx bx-diamond" />
                                    <div data-i18n="Pricing">Pricing</div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a href="javascript:void(0);" className="menu-link menu-toggle">
                                    <i className="menu-icon tf-icons bx bx-shape-circle" />
                                    <div data-i18n="Misc">Misc</div>
                                </a>
                                <ul className="menu-sub">
                                    <li className="menu-item">
                                        <a href="pages-misc-error.html" className="menu-link" target="_blank">
                                            <div data-i18n="Error">Error</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="pages-misc-under-maintenance.html" className="menu-link" target="_blank">
                                            <div data-i18n="Under Maintenance">Under Maintenance</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="pages-misc-comingsoon.html" className="menu-link" target="_blank">
                                            <div data-i18n="Coming Soon">Coming Soon</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="pages-misc-not-authorized.html" className="menu-link" target="_blank">
                                            <div data-i18n="Not Authorized">Not Authorized</div>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="menu-item">
                                <a href="javascript:void(0);" className="menu-link menu-toggle">
                                    <i className="menu-icon tf-icons bx bx-lock-open-alt" />
                                    <div data-i18n="Authentications">Authentications</div>
                                </a>
                                <ul className="menu-sub">
                                    <li className="menu-item">
                                        <a href="javascript:void(0);" className="menu-link menu-toggle">
                                            <div data-i18n="Login">Login</div>
                                        </a>
                                        <ul className="menu-sub">
                                            <li className="menu-item">
                                                <a href="auth-login-basic.html" className="menu-link" target="_blank">
                                                    <div data-i18n="Basic">Basic</div>
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="auth-login-cover.html" className="menu-link" target="_blank">
                                                    <div data-i18n="Cover">Cover</div>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="menu-item">
                                        <a href="javascript:void(0);" className="menu-link menu-toggle">
                                            <div data-i18n="Register">Register</div>
                                        </a>
                                        <ul className="menu-sub">
                                            <li className="menu-item">
                                                <a href="auth-register-basic.html" className="menu-link" target="_blank">
                                                    <div data-i18n="Basic">Basic</div>
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="auth-register-cover.html" className="menu-link" target="_blank">
                                                    <div data-i18n="Cover">Cover</div>
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="auth-register-multisteps.html" className="menu-link" target="_blank">
                                                    <div data-i18n="Multi-steps">Multi-steps</div>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="menu-item">
                                        <a href="javascript:void(0);" className="menu-link menu-toggle">
                                            <div data-i18n="Verify Email">Verify Email</div>
                                        </a>
                                        <ul className="menu-sub">
                                            <li className="menu-item">
                                                <a href="auth-verify-email-basic.html" className="menu-link" target="_blank">
                                                    <div data-i18n="Basic">Basic</div>
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="auth-verify-email-cover.html" className="menu-link" target="_blank">
                                                    <div data-i18n="Cover">Cover</div>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="menu-item">
                                        <a href="javascript:void(0);" className="menu-link menu-toggle">
                                            <div data-i18n="Reset Password">Reset Password</div>
                                        </a>
                                        <ul className="menu-sub">
                                            <li className="menu-item">
                                                <a href="auth-reset-password-basic.html" className="menu-link" target="_blank">
                                                    <div data-i18n="Basic">Basic</div>
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="auth-reset-password-cover.html" className="menu-link" target="_blank">
                                                    <div data-i18n="Cover">Cover</div>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="menu-item">
                                        <a href="javascript:void(0);" className="menu-link menu-toggle">
                                            <div data-i18n="Forgot Password">Forgot Password</div>
                                        </a>
                                        <ul className="menu-sub">
                                            <li className="menu-item">
                                                <a href="auth-forgot-password-basic.html" className="menu-link" target="_blank">
                                                    <div data-i18n="Basic">Basic</div>
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="auth-forgot-password-cover.html" className="menu-link" target="_blank">
                                                    <div data-i18n="Cover">Cover</div>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="menu-item">
                                        <a href="javascript:void(0);" className="menu-link menu-toggle">
                                            <div data-i18n="Two Steps">Two Steps</div>
                                        </a>
                                        <ul className="menu-sub">
                                            <li className="menu-item">
                                                <a href="auth-two-steps-basic.html" className="menu-link" target="_blank">
                                                    <div data-i18n="Basic">Basic</div>
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="auth-two-steps-cover.html" className="menu-link" target="_blank">
                                                    <div data-i18n="Cover">Cover</div>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li className="menu-item">
                                <a href="javascript:void(0);" className="menu-link menu-toggle">
                                    <i className="menu-icon tf-icons bx bx-spreadsheet" />
                                    <div data-i18n="Wizard Examples">Wizard Examples</div>
                                </a>
                                <ul className="menu-sub">
                                    <li className="menu-item">
                                        <a href="wizard-ex-checkout.html" className="menu-link">
                                            <div data-i18n="Checkout">Checkout</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="wizard-ex-property-listing.html" className="menu-link">
                                            <div data-i18n="Property Listing">Property Listing</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="wizard-ex-create-deal.html" className="menu-link">
                                            <div data-i18n="Create Deal">Create Deal</div>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="menu-item">
                                <a href="modal-examples.html" className="menu-link">
                                    <i className="menu-icon tf-icons bx bx-window-open" />
                                    <div data-i18n="Modal Examples">Modal Examples</div>
                                </a>
                            </li>
                        </ul>
                    </li>
                   
                    <li className="menu-item">
                        <a href="javascript:void(0)" className="menu-link menu-toggle">
                            <i className="menu-icon tf-icons bx bx-code-block" />
                            <div data-i18n="Components">Components</div>
                        </a>
                        <ul className="menu-sub">
                        
                            <li className="menu-item">
                                <a href="javascript:void(0);" className="menu-link menu-toggle">
                                    <i className="menu-icon tf-icons bx bx-collection" />
                                    <div data-i18n="Cards">Cards</div>
                                </a>
                                <ul className="menu-sub">
                                    <li className="menu-item">
                                        <a href="cards-basic.html" className="menu-link">
                                            <div data-i18n="Basic">Basic</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="cards-advance.html" className="menu-link">
                                            <div data-i18n="Advance">Advance</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="cards-statistics.html" className="menu-link">
                                            <div data-i18n="Statistics">Statistics</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="cards-analytics.html" className="menu-link">
                                            <div data-i18n="Analytics">Analytics</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="cards-actions.html" className="menu-link">
                                            <div data-i18n="Actions">Actions</div>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                           
                            <li className="menu-item">
                                <a href="javascript:void(0)" className="menu-link menu-toggle">
                                    <i className="menu-icon tf-icons bx bx-box" />
                                    <div data-i18n="User interface">User interface</div>
                                </a>
                                <ul className="menu-sub">
                                    <li className="menu-item">
                                        <a href="ui-accordion.html" className="menu-link">
                                            <div data-i18n="Accordion">Accordion</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="ui-alerts.html" className="menu-link">
                                            <div data-i18n="Alerts">Alerts</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="ui-badges.html" className="menu-link">
                                            <div data-i18n="Badges">Badges</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="ui-buttons.html" className="menu-link">
                                            <div data-i18n="Buttons">Buttons</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="ui-carousel.html" className="menu-link">
                                            <div data-i18n="Carousel">Carousel</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="ui-collapse.html" className="menu-link">
                                            <div data-i18n="Collapse">Collapse</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="ui-dropdowns.html" className="menu-link">
                                            <div data-i18n="Dropdowns">Dropdowns</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="ui-footer.html" className="menu-link">
                                            <div data-i18n="Footer">Footer</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="ui-list-groups.html" className="menu-link">
                                            <div data-i18n="List groups">List groups</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="ui-modals.html" className="menu-link">
                                            <div data-i18n="Modals">Modals</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="ui-navbar.html" className="menu-link">
                                            <div data-i18n="Navbar">Navbar</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="ui-offcanvas.html" className="menu-link">
                                            <div data-i18n="Offcanvas">Offcanvas</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="ui-pagination-breadcrumbs.html" className="menu-link">
                                            <div data-i18n="Pagination & Breadcrumbs">Pagination &amp; Breadcrumbs</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="ui-progress.html" className="menu-link">
                                            <div data-i18n="Progress">Progress</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="ui-spinners.html" className="menu-link">
                                            <div data-i18n="Spinners">Spinners</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="ui-tabs-pills.html" className="menu-link">
                                            <div data-i18n="Tabs & Pills">Tabs &amp; Pills</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="ui-toasts.html" className="menu-link">
                                            <div data-i18n="Toasts">Toasts</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="ui-tooltips-popovers.html" className="menu-link">
                                            <div data-i18n="Tooltips & popovers">Tooltips &amp; popovers</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="ui-typography.html" className="menu-link">
                                            <div data-i18n="Typography">Typography</div>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                           
                            <li className="menu-item">
                                <a href="javascript:void(0)" className="menu-link menu-toggle">
                                    <i className="menu-icon tf-icons bx bx-copy" />
                                    <div data-i18n="Extended UI">Extended UI</div>
                                </a>
                                <ul className="menu-sub">
                                    <li className="menu-item">
                                        <a href="extended-ui-avatar.html" className="menu-link">
                                            <div data-i18n="Avatar">Avatar</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="extended-ui-blockui.html" className="menu-link">
                                            <div data-i18n="BlockUI">BlockUI</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="extended-ui-drag-and-drop.html" className="menu-link">
                                            <div data-i18n="Drag & Drop">Drag &amp; Drop</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="extended-ui-media-player.html" className="menu-link">
                                            <div data-i18n="Media Player">Media Player</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="extended-ui-perfect-scrollbar.html" className="menu-link">
                                            <div data-i18n="Perfect scrollbar">Perfect scrollbar</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="extended-ui-star-ratings.html" className="menu-link">
                                            <div data-i18n="Star Ratings">Star Ratings</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="extended-ui-sweetalert2.html" className="menu-link">
                                            <div data-i18n="SweetAlert2">SweetAlert2</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="extended-ui-text-divider.html" className="menu-link">
                                            <div data-i18n="Text Divider">Text Divider</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="javascript:void(0);" className="menu-link menu-toggle">
                                            <div data-i18n="Timeline">Timeline</div>
                                        </a>
                                        <ul className="menu-sub">
                                            <li className="menu-item">
                                                <a href="extended-ui-timeline-basic.html" className="menu-link">
                                                    <div data-i18n="Basic">Basic</div>
                                                </a>
                                            </li>
                                            <li className="menu-item">
                                                <a href="extended-ui-timeline-fullscreen.html" className="menu-link">
                                                    <div data-i18n="Fullscreen">Fullscreen</div>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="menu-item">
                                        <a href="extended-ui-tour.html" className="menu-link">
                                            <div data-i18n="Tour">Tour</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="extended-ui-treeview.html" className="menu-link">
                                            <div data-i18n="Treeview">Treeview</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="extended-ui-misc.html" className="menu-link">
                                            <div data-i18n="Miscellaneous">Miscellaneous</div>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        
                            <li className="menu-item">
                                <a href="javascript:void(0)" className="menu-link menu-toggle">
                                    <i className="menu-icon tf-icons bx bx-crown" />
                                    <div data-i18n="Icons">Icons</div>
                                </a>
                                <ul className="menu-sub">
                                    <li className="menu-item">
                                        <a href="icons-boxicons.html" className="menu-link">
                                            <div data-i18n="Boxicons">Boxicons</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="icons-font-awesome.html" className="menu-link">
                                            <div data-i18n="Fontawesome">Fontawesome</div>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                   
                    <li className="menu-item">
                        <a href="javascript:void(0)" className="menu-link menu-toggle">
                            <i className="menu-icon tf-icons bx bx-book-content" />
                            <div data-i18n="Forms">Forms</div>
                        </a>
                        <ul className="menu-sub">
                            <li className="menu-item">
                                <a href="javascript:void(0);" className="menu-link menu-toggle">
                                    <i className="menu-icon tf-icons bx bx-detail" />
                                    <div data-i18n="Form Elements">Form Elements</div>
                                </a>
                                <ul className="menu-sub">
                                    <li className="menu-item">
                                        <a href="forms-basic-inputs.html" className="menu-link">
                                            <div data-i18n="Basic Inputs">Basic Inputs</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="forms-input-groups.html" className="menu-link">
                                            <div data-i18n="Input groups">Input groups</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="forms-custom-options.html" className="menu-link">
                                            <div data-i18n="Custom Options">Custom Options</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="forms-editors.html" className="menu-link">
                                            <div data-i18n="Editors">Editors</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="forms-file-upload.html" className="menu-link">
                                            <div data-i18n="File Upload">File Upload</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="forms-pickers.html" className="menu-link">
                                            <div data-i18n="Pickers">Pickers</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="forms-selects.html" className="menu-link">
                                            <div data-i18n="Select & Tags">Select &amp; Tags</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="forms-sliders.html" className="menu-link">
                                            <div data-i18n="Sliders">Sliders</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="forms-switches.html" className="menu-link">
                                            <div data-i18n="Switches">Switches</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="forms-extras.html" className="menu-link">
                                            <div data-i18n="Extras">Extras</div>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="menu-item">
                                <a href="javascript:void(0);" className="menu-link menu-toggle">
                                    <i className="menu-icon tf-icons bx bx-detail" />
                                    <div data-i18n="Form Layouts">Form Layouts</div>
                                </a>
                                <ul className="menu-sub">
                                    <li className="menu-item">
                                        <a href="form-layouts-vertical.html" className="menu-link">
                                            <div data-i18n="Vertical Form">Vertical Form</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="form-layouts-horizontal.html" className="menu-link">
                                            <div data-i18n="Horizontal Form">Horizontal Form</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="form-layouts-sticky.html" className="menu-link">
                                            <div data-i18n="Sticky Actions">Sticky Actions</div>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="menu-item">
                                <a href="javascript:void(0);" className="menu-link menu-toggle">
                                    <i className="menu-icon tf-icons bx bx-carousel" />
                                    <div data-i18n="Form Wizard">Form Wizard</div>
                                </a>
                                <ul className="menu-sub">
                                    <li className="menu-item">
                                        <a href="form-wizard-numbered.html" className="menu-link">
                                            <div data-i18n="Numbered">Numbered</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="form-wizard-icons.html" className="menu-link">
                                            <div data-i18n="Icons">Icons</div>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="menu-item">
                                <a href="form-validation.html" className="menu-link">
                                    <i className="menu-icon tf-icons bx bx-list-check" />
                                    <div data-i18n="Form Validation">Form Validation</div>
                                </a>
                            </li>
                        </ul>
                    </li>
                
                    <li className="menu-item">
                        <a href="javascript:void(0)" className="menu-link menu-toggle">
                            <i className="menu-icon tf-icons bx bx-grid-alt" />
                            <div data-i18n="Tables">Tables</div>
                        </a>
                        <ul className="menu-sub">
                         
                            <li className="menu-item">
                                <a href="tables-basic.html" className="menu-link">
                                    <i className="menu-icon tf-icons bx bx-table" />
                                    <div data-i18n="Tables">Tables</div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a href="javascript:void(0);" className="menu-link menu-toggle">
                                    <i className="menu-icon tf-icons bx bx-grid" />
                                    <div data-i18n="Datatables">Datatables</div>
                                </a>
                                <ul className="menu-sub">
                                    <li className="menu-item">
                                        <a href="tables-datatables-basic.html" className="menu-link">
                                            <div data-i18n="Basic">Basic</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="tables-datatables-advanced.html" className="menu-link">
                                            <div data-i18n="Advanced">Advanced</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="tables-datatables-extensions.html" className="menu-link">
                                            <div data-i18n="Extensions">Extensions</div>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
               
                    <li className="menu-item">
                        <a href="javascript:void(0)" className="menu-link menu-toggle">
                            <i className="menu-icon tf-icons bx bx-bar-chart-square" />
                            <div data-i18n="Charts & Maps">Charts &amp; Maps</div>
                        </a>
                        <ul className="menu-sub">
                            <li className="menu-item">
                                <a href="javascript:void(0);" className="menu-link menu-toggle">
                                    <i className="menu-icon tf-icons bx bx-chart" />
                                    <div data-i18n="Charts">Charts</div>
                                </a>
                                <ul className="menu-sub">
                                    <li className="menu-item">
                                        <a href="charts-apex.html" className="menu-link">
                                            <div data-i18n="Apex Charts">Apex Charts</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="charts-chartjs.html" className="menu-link">
                                            <div data-i18n="ChartJS">ChartJS</div>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="menu-item">
                                <a href="maps-leaflet.html" className="menu-link">
                                    <i className="menu-icon tf-icons bx bx-map-alt" />
                                    <div data-i18n="Leaflet Maps">Leaflet Maps</div>
                                </a>
                            </li>
                        </ul>
                    </li>
                    
                    <li className="menu-item">
                        <a href="javascript:void(0)" className="menu-link menu-toggle">
                            <i className="menu-icon tf-icons bx bx-shape-circle" />
                            <div data-i18n="Misc">Misc</div>
                        </a>
                        <ul className="menu-sub">
                            <li className="menu-item">
                                <a href="https://pixinvent.ticksy.com/" className="menu-link">
                                    <i className="menu-icon tf-icons bx bx-support" />
                                    <div data-i18n="Support">Support</div>
                                </a>
                            </li>
                            <li className="menu-item">
                                <a href="https://pixinvent.com/demo/frest-clean-bootstrap-admin-dashboard-template/documentation-bs5/" className="menu-link">
                                    <i className="menu-icon tf-icons bx bx-file" />
                                    <div data-i18n="Documentation">Documentation</div>
                                </a>
                            </li>
                        </ul>
                    </li> */}
                </ul>
            </div>
        </aside>
    );
}

export default Menu;