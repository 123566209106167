import {
    ALERT_MESSAGE,
    ALERT_MESSAGE_EMPTY,
    ERRORS_EMPTY, ERRORS_GET,
    MEDIA_UPDATE,
    SERVER_ERROR,
    MEDIA_EMPTY,
    UPDATED,
    SET_COUNT,
    LIST_HEARUS_OPTIONS
} from "../actions/types";

const initialState = {
    response: null,
    errors: null,
    server_error: null,
    media: null,
    updated: false,
    count: 0,
    hearusOptions: []
};
export default (state = initialState, { type, payload }) => {
    console.log('globalReducer', payload);

    switch (type) {
        case LIST_HEARUS_OPTIONS:
            return {
                ...state,
                hearusOptions: payload
            }
        case SET_COUNT:
            return {
                ...state,
                count: payload
            }
        case MEDIA_EMPTY:
            return {
                ...state,
                media: null
            }
        case MEDIA_UPDATE:
            return {
                ...state,
                media: payload
            }
        case ALERT_MESSAGE:
            return {
                ...state,
                response: payload
            }
        case ALERT_MESSAGE_EMPTY:
            return {
                ...state,
                response: null
            }
        case ERRORS_GET:
            return {
                ...state,
                errors: payload
            }
        case ERRORS_EMPTY:
            return {
                ...state,
                errors: [],
                response: null
            }
        case SERVER_ERROR:
            return {
                ...state,
                response: payload,
                server_error: payload
            }
        case UPDATED: 
            return {
                ...state,
                updated: payload
            }
        default:
            return state;
    }
}