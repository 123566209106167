import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { deleteEmployeeAction, listEmployees } from "../../../actions/employeeActions";
import { listStores } from "../../../actions/storeActions";
import { listUserRoles } from "../../../actions/userRoleActions";
import { capitalizeFirstChar } from "../../../utils";
const ListCustomers = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const employees = useSelector((state) => state.employee.employees)
    const userRoles = useSelector((state) => state.userRole.userRoles)
    // const mangestore = useSelector((state)=>state.store.stores)
    const stores = useSelector((state) => state.store.stores)
    const [modal, setModal] = useState(false);
    const [delId, setDelId] = useState(null);

    useEffect(() => {
        dispatch(listEmployees('CUSTOMER'))
        dispatch(listStores())
        dispatch(listUserRoles())
    }, [])

    const styleShow = {
        display: 'block',
        paddingRight: '17px'
    }

    const styleHide = {
        display: 'none',
        paddingRight: '17px'
    }

    const deleteUserRole = () => {
        // console.log(delId);
        delId && dispatch(deleteEmployeeAction(delId));
        setModal(false);
    }
    // console.log(employees)
    // console.log("Stores", stores)
    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="breadcrumbs-top py-4">
                    {/* <h5 className="content-header-title float-left pr-1 mb-0">
                    Manage User Roles
                </h5> */}
                    <div class="col-12 d-flex justify-content-between align-items-center">
                        <div className="breadcrumb-wrapper d-none d-sm-block">
                            <ol className="breadcrumb breadcrumb-style1 p-0 mb-0 pl-1">
                                <li className="breadcrumb-item">
                                    {/* <a href="javascript:void(0);">
                                    <i className="bx bx-home-alt" />
                                </a> */}
                                    <Link to="/admin">
                                        <i className="bx bx-home-alt" />
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    List All
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="row" id="basic-table">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title d-flex justify-content-between align-items-center">
                                    <h5 className="">
                                        Manage Customers
                                    </h5>
                                    <button onClick={() => { navigate('add') }} type="button" class="btn btn-primary mr-1">Add Customer</button>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Full Name</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th>City</th>
                                                <th>Address</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {employees && employees.map((employee, key) => {
                                                // if (employee.storeAccess.length > 0)
                                                //     debugger
                                                return !employee.isDeleted && employee.userType == 'CUSTOMER' && <tr key={key}>
                                                    <td>{capitalizeFirstChar(employee?.firstName)} {capitalizeFirstChar(employee?.lastName)}</td>
                                                    <td>{employee?.email}</td>
                                                    <td>{employee?.mobile}</td>
                                                    <td>{employee?.city}</td>
                                                    <td>{employee?.address}</td>
                                                    <td className="text-end">
                                                        <div class="demo-inline-spacing">
                                                            <div class="btn-group">
                                                                <button
                                                                    type="button"
                                                                    class="btn btn-primary btn-icon rounded-pill dropdown-toggle hide-arrow"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false"
                                                                >
                                                                    <i class="bx bx-dots-vertical-rounded"></i>
                                                                </button>
                                                                <ul class="dropdown-menu dropdown-menu-end">
                                                                    <li className="dropdown-item"><Link to={`/admin/customers/${employee._id}`}>
                                                                        <i className="badge-circle badge-circle-light-secondary bx bx-edit font-medium-1" /> Edit
                                                                    </Link></li>

                                                                    <li className="dropdown-item"><a href="javascript:void(0);" onClick={() => { setModal(true); setDelId(employee._id) }}>
                                                                        <i className="badge-circle badge-circle-light-secondary bx bx-trash font-medium-1" /> Delete
                                                                    </a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal card fade text-left ${modal ? 'show' : ''}`} id="danger" tabIndex={-1} aria-labelledby="myModalLabel120" style={modal ? styleShow : styleHide} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-primary py-2">
                            <h5 className="modal-title text-white" id="myModalLabel120">Danger!</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={() => { setModal(false) }}>
                                <i className="bx bx-x" />
                            </button>
                        </div>
                        <div className="modal-body">
                            Are you sure! You want to delete this Customer?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light-secondary" data-dismiss="modal"
                                onClick={() => { setModal(false) }}>
                                <i className="bx bx-x d-block d-sm-none" />
                                <span className="d-none d-sm-block">Cancel</span>
                            </button>
                            <button type="button" className="btn btn-danger ms-1" data-dismiss="modal"
                                onClick={() => { deleteUserRole(delId) }}>
                                <i className="bx bx-check d-block d-sm-none" />
                                <span className="d-none d-sm-block">Accept</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ListCustomers;