import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { registerUser } from "../../actions/authActions";
import { emptyAlertMessage, emptyErrors } from "../../actions/globalActions";

import Alert from "../elements/Alert";

const Signup = () => {
    const dispatch = useDispatch();

    const errors = useSelector((state) => state.global.errors);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");

    const submitForm = (e) => {
        e.preventDefault();
        dispatch(emptyErrors());
        dispatch(emptyAlertMessage());
        const new_user = {
            firstName,
            lastName,
            password,
            email,
        };
        dispatch(registerUser(new_user));
    };

    return (
        <div
            className="horizontal-layout horizontal-menu navbar-static 1-column   footer-static bg-full-screen-image  blank-page"
            data-open="hover"
            data-menu="horizontal-menu"
            data-col="1-column"
        >
            <div className="app-content content">
                <div className="content-overlay" />
                <div className="content-wrapper">
                    <div className="content-header row"></div>
                    <div className="content-body">
                        {/* register section starts */}
                        <section className="row flexbox-container">
                            <div className="col-xl-8 col-10">
                                <div className="card bg-authentication mb-0">
                                    <div className="row m-0">
                                        {/* register section left */}
                                        <div className="col-md-6 col-12 px-0">
                                            <div className="card disable-rounded-right mb-0 p-2 h-100 d-flex justify-content-center">
                                                <div className="card-header pb-1">
                                                    <div className="card-title">
                                                        <h4 className="text-center mb-2">
                                                            Sign Up
                                                        </h4>
                                                    </div>
                                                </div>
                                                <div className="text-center">
                                                    <p>
                                                        {" "}
                                                        <small>
                                                            {" "}
                                                            Please enter your
                                                            details to sign up
                                                            and be part of our
                                                            great community
                                                        </small>
                                                    </p>
                                                    <Alert />
                                                </div>
                                                <div className="card-body">
                                                    <form onSubmit={submitForm}>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6 mb-50">
                                                                <label htmlFor="inputfirstname4">
                                                                    first name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="inputfirstname4"
                                                                    placeholder="First name"
                                                                    value={
                                                                        firstName
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setFirstName(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <p className="error">
                                                                {errors &&
                                                                    errors.firstName}
                                                            </p>
                                                            <div className="form-group col-md-6 mb-50">
                                                                <label htmlFor="inputlastname4">
                                                                    last name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="inputlastname4"
                                                                    placeholder="Last name"
                                                                    value={
                                                                        lastName
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setLastName(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                            </div>

                                                            <p className="error">
                                                                {errors &&
                                                                    errors.lastName}
                                                            </p>
                                                        </div>
                                                        {/* <div className="form-group mb-50">
                                                            <label
                                                                className="text-bold-600"
                                                                htmlFor="exampleInputUsername1"
                                                            >
                                                                username
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputUsername1"
                                                                placeholder="Username"
                                                            />
                                                        </div> */}
                                                        <div className="form-group mb-50">
                                                            <label
                                                                className="text-bold-600"
                                                                htmlFor="exampleInputEmail1"
                                                            >
                                                                Email address
                                                            </label>
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                id="exampleInputEmail1"
                                                                placeholder="Email address"
                                                                value={email}
                                                                onChange={(e) =>
                                                                    setEmail(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <p className="error">
                                                            {errors &&
                                                                errors.email}
                                                        </p>
                                                        <div className="form-group mb-2">
                                                            <label
                                                                className="text-bold-600"
                                                                htmlFor="exampleInputPassword1"
                                                            >
                                                                Password
                                                            </label>
                                                            <input
                                                                type="password"
                                                                className="form-control"
                                                                id="exampleInputPassword1"
                                                                placeholder="Password"
                                                                value={password}
                                                                onChange={(e) =>
                                                                    setPassword(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <p className="error">
                                                            {errors &&
                                                                errors.password}
                                                        </p>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary glow position-relative w-100"
                                                        >
                                                            SIGN UP
                                                            <i
                                                                id="icon-arrow"
                                                                className="bx bx-right-arrow-alt"
                                                            />
                                                        </button>
                                                    </form>
                                                    <hr />
                                                    <div className="text-center">
                                                        <small className="mr-25">
                                                            Already have an
                                                            account?
                                                        </small>
                                                        <Link to="/signin">
                                                            <small>
                                                                Sign in
                                                            </small>{" "}
                                                        </Link>
                                                        {/* <a href="auth-login.html">
                                                            <small>
                                                                Sign in
                                                            </small>{" "}
                                                        </a> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* image section right */}
                                        <div className="col-md-6 d-md-block d-none text-center align-self-center p-3">
                                            <img
                                                className="img-fluid"
                                                src="../../../app-assets/images/pages/register.png"
                                                alt="branding logo"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* register section endss */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signup;
