import React from 'react'
import { Link } from 'react-router-dom'

const InventoryCountUpdate = () => {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">

            <nav aria-label=" breadcrumb " className='bg-white'>
                <ol className="breadcrumb breadcrumb-style1 p-1">
                    <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">
                        <Link to="/admin/inventory-count">Inventory Count</Link>
                    </li>
                    <li className="breadcrumb-item active">
                        Update Inventory Count
                    </li>
                </ol>
            </nav>

            <div>
            <div className="span9 border-bottom ">
                    <h3 className="txt24 m0"> Update Inventory Count
                    </h3>

                </div>
                <div className="toggle-box-filter border-bottom dropdown hide search-form p-3">
                    <b className="caret2" />
                    <form className="container-fluid py-4" action="/index.php?r=inventoryCount/index" method="get">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group d-flex flex-md-row flex-column mb-2">
                                 <h5 htmlfor="InventoryCount_id" className="col-sm-6 my-3">Count Name</h5>
                                   
                                        <h6 className="py-3 mx-0 w-xm h-xm">Mrfix EXTRA - 16 Aug, 2023 (11:19 AM)
</h6>
                                </div>
                                <div className="form-group d-flex flex-md-row flex-column mb-0">
                                    <h5 htmlfor="InventoryCount_store_id" className="col-sm-6 pb-3">Store</h5>
                                    <h6>Mrfix EXTRA</h6>
                                </div>
                                <div className="form-group d-flex flex-md-row flex-column mb-2">
                                    <h5 htmlfor="InventoryCount_employee_id" className="col-sm-6 my-3">Employee</h5>
                                    <select data-placeholder="Select Employee" name="InventoryCount[employee_id]" className="form-control">
                                        <option value>Sufyan</option>
                                        <option value="{1}">SJ</option>
                                        <option value="{2}">Saif</option>
                                        <option value="{3}">Nadeem</option>
                                        <option value="{4}">Hussnain</option>
                                        <option value="{4}">Ambreen sidiq</option>
                                    </select>
                                </div>
                                <div className="form-group d-flex flex-md-row flex-column mb-2">
                                    <h5 htmlfor="InventoryCount_product_name" className="col-sm-6 my-3">Date</h5>
                                    <input name="InventoryCount[product_name]" id="InventoryCount_product_name" type="date" className="form-control" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group d-flex flex-md-row flex-column mb-2">
                                    <h5 htmlfor="InventoryCount_status" className="col-sm-6 my-3">Category</h5>
                                    <select data-placeholder="Select Category" name="InventoryCount[category]" className="form-control">
                                        <option value>Select Category</option>
                                        <option value="{1}">Apple Pad</option>
                                        <option value="{2}">Google Accessories</option>
                                        <option value="{3}">Apple iphone</option>
                                    </select>
                                </div>
                                <div className="form-group d-flex flex-md-row flex-column mb-2">
                                    <h5 htmlfor="InventoryCount_status" className="col-sm-6 my-3">Subcategory</h5>
                                    <select data-placeholder="Select Subcategory" name="InventoryCount[subcategory]" className="form-control">
                                        <option value>
                                        </option><option value="{1}">
                                        </option></select>
                                </div>
                                <div className="form-group d-flex flex-md-row flex-column mb-2">
                                    <h5 htmlfor="InventoryCount_status" className="col-sm-6 my-3">Manufacturer</h5>
                                    <select data-placeholder="Select Manufacturer" name="InventoryCount[manufacturer]" className="form-control">
                                        <option value>
                                        </option><option value="{1}">
                                        </option></select>
                                </div>
                                <div className="form-group d-flex flex-md-row flex-column mb-2">
                                    <h5 htmlfor="InventoryCount_status" className="col-sm-6 my-3">Device</h5>
                                    <select data-placeholder="Select Device" name="InventoryCount[device]" className="form-control">
                                        <option value>
                                        </option><option value="{1}">
                                        </option></select>
                                </div>
                                <div className="form-group d-flex gap-x-25 mb-2">
                                    <h5 htmlfor="hideOutOfStock" style={{ whitespace: 'nowrap' }} className="px-2">Hide Out of Stock Items</h5>
                                    <input className="mx-2 mb-3" type="checkbox" id="hideOutOfStock" name="hideOutOfStock" />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div className='d-flex gap-3 py-3 flex-row-reverse'>
         <div className="nav-item ">
          <Link className="nav-link btn mx-1 btn-success" to="">
            <span><i className="bx bxs-plus-circle" />
            Add to Inventory Count List</span>
          </Link>
        </div>
         </div>






                <div className="v4input bg-light px-3 py-4">
                    <div className="row my-6">
                        <div className="col-md-1">
                            <h5 htmlFor="scan_item_qty">Quantity</h5>
                            <div className="input-group">
                                <input type="text" className="form-control py-3" id="scan_item_qty" autoComplete="off" defaultValue={1} style={{ width: 48, marginRight: 8 }} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h5 htmlFor="scan_item_input">
                                Search Inventory Items to Count</h5>
                            <input className="form-control search-input py-3 " id="scan_item_input" placeholder="ID, Serial, SKU or Scan Item" type="text" name="scan_item_input" autoComplete="off" />
                            <span role="status" aria-live="polite" className="ui-helper-hidden-accessible" />

                        </div>

                        <div className="input-group-append col-md-1 my-5">
                            <button className="btn btn-primary open-semibold " id="scan_add_btn" style={{ width: 90 }}>Count</button>
                        </div>
                        <input type="hidden" id="countOfRows" placeholder="It will contain the count of scanned items which will help to decide the validations of store selection" />
                    </div>
                </div>

                <div className="table-responsive container-full">
                    <div className='border  bg-white '>
                        <div>
                            <h5 className='px-2 pb-2 my-2 '>Inventory Count List</h5>

                        </div>



                    </div>
                    <table className="table table-bordered w-full" style={{ whiteSpace: "nowrap" }}>
                        <thead>
                            <tr className='bg-light'>
                                <th scope="col">
                                    <input type="checkbox" name="" id="" />
                                </th>
                                <th scope="col">ID</th>
                                <th scope="col">SKU</th>
                                <th scope="col">Category/Subcategory</th>
                                <th scope="col">Manufacturer</th>
                                <th scope="col">Device</th>
                                <th scope="col">Item</th>
                                <th scope="col">Expected</th>
                                <th scope="col">Counted</th>
                                <th scope="col">Add Notes</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr className="odd"  >
                                <td className=''></td>
                                <td>190612</td>
                                <td className=''>19061221607</td>
                                <td>Screen Assembly</td>
                                <td className=''>Apple</td>
                                <td>iPhone 11G</td>
                                <td className=''>Iphone 11G Reclaimed New Screen Replacement</td>
                                <td>7</td>
                                <td className='border border-2 text-danger'>7</td>
                                <td></td>



                            </tr>


                        </tbody>
                    </table>
                </div>
                <div className="d-flex gap-3 flex-row-reverse">
                    <a id="pauseBtn" className="btn btn-warning p-2 r0 open-semibold cp">
                        <i className="fa fa-pause-circle" />
                        &nbsp; Pause Count </a>
                    <input type="hidden" id="_csrf" name="_csrf" defaultValue="9f4c8a9f13a1bc6c28490fcce984ebfc" />
                    <a id="proceedBtn" className="btn-success p-2  open-semibold cp" href="/index.php?r=inventoryCount/reviewnew&id=&_csrf=9f4c8a9f13a1bc6c28490fcce984ebfc">
                        <i className="fa fa-check-circle" />
                        &nbsp; Proceed To Review </a>

                </div>

            </div>


        </div>
    )
}

export default InventoryCountUpdate