import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import { emptyAlertMessage, uploadImage } from "../../actions/globalActions";
import { updatePageAction, getPage } from "../../actions/seoPageActions";
import { MEDIA_UPDATE } from "../../actions/types";
import { base_url } from "../../utils";
import Alert from "../elements/Alert";
import axios from "axios";

const UpdateSeoPage = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    let media = useSelector(state => state.global.media);
    const page = useSelector(state => state.page.page);

    const [title, setTitle] = useState();
    const [slug, setSlug] = useState();
    const [content, setContent] = useState();
    const [readingString, setReadingString] = useState();
    const [shortDescription, setShortDescription] = useState();
    const [longDescription, setLongDescription] = useState();
    const [metaTitle, setMetaTitle] = useState();
    const [metaKeywords, setMetaKeywords] = useState();
    const [metaDescription, setMetaDescription] = useState();
    const [tags, setTags] = useState([]);

    const [name, setName] = useState("");
    const [image, setImage] = useState({ value: "" });
    const [button, setButton] = useState(false);
    let { id } = useParams();

    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file) => {
                        body.append("file", file);

                        axios.post(`${base_url}media`,
                            body, {
                            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
                        })
                            .then(res => {
                                resolve({
                                    default: `${base_url}/${res.data.path}`
                                });
                            })
                            .catch(err => {
                                reject(err);
                            });
                    });
                });
            }
        };
    }
    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }


    useLayoutEffect(() => {
        dispatch(emptyAlertMessage());
    }, []);

    useEffect(() => {
        if (page) {
            dispatch({
                type: MEDIA_UPDATE,
                payload: page.media
            });
            setName(page.name);
            setSlug(page.slug);
            setContent(page.content);
            setTitle(page.title);
            // setReadingString(page.readingString);
            // setShortDescription(page.shortDescription);
            // setLongDescription(page.longDescription);
            setMetaTitle(page.metaTitle);
            setMetaKeywords(page.metaKeywords);
            setMetaDescription(page.metaDescription);
            // setTags(page.tags);
        }
    }, [page])

    useEffect(() => {
        id && dispatch(getPage(id));
    }, [id]);

    useEffect(() => {
        media && setButton(true);
    }, [media])

    const updatePage = (e) => {
        e.preventDefault();
        const page = {
            title,
            slug,
            content,
            metaTitle,
            metaKeywords,
            metaDescription,
            media
        }
        dispatch(updatePageAction(id, page));
    }

    const updateImage = (e) => {
        if (e.files && e.files[0]) {
            setImage(e.files[0]);

            const data = new FormData()
            data.append('file', e.files[0], e.files[0].name);
            console.log('data', data);

            dispatch(uploadImage(data));
        }
    }

    const profileStyle = {
        width: '150px',
        height: '150px'
    }
    return (
        <>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="breadcrumbs-top py-4">
                {/* <h5 className="content-header-title float-left pr-1 mb-0">
                    Update Page
                </h5> */}
                <div className="breadcrumb-wrapper d-none d-sm-block">
                    <ol className="breadcrumb breadcrumb-style1 p-0 mb-0 pl-1">
                        <li className="breadcrumb-item">
                            <Link to="/admin">
                                <i className="bx bx-home-alt" />
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/admin/pages">Page</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <a href="javascript:void(0);">Update</a>
                        </li>
                    </ol>
                </div>
            </div>
       
        <section id="basic-horizontal-layouts">
            <div className="row match-height">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                            <h5 className="">
                                Update Page
                            </h5>
                            </div>
                            <Alert />
                        </div>
                        <div className="card-body">
                            <form className="form form-horizontal" onSubmit={updatePage}>
                                <div className="form-body">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <label>
                                                Title
                                            </label>
                                        </div>
                                        <div className="col-md-4 form-group">
                                            <input
                                                type="text"
                                                className="mb-4 form-control"
                                                required
                                                value={title}
                                                onChange={(e) => { setTitle(e.target.value) }}
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <label>
                                                Page Slug
                                            </label>
                                        </div>
                                        <div className="col-md-4 form-group">
                                            <input
                                                type="text"
                                                className="mb-4 form-control"
                                                required
                                                value={slug}
                                                onChange={(e) => { setSlug(e.target.value) }}
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <label>
                                                Meta Title
                                            </label>
                                        </div>
                                        <div className="col-md-4 form-group">
                                            <input
                                                type="text"
                                                className="mb-4 form-control"
                                                required
                                                value={metaTitle}
                                                onChange={(e) => { setMetaTitle(e.target.value) }}
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <label>
                                                Meta Keywords
                                            </label>
                                        </div>
                                        <div className="col-md-4 form-group">
                                            <input
                                                type="text"
                                                className="mb-4 form-control"
                                                required
                                                value={metaKeywords}
                                                onChange={(e) => { setMetaKeywords(e.target.value) }}
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <label>
                                                Meta Description
                                            </label>
                                        </div>
                                        <div className="col-md-4 form-group">
                                            <input
                                                type="text"
                                                className="mb-4 form-control"
                                                required
                                                value={metaDescription}
                                                onChange={(e) => { setMetaDescription(e.target.value) }}
                                            />
                                        </div>
                                        <div className="col-md-6"></div>
                                        {/* <div className="col-md-2">
                                            <label>
                                                Short Description
                                            </label>
                                        </div>
                                        <div className="col-md-4 form-group">
                                            <input
                                                type="text"
                                                className="mb-4 form-control"
                                                required
                                                value={shortDescription}
                                                onChange={(e) => { setShortDescription(e.target.value) }}
                                            />
                                        </div> */}
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label>Picture</label>
                                                </div>
                                                <div className="col-md-8 mb-1">
                                                    <img style={profileStyle} src={media ? base_url + "media/" + media.filename : "/app-assets/images/dummy.jpg"} alt={name} />
                                                </div>
                                                <div className="col-md-4">
                                                    <label></label>
                                                </div>
                                                <div className="col-md-8">
                                                    {/* <fieldset class="form-group">
                                                        <input type="file" class="mb-4 form-control-file" value={image.value}
                                                            onChange={(e) => { updateImage(e.target); setButton(false) }} />
                                                    </fieldset> */}
                                                           <div class="button-wrapper">
                                                            <label for="upload" class="btn btn-primary me-2 mb-4" tabindex="0">
                                                                <span class="d-none d-sm-block">Upload new photo</span>
                                                                <i class="bx bx-upload d-block d-sm-none"></i>
                                                                <input
                                                                type="file"
                                                                id="upload"
                                                                class="account-file-input"
                                                                value={image.value} required
                                                                hidden
                                                                accept="image/png, image/jpeg"
                                                                onChange={(e) => { updateImage(e.target); setButton(false) }}
                                                                />
                                                                </label>
                                                                    {/* <button type="button" class="btn btn-label-secondary account-image-reset mb-4">
                                                                        <i class="bx bx-reset d-block d-sm-none"></i>
                                                                        <span class="d-none d-sm-block">Reset</span>
                                                                    </button> */}

                                                                    {/* <p class="mb-0">Allowed JPG, GIF or PNG. Max size of 800K</p> */}
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                        </div>
                                        <div className="col-md-2">
                                            <label>
                                                Page Content
                                            </label>
                                        </div>
                                        <div className="col-md-10 form-group mb-2">
                                            <CKEditor
                                                editor={ClassicEditor}
                                                config={{
                                                    extraPlugins: [uploadPlugin]
                                                }}
                                                data={content}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData()
                                                    setContent(data)
                                                }}
                                            />
                                            {/* <textarea
                                                type="text"
                                                className="mb-4 form-control"
                                                required
                                                value={content}
                                                onChange={(e) => { setContent(e.target.value) }}
                                            ></textarea> */}
                                        </div>
                                        {/* <CKEditor
                                            editor={ClassicEditor}
                                            data="<p>Hello from CKEditor 5!</p>"
                                            onReady={editor => {
                                                console.log('Editor is ready to use!', editor);
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                console.log({ event, editor, data });
                                            }}
                                            onBlur={(event, editor) => {
                                                console.log('Blur.', editor);
                                            }}
                                            onFocus={(event, editor) => {
                                                console.log('Focus.', editor);
                                            }}
                                        /> */}


                                        {/* <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label>Thumbnail</label>
                                                </div>
                                                <div className="col-md-8">
                                                    <img style={profileStyle} src={media ? base_url + "media/" + media.filename : "/app-assets/images/dummy.jpg"} alt={name} />
                                                </div>
                                                <div className="col-md-4">
                                                    <label></label>
                                                </div>
                                                <div className="col-md-8">
                                                    <fieldset class="form-group">
                                                        <input type="file" class="mb-4 form-control-file" value={image.value} required
                                                            onChange={(e) => { updateImage(e.target); setButton(false) }} />
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div> */}



                                        <div className="col-sm-12 d-flex justify-content-end">
                                            <button
                                                type="button"
                                                className="btn btn-primary me-1"
                                                onClick={() => navigate(-1)}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Submit
                                            </button>
                                            {/* <button
                                                type="reset"
                                                className="btn btn-light-secondary"
                                                onClick={() => setImage("")}
                                            >
                                                Reset
                                            </button> */}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        </div>
        </>
    );
};

export default UpdateSeoPage;
