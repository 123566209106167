import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { emptyAlertMessage, uploadImage } from "../../actions/globalActions";
import { updateProblemAction, getProblem } from "../../actions/problemActions";
import Alert from "../elements/Alert";


const UpdateProblem = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    let { id } = useParams();

    const problem = useSelector(state => state.problem.problem);

    const [name, setName] = useState("");

    useLayoutEffect(() => {
        dispatch(emptyAlertMessage());
    }, []);

    useEffect(() => {
        if (problem) {
            setName(problem.name);
        }
    }, [problem])

    useEffect(() => {
        id && dispatch(getProblem(id));
    }, [id]);

    const UpdateProblem = (e) => {
        e.preventDefault();
        const problem = {
            name
        }
        dispatch(updateProblemAction(id, problem));
    }
    return (
        <>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="breadcrumbs-top py-4">
                {/* <h5 className="content-header-title float-left pr-1 mb-0">
                    Update Problem
                </h5> */}
                <div className="breadcrumb-wrapper d-none d-sm-block">
                    <ol className="breadcrumb breadcrumb-style1 p-0 mb-0 pl-1">
                        <li className="breadcrumb-item">
                            <a href="javascript:void(0);">
                                <i className="bx bx-home-alt" />
                            </a>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/admin/problems">Problems</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <a href="javascript:void(0);">Update</a>
                        </li>
                    </ol>
                </div>
            </div>
            <section id="basic-horizontal-layouts">
                <div className="row match-height">
                    <div className="col-md-6 col-12">
                        <div className="card">
                            <div className="card-header">
                            <h5 className="card-title">
                                Update Problem
                            </h5>
                                <Alert />
                            </div>
                            <div className="card-body">
                                <form className="form form-horizontal" onSubmit={UpdateProblem}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label>
                                                    Name
                                                </label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input
                                                    type="text"
                                                    id="class"
                                                    className="mb-4 form-control"
                                                    name="class"
                                                    placeholder="Tax Class"
                                                    required
                                                    value={name}
                                                    onChange={(e) => { setName(e.target.value) }}
                                                />
                                            </div>
                                            <div className="col-sm-12 d-flex justify-content-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary me-1"
                                                    onClick={() => navigate(-1)}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary "
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        </>
    );
};

export default UpdateProblem;

