import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { emptyAlertMessage, emptyMedia, uploadImage } from "../../actions/globalActions";
import { addStoreAction, listStores } from "../../actions/storeActions";
import { UPDATED } from "../../actions/types";
import { base_url } from "../../utils";
import Alert from "../elements/Alert";
const AddStore = () => {
    const imageRef = useRef();
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const media = useSelector(state => state.global.media);
    const updated = useSelector(state => state.global.updated);

    const [name, setName] = useState("");
    const [slug, setSlug] = useState("");
    const [description, setDescription] = useState("");
    const [availableString, setAvailableString] = useState("");
    const [phone, setPhone] = useState([]);
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [mapLink, setMapLink] = useState("");
    const [startTime1, setStartTime1] = useState();
    const [endTime1, setEndTime1] = useState();
    const [startTime2, setStartTime2] = useState();
    const [endTime2, setEndTime2] = useState();
    const [startTime3, setStartTime3] = useState();
    const [endTime3, setEndTime3] = useState();
    const [startTime4, setStartTime4] = useState();
    const [endTime4, setEndTime4] = useState();
    const [startTime5, setStartTime5] = useState();
    const [endTime5, setEndTime5] = useState();
    const [startTime6, setStartTime6] = useState();
    const [endTime6, setEndTime6] = useState();
    const [startTime7, setStartTime7] = useState();
    const [endTime7, setEndTime7] = useState();


    const [image, setImage] = useState("");
    const [button, setButton] = useState(false);
    const [parrent, setParrent] = useState("");

    useLayoutEffect(() => {
        dispatch(emptyMedia());
        dispatch(emptyAlertMessage());
    }, []);

    useEffect(() => {
        if (updated) {
            navigate('/admin/stores');
            dispatch({
                type: UPDATED,
                payload: false
            })
        }
    }, [updated]);

    useEffect(() => {
        media && setButton(true);
    }, [media])

    const addStore = (e) => {
        e.preventDefault();
        const periods = [];
        if (startTime1 && endTime1) {
            periods.push({ isoWeekDay: 1, shifts: [{ startTime: startTime1, endTime: endTime1 }] });
        }
        if (startTime2 && endTime2) {
            periods.push({ isoWeekDay: 2, shifts: [{ startTime: startTime2, endTime: endTime2 }] });
        }
        if (startTime3 && endTime3) {
            periods.push({ isoWeekDay: 3, shifts: [{ startTime: startTime3, endTime: endTime3 }] });
        }
        if (startTime4 && endTime4) {
            periods.push({ isoWeekDay: 4, shifts: [{ startTime: startTime4, endTime: endTime4 }] });
        }
        if (startTime5 && endTime5) {
            periods.push({ isoWeekDay: 5, shifts: [{ startTime: startTime5, endTime: endTime5 }] });
        }
        if (startTime6 && endTime6) {
            periods.push({ isoWeekDay: 6, shifts: [{ startTime: startTime6, endTime: endTime6 }] });
        }
        if (startTime7 && endTime7) {
            periods.push({ isoWeekDay: 7, shifts: [{ startTime: startTime7, endTime: endTime7 }] });
        }
        const store = {
            name,
            slug,
            description,
            availableString,
            phone: phone?.split(","),
            email,
            address,
            mapLink,
            availablePeriods: periods,
            media
        }
        dispatch(addStoreAction(store));
    }

    const updateImage = (e) => {
        if (e.files && e.files[0]) {
            setImage(e.files[0]);

            const data = new FormData()
            data.append('file', e.files[0], e.files[0].name);
            console.log('data', data);

            dispatch(uploadImage(data));
        }
    }

    const profileStyle = {
        width: '150px',
        height: '150px'
    }
    return (
        <>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="breadcrumbs-top py-4">
                {/* <h5 className="content-header-title float-left pr-1 mb-0">
                    Add New Store
                </h5> */}
                <div className="breadcrumb-wrapper d-none d-sm-block">
                    <ol className="breadcrumb p-0 mb-0 pl-1">
                        <li className="breadcrumb-item">
                            <a href="index.html">
                                <i className="bx bx-home-alt" />
                            </a>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/admin/stores">Stores</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <a href="javascript:void(0);">Add New</a>
                        </li>
                    </ol>
                </div>
            </div>
            <section id="basic-horizontal-layouts">
                <div className="row match-height">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">
                                <h5 className="content-header-title float-left pr-1 mb-0">
                                    Add New Store
                                </h5>
                                </div>
                                <Alert />
                            </div>
                            <div className="card-body">
                                <form className="form form-horizontal" onSubmit={addStore}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label>
                                                            Name
                                                        </label>
                                                    </div>
                                                    <div className="col-md-8 form-group">
                                                        <input
                                                            type="text"
                                                            className="mb-4 form-control"
                                                            required
                                                            value={name}
                                                            onChange={(e) => { setName(e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label>
                                                            Available Timings
                                                        </label>
                                                    </div>
                                                    <div className="col-md-8 form-group">
                                                        <input
                                                            type="text"
                                                            className="mb-4 form-control"
                                                            required
                                                            value={availableString}
                                                            onChange={(e) => { setAvailableString(e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label>
                                                            Email
                                                        </label>
                                                    </div>
                                                    <div className="col-md-8 form-group">
                                                        <input
                                                            type="text"
                                                            className="mb-4 form-control"
                                                            required
                                                            value={email}
                                                            onChange={(e) => { setEmail(e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label>
                                                            Phone
                                                        </label>
                                                    </div>
                                                    <div className="col-md-8 form-group">
                                                        <input
                                                            type="text"
                                                            className="mb-4 form-control"
                                                            required
                                                            value={phone}
                                                            onChange={(e) => { setPhone(e.target.value) }}
                                                        />
                                                    </div>
                                                <div className="col-md-4">
                                                    <label>
                                                        Map Link
                                                    </label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <input
                                                        type="text"
                                                        className="mb-4 form-control"
                                                        required
                                                        value={mapLink}
                                                        onChange={(e) => { setMapLink(e.target.value) }}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <label>
                                                        Slug
                                                    </label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <input
                                                        type="text"
                                                        className="mb-4 form-control"
                                                        required
                                                        value={slug}
                                                        onChange={(e) => { setSlug(e.target.value) }}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <label>
                                                        Description
                                                    </label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <textarea
                                                        className="mb-4 form-control"
                                                        required
                                                        value={description}
                                                        onChange={(e) => { setDescription(e.target.value) }}
                                                    ></textarea>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>
                                                        Address
                                                    </label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <textarea
                                                        className="mb-4 form-control"
                                                        required
                                                        value={address}
                                                        onChange={(e) => { setAddress(e.target.value) }}
                                                    ></textarea>
                                                </div>
                                                    <div className="col-md-4">
                                                        <label>Picture</label>
                                                    </div>
                                                    <div className="col-md-8 mb-2">
                                                        <img style={profileStyle} src={media ? base_url + "media/" + media.filename : "/app-assets/images/dummy.jpg"} alt={name} />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label></label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        {/* <fieldset class="form-group">
                                                            <input type="file" class="mb-4 form-control-file" value={image.value} required
                                                                onChange={(e) => { updateImage(e.target); setButton(false) }} />
                                                        </fieldset> */}
                                                                     <div class="button-wrapper">
                                                                    <label for="upload" class="btn btn-primary me-2 mb-4" tabindex="0">
                                                                <span class="d-none d-sm-block">Upload new photo</span>
                                                                <i class="bx bx-upload d-block d-sm-none"></i>
                                                                <input
                                                                type="file"
                                                                id="upload"
                                                                class="account-file-input"
                                                                value={image.value} required
                                                                hidden
                                                                accept="image/png, image/jpeg"
                                                                onChange={(e) => { updateImage(e.target); setButton(false) }}
                                                                />
                                                                </label>
                                                                    {/* <button type="button" class="btn btn-label-secondary account-image-reset mb-4">
                                                                        <i class="bx bx-reset d-block d-sm-none"></i>
                                                                        <span class="d-none d-sm-block">Reset</span>
                                                                    </button> */}

                                                                    {/* <p class="mb-0">Allowed JPG, GIF or PNG. Max size of 800K</p> */}
                                                                    </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="offset-md-1 col-md-5">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h4>
                                                            Available Periods
                                                        </h4>
                                                    </div>
                                                    <div className="col-md-1">
                                                        <label className="mt-1">
                                                            MON
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 form-group">
                                                        <input
                                                            type="time"
                                                            className="mb-4 form-control"
                                                            value={startTime1}
                                                            onChange={(e) => setStartTime1(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-md-1">
                                                        <label className="mt-1">
                                                            To
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 form-group">
                                                        <input
                                                            type="time"
                                                            className="mb-4 form-control"
                                                            value={endTime1}
                                                            onChange={(e) => { setEndTime1(e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="col-md-2">
                                                    </div>
                                                    <div className="col-md-1">
                                                        <label className="mt-1">
                                                            TUE
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 form-group">
                                                        <input
                                                            type="time"
                                                            className="mb-4 form-control"
                                                            value={startTime2}
                                                            onChange={(e) => { setStartTime2(e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="col-md-1">
                                                        <label className="mt-1">
                                                            To
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 form-group">
                                                        <input
                                                            type="time"
                                                            className="mb-4 form-control"
                                                            value={endTime2}
                                                            onChange={(e) => { setEndTime2(e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="col-md-2">
                                                    </div>
                                                    <div className="col-md-1">
                                                        <label className="mt-1">
                                                            WED
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 form-group">
                                                        <input
                                                            type="time"
                                                            className="mb-4 form-control"
                                                            value={startTime3}
                                                            onChange={(e) => { setStartTime3(e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="col-md-1">
                                                        <label className="mt-1">
                                                            To
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 form-group">
                                                        <input
                                                            type="time"
                                                            className="mb-4 form-control"
                                                            value={endTime3}
                                                            onChange={(e) => { setEndTime3(e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="col-md-2">
                                                    </div>
                                                    <div className="col-md-1">
                                                        <label className="mt-1">
                                                            THU
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 form-group">
                                                        <input
                                                            type="time"
                                                            className="mb-4 form-control"
                                                            value={startTime4}
                                                            onChange={(e) => { setStartTime4(e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="col-md-1">
                                                        <label className="mt-1">
                                                            To
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 form-group">
                                                        <input
                                                            type="time"
                                                            className="mb-4 form-control"
                                                            value={endTime4}
                                                            onChange={(e) => { setEndTime4(e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="col-md-2">
                                                    </div>
                                                    <div className="col-md-1">
                                                        <label className="mt-1">
                                                            FRI
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 form-group">
                                                        <input
                                                            type="time"
                                                            className="mb-4 form-control"
                                                            value={startTime5}
                                                            onChange={(e) => { setStartTime5(e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="col-md-1">
                                                        <label className="mt-1">
                                                            To
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 form-group">
                                                        <input
                                                            type="time"
                                                            className="mb-4 form-control"
                                                            value={endTime5}
                                                            onChange={(e) => { setEndTime5(e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="col-md-2">
                                                    </div>
                                                    <div className="col-md-1">
                                                        <label className="mt-1">
                                                            SAT
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 form-group">
                                                        <input
                                                            type="time"
                                                            className="mb-4 form-control"
                                                            value={startTime6}
                                                            onChange={(e) => { setStartTime6(e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="col-md-1">
                                                        <label className="mt-1">
                                                            To
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 form-group">
                                                        <input
                                                            type="time"
                                                            className="mb-4 form-control"
                                                            value={endTime6}
                                                            onChange={(e) => { setEndTime6(e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="col-md-2">
                                                    </div>
                                                    <div className="col-md-1">
                                                        <label className="mt-1">
                                                            SUN
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 form-group">
                                                        <input
                                                            type="time"
                                                            className="mb-4 form-control"
                                                            value={startTime7}
                                                            onChange={(e) => { setStartTime7(e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="col-md-1">
                                                        <label className="mt-1">
                                                            To
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 form-group">
                                                        <input
                                                            type="time"
                                                            className="mb-4 form-control"
                                                            value={endTime7}
                                                            onChange={(e) => { setEndTime7(e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 d-flex justify-content-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary me-1"
                                                    onClick={() => navigate(-1)}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary "
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        </>
    );
};

export default AddStore;