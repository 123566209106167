import { GET_MANUFACTURER, LIST_MANUFACTURERS } from "../actions/types";

const initialState = {
    manufacturers: [],
    error: null,
    manufacturer: null
};
export default (state = initialState, { type, payload }) => {

    switch (type) {
        case LIST_MANUFACTURERS: 
        return {
            ...state,
            manufacturers: payload
        }
        case GET_MANUFACTURER:
            return {
                ...state,
                manufacturer: payload
            }
        default:
            return state;
    }
}
