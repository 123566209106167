import { GET_BLOG, LIST_BLOGS } from "../actions/types";

const initialState = {
    blogs: [],
    error: null,
    blog: null
};
export default (state = initialState, { type, payload }) => {

    switch (type) {
        case LIST_BLOGS: 
        return {
            ...state,
            blogs: payload
        }
        case GET_BLOG:
            return {
                ...state,
                blog: payload
            }
        default:
            return state;
    }
}
