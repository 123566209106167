import axios from "axios";
import { alertMessage, base_url, check_response_status } from "../utils";
import { GET_TICKET, LIST_TICKETS, UPDATE_TICKET ,ADD_TICKET} from "./types";

// list Ticket Categories
export const listTickets = () => async dispatch => {

    axios.get(`${base_url}tickets`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            console.log("response", response);
            if (response.status == 200) {
                dispatch({
                    type: LIST_TICKETS,
                    payload: response.data
                });
            }
            else if (response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            dispatch(check_response_status(err.response));
        });
}

//get ticket category
export const getTicket = (id) => async dispatch => {
    axios.get(`${base_url}tickets/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: GET_TICKET,
                    payload: response.data
                });
            }
            else if (response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            dispatch(check_response_status(err.response));
        });
}

// add ticket category
export const addTicketAction = (ticket) => async dispatch => {

    axios.post(`${base_url}tickets`,
        ticket,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 201) {
                dispatch(alertMessage(response.data));
                dispatch(listTickets());
                dispatch({
                    type: ADD_TICKET,
                    payload: true
                });
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            dispatch(check_response_status(err.response));
        });
}

//update Ticket Category
export const updateTicketAction = (id, ticket) => async dispatch => {
    axios.put(`${base_url}tickets/${id}`,
        ticket,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch(alertMessage({ flag: 'success', message: "Ticket Upadated Successfully." }));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            dispatch(check_response_status(err.response));
        });
}

export const deleteTicketAction = (id) => async dispatch => {
    axios.delete(`${base_url}tickets/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch(alertMessage({ flag: 'success', message: response.data.message }));
                dispatch(listTickets());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            dispatch(check_response_status(err.response));
        });
}