import { Link } from "react-router-dom"
import PiChart from "./PiChart";
import { useLayoutEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Select from 'react-select';
import { useDispatch, useSelector } from "react-redux";
import { listTickets } from "../../../actions/ticketActions";
import { dateWithoutZone } from "../../../utils";

const ListTickets = () => {
    const [assignedTo, setAssignedTo] = useState(null);
    const [assignedToOptions, setAssignedToOptions] = useState([
        { value: '1', label: 'Won' },
        { value: '2', label: 'Lost' },
        { value: '3', label: 'Open Leads' },
        { value: '4', label: 'In Progress' },
        { value: '5', label: 'Expired' },
        { value: '6', label: 'Calcelled' },
    ]);

    const [ticketStatus, setTicketStatus] = useState(null);
    const [ticketStatusOptions, setTicketStatusOptions] = useState([
        { value: '1', label: 'Won' },
        { value: '2', label: 'Lost' },
        { value: '3', label: 'Open Leads' },
        { value: '4', label: 'In Progress' },
        { value: '5', label: 'Expired' },
        { value: '6', label: 'Calcelled' },
    ]);
    const [selectCriteria, setSelectCriteria] = useState(null);
    const [selectCriteriaOptions, setSelectCriteriaOptions] = useState([
        { value: '1', label: 'Won' },
        { value: '2', label: 'Lost' },
        { value: '3', label: 'Open Leads' },
        { value: '4', label: 'In Progress' },
        { value: '5', label: 'Expired' },
        { value: '6', label: 'Calcelled' },
    ]);
    const [repairs, setRepairs] = useState([
        { name: 'Total created', y: 131 },
        { name: 'Open', y: 5 },
        { name: 'Closed', y: 117 },
        { name: 'Cancelld', y: 5 },
        { name: 'On hold', y: 5 },
        { name: 'Warranty Repairs', y: 2 },
        { name: 'Un-assignd', y: 2 },
    ])
    const [unlock, setUnlock] = useState([
        { name: 'Total created', y: 131 },
        { name: 'Open', y: 5 },
        { name: 'Closed', y: 117 },
        { name: 'Cancelld', y: 5 },
        { name: 'On hold', y: 5 },
    ])
    const [critical, setCritical] = useState([
        { name: 'Total created', y: 131 },
        { name: 'Open', y: 5 },
        { name: 'Closed', y: 117 },
        { name: 'On hold', y: 5 },
        { name: 'Warranty Repairs', y: 2 },
        { name: 'Un-assignd', y: 2 },
    ])

    const dispatch = useDispatch();

    const tickets = useSelector((state) => state.ticket.tickets);

    useLayoutEffect(() => {
        dispatch(listTickets());
    }, []);

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <nav aria-label="breadcrumb my-2 py-2" >
                    <ol className="breadcrumb breadcrumb-style1">
                        <li className="breadcrumb-item">
                            <Link to="/admin" className="text-primary">Admin</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/admin/manage-tickets" className="active">Manage Tickets</Link>
                        </li>
                    </ol>
                </nav>
                <div className="row my-2 py-2 bg-white">
                    <div class="col-lg-12 d-flex justify-content-between align-items-center">
                        <h4 className="text-primary my-auto">Manage Tickets</h4>
                        {/* <div>
                        <a href="#" class="btn btn-primary me-1">Listing</a>
                        <a href="#" class="btn btn-light ">Calendar</a>
                    </div> */}
                        <div>
                            <a href="#" className="btn btn-light me-1">
                                <span><i className='bx bx-spreadsheet'></i>&nbsp;Export</span>
                            </a>
                            <Link to="/admin/manage-tickets/add" className="btn btn-success">
                                <span> <i className='bx bxs-plus-circle'></i>&nbsp;Create Ticket</span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row bg-white my-2 py-2">
                    <div className="col-sm-8">
                        <form>
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="mb-3">
                                        <Form.Label >Ticket ID</Form.Label>
                                        <Form.Control type="text" placeholder="Ticket Id" />
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="mb-3">
                                        <Form.Label >Customer Name</Form.Label>
                                        <Form.Control type="text" placeholder="Customer Name" />
                                    </div>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <Form.Label >Assigned To</Form.Label>
                                    <Select
                                        required
                                        value={assignedTo}
                                        onChange={(e) => setAssignedTo(e)}
                                        options={assignedToOptions}
                                    />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="select2Basic" className="form-label">Ticket Status</label>
                                    <Select
                                        required
                                        value={ticketStatus}
                                        onChange={(e) => setTicketStatus(e)}
                                        options={ticketStatusOptions}
                                    />
                                </div>
                                <div class="col-sm-3">
                                    <div>
                                        <Form.Label >Created Date</Form.Label>
                                        <Form.Control type="date" placeholder="Created Date" />
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div>
                                        <Form.Label >Due Date</Form.Label>
                                        <Form.Control type="date" placeholder="Due Date" />
                                    </div>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <Form.Label >Select Criteria</Form.Label>
                                    <Select
                                        required
                                        value={selectCriteria}
                                        onChange={(e) => setSelectCriteria(e)}
                                        options={selectCriteriaOptions}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-sm-4 d-flex justify-content-center align-items-center">
                        <div className="">
                            <a href="#" className="btn btn-outline-primary  px-5">
                                Search
                            </a>
                            <a href="#" className="btn btn-outline-primary mx-2">
                                <i class='bx bx-refresh '></i>
                            </a>
                            <div className="my-2">
                                <input type="checkbox" name="" id="" className="form-controle" /><a href="#"> Hide Closed Inquiries</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row my-2 py-2 bg-white">
                    <div className="col-sm-4">
                        <div className="card shadow-none border">
                            <div className="card-body">
                                <PiChart chartData={repairs} title="Repairs" />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="card shadow-none border">
                            <div className="card-body">
                                <PiChart chartData={unlock} title="Unlock" />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="card shadow-none border">
                            <div className="card-body">
                                <PiChart chartData={critical} title="Critial" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row my-2" >
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-bordered d-p">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Devices</th>
                                            <th>Task</th>
                                            <th>Customer</th>
                                            <th>Task Type</th>
                                            <th>Assigned To</th>
                                            <th>Due On</th>
                                            <th>Created Time</th>
                                            <th>Total</th>
                                            <th>Status</th>
                                            <th>More</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tickets && tickets?.map((ticket, id) => {
                                            return (
                                                <tr key={id}>
                                                    <td>{ticket?.ticket}</td>
                                                    <td>
                                                        <strong>Device: </strong>
                                                        {ticket?.repairs?.map(val => {
                                                            return val.device?.name && (
                                                                <p>
                                                                    <small className="d-block"> {val.repairCategory?.name}</small>
                                                                    <small className="d-block"> {val.manufacturers?.name}</small>
                                                                    <small className="d-block"> {val.device?.name}</small>
                                                                </p>
                                                            )
                                                        })}
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <span className="d-block">
                                                            <a href="javascript:void(0);">{ticket?.customer?.firstName} {ticket?.customer?.lastName}</a>
                                                        </span>
                                                        <small className="d-block">
                                                            <strong> Mobile:</strong> {ticket?.customer?.mobile}
                                                        </small>
                                                        <small className="b-block">
                                                            <strong>Email:</strong> {ticket?.customer?.email}
                                                        </small>
                                                        <small className="d-block">
                                                            <strong> Address:</strong> {ticket?.customer?.address}
                                                        </small>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        {ticket?.repairs?.map(val => {
                                                            return (
                                                                <>
                                                                    <p>{val.assignedTo?.label}</p>
                                                                </>
                                                            )
                                                        })}
                                                    </td>
                                                    <td></td>
                                                    <td><small>{dateWithoutZone(new Date(ticket?.createdAt))}</small></td>
                                                    <td>
                                                        {ticket?.repairs?.map(val => {
                                                            return (
                                                                <>
                                                                    <p>{val.price}</p>
                                                                </>
                                                            )
                                                        })}
                                                    </td>
                                                    <td>
                                                        {ticket?.repairs?.map(val => {
                                                            return (
                                                                <>
                                                                    <p>{val?.repairStatus?.label}</p>
                                                                </>
                                                            )
                                                        })}
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        )
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="my-2 text-end">
                                {/* <Pagination currentItems={inquiries} updateRecord={updateRecord} /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ListTickets