

import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { emptyAlertMessage, uploadImage } from "../../actions/globalActions";
import { updateRepairCategoryAction, getRepairCategory } from "../../actions/repairCategoriesActions";
import { MEDIA_UPDATE } from "../../actions/types";
import { base_url } from "../../utils";
import Alert from "../elements/Alert";


const UpdateRepairCategory = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    let media = useSelector(state => state.global.media);
    const repairCategory = useSelector(state => state.repairCategory.repairCategory);

    const [name, setName] = useState("");
    const [slug, setSlug] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState({ value: "" });
    const [button, setButton] = useState(false);
    let { id } = useParams();

    useLayoutEffect(() => {
        dispatch(emptyAlertMessage());
    }, []);

    useEffect(() => {
        if (repairCategory) {
            dispatch({
                type: MEDIA_UPDATE,
                payload: repairCategory.media
            });
            setName(repairCategory.name);
            setSlug(repairCategory.slug);
            setDescription(repairCategory.description);
        }
    }, [repairCategory])

    useEffect(() => {
        id && dispatch(getRepairCategory(id));
    }, [id]);

    useEffect(() => {
        media && setButton(true);
    }, [media])

    const updateRepairCategory = (e) => {
        e.preventDefault();
        const repairCategory = {
            name,
            slug,
            description,
            media
        }
        dispatch(updateRepairCategoryAction(id, repairCategory));
    }

    const updateImage = (e) => {
        if (e.files && e.files[0]) {
            setImage(e.files[0]);

            const data = new FormData()
            data.append('file', e.files[0], e.files[0].name);
            console.log('data', data);

            dispatch(uploadImage(data));
        }
    }

    const profileStyle = {
        width: '150px',
        height: '150px'
    }
    return (
        <>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="breadcrumbs-top py-4">
                {/* <h5 className="content-header-title float-left pr-1 mb-0">
                    Update Repair Category
                </h5> */}
                <div className="breadcrumb-wrapper d-none d-sm-block">
                    <ol className="breadcrumb breadcrumb-style1 p-0 mb-0 pl-1">
                        <li className="breadcrumb-item">
                            <Link to="/admin">
                                <i className="bx bx-home-alt" />
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/admin/repair-Categories">Repair Category</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <a href="javascript:void(0);">Update</a>
                        </li>
                    </ol>
                </div>
            </div>
            <section id="basic-horizontal-layouts">
                            <div className="row match-height">
                                <div className="col-md-6 col-12">
                                    <div className="card">
                                        <div className="card-header">
                                        <h5 className="card-title">
                                            Update Repair Category
                                        </h5>
                                            <Alert />
                                        </div>
                                        <div className="card-body">
                                            <form className="form form-horizontal" onSubmit={updateRepairCategory}>
                                                <div className="form-body">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <label>
                                                                Name
                                                            </label>
                                                        </div>
                                                        <div className="col-md-8 form-group">
                                                            <input
                                                                type="text"
                                                                id="name"
                                                                className="mb-4 form-control"
                                                                name="name"
                                                                placeholder="Name"
                                                                required
                                                                value={name}
                                                                onChange={(e) => { setName(e.target.value) }}
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label>
                                                                Slug
                                                            </label>
                                                        </div>
                                                        <div className="col-md-8 form-group">
                                                            <input
                                                                type="text"
                                                                className="mb-4 form-control"
                                                                placeholder="Slug"
                                                                required
                                                                value={slug}
                                                                onChange={(e) => { setSlug(e.target.value) }}
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label>
                                                                Description
                                                            </label>
                                                        </div>
                                                        <div className="col-md-8 form-group">
                                                            <textarea
                                                                type="text"
                                                                className="mb-4 form-control"
                                                                placeholder="Description"
                                                                rows={4}
                                                                required
                                                                value={description}
                                                                onChange={(e) => { setDescription(e.target.value) }}
                                                            ></textarea>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label>Picture</label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <img style={profileStyle} src={media ? base_url + "media/" + media.filename : "/app-assets/images/dummy.jpg"} />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label></label>
                                                        </div>
                                                        <div className="col-md-8">
                                                            {/* <fieldset class="form-group">
                                                                <input type="file" class="mb-4 form-control-file" value={image.value}
                                                                    onChange={(e) => { updateImage(e.target); setButton(false) }} />
                                                            </fieldset> */}
                                                                            <div class="button-wrapper">
                                                <label for="upload" class="btn btn-primary me-2 mb-4" tabindex="0">
                                                    <span class="d-none d-sm-block">Upload new photo</span>
                                                    <i class="bx bx-upload d-block d-sm-none"></i>
                                                    <input
                                                    type="file"
                                                    id="upload"
                                                    class="account-file-input"
                                                    value={image.value}
                                                    hidden
                                                    accept="image/png, image/jpeg"
                                                    onChange={(e) => { updateImage(e.target); setButton(false) }}
                                                    />
                                                </label>
                                                {/* <button type="button" class="btn btn-label-secondary account-image-reset mb-4">
                                                    <i class="bx bx-reset d-block d-sm-none"></i>
                                                    <span class="d-none d-sm-block">Reset</span>
                                                </button> */}

                                                {/* <p class="mb-0">Allowed JPG, GIF or PNG. Max size of 800K</p> */}
                                                </div>
                                                        </div>
                                                        <div className="col-sm-12 d-flex justify-content-end">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary me-1"
                                                                onClick={() => navigate(-1)}
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
        </div>
            <div className="app-content content">
                <div className="content-overlay" />
                <div className="content-wrapper">
                    <div className="content-header row">
                        <div className="content-header-left col-12 mb-2 mt-1">
             
                        </div>
                    </div>
                    <div className="content-body">
    
                    </div>
                </div>
            </div>
        </>
    );
};

export default UpdateRepairCategory;
