import { GET_LOYALTY_TYPES, GET_PRODUCT, GET_WARRANTY_TYPES, LIST_PRODUCTS, PRODUCTS_COUNT, GET_VALUATION_METHOD } from "../actions/types";

const initialState = {
    products: [],
    error: null,
    product: null,
    loyaltyTypes: [],
    warrantyTypes: [],
    count: null,
    valuationMethods: []
};
export default (state = initialState, { type, payload }) => {

    switch (type) {
        case GET_VALUATION_METHOD:
            return {
                ...state,
                valuationMethods: payload
            }
        case PRODUCTS_COUNT:
            return {
                ...state,
                count: payload
            }
        case LIST_PRODUCTS:
            return {
                ...state,
                products: payload
            }
        case GET_PRODUCT:
            return {
                ...state,
                product: payload
            }
        case GET_LOYALTY_TYPES:
            return {
                ...state,
                loyaltyTypes: payload
            }
        case GET_WARRANTY_TYPES:
            return {
                ...state,
                warrantyTypes: payload
            }
        default:
            return state;
    }
}
