import axios from "axios";
import { alertMessage, base_url, check_response_status } from "../utils";
import { GET_LOYALTY_TYPES, GET_REPAIR_SERVICE, GET_WARRANTY_TYPES, LIST_REPAIR_SERVICES, REPAIR_SERVICES_COUNT } from "./types";

const api = "repairServices";

// list repair service
export const listRepairServices = (from = 0, limit = 20) => async dispatch => {

    axios.get(`${base_url + api}?from=${from}&limit=${limit}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                // console.log('repairServices', response.data);
                dispatch({
                    type: LIST_REPAIR_SERVICES,
                    payload: response.data
                });
            }
            else if (response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}


// Repair Services Count
export const repairServicesCount = () => async dispatch => {

    axios.get(`${base_url}repairServices/count`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: REPAIR_SERVICES_COUNT,
                    payload: response?.data?.totalCount
                });
            }
            else if (response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            dispatch(check_response_status(err.response));
        });
}

//get repair service
export const getRepairService = (id) => async dispatch => {
    axios.get(`${base_url + api}/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                // console.log('repairService', response.data);
                dispatch({
                    type: GET_REPAIR_SERVICE,
                    payload: response.data
                });
            }
            else if (response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

// add repair service
export const addRepairServiceAction = (repairService) => async dispatch => {

    axios.post(`${base_url + api}`,
        repairService,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log('signup', response);
            if (response.status == 201) {
                dispatch(alertMessage(response.data));
                dispatch(listRepairServices());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//update repair service
export const updateRepairServiceAction = (id, repairService) => async dispatch => {
    axios.put(`${base_url + api}/${id}`,
        repairService,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log('signup', response);
            if (response.status == 200) {
                dispatch(alertMessage({ flag: 'success', message: "Repair Service Upadated Successfully." }));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

export const deleteRepairServiceAction = (id) => async dispatch => {
    // console.log('del man', id)
    axios.delete(`${base_url + api}/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log('delete', response);
            if (response.status == 200) {
                dispatch(alertMessage({ flag: 'success', message: response.data.message }));
                dispatch(listRepairServices());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

export const getLoyaltyTypes = () => async dispatch => {

    axios.get(`${base_url + api}/getLoyaltyTypes`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                // console.log('repairServices', response.data);
                dispatch({
                    type: GET_LOYALTY_TYPES,
                    payload: response.data
                });
            }
            else if (response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

export const getWarrantyTypes = () => async dispatch => {

    axios.get(`${base_url + api}/getWarrantyTypes`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                // console.log('repairServices', response.data);
                dispatch({
                    type: GET_WARRANTY_TYPES,
                    payload: response.data
                });
            }
            else if (response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

export const listRepairServicesById = (id) => async dispatch => {

    axios.get(`${base_url}repairServices/active/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200 || response.status == 201) {
                dispatch({
                    type: LIST_REPAIR_SERVICES,
                    payload: response.data
                });
            }
            else if (response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}