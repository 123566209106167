import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

const data = [
  { name: 'Won', value: 131 },
  { name: 'Lost', value: 5 },
  { name: 'Open Leads', value: 117 },
  { name: 'In Progress', value: 5 },
  { name: 'Expired', value: 5 },
  { name: 'Calcelled', value: 2 },
];
const COLORS = ['#008800', '#ff0000', '#ee8c1e', '#eecf00' ,"#dddddd" ,"#2c425e"];

export default class Example extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/pie-chart-with-padding-angle-7ux0o';

  render() {
    return (
      <PieChart height={200} width={200} onMouseEnter={this.onPieEnter}>
        <Pie
          data={data}
        //   cx={120}
        //   cy={200}
          innerRadius={60}
          outerRadius={80}
        //   fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
   
      </PieChart>
    );
  }
}
