import user1 from "./images/user1.png"
import inquryImg from "./images/icon7.png"
import question3 from "./images/question3.png"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useLayoutEffect, useState } from "react"
import { addOrderAction, listInvoiceStatusAction, listOrderStatusAction } from "../../../actions/orderActions"
import { emptyAlertMessage } from "../../../actions/globalActions"
import { listStores } from "../../../actions/storeActions"
import { listRepairCategories } from "../../../actions/repairCategoriesActions"
import Select from 'react-select';
import { listManufacturers } from "../../../actions/manufacturerActions"
import { listDevices } from "../../../actions/deviceActions"
import { listRepairServices, listRepairServicesById } from "../../../actions/repairServiceActions"
import { listEmployees } from "../../../actions/employeeActions"
import { listProductConditions } from "../../../actions/productConditionActions";
import { listProductNetworks } from "../../../actions/productNetworkActions"
import { listDeviceColors } from "../../../actions/deviceColorActions"
import { listProductSizes } from "../../../actions/productSizeActions"
import { addTradeinAction } from "../../../actions/tradeInActions"

const AddTradeIn = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const leadStatuses = useSelector(state => state.order.orderStatus);
    const invoiceStatuses = useSelector(state => state.order.invoiceStatus);
    const stores = useSelector(state => state.store.stores);
    const repairCategories = useSelector(state => state.repairCategory.repairCategories);
    const manufacturers = useSelector(state => state.manufacturer.manufacturers);
    const devices = useSelector(state => state.device.devices);
    const repairServices = useSelector(state => state.repairService.repairServices);
    const employees = useSelector(state => state.employee.employees);
    const productConditions = useSelector(state => state.productCondition.productConditions);
    const productNetworks = useSelector(state => state.productNetwork.productNetworks);
    const deviceColors = useSelector(state => state.deviceColor.deviceColors);
    const productSizes = useSelector(state => state.productSize.productSizes);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [postCode, setPostCode] = useState("");
    const [hearUs, setHearUs] = useState("");
    const [message, setMessage] = useState("");
    const [leadStatus, setLeadStatus] = useState("");
    const [timeTo, setTimeTo] = useState("");
    const [timeFrom, setTimeFrom] = useState("");
    const [orderType, setOrderType] = useState("walk_in");
    const [storeTiming, setStoreTiming] = useState("");
    const [returnDate, setReturnDate] = useState("");
    const [invoiceStatus, setInvoiceStatus] = useState("");

    const [name, setName] = useState("");
    const [imei, setIMEI] = useState("");
    const [serial, setSerial] = useState("");
    const [warrantyNumber, setWarrantyNumber] = useState("");
    const [warrantyType, setWarrantyType] = useState("");
    const [description, setDescription] = useState("");
    const [sku, setSku] = useState("");
    const [adminNotes, setAdminNotes] = useState("");
    const [retailPrice, setRetailPrice] = useState("");
    const [costPrice, setCostPrice] = useState("");
    const [media, setMedia] = useState("");
    const [transactionNotes, setTransactionNotes] = useState("");
    const [purchaseDate, setPurchaseDate] = useState("")


    const [preCondition, setPreCondition] = useState("");

    const [productSize, setProductSize] = useState("");
    const [purchaseCondition, setPurchaseCondition] = useState("");
    const [network, setNetwork] = useState("");
    const [size, setSize] = useState("");
    const [color, setColor] = useState("");
    const [repairCategory, setRepairCategory] = useState("");
    const [manufacturer, setManufacturer] = useState("");
    const [device, setDevice] = useState("");
    const [repairService, setRepairService] = useState("");
    const [store, setStore] = useState("");
    const [employee, setEmployee] = useState("");

    const [productSizeOptons, setProductSizeOptions] = useState([]);
    const [purchaseConditionOptions, setPurchaseConditionOptions] = useState([]);
    const [networkOptions, setNetworkOptions] = useState([]);
    const [sizeOptions, setSizeOptions] = useState([]);
    const [colorOptions, setColorOptions] = useState([]);
    const [repairCategoryOptions, setRepairCategoryOptions] = useState([]);
    const [manufacturerOptions, setManufacturerOptions] = useState([]);
    const [deviceOptions, setDeviceOptions] = useState([]);
    const [repairServiceOptions, setRepairServiceOptions] = useState([]);
    const [storeOptions, setStoreOptions] = useState([]);
    const [employeeOptions, setEmployeeOptions] = useState([]);

    useLayoutEffect(() => {
        dispatch(listRepairCategories());
        dispatch(listManufacturers());
        dispatch(listOrderStatusAction());
        dispatch(listInvoiceStatusAction());
        dispatch(listStores());
        dispatch(emptyAlertMessage());
        dispatch(listEmployees());
        dispatch(listProductConditions());
        dispatch(listProductNetworks());
        dispatch(listDeviceColors());
        dispatch(listProductSizes());
    }, []);

    useEffect(() => {
        const opts = [];
        productSizes && productSizes.map(productSize => {
            if (!productSize.isDeleted) {
                opts.push({ obj: productSize, value: productSize._id, label: productSize.name });
            }
        });
        setSizeOptions(opts);
    }, [productSizes]);

    useEffect(() => {
        const opts = [];
        deviceColors && deviceColors.map(deviceColor => {
            if (!deviceColor.isDeleted) {
                opts.push({ obj: deviceColor, value: deviceColor._id, label: deviceColor.name });
            }
        });
        setColorOptions(opts);
    }, [deviceColors]);

    useEffect(() => {
        const opts = [];
        productNetworks && productNetworks.map(productNetwork => {
            if (!productNetwork.isDeleted) {
                opts.push({ obj: productNetwork, value: productNetwork._id, label: productNetwork.name });
            }
        });
        setNetworkOptions(opts);
    }, [productNetworks]);

    useEffect(() => {
        const opts = [];
        productConditions && productConditions.map(productCondition => {
            if (!productCondition.isDeleted) {
                opts.push({ obj: productCondition, value: productCondition._id, label: productCondition.name });
            }
        });
        setPurchaseConditionOptions(opts);
    }, [productConditions]);

    useEffect(() => {
        const opts = [];
        employees && employees.map(employee => {
            if (!employee.isDeleted) {
                opts.push({ obj: employee, value: employee._id, label: employee.firstName + ' ' + employee.lastName });
            }
        });
        setEmployeeOptions(opts);
    }, [employees]);

    useEffect(() => {
        const opts = [];
        stores && stores.map(store => {
            if (!store.isDeleted) {
                opts.push({ obj: store, value: store._id, label: store.name });
            }
        });
        setStoreOptions(opts);
    }, [stores]);

    useEffect(() => {
        const opts = [];
        repairCategories && repairCategories.map(repairCategory => {
            if (!repairCategory.isDeleted) {
                opts.push({ obj: repairCategory, value: repairCategory._id, label: repairCategory.name });
            }
        });
        setRepairCategoryOptions(opts);
    }, [repairCategories]);

    useEffect(() => {
        if (repairCategory) {
            setManufacturer("");
            setDevice("");
            setRepairService("");
            const opts = [];
            manufacturers && manufacturers.map(manufacturer => {
                if (!manufacturer.isDeleted && manufacturer.repairCategories._id == repairCategory?.value) {
                    opts.push({ obj: manufacturer, value: manufacturer._id, label: manufacturer.name });
                }
            });
            setManufacturerOptions(opts);
        }
    }, [repairCategory]);

    useEffect(() => {
        const opts = [];
        devices && devices.map(device => {
            if (!device.isDeleted) {
                opts.push({ obj: device, value: device._id, label: device.name });
            }
        });
        setDeviceOptions(opts);
    }, [devices]);

    useEffect(() => {
        const opts = [];
        repairServices && repairServices.map(service => {
            if (!service.isDeleted) {
                opts.push({ obj: service, value: service._id, label: service.problem.name });
            }
        });
        setRepairServiceOptions(opts);
    }, [repairServices]);

    useEffect(() => {
        if (manufacturer) {
            setDevice("");
            setRepairService("");
            dispatch(listDevices(manufacturer?.value ))
        }
    }, [manufacturer])

    useEffect(() => {
        if (device) {
            setRepairService("");
            dispatch(listRepairServicesById(device?.value))
        }
    }, [device])

    const addLead = (e) => {
        e.preventDefault();

        const tradein = {
            repairCategories: repairCategory.obj,
            manufactures: manufacturer.obj,
            devices: device.obj,
            store: store.obj,
            name,
            imei,
            serial,
            warrantyNumber,
            warrantyType,
            description,
            sku,
            adminNotes,
            retailPrice,
            costPrice,
            transactionNotes,
            purchaseDate,
            buyer: employee.obj,
            color: color.obj,
            size: size.obj,
            purchaseCondition: purchaseCondition.obj,
            productNetwork: network.obj,
            seller: {
                firstName,
                lastName,
                email,
                mobile,
                address,
                city,
                postCode,
                hearUs,
                message,
                gdprCompliance: true
            },
        }
        console.log(tradein)
        dispatch(addTradeinAction(tradein))
        // navigate('/admin/tradeins')

    }

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <nav aria-label="breadcrumb" className="">
                    <ol className="breadcrumb p-1">
                        <li className="breadcrumb-item">
                            <Link to="/admin">Admin</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/admin/tradeins">Trade In</Link>
                        </li>
                    </ol>
                </nav>
                <form onSubmit={addLead}>
                    <div className="row">
                        <div className="col-md-6 col-lg-6  mb-4 order-lg-0">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-sm-flex align-items-center justify-content-between">
                                        <div>
                                            <div className="d-sm-flex">
                                                <img src={user1} alt="" />
                                                <h4 className="fw-bold mx-2 mt-4">
                                                    <a href="javascript:void(0);" className="">Seller Details</a>
                                                </h4>
                                            </div>
                                        </div>
                                        {/* <div>
                                        <div className="d-sm-flex d-block justify-content-end">
                                            <input type="search" className="form-control w-50 mx-1" placeholder="Search Customer" />
                                            <a href="#" className="btn btn-primary  rounded-0">
                                                <i className='bx bxs-plus-circle'></i>&nbsp;New</a>
                                        </div>
                                    </div> */}
                                    </div>
                                </div>
                                <hr className="" />
                                <div className="card-body">
                                    <div className="row">
                                        <div class="col-md-6 mb-4">
                                            <label class="form-label">First Name*</label>
                                            <input type="text" className="form-control"
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)} />
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <label class="form-label">Last Name*</label>
                                            <input type="text" className="form-control"
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)} />
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <label class="form-label">Email*</label>
                                            <input type="email" className="form-control"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)} />
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <label class="form-label">Mobile*</label>
                                            <input type="text" className="form-control"
                                                value={mobile}
                                                onChange={(e) => setMobile(e.target.value)} />
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <label class="form-label">Address</label>
                                            <input type="text" className="form-control"
                                                value={address}
                                                onChange={(e) => setAddress(e.target.value)} />
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <label class="form-label">City</label>
                                            <input type="text" className="form-control"
                                                value={city}
                                                onChange={(e) => setCity(e.target.value)} />
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <label class="form-label">Post Code</label>
                                            <input type="text" className="form-control"
                                                value={postCode}
                                                onChange={(e) => setPostCode(e.target.value)} />
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <label class="form-label">How did you hear about us*</label>
                                            <select class="select2 form-select form-select" data-allow-clear="true"
                                                value={hearUs}
                                                onChange={e => setHearUs(e.target.value)}>
                                                <option value="">Select an option</option>
                                                <option value="FACEBOOK">Facebook</option>
                                                <option value="BING">Bing</option>
                                                <option value="SEARCH_ENGINE">Search Engine</option>
                                                <option value="REFERRAL">Referral</option>
                                                <option value="GOOGLE_ADS">Google Ads</option>
                                                <option value="OTHER">Other</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 mb-4 order-lg-0 mb-4 ">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-sm-flex align-items-center justify-content-between">
                                        <div>
                                            <div className="d-sm-flex">
                                                <img src={inquryImg} alt="" />
                                                <h4 className="fw-bold mx-2 mt-4"><a href="javascript:void(0);" className="">Buyer Details</a></h4>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <hr className="" />
                                <div className="card-body">
                                    <div className="row">
                                        {/* <div class="col-md-6 mb-4">
                                            <label class="form-label">Lead Status</label>
                                            <select className="form-control" value={leadStatus} onChange={(e) => setLeadStatus(e.target.value)}>
                                                {leadStatuses.map(status => <option value={status[1]}>{status[0]}</option>)}
                                            </select>
                                        </div> */}
                                        <div class="col-md-12 mb-4">
                                            <label class="form-label">Employee Making Purchase</label>
                                            <Select
                                                value={employee}
                                                onChange={(e) => setEmployee(e)}
                                                options={employeeOptions}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <label className="form-label" htmlFor="multicol-birthdate">Created On</label>
                                            <input type="text" readOnly value="" className="form-control" />
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <label class="form-label">Last Modified</label>
                                            <input type="text" readOnly value="" className="form-control" />
                                        </div>
                                        <div className="col-md-12 mb-4">
                                            <label class="form-label">Store Details</label>
                                            <Select
                                                value={store}
                                                onChange={(e) => setStore(e)}
                                                options={storeOptions}
                                            />
                                        </div>
                                        {/* <div className="col-md-6 mb-4">
                                            <label class="form-label">Repair Type</label>
                                            <select class="select2 form-select form-select" data-allow-clear="true"
                                                value={orderType} onChange={e => setOrderType(e.target.value)}>
                                                <option value="walk_in">Walk In</option>
                                                <option value="mail_in">Mail In</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <label class="form-label">Invoice Status</label><select className="form-control"
                                                value={invoiceStatus} onChange={(e) => setInvoiceStatus(e.target.value)}>
                                                {invoiceStatuses.map(status => <option value={status[1]}>{status[0]}</option>)}
                                            </select>
                                        </div> */}
                                        <div className="col-md-6 mb-4">
                                            <label class="form-label">Purchase Date</label>
                                            <input type="datetime-local" class="form-control"
                                                value={purchaseDate} onChange={e => setPurchaseDate(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 mb-4 order-lg-0 mb-4 ">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-sm-flex align-items-center justify-content-between">
                                        <div>
                                            <div className="d-sm-flex">
                                                {/* <img src={inquryImg} alt="" /> */}
                                                <h4 className="fw-bold mx-2 mt-4"><a href="#" className="">Item Details</a></h4>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <hr className="" />
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4 mb-4">
                                            <label class="form-label">Item Name</label>
                                            <input
                                                type="text"
                                                className="mb-4 form-control"
                                                placeholder="Name"
                                                value={name}
                                                onChange={(e) => { setName(e.target.value) }}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-4">
                                            <label class="form-label">Item IMEI</label>
                                            <input
                                                type="text"
                                                className="mb-4 form-control"
                                                placeholder="Item IMEI"
                                                value={imei}
                                                onChange={(e) => { setIMEI(e.target.value) }}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-4">
                                            <label class="form-label">Item Serial</label>
                                            <input
                                                type="text"
                                                className="mb-4 form-control"
                                                placeholder="Item Serial"
                                                value={serial}
                                                onChange={(e) => { setSerial(e.target.value) }}
                                            />
                                        </div>
                                        <div class="col-md-4 mb-4">
                                            <label class="form-label">Select Category</label>
                                            <Select
                                                value={repairCategory}
                                                onChange={(e) => setRepairCategory(e)}
                                                options={repairCategoryOptions}
                                            />
                                        </div>
                                        <div class="col-md-4 mb-4">
                                            <label class="form-label">Select Brand</label>
                                            <Select
                                                value={manufacturer}
                                                onChange={(e) => setManufacturer(e)}
                                                options={manufacturerOptions}
                                            />
                                        </div>
                                        <div class="col-md-4 mb-4">
                                            <label class="form-label">Select Phone</label>
                                            <Select
                                                value={device}
                                                onChange={(e) => setDevice(e)}
                                                options={deviceOptions}
                                            />
                                        </div>
                                        <div class="col-md-4 mb-4">
                                            <label class="form-label">Color</label>
                                            <Select
                                                value={color}
                                                onChange={(e) => setColor(e)}
                                                options={colorOptions}
                                            />
                                        </div>
                                        <div class="col-md-4 mb-4">
                                            <label class="form-label">Size</label>
                                            <Select
                                                value={size}
                                                onChange={(e) => setSize(e)}
                                                options={sizeOptions}
                                            />
                                        </div>
                                        <div class="col-md-4 mb-4">
                                            <label class="form-label">Network</label>
                                            <Select
                                                value={network}
                                                onChange={(e) => setNetwork(e)}
                                                options={networkOptions}
                                            />
                                        </div>
                                        <div class="col-md-4 mb-4">
                                            <label class="form-label">Purchase Condition</label>
                                            <Select
                                                value={purchaseCondition}
                                                onChange={(e) => setPurchaseCondition(e)}
                                                options={purchaseConditionOptions}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-4">
                                            <label class="form-label">SKU</label>
                                            <input
                                                type="text"
                                                className="mb-4 form-control"
                                                placeholder="Item Serial"
                                                value={sku}
                                                onChange={(e) => { setSku(e.target.value) }}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-4">
                                            <label class="form-label">Retail Price</label>
                                            <input
                                                type="number"
                                                className="mb-4 form-control"
                                                placeholder="Retail Price"
                                                value={retailPrice}
                                                onChange={(e) => { setRetailPrice(e.target.value) }}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-4">
                                            <label class="form-label">Cost Price</label>
                                            <input
                                                type="number"
                                                className="mb-4 form-control"
                                                placeholder="Cost Price"
                                                value={costPrice}
                                                onChange={(e) => { setCostPrice(e.target.value) }}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-4">
                                            <label>
                                                Warranty
                                            </label>
                                            <div className="col-md-8">
                                                <input
                                                    type="number"
                                                    style={{ width: '30%', display: 'inline' }}
                                                    className="mb-4 form-control me-2"
                                                    value={warrantyNumber}
                                                    onChange={(e) => { setWarrantyNumber(e.target.value) }}
                                                />
                                                <select
                                                    value={warrantyType}
                                                    onChange={(e) => setWarrantyType(e.target.value)}
                                                    style={{ width: '60%', display: 'inline' }}
                                                    className="mb-4 form-control">
                                                    {/* {warrantyTypes && warrantyTypes.map(warrantyType => <option value={warrantyType}>{warrantyType}</option>)} */}
                                                    <option value="no_warranty">No Warranty</option>
                                                    <option value="days">Days</option>
                                                    <option value="months">Months</option>
                                                    <option value="years">Years</option>
                                                    <option value="lifetime">Lifetime</option>
                                                </select>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-4 mb-4">
                                            <label>
                                                Pre Condition
                                            </label>
                                            <div className="col-md-8">
                                                <input
                                                    type="checkbox"
                                                    style={{ display: 'inline' }}
                                                    className="form-control"
                                                />
                                                Dent
                                                <input
                                                    type="checkbox"
                                                    style={{ display: 'inline' }}
                                                    className="form-control"
                                                />
                                                Scratches
                                            </div>
                                        </div> */}
                                        <div class="col-md-4 mb-4">
                                            <label class="form-label">Description</label>
                                            <textarea className="form-control"
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}>
                                            </textarea>
                                        </div>
                                        <div class="col-md-4 mb-4">
                                            <label class="form-label">Admin Notes</label>
                                            <textarea className="form-control"
                                                value={adminNotes}
                                                onChange={(e) => setAdminNotes(e.target.value)}>
                                            </textarea>
                                        </div>
                                        <div class="col-md-4 mb-4">
                                            <label class="form-label">Transaction Notes</label>
                                            <textarea className="form-control"
                                                value={transactionNotes}
                                                onChange={(e) => setTransactionNotes(e.target.value)}>
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><div class="col-sm-12 d-flex justify-content-end">
                        <button type="button" class="btn btn-primary" onClick={() => navigate('/admin/tradeins')}>Cancel</button>
                        <button type="submit" class="btn btn-primary mx-1">Submit</button>
                    </div>
                </form>
            </div>
        </>
    )
}
export default AddTradeIn