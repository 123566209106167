import { GET_DEVICE, LIST_DEVICES } from "../actions/types";

const initialState = {
    devices: [],
    error: null,
    device: null
};
export default (state = initialState, { type, payload }) => {

    switch (type) {
        case LIST_DEVICES: 
        return {
            ...state,
            devices: payload
        }
        case GET_DEVICE:
            return {
                ...state,
                device: payload
            }
        default:
            return state;
    }
}
