import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { GetRecordsCount } from "../../../actions/globalActions";
// import { listOrdersAction } from "../../../actions/orderActions";
// import { deleteRepairCategoryAction, listRepairCategories } from "../../../actions/repairCategoriesActions";
import Pagination from "../../elements/Pagination";
import { listSellOrdersAction } from "../../../actions/buySellOrderActions";
import { dateWithoutZone } from "../../../utils";

const BuyOrders = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { buyOrders } = useSelector((state) => state.buySellOrder);
    const [modal, setModal] = useState(false);
    const [delId, setDelId] = useState(null);

    useEffect(() => {
        dispatch(GetRecordsCount("buySellOrders"));
        dispatch(listSellOrdersAction());
    }, []);

    const updateRecord = (val) => {
        dispatch(listSellOrdersAction(val));
    };

    const styleShow = {
        display: "block",
        paddingRight: "17px",
    };

    const styleHide = {
        display: "none",
        paddingRight: "17px",
    };

    const deleteRepairCategory = () => {
        // delId && dispatch(deleteRepairCategoryAction(delId));
        setModal(false);
    };

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <div>
                    <nav aria-label="breadcrumb" className>
                        <ol className="breadcrumb breadcrumb-style1 p-1">
                            <li className="breadcrumb-item">
                                <Link to="/admin">Admin</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/admin/sell-orders">Buy Orders</Link>
                            </li>
                        </ol>
                    </nav>
                    <nav className="navbar navbar-expand-lg">
                        <div className="navbar-nav me-auto">
                            <a
                                className="btn btn-primary me-1 collapsed toggle dropdown-toggle"
                                data-bs-toggle="collapse"
                                href="#collapseExample"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                            >
                                Search Filter
                            </a>
                        </div>
                        {/* <ul className="navbar-nav ms-lg-auto">
                            <li className="nav-item">
                                <Link
                                    className="nav-link btn mx-1 btn-success"
                                    to="add"
                                >
                                    <span>
                                        {" "}
                                        <i className="bx bxs-plus-circle" />
                                        &nbsp;Create Buy Order
                                    </span>
                                </Link>
                            </li>
                        </ul> */}
                    </nav>
                    {/* <div className="collapse" id="collapseExample">
                            <div className="col-12">
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6 mb-4">
                                                <label
                                                    htmlFor="TypeaheadBasic"
                                                    className="form-label"
                                                >
                                                    Search By
                                                </label>
                                                <input
                                                    id="TypeaheadBasic"
                                                    className="form-control typeahead"
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="Name / ID / IMEI"
                                                />
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <div>
                                                    <label
                                                        htmlFor="selectpickerBasic"
                                                        className="form-label"
                                                    >
                                                        Assigned To
                                                    </label>
                                                    <select
                                                        id="selectpickerBasic"
                                                        className="form-control selectpicker w-100"
                                                        data-style="btn-default"
                                                    >
                                                        <option value disabled>
                                                            Assigned To
                                                        </option>
                                                        <option>Rocky</option>
                                                        <option>
                                                            Pulp Fiction
                                                        </option>
                                                        <option>
                                                            The Godfather
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <div>
                                                    <label
                                                        htmlFor="selectpickerBasic"
                                                        className="form-label"
                                                    >
                                                        Manufacturer
                                                    </label>
                                                    <select
                                                        id="selectpickerBasic"
                                                        className="form-control selectpicker w-100"
                                                        data-style="btn-default"
                                                    >
                                                        <option>Rocky</option>
                                                        <option>
                                                            Pulp Fiction
                                                        </option>
                                                        <option>
                                                            The Godfather
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <div>
                                                    <label
                                                        htmlFor="selectpickerBasic"
                                                        className="form-label"
                                                    >
                                                        Referral Source
                                                    </label>
                                                    <select
                                                        id="selectpickerBasic"
                                                        className="form-control selectpicker w-100"
                                                        data-style="btn-default"
                                                    >
                                                        <option>Rocky</option>
                                                        <option>
                                                            Pulp Fiction
                                                        </option>
                                                        <option>
                                                            The Godfather
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <div>
                                                    <label
                                                        htmlFor="selectpickerBasic"
                                                        className="form-label"
                                                    >
                                                        Device
                                                    </label>
                                                    <select
                                                        id="selectpickerBasic"
                                                        className="form-control selectpicker w-100"
                                                        data-style="btn-default"
                                                    >
                                                        <option>Rocky</option>
                                                        <option>
                                                            Pulp Fiction
                                                        </option>
                                                        <option>
                                                            The Godfather
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6  mb-4">
                                                <label
                                                    htmlFor="flatpickr-date"
                                                    className="form-label"
                                                >
                                                    From date (created on)
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="YYYY-MM-DD"
                                                    id="flatpickr-date"
                                                />
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label
                                                    htmlFor="TypeaheadBasic"
                                                    className="form-label"
                                                >
                                                    Customer Name
                                                </label>
                                                <input
                                                    id="TypeaheadBasic"
                                                    className="form-control typeahead"
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="Name / ID / IMEI"
                                                />
                                            </div>
                                            <div className="col-md-6 col-12 mb-4">
                                                <label
                                                    htmlFor="flatpickr-date"
                                                    className="form-label"
                                                >
                                                    To date (created on)
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="YYYY-MM-DD"
                                                    id="flatpickr-date"
                                                />
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <div>
                                                    <label
                                                        htmlFor="selectpickerBasic"
                                                        className="form-label"
                                                    >
                                                        Status
                                                    </label>
                                                    <select
                                                        id="selectpickerBasic"
                                                        className="form-control selectpicker w-100"
                                                        data-style="btn-default"
                                                    >
                                                        <option>Rocky</option>
                                                        <option>
                                                            Pulp Fiction
                                                        </option>
                                                        <option>
                                                            The Godfather
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-4">
                                                <label
                                                    htmlFor="flatpickr-date"
                                                    className="form-label"
                                                >
                                                    From date (Due on)
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="YYYY-MM-DD"
                                                    id="flatpickr-date"
                                                />
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label
                                                    htmlFor="TypeaheadBasic"
                                                    className="form-label"
                                                >
                                                    Mobile/Phone
                                                </label>
                                                <input
                                                    id="TypeaheadBasic"
                                                    className="form-control typeahead"
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="Name / ID / IMEI"
                                                />
                                            </div>
                                            <div className="col-md-6 col-12 mb-4">
                                                <label
                                                    htmlFor="flatpickr-date"
                                                    className="form-label"
                                                >
                                                    To date (Due on)
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="YYYY-MM-DD"
                                                    id="flatpickr-date"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer border-top">
                                        <a href="#" className="btn btn-primary">
                                            Search
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                </div>
                <div className="row" id="basic-table">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Buy Orders</h4>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>ticket</th>
                                                <th>Device</th>
                                                <th>Customer</th>
                                                <th>Condition</th>
                                                <th>Date</th>
                                                <th>Estimated Price</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {buyOrders.map((order, key) => {
                                                return (
                                                    !order.isDeleted && (
                                                        <BuyOrderItem
                                                            key={key}
                                                            order={order}
                                                        />
                                                    )
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="card-footer">
                                <Pagination
                                    currentItems={buyOrders}
                                    updateRecord={updateRecord}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`modal fade text-left ${modal ? "show" : ""}`}
                id="danger"
                tabIndex={-1}
                aria-labelledby="myModalLabel120"
                style={modal ? styleShow : styleHide}
                aria-modal="true"
                role="dialog"
            >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-danger">
                            <h5
                                className="modal-title white"
                                id="myModalLabel120"
                            >
                                Danger!
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    setModal(false);
                                }}
                            >
                                <i className="bx bx-x" />
                            </button>
                        </div>
                        <div className="modal-body">
                            Are you sure! You want to delete this Repair
                            Category?
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light-secondary"
                                data-dismiss="modal"
                                onClick={() => {
                                    setModal(false);
                                }}
                            >
                                <i className="bx bx-x d-block d-sm-none" />
                                <span className="d-none d-sm-block">
                                    Cancel
                                </span>
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger ml-1"
                                data-dismiss="modal"
                                onClick={() => {
                                    deleteRepairCategory(delId);
                                }}
                            >
                                <i className="bx bx-check d-block d-sm-none" />
                                <span className="d-none d-sm-block">
                                    Accept
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BuyOrders;

export const BuyOrderItem = ({ order }) => {
    const [modal, setModal] = useState(false);
    const [delId, setDelId] = useState(null);
    const datetime = dateWithoutZone(new Date(order?.createdAt)).split(" ");
    const condition = {
        excellent: 0,
        good: 1,
        faulty: 2,
        recycle: 4,
    };
    let price = "";
    if (order?.devices?.prices?.length) {
        const parse = JSON.parse(order?.devices?.prices);
        price = parse[order.deviceSize][condition[order.deviceCondition]];
    }
    return (
        <tr>
            <td>
                <Link to={`/admin/sell-orders/edit/${order._id}`}>
                    {order?.ticket ? order?.ticket : order._id}
                </Link>
            </td>
            <td>
                {order?.devices?.name}
                <br />
                <strong>{order?.devices?.manufactures?.name} </strong>
            </td>
            <td>
                <span className="d-block">
                    <a href="javascript:void(0);">
                        {order?.customer?.firstName} {order?.customer?.lastName}
                    </a>
                </span>
                <small className="d-block">
                    <strong> Mobile:</strong> {order?.customer?.mobile}
                </small>
                <small className="b-block">
                    <strong>Email:</strong> {order?.customer?.email}
                </small>
            </td>
            <td>
                <small className="d-block">
                    <strong> Size:</strong> {order?.deviceSize}
                </small>
                <small className="b-block">
                    <strong>Network:</strong> {order?.deviceNetwork}
                </small>
                <small className="d-block">
                    <strong> Condition:</strong> {order?.deviceCondition}
                </small>
            </td>
            <td>
                <span className="d-block">
                    {datetime[0]} <br /> {datetime[1]} {datetime[2]}
                </span>
            </td>
            <td>{price ? `£${price}` : "TBA"}</td>
            <td>{order?.orderStatus}</td>
            <td>
                <button
                    type="button"
                    className="btn p-0 dropdown-toggle hide-arrow"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <i class="bx bx-dots-vertical-rounded"></i>
                </button>
                <ul className="dropdown-menu bg-white">
                    <li>
                        <Link
                            to={`/admin/sell-orders/edit/${order._id}`}
                            className="dropdown-item"
                        >
                            Edit
                        </Link>
                    </li>
                    {order?.orderStatus === "completed" && (
                        <li>
                            <Link
                                to={`/admin/sell-orders/${order._id}`}
                                className="dropdown-item"
                            >
                                Move to Buy
                            </Link>
                        </li>
                    )}
                    {/* <li>
                        <a
                            className="dropdown-item"
                            href="javascript:void(0);"
                            onClick={() => {
                                setModal(true);
                                setDelId(order._id);
                            }}
                        >
                            Delete
                        </a>
                    </li> */}
                    {/* <li><Link className="dropdown-item" to={`/admin/leads/${order._id}`}>View/Add Comment</Link>
                                                                </li> */}
                </ul>
            </td>
        </tr>
    );
};
