import axios from "axios";
import { alertMessage, base_url, check_response_status } from "../utils";
import { GET_TRADE_IN, LIST_INVOICE_STATUS, LIST_TRADE_IN, LIST_SALE_STATUS } from "./types";

// list  tradeins
export const listTradeinsAction = (from=0, limit=20) => async dispatch => {
    axios.get(`${base_url}tradein?from=${from}&limit=${limit}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                // console.log(' tradeins', response.data);
                dispatch({
                    type: LIST_TRADE_IN,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//add Tradein Action
export const addTradeinAction = (tradein) => async dispatch => {
    axios.post(`${base_url}tradein`,
    tradein,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200 || response.status == 201) {
                dispatch(alertMessage({flag: 'success', message: "This tradein has been Upadated Successfully."}));
                dispatch(listTradeinsAction());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

// get tradein
export const getTradeinAction = (id) => async dispatch => {

    axios.get(`${base_url}tradein/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                // console.log(' tradeins', response.data);
                dispatch({
                    type: GET_TRADE_IN,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

// list  tradeins
export const listSaleStatusAction = () => async dispatch => {
    axios.get(`${base_url}tradein/getSaleStatus`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                dispatch({
                    type: LIST_SALE_STATUS,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

// list  tradeins
export const listInvoiceStatusAction = () => async dispatch => {
    axios.get(`${base_url}tradeins/getInvoiceStatus`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                dispatch({
                    type: LIST_INVOICE_STATUS,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//update Device Color
export const updateTradeinAction = (id, tradein) => async dispatch => {
    axios.put(`${base_url}tradein/${id}`,
    tradein,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200 || response.status == 201) {
                dispatch(alertMessage({flag: 'success', message: "This tradein has been Upadated Successfully."}));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

export const deleteTradeinAction = (id) => async dispatch => {
    // console.log(' problem', id)
    axios.delete(`${base_url}tradein/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log('delete', response);
            if(response.status == 200) {
                dispatch(alertMessage({flag: 'success', message: response.data.message}));
                dispatch(listTradeinsAction());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}