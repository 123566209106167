import axios from "axios";
import { alertMessage, base_url, check_response_status } from "../utils";
import { GET_PROBLEM, LIST_PROBLEMS } from "./types";

// list  problems
export const listProblems = () => async dispatch => {

    axios.get(`${base_url}problems`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                // console.log(' problems', response.data);
                dispatch({
                    type: LIST_PROBLEMS,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//get  problem
export const getProblem = (id) => async dispatch => {
    axios.get(`${base_url}problems/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                // console.log(' problems', response.data);
                dispatch({
                    type: GET_PROBLEM,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

// add  problem
export const addProblemAction = (problem) => async dispatch => {

    axios.post(`${base_url}problems`,
    problem,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log(' problem', response);
            if(response.status == 201) {
                dispatch(alertMessage(response.data));
                dispatch(listProblems());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//update Device Color
export const updateProblemAction = (id, problem) => async dispatch => {
    axios.put(`${base_url}problems/${id}`,
    problem,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log(' problem', response);
            if(response.status == 200) {
                dispatch(alertMessage({flag: 'success', message: "Device Color Upadated Successfully."}));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

export const deleteProblemAction = (id) => async dispatch => {
    // console.log(' problem', id)
    axios.delete(`${base_url}problems/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log('delete', response);
            if(response.status == 200) {
                dispatch(alertMessage({flag: 'success', message: response.data.message}));
                dispatch(listProblems());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}