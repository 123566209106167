const Resetpassowrd = () => {
    return (
        <div class="horizontal-layout horizontal-menu navbar-static 1-column   footer-static bg-full-screen-image  blank-page" data-open="hover" data-menu="horizontal-menu" data-col="1-column">
            <div className="app-content content">
                <div className="content-overlay" />
                <div className="content-wrapper">
                    <div className="content-header row"></div>
                    <div className="content-body">
                        {/* reset password start */}
                        <section className="row flexbox-container">
                            <div className="col-xl-7 col-10">
                                <div className="card bg-authentication mb-0">
                                    <div className="row m-0">
                                        {/* left section-login */}
                                        <div className="col-md-6 col-12 px-0">
                                            <div className="card disable-rounded-right d-flex justify-content-center mb-0 p-2 h-100">
                                                <div className="card-header pb-1">
                                                    <div className="card-title">
                                                        <h4 className="text-center mb-2">
                                                            Reset your Password
                                                        </h4>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <form
                                                        className="mb-2"
                                                        action="index.html"
                                                    >
                                                        <div className="form-group">
                                                            <label
                                                                className="text-bold-600"
                                                                htmlFor="exampleInputPassword1"
                                                            >
                                                                New Password
                                                            </label>
                                                            <input
                                                                type="password"
                                                                className="form-control"
                                                                id="exampleInputPassword1"
                                                                placeholder="Enter a new password"
                                                            />
                                                        </div>
                                                        <div className="form-group mb-2">
                                                            <label
                                                                className="text-bold-600"
                                                                htmlFor="exampleInputPassword2"
                                                            >
                                                                Confirm New
                                                                Password
                                                            </label>
                                                            <input
                                                                type="password"
                                                                className="form-control"
                                                                id="exampleInputPassword2"
                                                                placeholder="Confirm your new password"
                                                            />
                                                        </div>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary glow position-relative w-100"
                                                        >
                                                            Reset my password
                                                            <i
                                                                id="icon-arrow"
                                                                className="bx bx-right-arrow-alt"
                                                            />
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        {/* right section image */}
                                        <div className="col-md-6 d-md-block d-none text-center align-self-center p-3">
                                            <img
                                                className="img-fluid"
                                                src="../../../app-assets/images/pages/reset-password.png"
                                                alt="branding logo"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* reset password ends */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Resetpassowrd;
