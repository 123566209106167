import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { emptyAlertMessage, uploadImage } from "../../actions/globalActions";
import { updateManufacturerAction, getManufacturer } from "../../actions/manufacturerActions";
import { listRepairCategories } from "../../actions/repairCategoriesActions";
import { MEDIA_UPDATE } from "../../actions/types";
import { base_url } from "../../utils";
import Alert from "../elements/Alert";
import Select from 'react-select';


const UpdateManufacturer = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    let media = useSelector(state => state.global.media);
    const manufacturer = useSelector(state => state.manufacturer.manufacturer);
    const repairCategories = useSelector(state => state.repairCategory.repairCategories);

    const [repairCategoryOptions, setRepairCategoryOptions] = useState([]);
    const [repairCategory, setRepairCategory] = useState("");
    const [name, setName] = useState("");
    const [slug, setSlug] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState({ value: "" });
    const [button, setButton] = useState(false);
    let { id } = useParams();

    useLayoutEffect(() => {
        dispatch(listRepairCategories());
        dispatch(emptyAlertMessage());
    }, []);

    useEffect(() => {
        const opts = [];
        repairCategories && repairCategories.map(repairCategory => {
            if (!repairCategory.isDeleted) {
                opts.push({ obj: repairCategory, value: repairCategory._id, label: repairCategory.name });
            }
        });
        setRepairCategoryOptions(opts);
    }, [repairCategories]);

    useEffect(() => {
        if (manufacturer) {
            dispatch({
                type: MEDIA_UPDATE,
                payload: manufacturer.media
            });
            setName(manufacturer.name);
            setSlug(manufacturer.slug);
            setDescription(manufacturer.description);
            setRepairCategory({
                label: manufacturer?.repairCategories.name,
                vlaue: manufacturer?.repairCategories._id,
                obj: manufacturer?.repairCategories
            });
            // setImage({value: manufacturer.media.originalname})
        }
    }, [manufacturer]);

    useEffect(() => {
        id && dispatch(getManufacturer(id));
    }, [id]);

    useEffect(() => {
        media && setButton(true);
    }, [media])

    const updateManufacturer = (e) => {
        e.preventDefault();
        const manufacturer = {
            repairCategories: repairCategory.obj,
            name,
            slug,
            description,
            media
        }
        dispatch(updateManufacturerAction(id, manufacturer));
    }

    const updateImage = (e) => {
        if (e.files && e.files[0]) {
            setImage(e.files[0]);

            const data = new FormData()
            data.append('file', e.files[0], e.files[0].name);
            console.log('data', data);

            dispatch(uploadImage(data));
        }
    }

    const profileStyle = {
        width: '150px',
        height: '150px'
    }
    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="breadcrumbs-top">

                    <div className="breadcrumb-wrapper d-none d-sm-block mb-4">
                        <ol className="breadcrumb p-0 mb-0 pl-1">
                            <li className="breadcrumb-item">
                                <a href="index.html">
                                    <i className="bx bx-home-alt" />
                                </a>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/admin/manufacturers">Manufacturers</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <a href="javascript:void(0);">Update</a>
                            </li>
                        </ol>
                    </div>
                    <h5 className="">
                        Update Manufacturer
                    </h5>
                </div>
                {/* Basic Horizontal form layout section start */}

                <section id="basic-horizontal-layouts">
                    <div className="row match-height">
                        <div className="col-md-6 col-12">
                            <div className="card">
                                <div className="card-header">
                                    <Alert />
                                </div>
                                <div className="card-body">
                                    <form className="form form-horizontal" onSubmit={updateManufacturer}>
                                        <div className="form-body">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label>
                                                        Category
                                                    </label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <Select
                                                        required
                                                        value={repairCategory}
                                                        onChange={(e) => { setRepairCategory(e); console.log(e) }}
                                                        options={repairCategoryOptions}
                                                        className="mb-4"
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <label>
                                                        Name
                                                    </label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <input
                                                        type="text"
                                                        id="name"
                                                        className="mb-4 form-control"
                                                        // name="name"
                                                        placeholder="Name"
                                                        required
                                                        value={name}
                                                        onChange={(e) => { setName(e.target.value) }}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <label>
                                                        Slug
                                                    </label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <input
                                                        type="text"
                                                        className="mb-4 form-control"
                                                        placeholder="Slug"
                                                        required
                                                        value={slug}
                                                        onChange={(e) => { setSlug(e.target.value) }}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <label>
                                                        Description
                                                    </label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <textarea
                                                        type="text"
                                                        className="mb-4 form-control"
                                                        placeholder="Description"
                                                        rows={4}
                                                        required
                                                        value={description}
                                                        onChange={(e) => { setDescription(e.target.value) }}
                                                    ></textarea>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Picture</label>
                                                </div>
                                                <div className="col-md-8 mb-1"
                                                // dangerouslySetInnerHTML={{__html: media ? base_url + "media/" + media.filename : "/app-assets/images/dummy.jpg"}}
                                                // dangerouslySetInnerHTML={media ? base_url + "media/" + media.filename : "/app-assets/images/dummy.jpg"}
                                                >

                                                    <img style={profileStyle} src={media ? base_url + "media/" + media.filename : "/app-assets/images/dummy.jpg"} />
                                                    {/* <fieldset className="form-group">
                                            <input type="file" className="custom-file-input" />
                                        </fieldset> */}
                                                    {/* <input type="file" value={Image} hidden="true" className="mb-4 form-control" placeholder="Name" required
                                            onChange={(e) => updateImage(e.target)} /> */}
                                                </div>
                                                <div className="col-md-4">
                                                    <label></label>
                                                </div>
                                                <div className="col-md-8">
                                                    {/* <fieldset class="form-group">
                                            <input type="file" class="mb-4 form-control-file" value={image.value}
                                                onChange={(e) => { updateImage(e.target); setButton(false) }} />
                                        </fieldset> */}
                                                    <div class="button-wrapper">
                                                        <label for="upload" class="btn btn-primary me-2 mb-4" tabindex="0">
                                                            <span class="d-none d-sm-block">Update old photo</span>
                                                            <i class="bx bx-upload d-block d-sm-none"></i>
                                                            <input
                                                                type="file"
                                                                id="upload"
                                                                class="account-file-input"
                                                                value={image.value}
                                                                hidden
                                                                accept="image/png, image/jpeg"
                                                                onChange={(e) => { updateImage(e.target); setButton(false) }}
                                                            />
                                                        </label>
                                                        {/* <button type="button" class="btn btn-label-secondary account-image-reset mb-4">
                                                <i class="bx bx-reset d-block d-sm-none"></i>
                                                <span class="d-none d-sm-block">Reset</span>
                                            </button> */}

                                                        {/* <p class="mb-0">Allowed JPG, GIF or PNG. Max size of 800K</p> */}
                                                    </div>
                                                </div>
                                                {/* <div class="edit"><a onClick={() => imageRef.current.click()} href="javascript:void(0);"><i class="fa fa-edit fa-lg"></i></a></div> */}
                                                {/* {progress > 0 && <ProgressBar labelSize="10px" height="10px" bgcolor="#175759" completed={progress} />} */}
                                                {/* <input type="file" value={Image} hidden="true" className="mb-4 form-control" placeholder="Name" required
                                        onChange={(e) => updateImage(e.target)} /> */}
                                                {/* <p className="error">{errors && errors.image}</p> */}
                                                {/* <div className="col-md-8">
                                        <fieldset className="form-group">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" />
                                                <label className="custom-file-label" htmlFor="inputGroupFile01">WhatsApp Image 2022-06-15 at 9.32.27 AM.jpeg</label>
                                            </div>
                                        </fieldset>
                                    </div> */}

                                                {/* <div className="col-md-4">
                                        <label>
                                            Mobile
                                        </label>
                                    </div> */}
                                                {/* <div className="col-md-8 form-group">
                                        <input
                                            type="number"
                                            id="contact-info"
                                            className="mb-4 form-control"
                                            name="contact"
                                            placeholder="Mobile"
                                        />
                                    </div> */}
                                                {/* <div className="col-md-4">
                                        <label>
                                            Password
                                        </label>
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <input
                                            type="password"
                                            id="password"
                                            className="mb-4 form-control"
                                            name="password"
                                            placeholder="Password"
                                        />
                                    </div> */}
                                                {/* <div className="col-12 col-md-8 offset-md-4 form-group">
                                        <fieldset>
                                            <div className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="checkbox__input"
                                                    id="checkbox1"
                                                    defaultChecked
                                                />
                                                <label htmlFor="checkbox1">
                                                    Remember
                                                    me
                                                </label>
                                            </div>
                                        </fieldset>
                                    </div> */}
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary "
                                                        onClick={() => navigate(-1)}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary mx-1"
                                                    >
                                                        Submit
                                                    </button>
                                                    {/* <button
                                            type="reset"
                                            className="btn btn-light-secondary"
                                            onClick={() => setImage("")}
                                        >
                                            Reset
                                        </button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Basic Horizontal form layout section end */}
            </div>

        </>
    );
};

export default UpdateManufacturer;
