import { useEffect, useLayoutEffect, useState, useTransition } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { Link, useNavigate, useParams } from "react-router-dom";
import { base_url } from "../../../utils";
import { addEmployeeAction, getEmployee, updateEmployeeAction } from "../../../actions/employeeActions"
import { listStores } from "../../../actions/storeActions"
import Alert from "../../elements/Alert";
import { listUserRoles } from "../../../actions/userRoleActions"
import { emptyAlertMessage } from "../../../actions/globalActions";
const UpdateEmployee = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()


  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("")
  const [hourlyRate, setHourlyRate] = useState(0)
  const [accessPin, setAccessPin] = useState(0)
  const [storeOptions, setStoreOptions] = useState([])
  const [roleOptions, setRoleOptions] = useState([])
  const [mobile, setMobile] = useState("")
  const [address, setAddress] = useState("")
  const [city, setCity] = useState("")
  const [country, setCountry] = useState("")
  const [zipCode, setZipCode] = useState("")
  const [userType, setUserType] = useState("")
  const [storeAccess, setStoreAccess] = useState([])

  const stores = useSelector((state) => state.store.stores);
  const userRoles = useSelector((state) => state.userRole.userRoles);
  const employee = useSelector((state) => state.employee.employee)

  useEffect(() => {
    if (stores) {
      let accesses = stores.filter(stor => !stor.isDeleted);

      setStoreAccess(accesses.map(stor => {
        return {
          store: { id: stor._id, name: stor.name },
          access: false,
          assignment: false
        };
      }));

    }
  }, [stores]);
  
  const updateAccess = (id, access, assignment) => {
    const updatedAccesses = storeAccess.map(acc => {
      if (acc.store.id === id) {
        return {
          ...acc,
          access,
          assignment
        };
      }
      return acc;
    });
    setStoreAccess(updatedAccesses);
  };

  useEffect(() => {
    dispatch(listStores());
    dispatch(listUserRoles())

  }, [])

  useEffect(() => {
    if (employee) {
      setFirstName(employee.firstName)
      setEmail(employee.email)
      setLastName(employee.lastName)
      // setPassword(employee.password)
      // console.log("Password", employee.password)
      setStoreOptions(employee.defaultStore)
      setAccessPin(employee.accessPin)
      setHourlyRate(employee.hourlyRate)
      setRoleOptions(employee.role)
      setPhone(employee.address?.phone)
      setUserType(employee?.userType)

    }
  }, [employee])

  useEffect(() => {
    id && dispatch(getEmployee(id));
  }, [id]);

  useLayoutEffect(() => {
    dispatch(emptyAlertMessage());
  }, []);

  const UpdateEmployee = (e) => {
    e.preventDefault()

    const new_employee = {
      firstName,
      lastName,
      email,
      role: roleOptions,
      defaultStore: storeOptions,
      hourlyRate,
      accessPin,
      userType,
      storeAccess: [
        {
          store: 123451,
          access: false,
          assignment: true
        }
      ],
      salesTarget: 1111,
      address: {
        phone,
        mobile: 1111,
        city: 1111,
        country: 1111,
        zip: 1111,
        address: 1111
      },
      calander: [
        {
          day: "Modany",
          hours: 5
        },
        {
          day: "Friday",
          hours: 8
        }
      ],
      paymentMethod: {
        hourly: true,
        hourlyRate: 23,
        salaried: false,
        salary: 0
      },
      paySchedule: "Monthly"
    }
    console.log(new_employee);
    // dispatch(updateEmployeeAction(id, new_employee))
    // navigate("/admin/employees")
  }

  return (
    <>
      <div className="container-xxl flex-grow-1 cotainer-p-y">
        <div className="breadcrumbs-top py-4">
          <div className="breadcrumb-wrapper d-none d-sm-block">
            <ol className="breadcrumb p-0 mb-0 pl-1">
              <li className="breadcrumb-item">
                <a href="javascript:void(0);">
                  <i className="bx bx-home-alt" />
                </a>
              </li>
              <li className="breadcrumb-item">
                <Link to="/admin/employees">Employees</Link>
              </li>
              <li className="breadcrumb-item active">
                <a href="javascript:void(0);">Update</a>
              </li>
            </ol>
          </div>
        </div>
        <section id="basic-horizontal-layouts">
          <div className="row match-height">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <form className="form form-horizontal" onSubmit={UpdateEmployee}>
                    <div className="form-body">
                      <h4>Basic information</h4>
                      <hr />
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <label >First Name</label>
                          <input
                            type="text"
                            className="mb-4 form-control"
                            placeholder="Enter your first Name"
                            required

                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label >Last Name</label>
                          <input
                            type="text"
                            className="mb-4 form-control"
                            placeholder="Enter your Last Name"
                            required
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <label >Email</label>
                          <input
                            type="email"
                            className="mb-4 form-control"
                            placeholder="Enter your Email"
                            required
                            readOnly
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label >User Type</label>
                          <select
                            className="mb-4 form-control"
                            required
                            value={userType}
                            onChange={(e) => setUserType(e.target.value)}
                          >
                            <option value="">Select User Type</option>
                            <option value="EMPLOYEE">Employee</option>
                            <option value="CUSTOMER">Customer</option>

                          </select>
                        </div>
                      </div>


                      <div className="row">
                        <h4>Roles & Access</h4>
                        <hr />
                        <div className="col-md-6 form-group">
                          <label>Default Store</label>
                          <select className="mb-4 form-control" name="name" value={storeOptions} onChange={(e) => { setStoreOptions(e.target.value) }}>
                            <option value="">Select Store</option>{stores.map((store, key) => { return !store.isDeleted ? <option value={store._id} key={key}>{store.name}</option> : "" })}
                          </select>
                        </div>

                        <div className="col-md-6 form-group">
                          <label>Access Pin</label>
                          <input
                            type="tel"
                            className="mb-4 form-control"
                            placeholder="Enter your phone number"
                            required
                            value={accessPin}
                            onChange={(e) => setAccessPin(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <label>Hourly Rate</label>
                          <input
                            type="number"
                            className="mb-4 form-control"
                            placeholder="Enter your Password"
                            required
                            value={hourlyRate}
                            onChange={(e) => setHourlyRate(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label>Sales Target</label>
                          <input
                            type="tel"
                            className="mb-4 form-control"
                            placeholder="Enter your phone number"
                            required
                            value={accessPin}
                            onChange={(e) => setAccessPin(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="card">
                            <h5 className="card-header">Store Access</h5>
                            <div className="table-responsive">
                              <table className="table table-striped table-borderless">
                                <thead>
                                  <tr>
                                    <th className="text-nowrap">Store Name</th>
                                    <th className="text-nowrap text-center">✉️ Access</th>
                                    <th className="text-nowrap text-center">🖥 Assignment</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {storeAccess && storeAccess.map((stor, key) => {
                                    return <tr key={key}>
                                      <td className="text-nowrap">{stor.store.name}</td>
                                      <td>
                                        <div className="form-check d-flex justify-content-center">
                                          <input className="form-check-input" type="checkbox" id="defaultCheck1"
                                            checked={stor.access}
                                            onChange={() => updateAccess(stor.store.id, !stor.access, stor.assignment)} />
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-check d-flex justify-content-center">
                                          <input className="form-check-input" type="checkbox" id="defaultCheck2"
                                            checked={stor.assignment}
                                            onChange={() => updateAccess(stor.store.id, stor.access, !stor.assignment)} />
                                        </div>
                                      </td>
                                    </tr>
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <h4>Contact Details</h4>
                        <hr />
                        <div className="col-md-6 form-group">
                          <label>Phone</label>
                          <input
                            type="text"
                            className="mb-4 form-control"
                            placeholder="Enter your Phone Number"
                            // required
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label >Mobile</label>
                          <input
                            type="tel"
                            className="mb-4 form-control"
                            placeholder="Enter your Mobile number"
                            // required
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label >Address</label>
                          <input
                            type="text"
                            className="mb-4 form-control"
                            placeholder="Enter your Address"
                            // required
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label >City</label>
                          <input
                            type="text"
                            className="mb-4 form-control"
                            placeholder="Enter your City Name"
                            // required
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label >Country</label>
                          <input
                            type="text"
                            className="mb-4 form-control"
                            placeholder="Enter your Country Name"
                            // required
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label >zip</label>
                          <input
                            type="text"
                            className="mb-4 form-control"
                            placeholder="Enter your phone number"
                            // required
                            value={zipCode}
                            onChange={(e) => setZipCode(e.target.value)}
                          />
                        </div>
                      </div>


                      <div className="col-sm-12 d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => navigate(-1)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary mx-1"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default UpdateEmployee;