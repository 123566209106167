import { useSelector } from "react-redux";

const Alert = () => {
    const response = useSelector(state => state.global.response);
    return (
        response && <div class={`alert alert-${response && response.flag}`} role="alert">
            {response.message}

            {/* {  (typeof(response.message) === 'string') ? response.message : response.message.map(msg => { return msg + '<br/>'})
            } */}
        </div>
    );
}

export default Alert;