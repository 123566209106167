import {
    GET_ORDER,
    LIST_ORDERS,
    LIST_ORDER_STATUS,
    LIST_INVOICE_STATUS
} from "../actions/types";

const initialState = {
    orders: [],
    order: null,
    error: null,
    orderStatus: [],
    invoiceStatus: []
};
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case LIST_ORDER_STATUS:
            return {
                ...state,
                orderStatus: payload
            }
        case LIST_INVOICE_STATUS:
            return {
                ...state,
                invoiceStatus: payload
            }
        case LIST_ORDERS:
            return {
                ...state,
                orders: payload
            }
        case GET_ORDER:
            return {
                ...state,
                order: payload
            }
        default:
            return state;
    }
}