import { GET_INQUIRY, LIST_INQUIRIES, LIST_INQUIRY_STATUS } from "../actions/types";

const initialState = {
    inquiries: [],
    error: null,
    inquiry: null,
    inquiryStatuses: []
};
export default (state = initialState, { type, payload }) => {

    switch (type) {
        case LIST_INQUIRIES: 
        return {
            ...state,
            inquiries: payload
        }
        case GET_INQUIRY:
            return {
                ...state,
                inquiry: payload
            }
        case LIST_INQUIRY_STATUS:
            return {
                ...state,
                inquiryStatuses: payload
            }
        default:
            return state;
    }
}
