import axios from "axios";
import { alertMessage, base_url, check_response_status } from "../utils";
import { GET_DEVICE, LIST_DEVICES } from "./types";

// list devices
export const listDevices = (manufactureId = '', reqFilters = {}) => async dispatch => {

    axios.post(`${base_url}devices/active`,
        { manufactureId, reqFilters },
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200 || response.status == 201) {
                // console.log('devices', response.data);
                dispatch({
                    type: LIST_DEVICES,
                    payload: response.data
                });
            }
            else if (response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//list devices with pagination
export const listDevicesAction = (from = 0, limit = 20) => async dispatch => {

    axios.get(`${base_url}devices/?from=${from}&limit=${limit}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200 || response.status == 201) {
                dispatch({
                    type: LIST_DEVICES,
                    payload: response.data
                });
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            dispatch(check_response_status(err.response));
        });
}

//get device
export const getDevice = (id) => async dispatch => {
    axios.get(`${base_url}devices/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                // console.log('device', response.data);
                dispatch({
                    type: GET_DEVICE,
                    payload: response.data
                });
            }
            else if (response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

// add device
export const addDeviceAction = (device, sortOrder) => async dispatch => {

    axios.post(`${base_url}devices`,
        { device, sortOrder },
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log('signup', response);
            if (response.status == 201) {
                dispatch(alertMessage(response.data));
                dispatch(listDevicesAction());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//update Device
export const updateDeviceAction = (id, device, sortOrder) => async dispatch => {
    axios.put(`${base_url}devices/${id}`,
        {device, sortOrder},
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log('signup', response);
            if (response.status == 200) {
                dispatch(alertMessage({ flag: 'success', message: "Device Upadated Successfully." }));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

export const deleteDeviceAction = (id) => async dispatch => {
    // console.log('del man', id)
    axios.delete(`${base_url}devices/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log('delete', response);
            if (response.status == 200) {
                dispatch(alertMessage({ flag: 'success', message: response.data.message }));
                dispatch(listDevicesAction());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}