import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { emptyAlertMessage, uploadImage } from "../../../actions/globalActions";
import { updateProductCategoryAction, getProductCategory } from "../../../actions/productCategoriesActions";
import { MEDIA_UPDATE } from "../../../actions/types";
import { base_url } from "../../../utils";
import Alert from "../../elements/Alert";
import Select from 'react-select';

const UpdateProductCategory = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    let media = useSelector(state => state.global.media);
    const productCategory = useSelector(state => state.productCategory.productCategory);

    const [name, setName] = useState("");
    const [image, setImage] = useState({ value: "" });
    const [subCategories, setSubCategories] = useState([])
    const [button, setButton] = useState(false);
    let { id } = useParams();
    const [subCategoryOptions, setSubCategoryOptions] = useState([])
    const [subCategory, setSubCategory] = useState();

    useLayoutEffect(() => {
        dispatch(emptyAlertMessage());
    }, []);

    useEffect(() => {
        if (productCategory) {
            dispatch({
                type: MEDIA_UPDATE,
                payload: { id: productCategory.media._id, filename: productCategory.media.filename }
            });
            setName(productCategory.name);

            const opts = [];
            productCategory.subCategories.map(subCategory => {
                if (subCategory?.id && subCategory?.name) {
                    opts.push({ obj: subCategory, value: subCategory.id, label: subCategory.name });
                }
            })
            setSubCategory(opts)
            setSubCategoryOptions(opts);
        }
    }, [productCategory]);

    useEffect(() => {
        id && dispatch(getProductCategory(id));
    }, [id]);

    useEffect(() => {
        media && setButton(true);
    }, [media])

    const updateProductCategory = (e) => {
        e.preventDefault();
        const productCategory = {
            name,
            media,
            subCategories: subCategory.map(sub => sub.obj)
        }
        dispatch(updateProductCategoryAction(id, productCategory));
    }

    const updateImage = (e) => {
        if (e.files && e.files[0]) {
            setImage(e.files[0]);

            const data = new FormData()
            data.append('file', e.files[0], e.files[0].name);

            dispatch(uploadImage(data));
        }
    }

    const profileStyle = {
        width: '150px',
        height: '150px'
    }
    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="breadcrumbs-top py-4">
                    <div className="breadcrumb-wrapper d-none d-sm-block">
                        <ol className="breadcrumb breadcrumb-style1 p-0 mb-0 pl-1">
                            <li className="breadcrumb-item">
                                <Link to="/admin">
                                    <i className="bx bx-home-alt" />
                                </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/admin/product-Categories">Product Categories</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <a href="javascript:void(0);">Update</a>
                            </li>
                        </ol>
                    </div>
                </div>
                <section id="basic-horizontal-layouts">
                    <div className="row match-height">
                        <div className="col-md-6 col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">
                                        Update Product Category
                                    </h5>
                                    <Alert />
                                </div>
                                <div className="card-body">
                                    <form className="form form-horizontal" onSubmit={updateProductCategory}>
                                        <div className="form-body">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label>
                                                        Name
                                                    </label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control mb-4"
                                                        placeholder="Name"
                                                        required
                                                        value={name}
                                                        onChange={(e) => { setName(e.target.value) }}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <label>
                                                        Sub Categories
                                                    </label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <Select
                                                        required
                                                        isMulti={true}
                                                        value={subCategory}
                                                        onChange={(e) => setSubCategory(e)}
                                                        options={subCategoryOptions}
                                                        className="mb-4"
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Picture</label>
                                                </div>
                                                <div className="col-md-8 mb-1">
                                                    <img style={profileStyle} src={media ? base_url + "media/" + media.filename : "/app-assets/images/dummy.jpg"} />
                                                </div>
                                                <div className="col-md-4">
                                                    <label></label>
                                                </div>
                                                <div className="col-md-8">
                                                    <div class="button-wrapper">
                                                        <label for="upload" class="btn btn-primary me-2 mb-4" tabindex="0">
                                                            <span class="d-none d-sm-block">Upload new photo</span>
                                                            <i class="bx bx-upload d-block d-sm-none"></i>
                                                            <input
                                                                type="file"
                                                                id="upload"
                                                                class="account-file-input"
                                                                value={image.value}
                                                                hidden
                                                                accept="image/png, image/jpeg"
                                                                onChange={(e) => { updateImage(e.target); setButton(false) }}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-1"
                                                        onClick={() => navigate(-1)}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary "
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    );
};

export default UpdateProductCategory;
