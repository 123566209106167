export const HEAR_US = [
    { key: "FACEBOOK", label: "Facebook" },
    { key: "BING", label: "Bing" },
    { key: "SEARCH_ENGINE", label: "Search Engine" },
    { key: "REFERRAL", label: "Referral" },
    { key: "GOOGLE_ADS", label: "Google Ads" },
    { key: "OTHER", label: "Other" }
]

export const MOBILE_SIZES = [
    { name: "16 GB", value: "16_gb" },
    { name: "32 GB", value: "32_gb" },
    { name: "64 GB", value: "64_gb" },
    { name: "128 GB", value: "128_gb" },
    { name: "256 GB", value: "256_gb" },
    { name: "512 GB", value: "512_gb" },
    { name: "1 TB", value: "1_tb" },
];

export const MOBILE_NETWORKS = [
    {
        name: "Unlocked",
        value: "unlocked",
        image_link: "mobilecondition_block_unlocked.svg",
    },
    {
        name: "O2",
        value: "o2",
        image_link: "mobilecondition_block_o2.svg",
    },
    {
        name: "Vodafone",
        value: "vodafone",
        image_link: "mobilecondition_block_vodafone.svg",
    },
    {
        name: "EE",
        value: "ee",
        image_link: "mobilecondition_block_ee.svg",
    },
    {
        name: "Three",
        value: "three",
        image_link: "mobilecondition_block_three.svg",
    },
];

export const MOBILE_CONDITIONS = [
    { name: "Excellent", value: "excellent" },
    { name: "Good", value: "good" },
    { name: "Faulty", value: "faulty" },
    { name: "Give it for recycle to save earth", value: "recycle" },
];

export const MOBILE_GRADES = [
    { name: "A", value: "a" },
    { name: "B", value: "b" },
    { name: "C", value: "c" }
]
