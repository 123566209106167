import { useEffect, useRef } from "react";
import { useLayoutEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { listEmployees } from "../../../actions/employeeActions";
import { listHearusOptionsAction } from "../../../actions/globalActions";
import { addInquiryAction, listInquiryStatusAction } from "../../../actions/inquiryActions";

const AddInquiry = () => {
    const dispatch = useDispatch();
    const closeRef = useRef();

    const hearusOptions = useSelector(state => state.global.hearusOptions);
    const inquiryStatuses = useSelector(state => state.inquiry.inquiryStatuses);
    const employees = useSelector(state => state.employee.employees);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [newBuyer, setNewBuyer] = useState("FACEBOOK");
    const [message, setMessage] = useState("");
    const [inquiryStatus, setInquiryStatus] = useState("new");
    const [employee, setEmployee] = useState("");

    const [employeeOptions, setEmployeeOptions] = useState([]);

    useLayoutEffect(() => {
        dispatch(listEmployees());
        dispatch(listInquiryStatusAction());
        dispatch(listHearusOptionsAction());
    }, []);

    useEffect(() => {
        const opts = [];
        employees && employees.map(employee => {
            if (!employee.isDeleted) {
                opts.push({ obj: employee, value: employee._id, label: employee.firstName + ' ' + employee.lastName });
            }
        });
        setEmployeeOptions(opts);
    }, [employees]);

    const createInquiry = (e) => {
        e.preventDefault();

        const inquiry = {
            name,
            email,
            message,
            assignedTo: employee.obj,
            newBuyer,
            inquiryStatus,
            phone
        }

        dispatch(addInquiryAction(inquiry));
        closeRef.current.click()
    }

    return (
        <div className="modal modal-top fade" id="modalTop" tabIndex={-1}>
            <div className="modal-dialog">
                <form className="modal-content" onSubmit={createInquiry}>
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalTopTitle">Create New Inquiry</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col mb-0">
                                <label className="form-label">Name</label>
                                <input type="text" className="form-control" placeholder="Enter Name" required
                                    value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className="col mb-0">
                                <label className="form-label">Email</label>
                                <input type="email" className="form-control" placeholder="Enter Email" required
                                    value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-0">
                                <label className="form-label">Mobile</label>
                                <input type="text" className="form-control" placeholder="Enter Mobile Number" required
                                    value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </div>
                            <div className="col mb-0">
                                <label className="form-label">assigned To</label>
                                <Select
                                    required
                                    value={employee}
                                    onChange={(e) => setEmployee(e)}
                                    options={employeeOptions}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-0">
                                <label className="form-label">Status</label>
                                <select className="form-control" required
                                    value={inquiryStatus} onChange={(e) => setInquiryStatus(e.target.value)}>
                                    {inquiryStatuses?.map(status => <option value={status[1]}>{status[0]}</option>)}
                                </select>
                            </div>
                            <div className="col mb-0">
                                <label className="form-label">Reference</label>
                                <select className="form-control" required
                                    value={newBuyer} onChange={(e) => setNewBuyer(e.target.value)}>
                                    {hearusOptions?.map(option => <option value={option[1]}>{option[0]}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-0">
                                <label className="form-label">Message</label>
                                <textarea className="form-control" required
                                    value={message} onChange={(e) => setMessage(e.target.value)}>
                                </textarea>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" ref={closeRef} className="btn btn-label-secondary" data-bs-dismiss="modal">
                            Close
                        </button>
                        <button type="submit" className="btn btn-primary">Create</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddInquiry;