import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { deleteProductCategoryAction, listProductCategories } from "../../../actions/productCategoriesActions";

const ListProductCategories = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const productCategories = useSelector((state) => state.productCategory.productCategories);
    const [modal, setModal] = useState(false);
    const [delId, setDelId] = useState(null);

    useEffect(() => {
        dispatch(listProductCategories());
    }, []);

    const styleShow = {
        display: 'block',
        paddingRight: '17px'
    }

    const styleHide = {
        display: 'none',
        paddingRight: '17px'
    }

    const deleteProductCategory = () => {
        console.log(delId);
        delId && dispatch(deleteProductCategoryAction(delId));
        setModal(false);
    }

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <nav aria-label="breadcrumb" className="">
                    <ol className="breadcrumb breadcrumb-style1 p-1">
                        <li className="breadcrumb-item">
                            <Link to="/admin">Admin</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <Link to="/admin/product-categories">Product Categories</Link>
                        </li>
                    </ol>
                </nav>

                <nav className="navbar navbar-expand-lg" style={{ marginTop: '-62px', width: '30%', float: 'right' }}>
                    <ul className="navbar-nav ms-lg-auto">
                        <li className="nav-item">
                            <Link to="/admin/product-categories/add" className="nav-link btn mx-1 btn-success">
                                <span> <i className='bx bxs-plus-circle'></i>&nbsp;Add New</span>
                            </Link>
                        </li>
                    </ul>
                </nav>
                <div className="row" id="basic-table">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">
                                    Product Categories
                                </h5>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Product Category</th>
                                                <th>Sub Categories</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {productCategories && productCategories.map(productCategory => {
                                                return !productCategory.isDeleted && <tr key={productCategory._id}>
                                                    <td>{productCategory.name}</td>
                                                    <td className="text-bold-500">
                                                        {productCategory?.subCategories.map(cat => `${cat.name}, `)}
                                                    </td>
                                                    <td>
                                                        <Link to={`/admin/product-categories/${productCategory._id}`}>
                                                            <i className="badge-circle badge-circle-light-secondary bx bx-edit font-medium-1" />
                                                        </Link>
                                                        <a href="javascript:void(0);" onClick={() => { setModal(true); setDelId(productCategory._id) }}>
                                                            <i className="badge-circle badge-circle-light-secondary bx bx-trash font-medium-1" />
                                                        </a>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade text-left ${modal ? 'show' : ''}`} id="danger" tabIndex={-1} aria-labelledby="myModalLabel120" style={modal ? styleShow : styleHide} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-danger">
                            <h5 className="modal-title white" id="myModalLabel120">Danger!</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={() => { setModal(false) }}>
                                <i className="bx bx-x" />
                            </button>
                        </div>
                        <div className="modal-body">
                            Are you sure! You want to delete this Product Category?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light-secondary" data-dismiss="modal"
                                onClick={() => { setModal(false) }}>
                                <i className="bx bx-x d-block d-sm-none" />
                                <span className="d-none d-sm-block">Cancel</span>
                            </button>
                            <button type="button" className="btn btn-danger ml-1" data-dismiss="modal"
                                onClick={() => { deleteProductCategory(delId) }}>
                                <i className="bx bx-check d-block d-sm-none" />
                                <span className="d-none d-sm-block">Accept</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ListProductCategories;
