import axios from "axios";
import { alertMessage, base_url, check_response_status } from "../utils";
import { GET_PRODUCT_NETWORK, LIST_PRODUCT_NETWORKS } from "./types";

// list  productNetworks
export const listProductNetworks = () => async dispatch => {

    axios.get(`${base_url}productNetworks`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                // console.log(' productNetworks', response.data);
                dispatch({
                    type: LIST_PRODUCT_NETWORKS,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//get  productNetwork
export const getProductNetwork = (id) => async dispatch => {
    axios.get(`${base_url}productNetworks/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                // console.log(' productNetworks', response.data);
                dispatch({
                    type: GET_PRODUCT_NETWORK,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

// add  productNetwork
export const addProductNetworkAction = (productNetwork) => async dispatch => {

    axios.post(`${base_url}productNetworks`,
    productNetwork,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log(' productNetwork', response);
            if(response.status == 201) {
                dispatch(alertMessage(response.data));
                dispatch(listProductNetworks());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//update Device Color
export const updateProductNetworkAction = (id, productNetwork) => async dispatch => {
    axios.put(`${base_url}productNetworks/${id}`,
    productNetwork,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log(' productNetwork', response);
            if(response.status == 200) {
                dispatch(alertMessage({flag: 'success', message: "Device Color Upadated Successfully."}));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

export const deleteProductNetworkAction = (id) => async dispatch => {
    // console.log(' productNetwork', id)
    axios.delete(`${base_url}productNetworks/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log('delete', response);
            if(response.status == 200) {
                dispatch(alertMessage({flag: 'success', message: response.data.message}));
                dispatch(listProductNetworks());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}