import { Link } from 'react-router-dom'

const InventoryTransferView = () => {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-style1 p-1">
                    <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">
                        <Link to="/admin/inventory-transfer">Inventory Transfer</Link>
                    </li>
                    <li className="breadcrumb-item active">
                        Inventory Transfer Order
                    </li>
                </ol>
            </nav>
            <div className="row-fluid d-flex justify-content-between">
                <div className="span9">
                    <h3 className="txt24 m0">Transfer Order # TO-275
                    </h3>

                </div>
                <div className="span3">
                    <div className="btn-group pull-right btn-group2 ">
                        <a className="btn dropdown-toggle btn-secondary" data-toggle="dropdown" href="#">
                            <i className="icon-setting3" /> Action
                            <span className="caret" />
                        </a>
                        <ul className="dropdown-menu">
                            <li><a id="print_report_btn" target="_blank" style={{ cursor: 'pointer' }}>Print </a></li>
                        </ul>

                    </div>
                </div>
            </div>
            <div className="caps txt22 open-eb class=" status_label label style={{ fontSize: 30, padding: '12px 15px' }}> <b>Completed</b></div>




            <div className="box2 container-xxl border pt-5">
                <div className="row-fluid row">
                    <div className="span4 col-sm">
                        <div className="infoBox">
                            <h3 className="txt24 m0">Transferred By </h3>
                            <hr className="hr" />
                            <br /> <h4 className="txt18 open-bold">
                                Mr Fix - Corstorphine
                            </h4>
                            <p className="txt15"><br />
                                , United Kingdom<br />
                                Email: mrfixs.edinburgh@gmail.com<br />
                            </p>
                        </div>
                    </div>
                    <div className="span4 col-sm">
                        <div className="infoBox">
                            <h3 className="txt24 m0">Transferred To </h3>
                            <hr className="hr" />
                            <br />
                            <h4 className="txt18 open-bold">Mrfix EXTRA</h4>
                            <p className="txt15">15 Home St<br />
                                Edinburgh , United Kingdom<br />
                                Email: mrfixs007@gmail.com<br />
                            </p>
                        </div>
                    </div>
                    <div className="span4 col-sm">
                        <div className="infoBox">
                            <h3 className="txt24 m0">Transfer Order Summary </h3>
                            <hr className="hr" />
                            <table width={200} border={1} className="table tos">
                                <tbody className='bg-white'>
                                    <tr>
                                        <td>Date </td>
                                        <td style={{ whiteSpace: "nowrap" }}>14 Dec, 2023 10:37 AM </td>
                                    </tr>
                                    <tr>
                                        <td>Created By </td>
                                        <td>Ambreen sidiq </td>
                                    </tr>
                                    <tr>
                                        <td>Received By </td>
                                        <td>Ambreen sidiq </td>
                                    </tr>
                                    <tr>
                                        <td>Shipping Date </td>
                                        <td />
                                    </tr>
                                    <tr>
                                        <td>Shipping Charges </td>
                                        <td>£0.00</td>
                                    </tr>
                                    <tr>
                                        <td>Tracking ID </td>
                                        <td />
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-responsive container-full">
            <table className="table table-bordered w-full my-4" style={{ whiteSpace: "nowrap" }} >
                <thead>
                    <tr className='bg-light'>
                        <th scope="col">SKU/Id</th>
                        <th scope="col">Manufacturer</th>
                        <th scope="col">Device</th>
                        <th scope="col">Item Name</th>
                        <th scope="col">Shipped Qty</th>
                        <th scope="col">Total Cost Price</th>
                        <th scope="col">Received Qty</th>
                        <th scope="col">Remaining Qty</th>
                        <th scope="col">Satus</th>
                    </tr>
                </thead>
                <tbody className='bg-white'>

                    <tr className="odd"  >
                        <td className=''>203182743</td>
                        <td>Samsung</td>
                        <td>Galaxy S9</td>
                        <td >Samsung S9 | Screen Replacement</td>
                        <td>1</td>
                        <td>Mrfix EXTRA</td>
                        <td>

                            £40.00
                        </td>
                        <td >	0</td>
                        <td>
                            <div className="nav-link btn btn-success" >
                                <span>
                                    Completed </span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
           
            
            <div>

                <h5 className=''>
                    <span className='border-bottom border-3'><b>Transfer Order Notes</b></span></h5>

            </div>

            <div>

                <div className="card-body">
                    <div className='border border-2 bg-white'>
                        <div>
                            <h5 className='px-2 pb-2 my-2'>Admin History</h5>

                        </div>


                    </div>

                    <div className="table-responsive container-full">

                        <table className="table table-bordered w-full" style={{ whiteSpace: "nowrap" }}>
                            <thead>
                                <tr className='bg-light'>
                                    <th scope="col">Date</th>
                                    <th scope="col">Task</th>
                                    <th scope="col">Employee</th>
                                    <th scope="col">Changes</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr className="odd"  >
                                    <td className=''>2023-12-14 10:38:25</td>
                                    <td>Update</td>
                                    <td>siddiqueambreen273@gmail.com</td>
                                    <td >Status From Transferred To Completed</td>


                                </tr>


                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

        </div>

    )
}

export default InventoryTransferView



