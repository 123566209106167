import axios from "axios";
import { alertMessage, base_url, check_response_status } from "../utils";
import { GET_USER_ROLE, LIST_USER_ROLES } from "./types";

// list  roles
export const listUserRoles = () => async dispatch => {

    axios.get(`${base_url}roles`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                console.log(' roles', response.data);
                dispatch({
                    type: LIST_USER_ROLES,
                    payload: response.data
                });
            }
            else if (response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

// list  permissions
export const getPermissions = () => async dispatch => {

    axios.get(`${base_url}roles/permissions`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                console.log('permissions', response.data);
            }
            else if (response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//get  productCondition
export const getUserRole = (id) => async dispatch => {
    axios.get(`${base_url}roles/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {

                dispatch({
                    type: GET_USER_ROLE,
                    payload: response.data
                });
            }
            else if (response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

// add  productCondition
export const addUserRoleAction = (role) => async dispatch => {

    axios.post(`${base_url}roles`,
        role,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {

            if (response.status == 201) {
                dispatch(alertMessage(response.data));
                dispatch(listUserRoles());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//update Device Color
export const updateUserRoleAction = (id, productCondition) => async dispatch => {
    axios.put(`${base_url}roles/${id}`,
        productCondition,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {

            if (response.status == 200) {
                dispatch(alertMessage({ flag: 'success', message: "Device Color Upadated Successfully." }));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

export const deleteUserRoleAction = (id) => async dispatch => {

    axios.delete(`${base_url}roles/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {

            if (response.status == 200) {
                dispatch(alertMessage({ flag: 'success', message: response.data.message }));
                dispatch(listUserRoles());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

