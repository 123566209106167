import { BrowserRouter, Routes, Route } from "react-router-dom";
import Forgotpassword from "./components/auth/Forgotpassword";
import Resetpassowrd from "./components/auth/Resetpassword";
import Signin from "./components/auth/Signin";
import Signup from "./components/auth/Signup";
import Dashboard from "./components/pages/Dashboard";

function App() {
    return (
  
        <BrowserRouter>
            <Routes>
                <Route path="/admin/*" element={<Dashboard />} > </Route>
                <Route path="/signup" element={<Signup /> }  > </Route>
                <Route path="/signin" element={<Signin />}  > </Route>
                <Route path="/forgot-password" element={<Forgotpassword />}></Route>
                <Route path="/reset-password" element={<Resetpassowrd />}></Route>
                <Route path="/" element={<Signin />}  > </Route>
            </Routes>
        </BrowserRouter>
     
    );
}

export default App;
