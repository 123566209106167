import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom'
import user1 from ".././images/user1.png"
import Accordion from 'react-bootstrap/Accordion'
import Form from 'react-bootstrap/Form'
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { motion } from 'framer-motion';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab'
import Dropdown from 'react-bootstrap/Dropdown'
import { useDispatch, useSelector } from 'react-redux'
import { listTaxClasses } from '../../../../actions/taxClassActions'
import { listRepairCategories } from '../../../../actions/repairCategoriesActions'
import { listDevices } from '../../../../actions/deviceActions'
import { listEmployees } from '../../../../actions/employeeActions'
import { listStores } from '../../../../actions/storeActions';
import { listProductConditions } from '../../../../actions/productConditionActions';
import { listProductNetworks } from '../../../../actions/productNetworkActions';
import { listProductPhysicalLocations } from '../../../../actions/productPhysicalLocationActions';
import { addTicketAction } from '../../../../actions/ticketActions';
import { listManufacturers } from '../../../../actions/manufacturerActions';
import AddCustomer from "./AddCustomer"
import AddSpecialParts from './AddSpecialPArts';
import { alertMessage, base_url, check_response_status } from '../../../../utils';
import axios from 'axios';
import AsyncSelect from "react-select/async";
import "./AddTickets.css"
// import InventoryItems from './InventoryItems';

const AddTicket = () => {
    const closeRef = useRef();
    const [repairs, setRepair] = useState([{
        repairCategory: "",
        manufacturers: "",
        addtionalNotes: "",
        device: "",
        deviceNetwork: "",
        devicePyshicalLocation: "",
        diagnosticNotes: "",
        internalNotes: "",
        serial: "",
        imei: "",
        leadStatus: "",
    }
    ])
    const [unlocking, setUnlocking] = useState([
        {
            unlockNetwork: "",
            unlockImei: "",
            unlockCode: "",
            unlockPrice: "",
            unlockCostPrice: "",
            unlockDiscount: "",
            unlockTax: "",
            unlockGST: "",
            unlockStatus: "",
            unlockViaApi: "",
        }
    ])
    const [inventoryItem, setInventoryItem] = useState([
        {
            itemSku: "",
            itemName: "",
            itemDescription: "",
            itemNotes: "",
            itemQuantity: "",
            itemPrice: "",
            itemDiscount: "",
            itemTax: "",
            itemGST: ""
        }
    ])
    const [spareParts, setSpareParts] = useState([
        {
            repairDevice: "",
            sku: "",
            name: "",
            warrantyType: "",
            warrantyNumber: "",
            status: "",
            unitCost: "",
        }
    ])

    const dispatch = useDispatch()
    const taxClasses = useSelector(state => state.taxClass.taxClasses);
    const repairCategories = useSelector(state => state.repairCategory.repairCategories);
    const devices = useSelector(state => state.device.devices);
    const employees = useSelector(state => state.employee.employees);
    const stores = useSelector(state => state.store.stores);
    const productConditions = useSelector((state) => state.productCondition.productConditions);
    const productNetworks = useSelector((state) => state.productNetwork.productNetworks);
    const physicalLocations = useSelector((state) => state.productPhysicalLocation.productPhysicalLocations);
    const manufacturers = useSelector(state => state.manufacturer.manufacturers);
    const repairServices = useSelector(state => state.repairService.repairServices);
    const orders = useSelector((state) => state.order.orders);
    const customer = useSelector((state) => state.ticket.current_customer);
    const leadStatuses = useSelector(state => state.order.orderStatus);

    useLayoutEffect(() => {
        dispatch(listTaxClasses());
        dispatch(listRepairCategories());
        dispatch(listDevices());
        dispatch(listEmployees());
        dispatch(listStores());
        dispatch(listProductConditions());
        dispatch(listProductNetworks());
        dispatch(listProductPhysicalLocations());
        dispatch(listManufacturers());
    }, []);

    const [email, setEmail] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [_id, setId] = useState("")
    const [mobile, setMobile] = useState("")
    const [address, setAddress] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [zip, setZip] = useState("")
    const [country, setCountry] = useState("")
    const [organization, setOrganization] = useState("")
    const [createdOn, setCreatedOn] = useState("")

    const [showNotes, setShowNotes] = useState(false);
    const handleNotesClose = () => setShowNotes(false);
    const handleNotesShow = () => setShowNotes(true);

    const [showDeviceIssue, setDeviceIssue] = useState(false);
    const handleDeviceIssueClose = () => setDeviceIssue(false);
    const handleDeviceIssueShow = () => setDeviceIssue(true);

    const [showPreRepair, setShowPreRepair] = useState(false);
    const handlePreRepairClose = () => setShowPreRepair(false);
    const handlePreRepairShow = () => setShowPreRepair(true);

    const [priceShow, setPriceShow] = useState(false)
    const handlePriceShow = () => setPriceShow(true)
    const handlePriceClose = () => setPriceShow(false)

    const [key, setKey] = useState('home');

    const [repairCategory, setRepairCategory] = useState("");
    const [repairCategoryOptions, setRepairCategoryOptions] = useState([])
    const [manufacturer, setManufacturer] = useState("");
    const [manufacturerOptions, setManufacturerOptions] = useState([])
    const [device, setDevice] = useState("");
    const [deviceOptions, setDeviceOptions] = useState([])
    const [leadStatus, setLeadStatus] = useState("");
    const [leadStatusOptions, setLeadStatusOptions] = useState([])

    const [taxClass, setTaxClass] = useState("")
    const [taxClassOptions, setTaxClassOptions] = useState([]);
    const [phone, setPhone] = useState("")
    const [phoneOptions, setPhoneOptions] = useState([{ "phone": "phone" }, { "mobile": "mobile" }])
    const [hearUs, setHearUs] = useState()
    const [countryName, setCountryName] = useState("")
    const [preRepairDeviceConditionOptions, setPreRepairDeviceConditionOptions] = useState([])
    const [deviceNetwork, setDeviceNetwork] = useState("")
    const [deviceNetworkOptions, setDeviceNetworkOptions] = useState([])
    const [devicePyshicalLocation, setDevicePyshicalLocation] = useState("")
    const [devicePyshicalLocationOptions, setDevicePyshicalLocationOptions] = useState([])
    const [assignedTo, setAssignedTo] = useState("")
    const [assignedToOptions, setAssignedToOptions] = useState([])
    const [sku, setSku] = useState("")
    const [item, setItem] = useState("")
    const [itemDescription, setItemDescription] = useState("")
    const [notes, setNotes] = useState("")
    const [quantity, setQuantity] = useState("")
    const [location, setLocation] = useState("")
    const [locationOptions, setLocationOptions] = useState([])
    const [warrantyType, setWarrantyType] = useState("")
    const [warrantyNumber, setWarrantyNumber] = useState("")
    const [orderStatusOptions, setOrderStatusOptions] = useState([])

    const [searchedCustomer, setSearchedCustomer] = useState()

    useEffect(() => {
        setId(customer?._id)
        setFirstName(customer?.firstName)
        setLastName(customer?.lastName)
        setEmail(customer?.email)
        setAddress(customer?.address)
        setCity(customer?.city)
        setZip(customer?.postCode)
        setState(customer?.state)
        setMobile(customer?.mobile)
    }, [customer])


    useEffect(() => {
        const opts = [];
        repairCategories && repairCategories.map(repairCategory => {
            if (!repairCategory.isDeleted) {
                opts.push({ obj: repairCategory, value: repairCategory._id, label: repairCategory.name });
            }
        });
        setRepairCategoryOptions(opts);
    }, [repairCategories]);

    useEffect(() => {
        if (repairCategory) {
            setManufacturer("");
            setDevice("");
            // setRepairService("");
            const opts = [];
            manufacturers && manufacturers.map(manufacturer => {
                if (!manufacturer.isDeleted && manufacturer.repairCategories._id == repairCategory?.value) {
                    opts.push({ obj: manufacturer, value: manufacturer._id, label: manufacturer.name });
                }
            });
            setManufacturerOptions(opts);
        }
    }, [repairCategory]);

    useEffect(() => {
        const opts = [];
        devices && devices.map(device => {
            if (!device.isDeleted) {
                opts.push({ obj: device, value: device._id, label: device.name });
            }
        });
        setDeviceOptions(opts);
    }, [devices]);

    useEffect(() => {
        if (manufacturer) {
            setDevice("");
            // setRepairService("");
            dispatch(listDevices(manufacturer?.value))
        }
    }, [manufacturer])

    useEffect(() => {
        const opts = []
        leadStatuses && leadStatuses.map(status => {
            if (!status.isDeleted) {
                opts.push({ obj: status, value: status[1], label: status[0] })
            }
        });
        setLeadStatusOptions(opts)
    }, [leadStatuses]);


    useEffect(() => {
        const opts = [];
        taxClasses && taxClasses.map(taxClass => {
            if (!taxClass.isDeleted) {
                opts.push({ obj: taxClass, value: taxClass._id, label: taxClass.class });
            }
        });
        setTaxClassOptions(opts);
    }, [taxClasses]);

    useEffect(() => {
        const opts = [];
        orders && orders.map(order => {
            if (!order.isDeleted) {
                opts.push({ obj: order, value: order._id, label: order.orderStatus });
            }
        });
        setOrderStatusOptions(opts);
    }, [orders]);

    useEffect(() => {
        const opts = [];
        physicalLocations && physicalLocations.map(physicalLocation => {
            if (!physicalLocation.isDeleted) {
                opts.push({ obj: physicalLocation, value: physicalLocation._id, label: physicalLocation.name });
            }
        });
        setDevicePyshicalLocationOptions(opts);
    }, [physicalLocations]);

    useEffect(() => {
        const opts = [];
        productConditions && productConditions.map(productCondition => {
            if (!productCondition.isDeleted) {
                opts.push({ obj: productCondition, value: productCondition._id, label: productCondition.name });
            }
        });
        setPreRepairDeviceConditionOptions(opts);
    }, [productConditions]);



    useEffect(() => {
        const opts = [];
        employees && employees.map(employee => {
            if (!employee.isDeleted) {
                opts.push({ obj: employee, value: employee._id, label: employee.firstName + ' ' + employee.lastName });
            }
        });
        setAssignedToOptions(opts);
    }, [employees]);

    useEffect(() => {
        const opts = [];
        stores && stores.map(store => {
            if (!store.isDeleted) {
                opts.push({ obj: store, value: store._id, label: store.name });
            }
        });
        setLocationOptions(opts);
    }, [stores]);

    useEffect(() => {
        const opts = [];
        productNetworks && productNetworks.map(productNetwork => {
            if (!productNetwork.isDeleted) {
                opts.push({ obj: productNetwork, value: productNetwork._id, label: productNetwork.name });
            }
        });
        setDeviceNetworkOptions(opts);
    }, [productNetworks]);

    const handleAddRepaireRow = () => {
        const newRow = {
            repairCategory: repairCategory.obj,
            manufacturers: manufacturer.obj,
            repairStatus: "",
            addtionalNotes: "",
            device: "",
            deviceNetwork: "",
            devicePyshicalLocation: "",
            diagnosticNotes: "",
            internalNotes: "",
            serial: "",
            imei: "",
            assignedTo: "",
            pickUptime: "",
            repairedandCollectedTime: "",
            discount: "",
            price: "",
            discountReason: "",
            taxAmount: "",
            gstClass: ""
        };
        setRepair((prevData) => [...prevData, newRow]);
    };


    const handleAddInventoryItemRow = () => {
        const newRow = {
            itemSku: "",
            itemName: "",
            itemDescription: "",
            itemNotes: "",
            itemQuantity: "",
            itemPrice: "",
            itemDiscount: "",
            itemTax: "",
            itemGST: ""
        }
        setInventoryItem((prevData) => [...prevData, newRow])
    }

    const handleAddUnlockRow = () => {
        const newRow = {
            unlockNetwork: "",
            unlockImei: "",
            unlockCode: "",
            unlockPrice: "",
            unlockCostPrice: "",
            unlockDiscount: "",
            unlockTax: "",
            unlockGST: "",
            unlockStatus: "",
            unlockViaApi: "",
        }
        setUnlocking((preData) => [...preData, newRow])
    }

    const handleServiceDetailSelectValue = (value, rowIndex, dataIndex) => {
        const newObj = {
            name: value.obj?.name,
            _id: value.obj?._id,
            label: value?.label
        }
        const updatedData = [...repairs];
        updatedData[rowIndex][dataIndex] = newObj
        setRepair(updatedData);
    };

    const handleServiceDetailSelectValueRepairCategory = (value, rowIndex, dataIndex) => {
        setRepairCategory(value)
        const newObj = {
            name: value.obj?.name,
            _id: value.obj?._id,
            label: value?.label
        }
        const updatedData = [...repairs];
        updatedData[rowIndex][dataIndex] = newObj
        setRepair(updatedData);
    };

    const handleServiceDetailSelectValueManufacturer = (value, rowIndex, dataIndex) => {
        setManufacturer(value)
        const newObj = {
            name: value.obj?.name,
            _id: value.obj?._id,
            label: value?.label
        }
        const updatedData = [...repairs];
        updatedData[rowIndex][dataIndex] = newObj
        setRepair(updatedData);
    };

    const handleServiceDetailValue = (value, rowIndex, dataIndex) => {
        const updatedData = [...repairs];
        updatedData[rowIndex][dataIndex] = value;
        setRepair(updatedData);
    }

    const handleInventoryItemValue = (value, rowIndex, dataIndex) => {
        const updatedData = [...inventoryItem];
        updatedData[rowIndex][dataIndex] = value;
        setInventoryItem(updatedData);

    };
    const handleInventoryItemSelectValue = (value, rowIndex, dataIndex) => {
        const newObj = {
            name: value?.obj.class,
            _id: value.obj?._id,
            label: value?.label
        }
        const updatedData = [...inventoryItem];
        updatedData[rowIndex][dataIndex] = newObj
        setInventoryItem(updatedData);
    };

    const handleUnlockValue = (value, rowIndex, dataIndex) => {
        const updatedData = [...unlocking];
        updatedData[rowIndex][dataIndex] = value;
        setUnlocking(updatedData);
    };

    const handleUnlockSelectValue = (value, rowIndex, dataIndex) => {
        const newObj = {
            name: value?.obj.name,
            _id: value.obj?._id,
            label: value?.label
        }
        const updatedData = [...unlocking];
        updatedData[rowIndex][dataIndex] = newObj;
        setUnlocking(updatedData);
    };

    // Function to add a new ticket
    const handleAddTicket = (e) => {
        e.preventDefault();
        const newTicket = {
            customer: {
                _id,
                email,
                firstName,
                lastName,
                mobile,
                address,
                city,
                state,
                zip,
                country,
                organization,
            },
            location: {
                _id: location ? location.obj._id : null,
                location: location ? location.obj.name : "",
            },
            createdOn: createdOn,
            hearUs: hearUs,
            repairs: repairs,
            inventoryItem: inventoryItem,
            unlocking: unlocking,
            spareParts: spareParts
        }
        dispatch(addTicketAction(newTicket))
        console.log(newTicket, "new ticke============================")

    };

    const getText = (filter) => {
        return new Promise((resolve, reject) => {
            axios.post(`${base_url}users/search`,
                { reqFilters: { firstName: filter, lastName: filter, mobile: filter, email: filter } },
                {
                    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
                })
                .then(response => {
                    if (response.status == 200 || response.status == 201) {
                        resolve(setsearchOptions(response.data))
                    }
                    else if (response.status == 400) {
                        dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
                    } else {
                        dispatch(check_response_status(response))
                    }
                })
                .catch(err => {
                    dispatch(check_response_status(err.response));
                });
        });
    }

    const setsearchOptions = (customers) => {
        const opts = [];
        customers && customers.map(customer => {
            if (!customer.isDeleted) {
                opts.push({ obj: customer, value: customer._id, label: customer.firstName + ' ' + customer.lastName + '\n' + customer.mobile + '\n' + customer.email });
            }
        });
        return opts;
    }

    const searchCustomer = (obj) => {
        const customer = obj.obj;
        console.log('device', customer);
        setSearchedCustomer(customer.firstName + ' ' + customer.lastName);
        setFirstName(customer.firstName);
        setLastName(customer.lastName);
        setMobile(customer.mobile);
        setEmail(customer.email);
        setAddress(customer.address);
        setCity(customer.city);
        setCountry(customer.country);
        setZip(customer.postCode);
        setOrganization(customer.organization);
        // navigate(`/repairs/${device?.manufactures?.repairCategories?.slug}/${device?.manufactures?.slug}/${device?.slug}`);
    }

    const handleToggle = (rowIndex) => {
        const updatedData = [...repairs];
        updatedData[rowIndex].show = !updatedData[rowIndex].show;
        setRepair(updatedData);
    };
    const handleToggle1 = (rowIndex) => {
        const updatedData = [...repairs];
        updatedData[rowIndex].showTime = !updatedData[rowIndex].showTime;
        setRepair(updatedData);
    };
    const priceHandleToggle = (rowIndex) => {
        const updatedData = [...repairs]
        updatedData[rowIndex].showPrice = !updatedData[rowIndex].showPrice
        setRepair(updatedData)
    }

    const handleTaxToggle = (rowIndex) => {
        const updatedData = [...repairs]
        updatedData[rowIndex].showTax = !updatedData[rowIndex].showTax
        setRepair(updatedData)
    }

    const handleUnlockTaxToggle = (rowIndex) => {
        const updatedData = [...unlocking]
        updatedData[rowIndex].showUnlockTax = !updatedData[rowIndex].showUnlockTax
        setUnlocking(updatedData)
    }
    const handleSparePartsValue = (value, rowIndex, dataIndex) => {
        const updatedData = [...spareParts];
        updatedData[rowIndex][dataIndex] = value;
        setSpareParts(updatedData);
    };
    const handleSparePartsSelectValue = (value, rowIndex, dataIndex) => {
        const newObj = {
            name: value?.obj.name,
            _id: value.obj?._id,
            label: value?.label
        }
        const updatedData = [...spareParts];
        updatedData[rowIndex][dataIndex] = newObj
        setSpareParts(updatedData);
    };
    // Framer Motion animation
    const inputVariants = {
        hidden: { opacity: 1, y: 0 },
        visible: { opacity: 0, y: 5, transition: { duration: 0.5 } }
    }
    return (
        <>
            <div className="container-fluid flex-grow-1 container-p-y">
                <form onSubmit={handleAddTicket}>
                    <nav aria-label="breadcrumb my-2 py-2" >
                        <ol className="breadcrumb breadcrumb-style1">
                            <li className="breadcrumb-item">
                                <Link to="/admin" className="text-primary">Admin</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/admin/manage-tickets">Manage Tickets</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="#" className="active">12345</Link>
                            </li>
                        </ol>
                    </nav>
                    <div className="row my-2 py-2 bg-white">
                        <div class="col-lg-12 d-flex justify-content-between align-items-center">
                            <div className='d-flex'>
                                <Link to="/admin/manage-tickets/add" className="btn btn-primary me-1">
                                    <span> <i className='bx bx-spreadsheet'></i>&nbsp;Make Invoice</span>
                                </Link>
                                <Dropdown>
                                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                        <i class='bx bx-calculator'></i>&nbsp; Signature
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Link to="/admin/manage-tickets/add" className="btn btn-success mx-1">
                                    <span> <i class='bx bx-edit-alt' ></i>&nbsp;Make New Ticket</span>
                                </Link>
                                <a href="#" className="btn btn-primary me-1" data-bs-toggle="modal" data-bs-target="#modalTop1">
                                    <span> &nbsp;Add Special Part</span>
                                </a>
                                <AddSpecialParts />
                            </div>
                            <div>
                                <button type="submit" className="btn btn-primary"><span>Save Ticket&nbsp; <i class='bx bxs-cloud-download' ></i></span></button>
                            </div>
                        </div>
                    </div>
                    <div className="row my-2 py-2 bg-white">
                        <div className="mb-2 col-lg-6 border">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                    </Accordion.Header>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className='d-flex justify-content-evenly align-items-center'>
                                            <img src={user1} alt="" />
                                            <h4 className="fw-bold mx-2 mt-4">
                                                <a href="javascript:void(0);" className="">Walkin Customer</a>
                                            </h4>
                                        </div>
                                        <div>
                                            <div className="d-sm-flex d-block justify-content-center align-items-center">
                                                <AsyncSelect
                                                    value={searchedCustomer}
                                                    onChange={(e) => searchCustomer(e)}
                                                    loadOptions={(e) => getText(e)}
                                                    defaultOptions
                                                    placeholder="Search Customer"
                                                    
                                                />
                                                {/* <input type="search" className="form-control  mx-1" placeholder="Search Customer" /> */}
                                                <a href="#" className="btn btn-success d-flex rounded ms-1 py-2 px-2 justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#modalTop">
                                                    <i className='bx bxs-plus-circle'></i>
                                                    <span>&nbsp;New</span>
                                                </a>
                                                <AddCustomer />
                                            </div>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <Accordion.Body>
                                        <div className="row">
                                            {/* <div className="mb-2 col-lg-6">
                                                <Form.Label className='fw-bold'>Tax Class</Form.Label>
                                                <Select value={taxClass} onChange={(e) => setTaxClass(e)} options={taxClassOptions} />
                                            </div> */}
                                            <div className="mb-2 col-lg-6">
                                                <Form.Label className='fw-bold'>First Name</Form.Label>
                                                <Form.Control type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                            </div>
                                            <div className="mb-2 col-lg-6">
                                                <Form.Label className='fw-bold'>Last Name</Form.Label>
                                                <Form.Control type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                            </div>
                                            <div className="mb-2 col-lg-6">
                                                <Form.Label className='fw-bold'>Email</Form.Label>
                                                <Form.Control type="email" placeholder="name@example.com" value={email} onChange={(e) => { setEmail(e.target.value); console.log(e.target.value) }} />
                                            </div>
                                            <div className="mb-2 col-lg-6">
                                                <Form.Label className='fw-bold'>Phone</Form.Label>
                                                <Form.Control type="tel" placeholder="Phone" value={mobile} onChange={(e) => setMobile(e.target.value)} />
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <h5 className="fw-bold mx-2 mt-4">
                                            <a href="javascript:void(0);" className="">More Details</a>
                                        </h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="mb-2 col-lg-6">
                                                <Form.Label className='fw-bold'>Address</Form.Label>
                                                <Form.Control type="text" placeholder="Enter Address" value={address} onChange={(e) => setAddress(e.target.value)} />
                                            </div>
                                            <div className="mb-2 col-lg-6">
                                                <Form.Label className='fw-bold'>Postcode</Form.Label>
                                                <Form.Control type="text" placeholder="Enter Postcode" value={zip} onChange={(e) => setZip(e.target.value)} />
                                            </div>
                                            <div className="mb-2 col-lg-6">
                                                <Form.Label className='fw-bold'>City</Form.Label>
                                                <Form.Control type="text" placeholder="Enter City Name" value={city} onChange={(e) => setCity(e.target.value)} />
                                            </div>
                                            <div className="mb-2 col-lg-6">
                                                <Form.Label className='fw-bold'>State</Form.Label>
                                                <Form.Control type="text" placeholder="Enter State Name" value={state} onChange={(e) => setState(e.target.value)} />
                                            </div>

                                            <div className="mb-2 col-lg-6">
                                                <Form.Label className='fw-bold'>Country</Form.Label>
                                                <Form.Control type="text" placeholder="Enter Country Name" value={country} onChange={(e) => setCountry(e.target.value)} />
                                            </div>
                                            <div className="mb-2 col-lg-6">
                                                <Form.Label className='fw-bold'>Organization</Form.Label>
                                                <Form.Control type="text" placeholder="Enter Organization Name" value={organization} onChange={(e) => setOrganization(e.target.value)} />
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="mb-2 col-lg-6 border">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                    </Accordion.Header>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <div className='d-flex justify-content-start align-items-center'>
                                            <img src={user1} alt="" />
                                            <h4 className="fw-bold mx-2 mt-4">
                                                <a href="javascript:void(0);" className="">Ticket Summary</a>
                                            </h4>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="mb-2 col-lg-6">
                                                <Form.Label className='fw-bold'>Created At</Form.Label>
                                                <Form.Control type="date" placeholder="" value={createdOn} onChange={(e) => setCreatedOn(e.target.value)} />
                                            </div>
                                            <div className="mb-2 col-lg-6">
                                                <Form.Label className='fw-bold'>Location&nbsp;<sup className='text-danger fs-6'>*</sup></Form.Label>
                                                <Select
                                                    value={location}
                                                    onChange={(e) => setLocation(e)}
                                                    options={locationOptions}
                                                />
                                            </div>
                                            <div className="mb-2 col-lg-6">
                                                <Form.Label className='fw-bold'>Last Modified</Form.Label>
                                                <Form.Control type="date" />
                                            </div>

                                            <div className="mb-2 col-lg-6">
                                                <Form.Label className='fw-bold'>How did you hear about us&nbsp;<sup className='text-danger fs-6'>*</sup></Form.Label>
                                                <select class="select2 form-select form-select" data-allow-clear="true"
                                                    value={hearUs}
                                                    onChange={e => setHearUs(e.target.value)}>
                                                    <option value="">Select an option</option>
                                                    <option value="FACEBOOK">Facebook</option>
                                                    <option value="BING">Bing</option>
                                                    <option value="SEARCH_ENGINE">Search Engine</option>
                                                    <option value="REFERRAL">Referral</option>
                                                    <option value="GOOGLE_ADS">Google Ads</option>
                                                    <option value="OTHER">Other</option>
                                                </select>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                    <div className='row my-2 py-2 bg-white text-center'>
                        <Accordion defaultActiveKey="1">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <h4>
                                        <a href="javascript:void(0);" className="">Service Details</a>
                                    </h4>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className="table-responsive-xxl">
                                        <table className="table-bordered d-p table-lg ticket-table">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Repair Category</th>
                                                    <th>Manufacturer</th>
                                                    <th>Device</th>
                                                    <th>Device Issue</th>
                                                    <th>Custom Fields</th>
                                                    <th>IMEI/Serial</th>
                                                    <th>Repair Status</th>
                                                    <th>Repair Time</th>
                                                    <th>Assigned To</th>
                                                    <th>Pickup Time</th>
                                                    <th>QTY</th>
                                                    <th>Price</th>
                                                    <th>Tax</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {repairs.map((row, rowIndex) => {
                                                    const inputVariants = {
                                                        hidden: { opacity: 1, y: 0 },
                                                        visible: { opacity: 0, y: 5, transition: { duration: 0.5 } },
                                                    };
                                                    return (
                                                        <tr key={rowIndex}>
                                                            <td>{row.id}</td>
                                                            <td>
                                                                <Select value={row.repairCategory} onChange={(e) => handleServiceDetailSelectValueRepairCategory(e, rowIndex, 'repairCategory')} options={repairCategoryOptions} className='me-1' />
                                                                <a href="#" variant="primary" className='mt-2 pt-2' onClick={handleNotesShow}><small>Add Notes</small></a>
                                                                <Modal show={showNotes} onHide={handleNotesClose} centered >
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>Add Notes</Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <Form.Control as="textarea" rows={5} className='bg-warning-100' value={row.addtionalNotes} onChange={(e) => handleServiceDetailValue(e.target.value, rowIndex, 'addtionalNotes')} />
                                                                    </Modal.Body>
                                                                    <Modal.Footer>
                                                                        <Button variant="primary" onClick={handleNotesClose} className='mx-auto'>
                                                                            Save &nbsp; <i class='bx bxs-cloud-download' ></i>
                                                                        </Button>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                            </td>
                                                            <td>
                                                                <Select value={row.manufacturers} onChange={(e) => handleServiceDetailSelectValueManufacturer(e, rowIndex, 'manufacturers')} options={manufacturerOptions} className='me-1' />
                                                            </td>
                                                            <td>
                                                                <Select value={row.device} onChange={(e) => handleServiceDetailSelectValue(e, rowIndex, 'device')} options={deviceOptions} className='me-1' />
                                                                <a href="#" variant="primary" className='mt-2 pt-2' onClick={handlePreRepairShow}><small>Pre-Repair Device Condition</small></a>
                                                                <Modal show={showPreRepair} onHide={handlePreRepairClose} dialogClassName="modal-90w" size='lg' centered >
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>Modal heading</Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <div className="row w-100">
                                                                            <div className="col-lg-3">
                                                                                <Form.Label>Pre-Repair Device Condition</Form.Label>
                                                                                <Select value={row.preRepairDeviceCondition} onChange={(e) => handleServiceDetailSelectValue(e, rowIndex, 'preRepairDeviceCondition')} options={preRepairDeviceConditionOptions}
                                                                                    className='me-1' />
                                                                            </div>
                                                                            <div className="col-lg-6">

                                                                            </div>
                                                                            <div className="col-lg-3">
                                                                                <div>
                                                                                    <Form.Label>Device Network</Form.Label>
                                                                                    <Select value={row.deviceNetwork} onChange={(e) => handleServiceDetailSelectValue(e, rowIndex, 'deviceNetwork')} options={deviceNetworkOptions} className='mb-2' />
                                                                                </div>
                                                                                <div>
                                                                                    <Form.Label>Device Physical Location</Form.Label>
                                                                                    <Select value={row.devicePyshicalLocation} onChange={(e) => handleServiceDetailSelectValue(e, rowIndex, 'devicePyshicalLocation')} options={devicePyshicalLocationOptions} className='mb-2' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Modal.Body>
                                                                    <Modal.Footer>
                                                                        <Button variant="primary" onClick={handlePreRepairClose} className='mx-auto'>
                                                                            Save &nbsp; <i class='bx bxs-cloud-download' ></i>
                                                                        </Button>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                            </td>
                                                            <td>
                                                                <Form.Control type='text' value={row.deviceIssue} onChange={(e) => handleServiceDetailValue(e.target.value)} readOnly />
                                                                <a href="#" variant="primary" className='mt-2 pt-2' onClick={handleDeviceIssueShow}><small>Add Diagnostic/Internal Notes</small></a>
                                                                <Modal show={showDeviceIssue} onHide={handleDeviceIssueClose} centered size='lg'>
                                                                    <Modal.Header closeButton>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <div className="row w-100">
                                                                            <div className="col-lg-6">
                                                                                <Form.Label>Diagnostic Notes <i class='bx bxs-flag' title='remove-flag'></i></Form.Label>
                                                                                <Form.Control as="textarea" value={row.diagnosticNotes} onChange={(e) => handleServiceDetailValue(e.target.value, rowIndex, 'diagnosticNotes')} rows={5} className='bg-warning-100' />
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <Form.Label>Internal Notes</Form.Label>
                                                                                <Form.Control as="textarea" value={row.internalNotes} rows={5} onChange={(e) => handleServiceDetailValue(e.target.value, rowIndex, 'internalNotes')} className='bg-warning-100' />
                                                                            </div>
                                                                        </div>
                                                                    </Modal.Body>

                                                                    <Modal.Footer>
                                                                        <Button variant="primary" onClick={handleDeviceIssueClose} className='mx-auto'>
                                                                            Save &nbsp; <i class='bx bxs-cloud-download' ></i>
                                                                        </Button>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                            </td>
                                                            <td></td>
                                                            <td>
                                                                <motion.div variants={inputVariants} initial="hidden" animate={row.show ? "visible" : "hidden"}>
                                                                    <Form.Control type="text" placeholder="IMEI Number" value={row.imei} onChange={(e) => handleServiceDetailValue(e.target.value, rowIndex, "imei")} />
                                                                    <motion.a href="#id" initial="hidden" animate="visible" onClick={() => handleToggle(rowIndex)} className='mt-2 pt-2'>
                                                                        {row.show ? <small> IMEI Number </small> : <small>Serial Number</small>}
                                                                    </motion.a>
                                                                </motion.div>
                                                                <motion.div variants={inputVariants} initial="hidden" animate={!row.show ? "visible" : "hidden"}>
                                                                    <Form.Control type="text" placeholder="Serial Number" value={row.serial} onChange={(e) => handleServiceDetailValue(e.target.value, rowIndex, "serial")} />
                                                                    <motion.a href="#id" className='mt-2 pt-2' initial="hidden" animate="visible" onClick={() => handleToggle(rowIndex)}>
                                                                        {row.show ? <small> IMEI Number </small> : <small>Serial Number</small>}
                                                                    </motion.a>
                                                                </motion.div>
                                                            </td>
                                                            <td>
                                                                <Select value={row.repairStatus} onChange={(e) => handleServiceDetailSelectValue(e, rowIndex, "repairStatus")} options={leadStatusOptions} />
                                                                {/* {leadStatuses.map(status => <option value={status[1]}>{status[0]}</option>)}
                                                    </select> */}
                                                            </td>
                                                            <td>missing time</td>

                                                            <td>
                                                                <Select value={row.assignedTo} onChange={(e) => handleServiceDetailSelectValue(e, rowIndex, "assignedTo")} options={assignedToOptions} className='me-1' />
                                                            </td>
                                                            <td>
                                                                <motion.div variants={inputVariants} initial="hidden" animate={row.showTime ? "visible" : "hidden"}>
                                                                    <Form.Control type="date" value={row.pickUptime} onChange={(e) => handleServiceDetailValue(e.target.value, rowIndex, "pickUptime")} />
                                                                    <motion.a href="#id" initial="hidden" animate="visible" onClick={() => handleToggle1(rowIndex)} className='mt-2 pt-2'>
                                                                        {row.showTime ? <small>Repaired & Collected </small> : <small>Pickup Time</small>}
                                                                    </motion.a>
                                                                </motion.div>
                                                                <motion.div variants={inputVariants} initial="hidden" animate={!row.showTime ? "visible" : "hidden"}>
                                                                    <Form.Control type="time" value={row.repairedandCollectedTime} onChange={(e) => handleServiceDetailValue(e.target.value, rowIndex, "repairedandCollectedTime")} />
                                                                    <motion.a href="#id" className='mt-2 pt-2' initial="hidden" animate="visible" onClick={() => handleToggle1(rowIndex)}>
                                                                        {row.showTime ? <small>Repaired & Collected </small> : <small>Pickup Time</small>}
                                                                    </motion.a>
                                                                </motion.div>
                                                            </td>
                                                            <td>
                                                                <Form.Control type="text" readOnly />
                                                            </td>
                                                            <td>
                                                                <motion.div variants={inputVariants} initial="hidden" animate={row.showPrice ? "visible" : "hidden"}>
                                                                    <Form.Control type='text' value={row.price} onChange={(e) => handleServiceDetailValue(e.target.value, rowIndex, "price")} />
                                                                    <motion.a href="#id" initial="hidden" animate="visible" onClick={() => priceHandleToggle(rowIndex)} className='mt-2 pt-2'>
                                                                        {row.showTime ? <small>Price</small> : <small>Discount</small>}
                                                                    </motion.a>
                                                                </motion.div>
                                                                <motion.div variants={inputVariants} initial="hidden" animate={!row.showPrice ? "visible" : "hidden"}>
                                                                    <Form.Control type="text" value={row.discount} onChange={(e) => handleServiceDetailValue(e.target.value, rowIndex, "discount")} />
                                                                    <motion.a href="#id" className='mt-2 pt-2' initial="hidden" animate="visible" onClick={() => priceHandleToggle(rowIndex)}>
                                                                        {row.showPrice ? <small>Price</small> : <small>Discount</small>} |
                                                                        <a href="#" variant="primary" className='mt-2 pt-2' onClick={handlePriceShow}><small>Add Reason</small> </a>
                                                                        <Modal show={priceShow} onHide={handlePriceClose} centered >
                                                                            <Modal.Header closeButton>
                                                                                <Modal.Title><Form.Label>Add Discount Reason</Form.Label></Modal.Title>
                                                                            </Modal.Header>
                                                                            <Modal.Body>
                                                                                <Form.Control as="textarea" rows={5} className='bg-warning-100' value={row.discountReason} onChange={(e) => handleServiceDetailValue(e.target.value, rowIndex, 'discountReason')} />
                                                                            </Modal.Body>
                                                                            <Modal.Footer>
                                                                                <Button variant="primary" onClick={handlePriceClose} className='mx-auto'>
                                                                                    Save &nbsp; <i class='bx bxs-cloud-download' ></i>
                                                                                </Button>
                                                                            </Modal.Footer>
                                                                        </Modal>
                                                                    </motion.a>
                                                                </motion.div>
                                                            </td>
                                                            <td>
                                                                <motion.div variants={inputVariants} initial="hidden" animate={row.showTax ? "visible" : "hidden"}>
                                                                    <Form.Control type="text" value={row.taxAmount} onChange={(e) => handleServiceDetailValue(e.target.value, rowIndex, "taxAmount")} />
                                                                    <motion.a href="#id" initial="hidden" animate="visible" onClick={() => handleTaxToggle(rowIndex)} className='mt-2 pt-2'>
                                                                        {row.showTax ? <small>GST class</small> : <small>GST Tax</small>}
                                                                    </motion.a>
                                                                </motion.div>
                                                                <motion.div variants={inputVariants} initial="hidden" animate={!row.showTax ? "visible" : "hidden"}>
                                                                    <Select value={row.gstClass} onChange={(e) => handleServiceDetailSelectValue(e, rowIndex, "gstClass")} options={taxClassOptions} />
                                                                    <motion.a href="#id" className='mt-2 pt-2' initial="hidden" animate="visible" onClick={() => handleTaxToggle(rowIndex)}>
                                                                        {row.showTax ? <small>Tax Class</small> : <small>Tax Classs</small>}
                                                                    </motion.a>
                                                                </motion.div>
                                                            </td>
                                                            <td>
                                                                <button onClick={handleAddRepaireRow} className='btn btn-primary py-2 px-2' style={{ width: "100px" }}><small>Add New Row</small></button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>

                    
                    <div className='row my-2 py-2 bg-white text-center'>
                        <Accordion defaultActiveKey="1">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <h4>
                                        <a href="javascript:void(0);" className="">Inventory Items</a>
                                    </h4>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className="table-responsive-xxl">
                                        <table className="table-bordered d-p table-lg ticket-table">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Repair Category</th>
                                                    <th>Manufacturer</th>
                                                    <th>Device</th>
                                                    <th>Device Issue</th>
                                                    <th>Custom Fields</th>
                                                    <th>IMEI/Serial</th>
                                                    <th>Repair Status</th>
                                                    <th>Repair Time</th>
                                                    <th>Assigned To</th>
                                                    <th>Pickup Time</th>
                                                    <th>QTY</th>
                                                    <th>Price</th>
                                                    <th>Tax</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {repairs.map((row, rowIndex) => {
                                                    const inputVariants = {
                                                        hidden: { opacity: 1, y: 0 },
                                                        visible: { opacity: 0, y: 5, transition: { duration: 0.5 } },
                                                    };
                                                    return (
                                                        <tr key={rowIndex}>
                                                            <td>{row.id}</td>
                                                            <td>
                                                                <Select value={row.repairCategory} onChange={(e) => handleServiceDetailSelectValueRepairCategory(e, rowIndex, 'repairCategory')} options={repairCategoryOptions} className='me-1' />
                                                                <a href="#" variant="primary" className='mt-2 pt-2' onClick={handleNotesShow}><small>Add Notes</small></a>
                                                                <Modal show={showNotes} onHide={handleNotesClose} centered >
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>Add Notes</Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <Form.Control as="textarea" rows={5} className='bg-warning-100' value={row.addtionalNotes} onChange={(e) => handleServiceDetailValue(e.target.value, rowIndex, 'addtionalNotes')} />
                                                                    </Modal.Body>
                                                                    <Modal.Footer>
                                                                        <Button variant="primary" onClick={handleNotesClose} className='mx-auto'>
                                                                            Save &nbsp; <i class='bx bxs-cloud-download' ></i>
                                                                        </Button>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                            </td>
                                                            <td>
                                                                <Select value={row.manufacturers} onChange={(e) => handleServiceDetailSelectValueManufacturer(e, rowIndex, 'manufacturers')} options={manufacturerOptions} className='me-1' />
                                                            </td>
                                                            <td>
                                                                <Select value={row.device} onChange={(e) => handleServiceDetailSelectValue(e, rowIndex, 'device')} options={deviceOptions} className='me-1' />
                                                                <a href="#" variant="primary" className='mt-2 pt-2' onClick={handlePreRepairShow}><small>Pre-Repair Device Condition</small></a>
                                                                <Modal show={showPreRepair} onHide={handlePreRepairClose} dialogClassName="modal-90w" size='lg' centered >
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>Modal heading</Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <div className="row w-100">
                                                                            <div className="col-lg-3">
                                                                                <Form.Label>Pre-Repair Device Condition</Form.Label>
                                                                                <Select value={row.preRepairDeviceCondition} onChange={(e) => handleServiceDetailSelectValue(e, rowIndex, 'preRepairDeviceCondition')} options={preRepairDeviceConditionOptions}
                                                                                    className='me-1' />
                                                                            </div>
                                                                            <div className="col-lg-6">

                                                                            </div>
                                                                            <div className="col-lg-3">
                                                                                <div>
                                                                                    <Form.Label>Device Network</Form.Label>
                                                                                    <Select value={row.deviceNetwork} onChange={(e) => handleServiceDetailSelectValue(e, rowIndex, 'deviceNetwork')} options={deviceNetworkOptions} className='mb-2' />
                                                                                </div>
                                                                                <div>
                                                                                    <Form.Label>Device Physical Location</Form.Label>
                                                                                    <Select value={row.devicePyshicalLocation} onChange={(e) => handleServiceDetailSelectValue(e, rowIndex, 'devicePyshicalLocation')} options={devicePyshicalLocationOptions} className='mb-2' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Modal.Body>
                                                                    <Modal.Footer>
                                                                        <Button variant="primary" onClick={handlePreRepairClose} className='mx-auto'>
                                                                            Save &nbsp; <i class='bx bxs-cloud-download' ></i>
                                                                        </Button>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                            </td>
                                                            <td>
                                                                <Form.Control type='text' value={row.deviceIssue} onChange={(e) => handleServiceDetailValue(e.target.value)} readOnly />
                                                                <a href="#" variant="primary" className='mt-2 pt-2' onClick={handleDeviceIssueShow}><small>Add Diagnostic/Internal Notes</small></a>
                                                                <Modal show={showDeviceIssue} onHide={handleDeviceIssueClose} centered size='lg'>
                                                                    <Modal.Header closeButton>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <div className="row w-100">
                                                                            <div className="col-lg-6">
                                                                                <Form.Label>Diagnostic Notes <i class='bx bxs-flag' title='remove-flag'></i></Form.Label>
                                                                                <Form.Control as="textarea" value={row.diagnosticNotes} onChange={(e) => handleServiceDetailValue(e.target.value, rowIndex, 'diagnosticNotes')} rows={5} className='bg-warning-100' />
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <Form.Label>Internal Notes</Form.Label>
                                                                                <Form.Control as="textarea" value={row.internalNotes} rows={5} onChange={(e) => handleServiceDetailValue(e.target.value, rowIndex, 'internalNotes')} className='bg-warning-100' />
                                                                            </div>
                                                                        </div>
                                                                    </Modal.Body>

                                                                    <Modal.Footer>
                                                                        <Button variant="primary" onClick={handleDeviceIssueClose} className='mx-auto'>
                                                                            Save &nbsp; <i class='bx bxs-cloud-download' ></i>
                                                                        </Button>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                            </td>
                                                            <td></td>
                                                            <td>
                                                                <motion.div variants={inputVariants} initial="hidden" animate={row.show ? "visible" : "hidden"}>
                                                                    <Form.Control type="text" placeholder="IMEI Number" value={row.imei} onChange={(e) => handleServiceDetailValue(e.target.value, rowIndex, "imei")} />
                                                                    <motion.a href="#id" initial="hidden" animate="visible" onClick={() => handleToggle(rowIndex)} className='mt-2 pt-2'>
                                                                        {row.show ? <small> IMEI Number </small> : <small>Serial Number</small>}
                                                                    </motion.a>
                                                                </motion.div>
                                                                <motion.div variants={inputVariants} initial="hidden" animate={!row.show ? "visible" : "hidden"}>
                                                                    <Form.Control type="text" placeholder="Serial Number" value={row.serial} onChange={(e) => handleServiceDetailValue(e.target.value, rowIndex, "serial")} />
                                                                    <motion.a href="#id" className='mt-2 pt-2' initial="hidden" animate="visible" onClick={() => handleToggle(rowIndex)}>
                                                                        {row.show ? <small> IMEI Number </small> : <small>Serial Number</small>}
                                                                    </motion.a>
                                                                </motion.div>
                                                            </td>
                                                            <td>
                                                                <Select value={row.repairStatus} onChange={(e) => handleServiceDetailSelectValue(e, rowIndex, "repairStatus")} options={leadStatusOptions} />
                                                                {/* {leadStatuses.map(status => <option value={status[1]}>{status[0]}</option>)}
                                                    </select> */}
                                                            </td>
                                                            <td>missing time</td>

                                                            <td>
                                                                <Select value={row.assignedTo} onChange={(e) => handleServiceDetailSelectValue(e, rowIndex, "assignedTo")} options={assignedToOptions} className='me-1' />
                                                            </td>
                                                            <td>
                                                                <motion.div variants={inputVariants} initial="hidden" animate={row.showTime ? "visible" : "hidden"}>
                                                                    <Form.Control type="date" value={row.pickUptime} onChange={(e) => handleServiceDetailValue(e.target.value, rowIndex, "pickUptime")} />
                                                                    <motion.a href="#id" initial="hidden" animate="visible" onClick={() => handleToggle1(rowIndex)} className='mt-2 pt-2'>
                                                                        {row.showTime ? <small>Repaired & Collected </small> : <small>Pickup Time</small>}
                                                                    </motion.a>
                                                                </motion.div>
                                                                <motion.div variants={inputVariants} initial="hidden" animate={!row.showTime ? "visible" : "hidden"}>
                                                                    <Form.Control type="time" value={row.repairedandCollectedTime} onChange={(e) => handleServiceDetailValue(e.target.value, rowIndex, "repairedandCollectedTime")} />
                                                                    <motion.a href="#id" className='mt-2 pt-2' initial="hidden" animate="visible" onClick={() => handleToggle1(rowIndex)}>
                                                                        {row.showTime ? <small>Repaired & Collected </small> : <small>Pickup Time</small>}
                                                                    </motion.a>
                                                                </motion.div>
                                                            </td>
                                                            <td>
                                                                <Form.Control type="text" readOnly />
                                                            </td>
                                                            <td>
                                                                <motion.div variants={inputVariants} initial="hidden" animate={row.showPrice ? "visible" : "hidden"}>
                                                                    <Form.Control type='text' value={row.price} onChange={(e) => handleServiceDetailValue(e.target.value, rowIndex, "price")} />
                                                                    <motion.a href="#id" initial="hidden" animate="visible" onClick={() => priceHandleToggle(rowIndex)} className='mt-2 pt-2'>
                                                                        {row.showTime ? <small>Price</small> : <small>Discount</small>}
                                                                    </motion.a>
                                                                </motion.div>
                                                                <motion.div variants={inputVariants} initial="hidden" animate={!row.showPrice ? "visible" : "hidden"}>
                                                                    <Form.Control type="text" value={row.discount} onChange={(e) => handleServiceDetailValue(e.target.value, rowIndex, "discount")} />
                                                                    <motion.a href="#id" className='mt-2 pt-2' initial="hidden" animate="visible" onClick={() => priceHandleToggle(rowIndex)}>
                                                                        {row.showPrice ? <small>Price</small> : <small>Discount</small>} |
                                                                        <a href="#" variant="primary" className='mt-2 pt-2' onClick={handlePriceShow}><small>Add Reason</small> </a>
                                                                        <Modal show={priceShow} onHide={handlePriceClose} centered >
                                                                            <Modal.Header closeButton>
                                                                                <Modal.Title><Form.Label>Add Discount Reason</Form.Label></Modal.Title>
                                                                            </Modal.Header>
                                                                            <Modal.Body>
                                                                                <Form.Control as="textarea" rows={5} className='bg-warning-100' value={row.discountReason} onChange={(e) => handleServiceDetailValue(e.target.value, rowIndex, 'discountReason')} />
                                                                            </Modal.Body>
                                                                            <Modal.Footer>
                                                                                <Button variant="primary" onClick={handlePriceClose} className='mx-auto'>
                                                                                    Save &nbsp; <i class='bx bxs-cloud-download' ></i>
                                                                                </Button>
                                                                            </Modal.Footer>
                                                                        </Modal>
                                                                    </motion.a>
                                                                </motion.div>
                                                            </td>
                                                            <td>
                                                                <motion.div variants={inputVariants} initial="hidden" animate={row.showTax ? "visible" : "hidden"}>
                                                                    <Form.Control type="text" value={row.taxAmount} onChange={(e) => handleServiceDetailValue(e.target.value, rowIndex, "taxAmount")} />
                                                                    <motion.a href="#id" initial="hidden" animate="visible" onClick={() => handleTaxToggle(rowIndex)} className='mt-2 pt-2'>
                                                                        {row.showTax ? <small>GST class</small> : <small>GST Tax</small>}
                                                                    </motion.a>
                                                                </motion.div>
                                                                <motion.div variants={inputVariants} initial="hidden" animate={!row.showTax ? "visible" : "hidden"}>
                                                                    <Select value={row.gstClass} onChange={(e) => handleServiceDetailSelectValue(e, rowIndex, "gstClass")} options={taxClassOptions} />
                                                                    <motion.a href="#id" className='mt-2 pt-2' initial="hidden" animate="visible" onClick={() => handleTaxToggle(rowIndex)}>
                                                                        {row.showTax ? <small>Tax Class</small> : <small>Tax Classs</small>}
                                                                    </motion.a>
                                                                </motion.div>
                                                            </td>
                                                            <td>
                                                                <button onClick={handleAddRepaireRow} className='btn btn-primary py-2 px-2' style={{ width: "100px" }}><small>Add New Row</small></button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>

                    {/* <InventoryItems /> */}

                    <div className='row my-2 py-2 bg-white text-center'>
                        <Accordion defaultActiveKey="1">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <h4>
                                        <a href="javascript:void(0);" className="">Unlocking</a>
                                    </h4>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className="table-responsive-xxl">
                                        <table className="table-bordered d-p table-lg ticket-table">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Network</th>
                                                    <th>IMEI</th>
                                                    <th>Unlock Code</th>
                                                    <th>Price</th>
                                                    <th>Cost Price</th>
                                                    <th>Discount</th>
                                                    <th>Tax</th>
                                                    <th>Unlock Status</th>
                                                    <th>Unlock Via Api</th>
                                                    <th>Actions</th>
                                                    {/*
                                            <th>Linr Total</th>
                                            */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {unlocking.map((row, rowIndex) => {
                                                    const inputVariants = {
                                                        hidden: { opacity: 1, y: 0 },
                                                        visible: { opacity: 0, y: 5, transition: { duration: 0.5 } },
                                                    };
                                                    return (
                                                        <tr key={rowIndex}>
                                                            <td>{rowIndex}</td>
                                                            <td>
                                                                <Select value={row.unlockNetwork} onChange={(e) => handleUnlockSelectValue(e, rowIndex, 'unlockNetwork')} options={deviceNetworkOptions} className='mb-2' />
                                                            </td>
                                                            <td>
                                                                <Form.Control type="text" placeholder='Search Item' value={row.unlockImei} onChange={(e) => handleUnlockValue(e.target.value, rowIndex, "unlockImei")} />
                                                            </td>
                                                            <td>
                                                                <Form.Control as="textarea" value={row.unlockCode} rows={5} onChange={(e) => handleUnlockValue(e.target.value, rowIndex, 'unlockCode')} className='bg-warning-100' />
                                                            </td>
                                                            <td>
                                                                <Form.Control type="number" placeholder='Search Item' value={row.unlockPrice} onChange={(e) => handleUnlockValue(e.target.value, rowIndex, "unlockPrice")} />
                                                            </td>
                                                            <td>
                                                                <Form.Control type="number" placeholder='Search Item' value={row.unlockCostPrice} onChange={(e) => handleUnlockValue(e.target.value, rowIndex, "unlockCostPrice")} />
                                                            </td>
                                                            <td>
                                                                <Form.Control type="number" placeholder='Search Item' value={row.unlockDiscount} onChange={(e) => handleUnlockValue(e.target.value, rowIndex, "unlockDiscount")} />
                                                            </td>

                                                            <td>
                                                                <motion.div variants={inputVariants} initial="hidden" animate={row.showUnlockTax ? "visible" : "hidden"}>
                                                                    <Form.Control type="text" value={row.unlockTax} onChange={(e) => handleUnlockValue(e.target.value, rowIndex, "unlockTax")} />
                                                                    <motion.a href="#id" initial="hidden" animate="visible" onClick={() => handleUnlockTaxToggle(rowIndex)} className='mt-2 pt-2'>
                                                                        {row.showUnlockTax ? <small>GST class</small> : <small>GST Tax</small>}
                                                                    </motion.a>
                                                                </motion.div>
                                                                <motion.div variants={inputVariants} initial="hidden" animate={!row.showUnlockTax ? "visible" : "hidden"}>
                                                                    <Select value={row.unlockGST} onChange={(e) => handleUnlockSelectValue(e, rowIndex, "unlockGST")} options={taxClassOptions} />
                                                                    <motion.a href="#id" className='mt-2 pt-2' initial="hidden" animate="visible" onClick={() => handleUnlockTaxToggle(rowIndex)}>
                                                                        {row.showUnlockTax ? <small>Tax Class</small> : <small>Tax Classs</small>}
                                                                    </motion.a>
                                                                </motion.div>
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                                <button onClick={handleAddUnlockRow} className='btn btn-primary py-2 px-2' style={{ width: "100px" }}><small>Add New Row</small></button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div className='row my-2 py-2 bg-white text-center'>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey='1'>
                                <Accordion.Header>
                                    <h4>
                                        <a href='javascript:viod(0)'>Spare Parts </a>
                                    </h4>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3 text-bold" >
                                        <Tab eventKey="home" title="Ticket Items (Repair Parts Being Used)">
                                            <div className="table-responsive-xxl">
                                                <table className="table-bordered d-p table-lg ticket-table">
                                                    <thead>
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>Device|Repair Reference</th>
                                                            <th>Sku</th>
                                                            <th>Name</th>
                                                            <th>Warranty Type</th>
                                                            <th>Warranty Number</th>
                                                            <th>Status</th>
                                                            <th>Unit Cost</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {spareParts.map((row, rowIndex) => {
                                                            const inputVariants = {
                                                                hidden: { opacity: 1, y: 0 },
                                                                visible: { opacity: 0, y: 5, transition: { duration: 0.5 } },
                                                            };
                                                            return (
                                                                <tr key={rowIndex}>
                                                                    <td>{rowIndex}</td>
                                                                    <td>
                                                                        {/* {Device|Repair Reference */}
                                                                        <Select value={row.repairDevice} onChange={(e) => handleSparePartsSelectValue(e, rowIndex, 'repairDevice')} options={deviceOptions} className='mb-2' />
                                                                    </td>
                                                                    <td>
                                                                        {/* {sku} inputs text field */}
                                                                        <Form.Control type="text" placeholder='Search Item' value={row.sku} onChange={(e) => handleSparePartsValue(e.target.value, rowIndex, "sku")} />
                                                                    </td>
                                                                    <td>
                                                                        {/* {name} inputs text field */}
                                                                        <Form.Control type="text" placeholder='Search Item' value={row.name} onChange={(e) => handleSparePartsValue(e.target.value, rowIndex, "name")} />
                                                                    </td>
                                                                    <td>
                                                                        {/* {warrantyType} inputs text field */}
                                                                        <select
                                                                            value={warrantyType}
                                                                            onChange={(e) => setWarrantyType(e.target.value)}
                                                                            className="select2 form-select form-select">
                                                                            <option value="no_warranty">No Warranty</option>
                                                                            <option value="days">Days</option>
                                                                            <option value="months">Months</option>
                                                                            <option value="years">Years</option>
                                                                            <option value="lifetime">Lifetime</option>
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        {/* {warrantyNumber} inputs number field */}
                                                                        <Form.Control type="number" placeholder='Search Item' value={row.warrantyNumber} onChange={(e) => handleSparePartsValue(e.target.value, rowIndex, "warrantyNumber")} />
                                                                    </td>
                                                                    <td>
                                                                        {/* Select field  */}
                                                                        <Select value={row.status} onChange={(e) => handleSparePartsSelectValue(e, rowIndex, 'status')} options={orderStatusOptions} className='mb-2' />
                                                                    </td>
                                                                    <td>
                                                                        {/* {unitCost} inputs number field */}
                                                                        <Form.Control type="number" placeholder='Search Item' value={row.unitCost} onChange={(e) => handleSparePartsValue(e.target.value, rowIndex, "unitCost")} />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="profile" title="Special Part Order (Special Parts Being Used)">
                                            <div className="table-responsive-xxl">
                                                <table className="table-bordered d-p table-lg ticket-table">
                                                    <thead>
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>Device|Repair Reference</th>
                                                            <th>Sku</th>
                                                            <th>Name</th>
                                                            <th>Warranty Type</th>
                                                            <th>Warranty Number</th>
                                                            <th>Status</th>
                                                            <th>Unit Cost</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </form>
            </div>
        </>
    );
}
export default AddTicket;
