import inquryImg from "../images/icon7.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useLayoutEffect, useState } from "react";
import { emptyAlertMessage } from "../../../actions/globalActions";
import { listStores } from "../../../actions/storeActions";
import { listRepairCategories } from "../../../actions/repairCategoriesActions";
import Select from "react-select";
import { listManufacturers } from "../../../actions/manufacturerActions";
import { listDevices } from "../../../actions/deviceActions";
import {
    MOBILE_CONDITIONS,
    MOBILE_NETWORKS,
    MOBILE_SIZES,
} from "../../../constants";
import SellCustomerDetail from "./SellCustomerDetail";
import { addSellOrderAction } from "../../../actions/buySellOrderActions";

const SellOrderAdd = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const stores = useSelector((state) => state.store.stores);
    const repairCategories = useSelector(
        (state) => state.repairCategory.repairCategories
    );
    const manufacturers = useSelector(
        (state) => state.manufacturer.manufacturers
    );
    const devices = useSelector((state) => state.device.devices);

    const [deviceSize, setDeviceSize] = useState("");
    const [deviceNetwork, setDeviceNetwork] = useState("");
    const [deviceCondition, setDeviceCondition] = useState("");
    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        address: "",
        city: "",
        postCode: "",
        hearUs: "",
    });

    const condition = {
        excellent: 0,
        good: 1,
        faulty: 2,
        recycle: 4,
    };

    const [repairCategory, setRepairCategory] = useState("");
    const [manufacturer, setManufacturer] = useState("");
    const [device, setDevice] = useState("");
    const [store, setStore] = useState("");

    const [repairCategoryOptions, setRepairCategoryOptions] = useState([]);
    const [manufacturerOptions, setManufacturerOptions] = useState([]);
    const [deviceOptions, setDeviceOptions] = useState([]);
    const [storeOptions, setStoreOptions] = useState([]);

    useLayoutEffect(() => {
        dispatch(listRepairCategories());
        dispatch(listManufacturers());
        dispatch(listStores());
        dispatch(emptyAlertMessage());
    }, []);

    useEffect(() => {
        const opts = [];
        stores &&
            stores.map((store) => {
                if (!store.isDeleted) {
                    opts.push({
                        obj: store,
                        value: store._id,
                        label: store.name,
                    });
                }
            });
        setStoreOptions(opts);
    }, [stores]);

    useEffect(() => {
        const opts = [];
        repairCategories &&
            repairCategories.map((repairCategory) => {
                if (!repairCategory.isDeleted) {
                    opts.push({
                        obj: repairCategory,
                        value: repairCategory._id,
                        label: repairCategory.name,
                    });
                }
            });
        setRepairCategoryOptions(opts);
    }, [repairCategories]);

    useEffect(() => {
        if (repairCategory) {
            setManufacturer("");
            setDevice("");
            const opts = [];
            manufacturers &&
                manufacturers.map((manufacturer) => {
                    if (
                        !manufacturer.isDeleted &&
                        manufacturer.repairCategories._id ==
                            repairCategory?.value
                    ) {
                        opts.push({
                            obj: manufacturer,
                            value: manufacturer._id,
                            label: manufacturer.name,
                        });
                    }
                });
            setManufacturerOptions(opts);
        }
    }, [repairCategory]);

    useEffect(() => {
        const opts = [];
        devices &&
            devices.map((device) => {
                if (!device.isDeleted) {
                    opts.push({
                        obj: device,
                        value: device._id,
                        label: device.name,
                    });
                }
            });
        setDeviceOptions(opts);
    }, [devices]);

    useEffect(() => {
        if (manufacturer) {
            setDevice("");
            dispatch(listDevices(manufacturer?.value));
        }
    }, [manufacturer]);

    const addLead = (e) => {
        e.preventDefault();

        const order = {
            devices: device.obj,
            store: store.obj,
            deviceNetwork,
            deviceSize,
            deviceCondition,
            customer: user,
        }
        dispatch(addSellOrderAction(order));
        navigate("/admin/sell-orders");
    };

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <nav aria-label="breadcrumb" className="">
                    <ol className="breadcrumb p-1">
                        <li className="breadcrumb-item">
                            <Link to="/admin">Admin</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/admin/sell-orders">Sell Orders</Link>
                        </li>
                    </ol>
                </nav>
                <form onSubmit={addLead}>
                    <div className="row">
                        <div className="col-md-6 col-lg-6  mb-4 order-lg-0">
                            <SellCustomerDetail user={user} setUser={setUser} />
                        </div>
                        <div className="col-md-6 col-lg-6 mb-4 order-lg-0 mb-4 ">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-sm-flex align-items-center justify-content-between">
                                        <div>
                                            <div className="d-sm-flex">
                                                <img src={inquryImg} alt="" />
                                                <h4 className="fw-bold mx-2 mt-4">
                                                    <a
                                                        href="javascript:void(0);"
                                                        className=""
                                                    >
                                                        Order Summary
                                                    </a>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="" />
                                <div className="card-body">
                                    <div className="row">
                                        <div class="col-md-6 mb-4">
                                            <label class="form-label">
                                                Select Category
                                            </label>
                                            <Select
                                                required
                                                value={repairCategory}
                                                onChange={(e) =>
                                                    setRepairCategory(e)
                                                }
                                                options={repairCategoryOptions}
                                            />
                                        </div>
                                        <div class="col-md-6 mb-4">
                                            <label class="form-label">
                                                Select Brand
                                            </label>
                                            <Select
                                                required
                                                value={manufacturer}
                                                onChange={(e) =>
                                                    setManufacturer(e)
                                                }
                                                options={manufacturerOptions}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <label class="form-label">
                                                Select Phone
                                            </label>
                                            <Select
                                                required
                                                value={device}
                                                onChange={(e) => setDevice(e)}
                                                options={deviceOptions}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <label class="form-label">
                                                Size
                                            </label>
                                            <select
                                                required
                                                className="form-control"
                                                value={deviceSize}
                                                onChange={(e) =>
                                                    setDeviceSize(
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <option value="">
                                                    Select...
                                                </option>
                                                {device.obj?.sizes?.length > 0
                                                    ? device.obj?.sizes.map(
                                                          (size) => (
                                                              <option
                                                                  key={
                                                                      size.name
                                                                  }
                                                                  value={
                                                                      size.name
                                                                  }
                                                              >
                                                                  {size.name}
                                                              </option>
                                                          )
                                                      )
                                                    : MOBILE_SIZES.map(
                                                          (size) => (
                                                              <option
                                                                  key={
                                                                      size.name
                                                                  }
                                                                  value={
                                                                      size.name
                                                                  }
                                                              >
                                                                  {size.name}
                                                              </option>
                                                          )
                                                      )}
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <label class="form-label">
                                                Network
                                            </label>
                                            <select
                                                required
                                                className="form-control"
                                                value={deviceNetwork}
                                                onChange={(e) =>
                                                    setDeviceNetwork(
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <option value="">
                                                    Select...
                                                </option>
                                                {MOBILE_NETWORKS.map(
                                                    (network) => (
                                                        <option
                                                            key={network.name}
                                                            value={network.name}
                                                        >
                                                            {network.name}
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <label class="form-label">
                                                Condition
                                            </label>
                                            <select
                                                className="form-control"
                                                value={deviceCondition}
                                                onChange={(e) =>
                                                    setDeviceCondition(
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <option value="">
                                                    Select...
                                                </option>
                                                {MOBILE_CONDITIONS.map(
                                                    (condition) => (
                                                        <option
                                                            key={condition.name}
                                                            value={
                                                                condition.value
                                                            }
                                                        >
                                                            {condition.name}
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <label class="form-label">
                                                Store
                                            </label>
                                            <Select
                                                required
                                                value={store}
                                                onChange={(e) => setStore(e)}
                                                options={storeOptions}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <label class="form-label">
                                                Estimated Price
                                            </label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                value={ (device?.obj?.prices?.length > 0 && deviceCondition.length > 0 && deviceSize.length > 0) ?
                                                    '£' + JSON.parse(device?.obj?.prices)[deviceSize][condition[deviceCondition]] : 'TBA'}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 d-flex justify-content-end">
                        <button
                            type="button"
                            class="btn btn-primary"
                            onClick={() => navigate(-1)}
                        >
                            Cancel
                        </button>
                        <button type="submit" class="btn btn-primary mx-1">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};
export default SellOrderAdd;
