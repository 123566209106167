import axios from "axios";
import { alertMessage, base_url, check_response_status } from "../utils";
import { GET_MANUFACTURER, LIST_MANUFACTURERS } from "./types";

// register a user
export const listManufacturers = () => async dispatch => {

    axios.get(`${base_url}manufactures`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                // console.log('manufacturers', response.data);
                dispatch({
                    type: LIST_MANUFACTURERS,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//get manufacturer
export const getManufacturer = (id) => async dispatch => {
    axios.get(`${base_url}manufactures/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                // console.log('manufacturer', response.data);
                dispatch({
                    type: GET_MANUFACTURER,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

// add manufacturer
export const addManufacturerAction = (manufacturer) => async dispatch => {

    axios.post(`${base_url}manufactures`,
        manufacturer,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log('signup', response);
            if(response.status == 201) {
                dispatch(alertMessage(response.data));
                dispatch(listManufacturers());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//update Manufacturers
export const updateManufacturerAction = (id, manufacturer) => async dispatch => {
    axios.put(`${base_url}manufactures/${id}`,
        manufacturer,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log('signup', response);
            if(response.status == 200) {
                dispatch(alertMessage({flag: 'success', message: "Manufacturer Upadated Successfully."}));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

export const deleteManufacturerAction = (id) => async dispatch => {
    // console.log('del man', id)
    axios.delete(`${base_url}manufactures/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log('delete', response);
            if(response.status == 200) {
                dispatch(alertMessage({flag: 'success', message: response.data.message}));
                dispatch(listManufacturers());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}