import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { addProductNetworkAction } from "../../../actions/productNetworkActions";

const AddProductNetwork = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const [name, setName] = useState("");

    const AddProductNetwork = (e) => {
        e.preventDefault();
        const productNetwork = {
            name
        }
        dispatch(addProductNetworkAction(productNetwork));
        navigate('/admin/product-networks');
    }
    return (
        <>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="breadcrumbs-top py-4">
                {/* <h5 className="content-header-title float-left pr-1 mb-0">
                    Add New Product Network
                </h5> */}
                <div className="breadcrumb-wrapper d-none d-sm-block">
                    <ol className="breadcrumb breadcrumb-style1 p-0 mb-0 pl-1">
                        <li className="breadcrumb-item">
                            <a href="javascript:void(0);">
                                <i className="bx bx-home-alt" />
                            </a>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/admin/product-networks">Product Networks</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <a href="javascript:void(0);">Add New</a>
                        </li>
                    </ol>
                </div>
            </div>
            <section id="basic-horizontal-layouts">
                <div className="row match-height">
                    <div className="col-md-6 col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">
                                <h5 className="content-header-title float-left pr-1 mb-0">
                                    Add New Product Network
                                </h5>
                                </div>
                            </div>
                            <div className="card-body">
                                <form className="form form-horizontal" onSubmit={AddProductNetwork}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label>
                                                    Name
                                                </label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input
                                                    type="text"
                                                    id="name"
                                                    className="form-control mb-4"
                                                    name="name"
                                                    placeholder="Name"
                                                    required
                                                    value={name}
                                                    onChange={(e) => { setName(e.target.value) }}
                                                />
                                            </div>
                                            <div className="col-sm-12 d-flex justify-content-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary me-1"
                                                    onClick={() => navigate(-1)}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary "
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        </>
    );
};

export default AddProductNetwork;
