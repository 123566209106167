import axios from "axios";
import { alertMessage, base_url, check_response_status } from "../utils";
import { GET_PRODUCT_CONDITION, LIST_PRODUCT_CONDITIONS } from "./types";

// list  productConditions
export const listProductConditions = () => async dispatch => {

    axios.get(`${base_url}productConditions`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                console.log(' productConditions', response.data);
                dispatch({
                    type: LIST_PRODUCT_CONDITIONS,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//get  productCondition
export const getProductCondition = (id) => async dispatch => {
    axios.get(`${base_url}productConditions/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                // console.log(' productConditions', response.data);
                dispatch({
                    type: GET_PRODUCT_CONDITION,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

// add  productCondition
export const addProductConditionAction = (productCondition) => async dispatch => {

    axios.post(`${base_url}productConditions`,
    productCondition,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log(' productCondition', response);
            if(response.status == 201) {
                dispatch(alertMessage(response.data));
                dispatch(listProductConditions());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//update Device Color
export const updateProductConditionAction = (id, productCondition) => async dispatch => {
    axios.put(`${base_url}productConditions/${id}`,
    productCondition,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log(' productCondition', response);
            if(response.status == 200) {
                dispatch(alertMessage({flag: 'success', message: "Device Color Upadated Successfully."}));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

export const deleteProductConditionAction = (id) => async dispatch => {
    console.log(' productCondition', id)
    axios.delete(`${base_url}productConditions/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            console.log('delete', response);
            if(response.status == 200) {
                dispatch(alertMessage({flag: 'success', message: response.data.message}));
                dispatch(listProductConditions());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}