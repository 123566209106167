import React from 'react'

const AddSpecialParts = () => {
  return (
<div className="modal modal-center fade" id="modalTop1" tabIndex={-1}>
        <div className="modal-dialog">
          <form className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Add New Special Parts
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className='body'>
                <div className='row'>
                    
                </div>
            </div>
            
          </form>
        </div>
      </div>
  )
}

export default AddSpecialParts