import React from "react";
import {
    MOBILE_GRADES,
    MOBILE_NETWORKS,
    MOBILE_SIZES,
} from "../../../constants";
import { useState } from "react";
import Select from "react-select";
import { useEffect } from "react";
import { listDeviceColors } from "../../../actions/deviceColorActions";
import { useDispatch, useSelector } from "react-redux";

const SellOrderSpecifications = ({
    specifications,
    setSpecifications,
    device,
    devicePrices,
    setDevicePrices,
    handleInput,
    handlePriceInput,
}) => {
    const dispatch = useDispatch();
    const { deviceColors } = useSelector((state) => state.deviceColor);

    const [deviceColor, setDeviceColor] = useState();
    const [deviceColorOptions, setDeviceColorOptions] = useState();

    useEffect(() => {
        dispatch(listDeviceColors());
    }, []);

    useEffect(() => {
        const opts = [];
        if (device?.colors?.length > 0 && device?.colors[0].name) {
            device.colors.map((color) => {
                if (!color.isDeleted) {
                    opts.push({
                        obj: color,
                        value: color._id,
                        label: color.name,
                    });
                }
            });
        } else {
            deviceColors.map((color) => {
                if (!color.isDeleted) {
                    opts.push({
                        obj: color,
                        value: color._id,
                        label: color.name,
                    });
                }
            });
        }

        setDeviceColorOptions(opts);
    }, [device, deviceColors]);

    return (
        <div className="row">
            <div className="col-xxl">
                <div className="card mb-4">
                    <h5 className="card-header">
                        Device Prices & Specifications
                    </h5>
                    <div className="card-body">
                        <h6 className="mb-b fw-normal">1. General</h6>
                        <div className="row mb-3">
                            <div class="col-md-3">
                                <label class="form-label">Grade *</label>
                                <select
                                    required
                                    name="grade"
                                    className="form-control"
                                    value={specifications?.grade}
                                    onChange={handleInput}
                                >
                                    <option value="">Select Grade</option>
                                    {MOBILE_GRADES.map((grade) => (
                                        <option
                                            key={grade.name}
                                            value={grade.name}
                                        >
                                            {grade.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">Color *</label>
                                <Select
                                    required
                                    value={deviceColor}
                                    onChange={(e) => {
                                        setDeviceColor(e);
                                        setSpecifications((prev) => ({
                                            ...prev,
                                            ["color"]: e.obj,
                                        }));
                                    }}
                                    options={deviceColorOptions}
                                />
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">Cost Price (£) *</label>
                                <input
                                    min="1"
                                    step="0.01"
                                    type="number"
                                    name="costPrice"
                                    className="form-control"
                                    value={devicePrices?.costPrice}
                                    onChange={handlePriceInput}
                                />
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">
                                    Retail Price (£) *
                                </label>
                                <input
                                    min="1"
                                    step="0.01"
                                    required
                                    type="number"
                                    name="retailPrice"
                                    className="form-control"
                                    value={devicePrices?.retailPrice}
                                    onChange={handlePriceInput}
                                />
                            </div>
                        </div>
                        <hr className="my-4 mx-n4" />
                        <h6 className="mb-3 fw-normal">2. Display</h6>
                        <div className="row mb-3">
                            <div class="col-md-3">
                                <label class="form-label">Size</label>
                                <input
                                    type="text"
                                    name="size"
                                    className="form-control"
                                    value={specifications?.size}
                                    onChange={handleInput}
                                />
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">Type</label>
                                <input
                                    type="text"
                                    name="type"
                                    className="form-control"
                                    value={specifications?.type}
                                    onChange={handleInput}
                                />
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">Resolution</label>
                                <input
                                    type="text"
                                    name="resolution"
                                    className="form-control"
                                    value={specifications?.resolution}
                                    onChange={handleInput}
                                />
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">Protection</label>
                                <input
                                    type="text"
                                    name="protection"
                                    className="form-control"
                                    value={specifications?.protection}
                                    onChange={handleInput}
                                />
                            </div>
                        </div>
                        <hr className="my-4 mx-n4" />
                        <h6 className="mb-3 fw-normal">3. Body</h6>
                        <div className="row mb-3">
                            <div class="col-md-3">
                                <label class="form-label">Build</label>
                                <input
                                    type="text"
                                    name="build"
                                    className="form-control"
                                    value={specifications?.build}
                                    onChange={handleInput}
                                />
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">Weight</label>
                                <input
                                    type="text"
                                    name="weight"
                                    className="form-control"
                                    value={specifications?.weight}
                                    onChange={handleInput}
                                />
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">Dimensions</label>
                                <input
                                    type="text"
                                    name="dimensions"
                                    className="form-control"
                                    value={specifications?.dimensions}
                                    onChange={handleInput}
                                />
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">Sim</label>
                                <input
                                    type="text"
                                    name="sim"
                                    className="form-control"
                                    value={specifications?.sim}
                                    onChange={handleInput}
                                />
                            </div>
                        </div>
                        <hr className="my-4 mx-n4" />
                        <h6 className="mb-3 fw-normal">4. Camera</h6>
                        <div className="row mb-3">
                            <div class="col-md-3">
                                <label class="form-label">Rear Camera</label>
                                <input
                                    type="text"
                                    name="rear"
                                    className="form-control"
                                    value={specifications?.rear}
                                    onChange={handleInput}
                                />
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">Video</label>
                                <input
                                    type="text"
                                    name="video"
                                    className="form-control"
                                    value={specifications?.video}
                                    onChange={handleInput}
                                />
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">Features</label>
                                <input
                                    type="text"
                                    name="features"
                                    className="form-control"
                                    value={specifications?.features}
                                    onChange={handleInput}
                                />
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">Front Camera</label>
                                <input
                                    type="text"
                                    name="front"
                                    className="form-control"
                                    value={specifications?.front}
                                    onChange={handleInput}
                                />
                            </div>
                        </div>
                        <hr className="my-4 mx-n4" />
                        <h6 className="mb-3 fw-normal">5. Platform</h6>
                        <div className="row mb-3">
                            <div class="col-md-3">
                                <label class="form-label">OS</label>
                                <input
                                    type="text"
                                    name="os"
                                    className="form-control"
                                    value={specifications?.os}
                                    onChange={handleInput}
                                />
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">Chipset</label>
                                <input
                                    type="text"
                                    name="chipset"
                                    className="form-control"
                                    value={specifications?.chipset}
                                    onChange={handleInput}
                                />
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">CPU</label>
                                <input
                                    type="text"
                                    name="cpu"
                                    className="form-control"
                                    value={specifications?.cpu}
                                    onChange={handleInput}
                                />
                            </div>
                            <div class="col-md-3">
                                <label class="form-label">Rom, Ram</label>
                                <input
                                    type="text"
                                    name="romRam"
                                    className="form-control"
                                    value={specifications?.romRam}
                                    onChange={handleInput}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SellOrderSpecifications;
