import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { emptyMedia, uploadImage } from "../../actions/globalActions";
import { addDeviceAction } from "../../actions/deviceActions";
import { listManufacturers } from "../../actions/manufacturerActions";
import { listDeviceColors } from "../../actions/deviceColorActions";
import { base_url } from "../../utils";
import Select from 'react-select';
// import { listRepairCategories } from "../../actions/repairCategoriesActions";

const AddDevice = () => {
    const imageRef = useRef();
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const media = useSelector(state => state.global.media);

    // const repairCategories = useSelector(state => state.repairCategory.repairCategories);
    const manufacturers = useSelector(state => state.manufacturer.manufacturers);
    const deviceColors = useSelector(state => state.deviceColor.deviceColors);

    const [name, setName] = useState("");
    const [slug, setSlug] = useState("");
    const [description, setDescription] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [image, setImage] = useState("");

    const [deviceColorOptions, setDeviceColorOptions] = useState([]);
    const [manufacturerOptions, setManufacturerOptions] = useState([]);

    const [manufacturer, setManufacturer] = useState("");
    const [deviceColor, setDeviceColor] = useState("");

    useLayoutEffect(() => {
        dispatch(listManufacturers());
        dispatch(listDeviceColors());
        dispatch(emptyMedia());
    }, []);

    useEffect(() => {
        const opts = [];
        deviceColors && deviceColors.map(deviceColor => {
            if (!deviceColor.isDeleted) {
                opts.push({ obj: deviceColor, value: deviceColor._id, label: deviceColor.name });
            }
        });
        setDeviceColorOptions(opts);
    }, [deviceColors]);

    useEffect(() => {
        const opts = [];
        manufacturers && manufacturers.map(manufacturer => {
            if (!manufacturer.isDeleted) {
                opts.push({ obj: manufacturer, value: manufacturer._id, label: manufacturer.name });
            }
        });
        setManufacturerOptions(opts);
    }, [manufacturers]);

    const addDevice = (e) => {
        e.preventDefault();
        const device = {
            name,
            slug: slug ? slug : name.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase(),
            description,
            media,
            manufactures: manufacturer.obj,
            colors: deviceColor.map(color => color.obj)
        }
        dispatch(addDeviceAction(device, sortOrder));
        navigate('/admin/devices');
    }

    const updateImage = (e) => {
        if (e.files && e.files[0]) {
            setImage(e.files[0]);

            const data = new FormData()
            data.append('file', e.files[0], e.files[0].name);

            dispatch(uploadImage(data));
        }
    }

    const profileStyle = {
        width: '150px',
        height: '150px'
    }
    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="breadcrumbs-top">

                    <div className="breadcrumb-wrapper d-none d-sm-block mb-4">
                        <ol className="breadcrumb p-0 mb-0 pl-1">
                            <li className="breadcrumb-item">
                                <Link to="/admin">
                                    <i className="bx bx-home-alt" />
                                </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/admin/devices">Devices</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <a href="javascript:void(0);">Add New</a>
                            </li>
                        </ol>
                    </div>
                </div>
                <section id="basic-horizontal-layouts">
                    <div className="row match-height">
                        <div className="col-md-6 col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">
                                        Add New Device
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <form className="form form-horizontal" onSubmit={addDevice}>
                                        <div className="form-body">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label>
                                                        Manufacturer
                                                    </label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <Select
                                                        required
                                                        value={manufacturer}
                                                        onChange={(e) => setManufacturer(e)}
                                                        options={manufacturerOptions}
                                                        className="mb-4"
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <label>
                                                        Device Name
                                                    </label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <input
                                                        type="text"
                                                        id="name"
                                                        className="mb-4 form-control"
                                                        name="name"
                                                        placeholder="Name"
                                                        required
                                                        value={name}
                                                        onChange={(e) => { setName(e.target.value) }}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <label>
                                                        Slug (Optional)
                                                    </label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <input
                                                        type="text"
                                                        className="mb-4 form-control"
                                                        placeholder="Slug"

                                                        value={slug}
                                                        onChange={(e) => { setSlug(e.target.value) }}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <label>
                                                        Sort Order
                                                    </label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <input
                                                        type="number"
                                                        className="mb-4 form-control"
                                                        placeholder="Sort Order"
                                                        value={sortOrder}
                                                        required
                                                        onChange={(e) => { setSortOrder(e.target.value) }}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <label>
                                                        Description
                                                    </label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <textarea
                                                        type="text"
                                                        className="mb-4 form-control"
                                                        placeholder="Description"
                                                        rows={4}
                                                        value={description}
                                                        onChange={(e) => { setDescription(e.target.value) }}
                                                    ></textarea>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>
                                                        Colors
                                                    </label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <Select
                                                        required
                                                        isMulti={true}
                                                        value={deviceColor}
                                                        onChange={(e) => setDeviceColor(e)}
                                                        options={deviceColorOptions}
                                                        className="mb-4"
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Picture</label>
                                                </div>
                                                <div className="col-md-8 mb-1">
                                                    <img style={profileStyle} src={media ? base_url + "media/" + media.filename : "/app-assets/images/dummy.jpg"} alt={name} />
                                                </div>
                                                <div className="col-md-4">
                                                    <label></label>
                                                </div>
                                                <div className="col-md-8">
                                                    <div class="button-wrapper">
                                                        <label for="upload" class="btn btn-primary me-2 mb-4" tabindex="0">
                                                            <span class="d-none d-sm-block">Upload new photo</span>
                                                            <i class="bx bx-upload d-block d-sm-none"></i>
                                                            <input
                                                                type="file"
                                                                id="upload"
                                                                class="account-file-input"
                                                                value={image.value} required
                                                                hidden
                                                                accept="image/png, image/jpeg"
                                                                onChange={(e) => updateImage(e.target)}
                                                            />
                                                        </label></div>
                                                </div>
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary mx-1"
                                                        onClick={() => navigate(-1)}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary "
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    );
};

export default AddDevice;
