import { GET_REPAIR_CATEGORY, LIST_REPAIR_CATEGORIES } from "../actions/types";

const initialState = {
    repairCategories: [],
    error: null,
    repairCategory: null
};
export default (state = initialState, { type, payload }) => {

    switch (type) {
        case LIST_REPAIR_CATEGORIES: 
        return {
            ...state,
            repairCategories: payload
        }
        case GET_REPAIR_CATEGORY:
            return {
                ...state,
                repairCategory: payload
            }
        default:
            return state;
    }
}
