import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import { emptyAlertMessage } from "../../actions/globalActions";
import { getOrderAction, listInvoiceStatusAction, listOrderStatusAction, updateOrderAction } from "../../actions/orderActions";
import { listRepairServices } from "../../actions/repairServiceActions";
import { getDateTime } from "../../utils";
import Alert from "../elements/Alert"
import './ViewOrder.css'
const ViewOrder = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const order = useSelector(state => state.order.order);
    const orderStatuses = useSelector(state => state.order.orderStatus);
    const invoiceStatuses = useSelector(state => state.order.invoiceStatus);
    const repairServices = useSelector((state) => state.repairService.repairServices);
    const { id } = useParams('id');

    const [repairServiceOptions, setRepairServiceOptions] = useState([]);
    const [orderStatus, setOrderStatus] = useState();
    const [invoiceStatus, setInvoiceStatus] = useState();
    const [comments, setComments] = useState("");
    const [returnDate, setReturnDate] = useState();
    const [repairService, setRepairService] = useState("");

    useLayoutEffect(() => {
        dispatch(emptyAlertMessage());
    }, []);

    useEffect(() => {
        if (id) {
            dispatch(getOrderAction(id));
            dispatch(listOrderStatusAction());
            dispatch(listInvoiceStatusAction());
            dispatch(listRepairServices());
        }
    }, [id]);

    useEffect(() => {
        const opts = [];
        repairServices && repairServices.map(service => {
            if (!service.isDeleted) {
                opts.push({ obj: service, value: service._id, label: service.problem.name });
            }
        });
        setRepairServiceOptions(opts);
    }, [repairServices]);

    useEffect(() => {
        if (order) {
            setReturnDate((new Date(order.returnDate)).toISOString().substring(0,10))
            setComments(order.notes);
            setOrderStatus(order.orderStatus);
            setInvoiceStatus(order.invoiceStatus);
            setRepairService(order?.repairServices.map(service => { return { obj: service, value: service._id, label: service?.problem?.name } }));
        }
    }, [order])

    const updateStatus = (e) => {
        e.preventDefault();

        const orderObj = {
            orderStatus,
            invoiceStatus,
            returnDate,
            repairServices: repairService.map(service => service.obj),
            notes: comments
        }
        dispatch(updateOrderAction(id, orderObj));
    }

    return (
        <>
    <div className="container-xxl flex-grow-1 container-p-y">
        <div className="breadcrumbs-top">          
            <div className="breadcrumb-wrapper d-none d-sm-block mb-4">
                <ol className="breadcrumb p-0 mb-0 pl-1">
                    <li className="breadcrumb-item">
                        <Link to="/admin">
                            <i className="bx bx-home-alt" />admin
                        </Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/admin/orders">Orders</Link>
                    </li>
                    <li className="breadcrumb-item active">
                        <a href="javascript:void(0);">View</a>
                    </li>
                </ol>
            </div>
            <h5 className="mb-2">
                Update Order Status
            </h5>
        </div>
            <Alert />
            {order && <section className="users-view">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <table className="table table-borderless">
                                    <tbody>
                                        <tr>
                                            <td>Category:</td>
                                            <td className="users-view-username">{order.repairCategories.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Manufacturer:</td>
                                            <td className="users-view-name">{order.manufactures.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Device:</td>
                                            <td className="users-view-email">{order.devices.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Repair Services:</td>
                                            <td>{order.repairServices.map(service => service.problem.name).toString()}</td>
                                        </tr>
                                        <tr>
                                            <td>Price:</td>
                                            <td>£{order.repairServices.reduce((previousValue, currentValue) => previousValue + Number(currentValue.retailPrice), 0)
                                                // order.repairServices.map(service => service.problem.name).toString()
                                            }</td>
                                        </tr>
                                        <tr>
                                            <td>Service Type:</td>
                                            <td>{order.serviceType == "walk_in" ? 'Walk In' : 'Mail In'}</td>
                                        </tr>
                                        {order?.storeTiming && <tr>
                                            <td>Appointment Time:</td>
                                            <td>{getDateTime(order?.storeTiming)}</td>
                                        </tr>}
                                        <tr>
                                            <td>Order Status:</td>
                                            <td>{order.orderStatus}</td>
                                        </tr>
                                        <tr>
                                            <td>Invoice Status:</td>
                                            <td>{order.invoiceStatus}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-6">
                                <table className="table table-borderless">
                                    <tbody>
                                        <tr>
                                            <td>Name:</td>
                                            <td className="users-view-username">{order.orderDetails.firstName + " " + order.orderDetails.lastName}</td>
                                        </tr>
                                        <tr>
                                            <td>Phone:</td>
                                            <td className="users-view-name">{order.orderDetails.mobile}</td>
                                        </tr>
                                        <tr>
                                            <td>E-mail:</td>
                                            <td className="users-view-email">{order.orderDetails.email}</td>
                                        </tr>
                                        <tr>
                                            <td>Post Code:</td>
                                            <td className="users-view-name">{order.orderDetails.postCode}</td>
                                        </tr>
                                        <tr>
                                            <td>City:</td>
                                            <td className="users-view-name">{order.orderDetails.city}</td>
                                        </tr>
                                        <tr>
                                            <td>Address:</td>
                                            <td className="users-view-email">{order.orderDetails.address}</td>
                                        </tr>
                                        <tr>
                                            <td>Message:</td>
                                            <td>{order.orderDetails.message}</td>
                                        </tr>
                                        <tr>
                                            <td>Date & Time:</td>
                                            <td>{(new Date(order.createdAt)).toUTCString()}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <h4 className="card-title">Update Order Information</h4>
                        <form className="form form-vertical" onSubmit={updateStatus}>
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-md-4 mb-4">
                                        <div className="form-group">
                                            <label className="form-label">Order Status</label>
                                            <select className="form-control"
                                                value={orderStatus}
                                                onChange={(e) => setOrderStatus(e.target.value)}>
                                                {orderStatuses.map(status => {
                                                    return <option value={status[1]}>{status[0]}</option>
                                                }
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <div className="form-group">
                                            <label className="form-label">Invoice Status</label>
                                            <select className="form-control"
                                                value={invoiceStatus}
                                                onChange={(e) => setInvoiceStatus(e.target.value)}>
                                                {invoiceStatuses.map(status => {
                                                    return <option value={status[1]}>{status[0]}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <div className="form-group">
                                            <label className="form-label">Update Services</label>
                                            <Select
                                                required
                                                isMulti={true}
                                                value={repairService}
                                                onChange={(e) => setRepairService(e)}
                                                options={repairServiceOptions}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-8 mb-4">
                                        <div className="form-group">
                                            {/* <label className="form-label">Comments</label> */}
                                            <textarea className="form-control"
                                                value={comments}
                                                onChange={(e) => setComments(e.target.value)}
                                                placeholder="Enter Comments here..."
                                            > </textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <div className="form-group">
                                            <label className="form-label">Return Date</label>
                                            <input className="form-control"
                                                value={returnDate}
                                                onChange={(e) => setReturnDate(e.target.value)}
                                                type="date"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12 d-flex justify-content-end">
                                        <button type="button" className="btn btn-primary mr-1"
                                            onClick={() => navigate(-1)}>Back</button>
                                        <button type="submit" className="btn btn-primary mx-1">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </section>}
    </div>
        </>
    );
}

export default ViewOrder;