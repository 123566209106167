import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { Link, useNavigate } from "react-router-dom";
import { base_url } from "../../../utils";
import { addEmployeeAction } from "../../../actions/employeeActions"
import { listStores } from "../../../actions/storeActions"
import Alert from "../../elements/Alert";
import { listUserRoles } from "../../../actions/userRoleActions"
const AddCustomer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postCode, setPostCode] = useState("");
  const [hearUs, setHearUs] = useState("");



  const addEmployee = (e) => {
    e.preventDefault()
    const new_employee = {
      firstName,
      lastName,
      email,
      mobile,
      address,
      city,
      postCode,
      hearUs,
      password: mobile,
      userType: 'CUSTOMER'
    }
    dispatch(addEmployeeAction(new_employee));
    navigate("/admin/customers");
  }

  return (
    <>
      <div className="container-xxl flex-grow-1 cotainer-p-y">
        <div className="breadcrumbs-top py-4">
          <div className="breadcrumb-wrapper d-none d-sm-block">
            <ol className="breadcrumb p-0 mb-0 pl-1">
              <li className="breadcrumb-item">
                <a href="javascript:void(0);">
                  <i className="bx bx-home-alt" />
                </a>
              </li>
              <li className="breadcrumb-item">
                <Link to="/admin/customers">Customers</Link>
              </li>
              <li className="breadcrumb-item active">
                <a href="javascript:void(0);">Add New</a>
              </li>
            </ol>
          </div>
        </div>
        <section id="basic-horizontal-layouts">
          <div className="row match-height">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-title">
                    <h5 className="content-header-title float-left pr-1 mb-0">
                      Add New Customer
                    </h5>
                  </div>
                </div>
                <div className="card-body">
                  <form onSubmit={addEmployee}>
                    <div className="row">
                      <div class="col-md-6 mb-4">
                        <label class="form-label">First Name*</label>
                        <input type="text" className="form-control" required
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)} />
                      </div>
                      <div class="col-md-6 mb-4">
                        <label class="form-label">Last Name*</label>
                        <input type="text" className="form-control" required
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)} />
                      </div>
                      <div class="col-md-6 mb-4">
                        <label class="form-label">Email*</label>
                        <input type="email" className="form-control" required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)} />
                      </div>
                      <div class="col-md-6 mb-4">
                        <label class="form-label">Mobile*</label>
                        <input type="text" className="form-control" required
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)} />
                      </div>
                      <div class="col-md-6 mb-4">
                        <label class="form-label">Address</label>
                        <input type="text" className="form-control"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)} />
                      </div>
                      <div class="col-md-6 mb-4">
                        <label class="form-label">City</label>
                        <input type="text" className="form-control"
                          value={city}
                          onChange={(e) => setCity(e.target.value)} />
                      </div>
                      <div class="col-md-6 mb-4">
                        <label class="form-label">Post Code</label>
                        <input type="text" className="form-control"
                          value={postCode}
                          onChange={(e) => setPostCode(e.target.value)} />
                      </div>
                      <div class="col-md-6 mb-4">
                        <label class="form-label">How did you hear about us*</label>
                        <select class="select2 form-select form-select" data-allow-clear="true"
                          value={hearUs} required
                          onChange={e => setHearUs(e.target.value)}>
                          <option value="">Select an option</option>
                          <option value="FACEBOOK">Facebook</option>
                          <option value="BING">Bing</option>
                          <option value="SEARCH_ENGINE">Search Engine</option>
                          <option value="REFERRAL">Referral</option>
                          <option value="GOOGLE_ADS">Google Ads</option>
                          <option value="OTHER">Other</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-sm-12 d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary mx-1"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default AddCustomer;