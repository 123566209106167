import axios from "axios";
import { alertMessage, base_url, check_response_status } from "../utils";
import { GET_PRODUCT_PHYSICAL_LOCATION, LIST_PRODUCT_PHYSICAL_LOCATIONS } from "./types";

// list  productPhysicalLocations
export const listProductPhysicalLocations = () => async dispatch => {

    axios.get(`${base_url}productPhysicalLocations`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                // console.log(' productPhysicalLocations', response.data);
                dispatch({
                    type: LIST_PRODUCT_PHYSICAL_LOCATIONS,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//get  productPhysicalLocation
export const getProductPhysicalLocation = (id) => async dispatch => {
    axios.get(`${base_url}productPhysicalLocations/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                // console.log(' productPhysicalLocations', response.data);
                dispatch({
                    type: GET_PRODUCT_PHYSICAL_LOCATION,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

// add  productPhysicalLocation
export const addProductPhysicalLocationAction = (productPhysicalLocation) => async dispatch => {

    axios.post(`${base_url}productPhysicalLocations`,
    productPhysicalLocation,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log(' productPhysicalLocation', response);
            if(response.status == 201) {
                dispatch(alertMessage(response.data));
                dispatch(listProductPhysicalLocations());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//update Device Color
export const updateProductPhysicalLocationAction = (id, productPhysicalLocation) => async dispatch => {
    axios.put(`${base_url}productPhysicalLocations/${id}`,
    productPhysicalLocation,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log(' productPhysicalLocation', response);
            if(response.status == 200) {
                dispatch(alertMessage({flag: 'success', message: "Device Color Upadated Successfully."}));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

export const deleteProductPhysicalLocationAction = (id) => async dispatch => {
    // console.log(' productPhysicalLocation', id)
    axios.delete(`${base_url}productPhysicalLocations/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log('delete', response);
            if(response.status == 200) {
                dispatch(alertMessage({flag: 'success', message: response.data.message}));
                dispatch(listProductPhysicalLocations());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}