import { LIST_EMPLOYEES,GET_EMPLOYEE } from "../actions/types";

const initialState = {
    employees: [],
    error: null,
    employee: null
};
export default (state = initialState, { type, payload }) => {

    switch (type) {
        case LIST_EMPLOYEES: 
        return {
            ...state,
            employees: payload
        }
        case GET_EMPLOYEE:
            return {
                ...state,
                employee: payload
            }
        default:
            return state;
    }
}
