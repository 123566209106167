import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { GetRecordsCount } from "../../../actions/globalActions";
// import { listOrdersAction } from "../../../actions/orderActions";
// import { deleteRepairCategoryAction, listRepairCategories } from "../../../actions/repairCategoriesActions";
import Pagination from "../../elements/Pagination";
import { dateWithoutZone } from "../../../utils";
import { listBuyDevicesAction } from "../../../actions/buySellOrderActions";

const BuyDevices = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { buyDevices } = useSelector((state) => state.buySellOrder);
    const [modal, setModal] = useState(false);
    const [delId, setDelId] = useState(null);

    useEffect(() => {
        dispatch(GetRecordsCount("buyDevices"));
        dispatch(listBuyDevicesAction());
    }, []);

    const updateRecord = (val) => {
        dispatch(listBuyDevicesAction(val));
    };

    const styleShow = {
        display: "block",
        paddingRight: "17px",
    };

    const styleHide = {
        display: "none",
        paddingRight: "17px",
    };

    const deleteRepairCategory = () => {
        // delId && dispatch(deleteRepairCategoryAction(delId));
        setModal(false);
    };

    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <div>
                    <nav aria-label="breadcrumb" className>
                        <ol className="breadcrumb breadcrumb-style1 p-1">
                            <li className="breadcrumb-item">
                                <Link to="/admin">Admin</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/admin/buy-devices">Buy Devices</Link>
                            </li>
                        </ol>
                    </nav>
                    <nav className="navbar navbar-expand-lg">
                        <div className="navbar-nav me-auto">
                            {/* <a
                                className="btn btn-primary me-1 collapsed toggle dropdown-toggle"
                                data-bs-toggle="collapse"
                                href="#collapseExample"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                            >
                                Search Filter
                            </a> */}
                        </div>
                        <ul className="navbar-nav ms-lg-auto">
                            <li className="nav-item">
                                {/* <Link
                                    className="nav-link btn mx-1 btn-success"
                                    to="add"
                                >
                                    <span>
                                        {" "}
                                        <i className="bx bxs-plus-circle" />
                                        &nbsp;Create Buy Device
                                    </span>
                                </Link> */}
                            </li>
                        </ul>
                    </nav>
                    {/* <div className="collapse" id="collapseExample">
                            <div className="col-12">
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6 mb-4">
                                                <label
                                                    htmlFor="TypeaheadBasic"
                                                    className="form-label"
                                                >
                                                    Search By
                                                </label>
                                                <input
                                                    id="TypeaheadBasic"
                                                    className="form-control typeahead"
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="Name / ID / IMEI"
                                                />
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <div>
                                                    <label
                                                        htmlFor="selectpickerBasic"
                                                        className="form-label"
                                                    >
                                                        Assigned To
                                                    </label>
                                                    <select
                                                        id="selectpickerBasic"
                                                        className="form-control selectpicker w-100"
                                                        data-style="btn-default"
                                                    >
                                                        <option value disabled>
                                                            Assigned To
                                                        </option>
                                                        <option>Rocky</option>
                                                        <option>
                                                            Pulp Fiction
                                                        </option>
                                                        <option>
                                                            The Godfather
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <div>
                                                    <label
                                                        htmlFor="selectpickerBasic"
                                                        className="form-label"
                                                    >
                                                        Manufacturer
                                                    </label>
                                                    <select
                                                        id="selectpickerBasic"
                                                        className="form-control selectpicker w-100"
                                                        data-style="btn-default"
                                                    >
                                                        <option>Rocky</option>
                                                        <option>
                                                            Pulp Fiction
                                                        </option>
                                                        <option>
                                                            The Godfather
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <div>
                                                    <label
                                                        htmlFor="selectpickerBasic"
                                                        className="form-label"
                                                    >
                                                        Referral Source
                                                    </label>
                                                    <select
                                                        id="selectpickerBasic"
                                                        className="form-control selectpicker w-100"
                                                        data-style="btn-default"
                                                    >
                                                        <option>Rocky</option>
                                                        <option>
                                                            Pulp Fiction
                                                        </option>
                                                        <option>
                                                            The Godfather
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <div>
                                                    <label
                                                        htmlFor="selectpickerBasic"
                                                        className="form-label"
                                                    >
                                                        Device
                                                    </label>
                                                    <select
                                                        id="selectpickerBasic"
                                                        className="form-control selectpicker w-100"
                                                        data-style="btn-default"
                                                    >
                                                        <option>Rocky</option>
                                                        <option>
                                                            Pulp Fiction
                                                        </option>
                                                        <option>
                                                            The Godfather
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6  mb-4">
                                                <label
                                                    htmlFor="flatpickr-date"
                                                    className="form-label"
                                                >
                                                    From date (created on)
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="YYYY-MM-DD"
                                                    id="flatpickr-date"
                                                />
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label
                                                    htmlFor="TypeaheadBasic"
                                                    className="form-label"
                                                >
                                                    Customer Name
                                                </label>
                                                <input
                                                    id="TypeaheadBasic"
                                                    className="form-control typeahead"
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="Name / ID / IMEI"
                                                />
                                            </div>
                                            <div className="col-md-6 col-12 mb-4">
                                                <label
                                                    htmlFor="flatpickr-date"
                                                    className="form-label"
                                                >
                                                    To date (created on)
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="YYYY-MM-DD"
                                                    id="flatpickr-date"
                                                />
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <div>
                                                    <label
                                                        htmlFor="selectpickerBasic"
                                                        className="form-label"
                                                    >
                                                        Status
                                                    </label>
                                                    <select
                                                        id="selectpickerBasic"
                                                        className="form-control selectpicker w-100"
                                                        data-style="btn-default"
                                                    >
                                                        <option>Rocky</option>
                                                        <option>
                                                            Pulp Fiction
                                                        </option>
                                                        <option>
                                                            The Godfather
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mb-4">
                                                <label
                                                    htmlFor="flatpickr-date"
                                                    className="form-label"
                                                >
                                                    From date (Due on)
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="YYYY-MM-DD"
                                                    id="flatpickr-date"
                                                />
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label
                                                    htmlFor="TypeaheadBasic"
                                                    className="form-label"
                                                >
                                                    Mobile/Phone
                                                </label>
                                                <input
                                                    id="TypeaheadBasic"
                                                    className="form-control typeahead"
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="Name / ID / IMEI"
                                                />
                                            </div>
                                            <div className="col-md-6 col-12 mb-4">
                                                <label
                                                    htmlFor="flatpickr-date"
                                                    className="form-label"
                                                >
                                                    To date (Due on)
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="YYYY-MM-DD"
                                                    id="flatpickr-date"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer border-top">
                                        <a href="#" className="btn btn-primary">
                                            Search
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                </div>
                <div className="row" id="basic-table">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Buy Devices</h4>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Device</th>
                                                <th>Condition</th>
                                                <th>Buy Prices</th>
                                                <th>Store</th>
                                                <th>Date</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {buyDevices?.map((device, key) => {
                                                return (
                                                    !device.isDeleted && (
                                                        <BuyDeviceItem
                                                            key={key}
                                                            device={device}
                                                        />
                                                    )
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="card-footer">
                                <Pagination
                                    currentItems={buyDevices}
                                    updateRecord={updateRecord}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BuyDevices;

export const BuyDeviceItem = ({ device }) => {
    const datetime = dateWithoutZone(new Date(device?.createdAt)).split(" ");
    return (
        <tr>
            <td>
                {device?.devices?.name}
                <br />
                <strong>{device?.devices?.manufactures?.name} </strong>
            </td>
            <td>
                <small className="d-block">
                    <strong> Grades:</strong>{" "}
                    {device?.availableGrades
                        ?.map((grade) => grade.name)
                        .join(", ")}
                </small>
                <small className="b-block">
                    <strong>Colors:</strong>{" "}
                    {device?.availableColors
                        ?.map((color) => color.name)
                        .join(", ")}
                </small>
                <small className="d-block">
                    <strong>Sizes:</strong>{" "}
                    {device?.availableSizes
                        ?.map((size) => size.name)
                        .join(", ")}
                </small>
                <small className="d-block">
                    <strong>Networks:</strong>{" "}
                    {device?.availableNetworks
                        ?.map((network) => network.name)
                        .join(", ")}
                </small>
            </td>
            <td>
                {device?.prices.map((price) => (
                    <small className="d-block">
                        <strong>£{price.price}</strong>{` (Grade ${price.grade}, ${price.size}, ${price.network})`}
                    </small>
                ))}
            </td>
            <td>{device?.store?.name}</td>
            <td>
                <span className="d-block">
                    {datetime[0]} <br /> {datetime[1]} {datetime[2]}
                </span>
            </td>
            <td>
                <button
                    type="button"
                    className="btn p-0 dropdown-toggle hide-arrow"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <i class="bx bx-dots-vertical-rounded"></i>
                </button>
                {/* <ul className="dropdown-menu bg-white">
                    <li>
                        <Link
                            to={`/admin/sell-orders/edit/${device._id}`}
                            className="dropdown-item"
                        >
                            Edit
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={`/admin/sell-orders/${device._id}`}
                            className="dropdown-item"
                        >
                            Move to Buy
                        </Link>
                    </li>
                </ul> */}
            </td>
        </tr>
    );
};
