import React from 'react'
import { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listManufacturers } from '../../actions/manufacturerActions';
import { useState } from 'react';
import { useEffect } from 'react';
import Select from 'react-select';
import { listDevices, listDevicesAction } from '../../actions/deviceActions';

const DeviceSerachFilters = () => {
    const dispatch = useDispatch();

    const manufacturers = useSelector(state => state.manufacturer.manufacturers);

    const [manufacturerOptions, setManufacturerOptions] = useState([]);
    const [deviceId, setDeviceId] = useState("");
    const [name, setName] = useState("");
    const [manufacturer, setManufacturer] = useState(null)

    useLayoutEffect(() => {
        dispatch(listManufacturers());
    }, []);

    useEffect(() => {
        const opts = [];
        manufacturers && manufacturers.map(manufacturer => {
            if (!manufacturer.isDeleted) {
                opts.push({ obj: manufacturer, value: manufacturer._id, label: manufacturer.name });
            }
        });
        setManufacturerOptions(opts);
    }, [manufacturers]);

    const searchDevices = (e) => {
        e.preventDefault();

        const filters = {
            _id: deviceId,
            name,
            manufacturer: manufacturer?.obj
        }
        dispatch(listDevices(manufacturer?.obj?._id));

    }

    return (
        <div className="collapse" id="collapseExample" style={{}}>
            <div className="col-12">
                <div className="card mb-4">
                    <form onSubmit={searchDevices}>
                        <div className="card-body">
                            <div className="row">
                                {/* <div className="col-md-4 mb-4">
                                    <label htmlFor="deviceId" className="form-label">Device Id</label>
                                    <input id="deviceId" className="form-control typeahead" type="text" autoComplete="off" placeholder="Device ID"
                                        value={deviceId}
                                        onChange={e => setDeviceId(e.target.value)} />
                                </div>

                                <div className="col-md-4 mb-4">
                                    <label htmlFor="deviceName" className="form-label">Device Name</label>
                                    <input id="deviceName" className="form-control typeahead" type="text" autoComplete="off" placeholder="Device Name"
                                        value={name}
                                        onChange={e => setName(e.target.value)} />
                                </div> */}

                                <div className="col-md-4 mb-4">
                                    <label htmlFor="deviceManufacturer" className="form-label">Manufacturer *</label>
                                    <Select
                                        id="deviceManufacturer"
                                        required
                                        value={manufacturer}
                                        onChange={(e) => setManufacturer(e)}
                                        options={manufacturerOptions}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="card-footer border-top">
                            <button type='submit' className="btn btn-primary"
                                data-bs-toggle="collapse" href="#collapseExample" role="button"
                                aria-expanded="false" aria-controls="collapseExample">
                                Search
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default DeviceSerachFilters