import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { GetRecordsCount } from "../../../actions/globalActions";
import { deleteProductAction, listProducts } from "../../../actions/productsActions";
import Pagination from "../../elements/Pagination";

const ListProducts = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const products = useSelector((state) => state.product.products);
    const [modal, setModal] = useState(false);
    const [delId, setDelId] = useState(null);

    useLayoutEffect(() => {
        dispatch(GetRecordsCount('products'));
        dispatch(listProducts());
    }, []);

    const updateRecord = (val) => {
        dispatch(listProducts(val));
    }

    const styleShow = {
        display: 'block',
        paddingRight: '17px'
    }

    const styleHide = {
        display: 'none',
        paddingRight: '17px'
    }

    const deleteDevice = () => {
        console.log(delId);
        delId && dispatch(deleteProductAction(delId));
        setModal(false);
    }

    return (
        <>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="breadcrumbs-top py-4">
                {/* <h5 className="content-header-title float-left pr-1 mb-0">
                    Products
                </h5> */}
   
                <div class="col-12 d-flex justify-content-between align-items-center" style={{ top: '-40px' }}>
                <div className="breadcrumb-wrapper d-none d-sm-block">
                    <ol className="breadcrumb breadcrumb-style1 p-0 mb-0 pl-1">
                        <li className="breadcrumb-item">
                            <Link to="/admin">
                                <i className="bx bx-home-alt" />
                            </Link>
                        </li>
                        <li className="breadcrumb-item active">
                            List All
                        </li>
                    </ol>
                </div>
                    <button onClick={() => { navigate('add') }} type="button" class="btn btn-primary mr-1">Add New</button>
                </div>
            </div>
            
            <div className="row" id="basic-table">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                            <h5 className="content-header-title float-left pr-1 mb-0">
                                Products
                            </h5>
                        </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            {/* <th>
                                                <input type="checkbox" />
                                            </th> */}
                                            <th>Name</th>
                                            <th>Category</th>
                                            <th>Manufacturer</th>
                                            <th>Device / Model</th>
                                            <th>Price</th>
                                            {/* <th>Show on POS</th>
                                            <th>Show on widgets</th> */}
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {products && products.map((product, key) => {
                                            return !product.isDeleted && <tr key={key}>
                                                {/* <td className="text-bold-500">
                                                    <input type="checkbox" />
                                                </td> */}
                                                <td>{product.name}</td>
                                                <td>{product.category?.name}</td>
                                                <td>{product.manufactures?.name}</td>
                                                <td>{product.devices?.name}</td>
                                                <td>{product.retailPrice}</td>
                                                {/* <td className="text-bold-500">
                                                    <input type="checkbox" />
                                                </td>
                                                <td className="text-bold-500">
                                                    <input type="checkbox" />
                                                </td> */}
                                                <td>
                                                    <Link to={`/admin/products/${product._id}`}>
                                                        <i className="badge-circle badge-circle-light-secondary bx bx-edit font-medium-1" />
                                                    </Link>
                                                    <a href="javascript:void(0);" onClick={() => { setModal(true); setDelId(product._id) }}>
                                                        <i className="badge-circle badge-circle-light-secondary bx bx-trash font-medium-1" />
                                                    </a>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <Pagination currentItems={products} updateRecord={updateRecord} />
                        </div>
                    </div>

                </div>
            </div>
        </div>

            <div className={`modal fade text-left ${modal ? 'show' : ''}`} id="danger" tabIndex={-1} aria-labelledby="myModalLabel120" style={modal ? styleShow : styleHide} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-danger">
                            <h5 className="modal-title white" id="myModalLabel120">Danger!</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={() => { setModal(false) }}>
                                <i className="bx bx-x" />
                            </button>
                        </div>
                        <div className="modal-body">
                            Are you sure! You want to delete this Manufacturer?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light-secondary" data-dismiss="modal"
                                onClick={() => { setModal(false) }}>
                                <i className="bx bx-x d-block d-sm-none" />
                                <span className="d-none d-sm-block">Cancel</span>
                            </button>
                            <button type="button" className="btn btn-danger ml-1" data-dismiss="modal"
                                onClick={() => { deleteDevice(delId) }}>
                                <i className="bx bx-check d-block d-sm-none" />
                                <span className="d-none d-sm-block">Accept</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ListProducts;
