import { GET_PRODUCT_CONDITION, LIST_PRODUCT_CONDITIONS } from "../actions/types";

const initialState = {
    productConditions: [],
    error: null,
    productCondition: null
};
export default (state = initialState, { type, payload }) => {

    switch (type) {
        case LIST_PRODUCT_CONDITIONS: 
        return {
            ...state,
            productConditions: payload
        }
        case GET_PRODUCT_CONDITION:
            return {
                ...state,
                productCondition: payload
            }
        default:
            return state;
    }
}
