import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { addUserRoleAction } from "../../../actions/userRoleActions";
import { PERMISSIONS } from "../../../variables/permissions";

const AddRole = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [permissions, setPermissions] = useState(PERMISSIONS);
    const [superUser, setSuperUser] = useState(false);

    const updateAll = () => {
        if (superUser) {
            setSuperUser(false);
            const updatedPermissions = Object.keys(permissions).reduce((newPermission, key) => {
                newPermission[key] = false;
                return newPermission;
            }, {});
            setPermissions(updatedPermissions);
        } else {
            setSuperUser(true);
            const updatedPermissions = Object.keys(permissions).reduce((newPermission, key) => {
                newPermission[key] = true;
                return newPermission;
            }, {});
            setPermissions(updatedPermissions);
        }
    }

    const updatePermissions = (permission) => {
        if (permissions[permission]) {
            setPermissions({ ...permissions, [permission]: false });
        } else {
            setPermissions({ ...permissions, [permission]: true });
        }
    }

    const addRole = (e) => {
        e.preventDefault();
        const role = {
            name,
            permissions
        }
        dispatch(addUserRoleAction(role));
        navigate('/admin/roles');
    }

    return (
        <>
            <div className="container-xxl flex-grow-1 cotainer-p-y">
                <div className="breadcrumbs-top py-4">
                    <div className="breadcrumb-wrapper d-none d-sm-block">
                        <ol className="breadcrumb p-0 mb-0 pl-1">
                            <li className="breadcrumb-item">
                                <a href="javascript:void(0);">
                                    <i className="bx bx-home-alt" />
                                </a>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/admin/roles">Roles</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <a href="javascript:void(0);">Add New</a>
                            </li>
                        </ol>
                    </div>
                </div>

                <section id="basic-horizontal-layouts">
                    <div className="row match-height">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <form className="row g-3" onSubmit={addRole}>
                                        <div className="col-6 mb-4">
                                            <label className="form-label" htmlFor="modalRoleName">Role Name</label>
                                            <input type="text" className="form-control" required placeholder="Enter a role name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)} />
                                        </div>
                                        <div className="col-12">
                                            <h5>Role Permissions</h5>
                                            <div className="table-responsive">
                                                <table className="table table-flush-spacing">
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-nowrap">
                                                                Administrator Access
                                                                <i className="bx bx-info-circle bx-xs" data-bs-toggle="tooltip" data-bs-placement="top" title="Allows a full access to the system" />
                                                            </td>
                                                            <td>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" id="selectAll"
                                                                        checked={superUser}
                                                                        onChange={() => updateAll()} />
                                                                    <label className="form-check-label" htmlFor="selectAll"> Select All </label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-nowrap">Manage Leads</td>
                                                            <td>
                                                                <div className="d-flex">
                                                                    <div className="form-check me-3 me-lg-5">
                                                                        <input className="form-check-input" type="checkbox" id="listLeads"
                                                                            checked={permissions.list_leads}
                                                                            onChange={() => updatePermissions('list_leads')}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="listLeads"> List </label>
                                                                    </div>
                                                                    <div className="form-check me-3 me-lg-5">
                                                                        <input className="form-check-input" type="checkbox" id="updateLead"
                                                                            checked={permissions.update_lead}
                                                                            onChange={() => updatePermissions('update_lead')}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="updateLead"> Update </label>
                                                                    </div>
                                                                    <div className="form-check me-3 me-lg-5">
                                                                        <input className="form-check-input" type="checkbox" id="addLead"
                                                                            checked={permissions.add_lead}
                                                                            onChange={() => updatePermissions('add_lead')}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="addLead"> Create </label>
                                                                    </div>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" id="deleteLead"
                                                                            checked={permissions.delete_lead}
                                                                            onChange={() => updatePermissions('delete_lead')}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="deleteLead"> Delete </label>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-nowrap">Manage Inquiries</td>
                                                            <td>
                                                                <div className="d-flex">
                                                                    <div className="form-check me-3 me-lg-5">
                                                                        <input className="form-check-input" type="checkbox" id="listInquiries"
                                                                            checked={permissions.list_inquiries}
                                                                            onChange={() => updatePermissions('list_inquiries')}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="listInquiries"> List </label>
                                                                    </div>
                                                                    <div className="form-check me-3 me-lg-5">
                                                                        <input className="form-check-input" type="checkbox" id="updateInquiry"
                                                                            checked={permissions.update_inquiry}
                                                                            onChange={() => updatePermissions('update_inquiry')}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="updateInquiry"> Update </label>
                                                                    </div>
                                                                    <div className="form-check me-3 me-lg-5">
                                                                        <input className="form-check-input" type="checkbox" id="addInquiry"
                                                                            checked={permissions.add_inquiry}
                                                                            onChange={() => updatePermissions('add_inquiry')}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="addInquiry"> Create </label>
                                                                    </div>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" id="deleteInquiry"
                                                                            checked={permissions.delete_inquiry}
                                                                            onChange={() => updatePermissions('delete_inquiry')}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="deleteInquiry"> Delete </label>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <button type="submit" className="btn btn-primary me-sm-3 me-1">Submit</button>
                                            <Link to="/admin/roles" className="btn btn-label-secondary">
                                                Cancel
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* <div className="modal fade" id="addRoleModal" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-add-new-role">
                    <div className="modal-content p-3 p-md-5">
                        <button type="button" className="btn-close btn-pinned" data-bs-dismiss="modal" aria-label="Close" />
                        <div className="modal-body">
                            <div className="text-center mb-4">
                                <h3 className="role-title">Add New Role</h3>
                                <p>Set role permissions</p>
                            </div>
                            <form id="addRoleForm" className="row g-3" onsubmit="return false">
                                <div className="col-12 mb-4">
                                    <label className="form-label" htmlFor="modalRoleName">Role Name</label>
                                    <input type="text" id="modalRoleName" name="modalRoleName" className="form-control" placeholder="Enter a role name" tabIndex={-1} />
                                </div>
                                <div className="col-12">
                                    <h5>Role Permissions</h5>
                                    <div className="table-responsive">
                                        <table className="table table-flush-spacing">
                                            <tbody>
                                                <tr>
                                                    <td className="text-nowrap">
                                                        Administrator Access
                                                        <i className="bx bx-info-circle bx-xs" data-bs-toggle="tooltip" data-bs-placement="top" title="Allows a full access to the system" />
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" id="selectAll" />
                                                            <label className="form-check-label" htmlFor="selectAll"> Select All </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-nowrap">User Management</td>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="form-check me-3 me-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="userManagementRead" />
                                                                <label className="form-check-label" htmlFor="userManagementRead"> Read </label>
                                                            </div>
                                                            <div className="form-check me-3 me-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="userManagementWrite" />
                                                                <label className="form-check-label" htmlFor="userManagementWrite"> Write </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="userManagementCreate" />
                                                                <label className="form-check-label" htmlFor="userManagementCreate"> Create </label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-nowrap">Content Management</td>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="form-check me-3 me-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="contentManagementRead" />
                                                                <label className="form-check-label" htmlFor="contentManagementRead"> Read </label>
                                                            </div>
                                                            <div className="form-check me-3 me-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="contentManagementWrite" />
                                                                <label className="form-check-label" htmlFor="contentManagementWrite"> Write </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="contentManagementCreate" />
                                                                <label className="form-check-label" htmlFor="contentManagementCreate"> Create </label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-nowrap">Disputes Management</td>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="form-check me-3 me-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="dispManagementRead" />
                                                                <label className="form-check-label" htmlFor="dispManagementRead"> Read </label>
                                                            </div>
                                                            <div className="form-check me-3 me-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="dispManagementWrite" />
                                                                <label className="form-check-label" htmlFor="dispManagementWrite"> Write </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="dispManagementCreate" />
                                                                <label className="form-check-label" htmlFor="dispManagementCreate"> Create </label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-nowrap">Database Management</td>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="form-check me-3 me-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="dbManagementRead" />
                                                                <label className="form-check-label" htmlFor="dbManagementRead"> Read </label>
                                                            </div>
                                                            <div className="form-check me-3 me-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="dbManagementWrite" />
                                                                <label className="form-check-label" htmlFor="dbManagementWrite"> Write </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="dbManagementCreate" />
                                                                <label className="form-check-label" htmlFor="dbManagementCreate"> Create </label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-nowrap">Financial Management</td>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="form-check me-3 me-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="finManagementRead" />
                                                                <label className="form-check-label" htmlFor="finManagementRead"> Read </label>
                                                            </div>
                                                            <div className="form-check me-3 me-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="finManagementWrite" />
                                                                <label className="form-check-label" htmlFor="finManagementWrite"> Write </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="finManagementCreate" />
                                                                <label className="form-check-label" htmlFor="finManagementCreate"> Create </label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-nowrap">Reporting</td>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="form-check me-3 me-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="reportingRead" />
                                                                <label className="form-check-label" htmlFor="reportingRead"> Read </label>
                                                            </div>
                                                            <div className="form-check me-3 me-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="reportingWrite" />
                                                                <label className="form-check-label" htmlFor="reportingWrite"> Write </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="reportingCreate" />
                                                                <label className="form-check-label" htmlFor="reportingCreate"> Create </label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-nowrap">API Control</td>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="form-check me-3 me-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="apiRead" />
                                                                <label className="form-check-label" htmlFor="apiRead"> Read </label>
                                                            </div>
                                                            <div className="form-check me-3 me-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="apiWrite" />
                                                                <label className="form-check-label" htmlFor="apiWrite"> Write </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="apiCreate" />
                                                                <label className="form-check-label" htmlFor="apiCreate"> Create </label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-nowrap">Repository Management</td>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="form-check me-3 me-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="repoRead" />
                                                                <label className="form-check-label" htmlFor="repoRead"> Read </label>
                                                            </div>
                                                            <div className="form-check me-3 me-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="repoWrite" />
                                                                <label className="form-check-label" htmlFor="repoWrite"> Write </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="repoCreate" />
                                                                <label className="form-check-label" htmlFor="repoCreate"> Create </label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-nowrap">Payroll</td>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="form-check me-3 me-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="payrollRead" />
                                                                <label className="form-check-label" htmlFor="payrollRead"> Read </label>
                                                            </div>
                                                            <div className="form-check me-3 me-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="payrollWrite" />
                                                                <label className="form-check-label" htmlFor="payrollWrite"> Write </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="payrollCreate" />
                                                                <label className="form-check-label" htmlFor="payrollCreate"> Create </label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <button type="submit" className="btn btn-primary me-sm-3 me-1">Submit</button>
                                    <button type="reset" className="btn btn-label-secondary" data-bs-dismiss="modal" aria-label="Close">
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default AddRole;