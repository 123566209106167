// import { GET_PRODUCT_TYPE, LIST_PRODUCT_TYPES } from "./types";
import { GET_PRODUCT_TYPE, LIST_PRODUCT_TYPES } from "../actions/types";

const initialState = {
    productTypes: [],
    error: null,
    productType: null
};
export default (state = initialState, { type, payload }) => {

    switch (type) {
        case LIST_PRODUCT_TYPES: 
        return {
            ...state,
            productTypes: payload
        }
        case GET_PRODUCT_TYPE:
            return {
                ...state,
                productType: payload
            }
        default:
            return state;
    }
}
