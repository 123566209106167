import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { emptyMedia, uploadImage } from "../../actions/globalActions";
import { addDeviceColorAction, AddDeviceColorAction } from "../../actions/deviceColorActions";
import { base_url } from "../../utils";
const AddDeviceColor = () => {
    const imageRef = useRef();
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const media = useSelector(state => state.global.media);

    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [image, setImage] = useState("");
    const [button, setButton] = useState(false);

    useLayoutEffect(() => {
        dispatch(emptyMedia());
    }, []);

    useEffect(() => {
        media && setButton(true);
    }, [media])

    const AddDeviceColor = (e) => {
        e.preventDefault();
        const deviceColor = {
            name,
            code
            // media: media.id
        }
        dispatch(addDeviceColorAction(deviceColor));
        navigate('/admin/device-colors');
    }

    const updateImage = (e) => {
        if (e.files && e.files[0]) {
            setImage(e.files[0]);

            const data = new FormData()
            data.append('file', e.files[0], e.files[0].name);
            console.log('data', data);

            dispatch(uploadImage(data));
        }
    }

    const profileStyle = {
        width: '150px',
        height: '150px'
    }
    return (
        <>
<div className="container-xxl flex-grow-1 container-p-y">
    <div className="breadcrumbs-top">

        <div className="breadcrumb-wrapper d-none d-sm-block mb-4">
            <ol className="breadcrumb p-0 mb-0 pl-1">
                <li className="breadcrumb-item">
                    <a href="index.html">
                        <i className="bx bx-home-alt" />
                    </a>
                </li>
                <li className="breadcrumb-item">
                    <Link to="/admin/device-colors">Device Colors</Link>
                </li>
                <li className="breadcrumb-item active">
                    <a href="javascript:void(0);">Add New</a>
                </li>
            </ol>
        </div>
        <h5 className="mb-1">
            Add New Device Color
        </h5>
    </div>
    <section id="basic-horizontal-layouts">
        <div className="row match-height">
            <div className="col-md-6 col-12">
                <div className="card">
                    <div className="card-header">
                        {/* <h4 className="card-title">
                            Device Color
                        </h4> */}
                    </div>
                    <div className="card-body">
                        <form className="form form-horizontal" onSubmit={AddDeviceColor}>
                            <div className="form-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label>
                                            Name
                                        </label>
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <input
                                            type="text"
                                            id="name"
                                            className="mb-4 form-control"
                                            name="name"
                                            placeholder="Name"
                                            required
                                            value={name}
                                            onChange={(e) => { setName(e.target.value) }}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label>
                                            Code
                                        </label>
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <input
                                            type="color"
                                            id="code"
                                            className="mb-4 form-control"
                                            name="code"
                                            placeholder="Code"
                                            required
                                            value={code}
                                            onChange={(e) => { setCode(e.target.value) }}
                                        />
                                    </div>
                                    {/* <div className="col-md-4">
                                        <label>Picture</label>
                                    </div>
                                    <div className="col-md-8">
                                        <img style={profileStyle} src={media ? base_url + "media/" + media.filename : "/app-assets/images/dummy.jpg"} alt={name} />
                                            </div>
                                    <div className="col-md-4">
                                        <label></label>
                                    </div>
                                    <div className="col-md-8">
                                        <fieldset class="form-group">
                                            <input type="file" class="mb-4 form-control-file" value={image.value} required
                                            onChange={(e) => {updateImage(e.target); setButton(false) }} />
                                        </fieldset>
                                    </div> */}
                                    <div className="col-sm-12 d-flex justify-content-end">
                                        <button
                                            type="button"
                                            className="btn btn-primary "
                                            onClick={() => navigate(-1)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary mx-1"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
  
        </>
    );
};

export default AddDeviceColor;
