import { GET_PRODUCT_CATEGORY, LIST_PRODUCT_CATEGORIES } from "../actions/types";

const initialState = {
    productCategories: [],
    error: null,
    productCategory: null
};
export default (state = initialState, { type, payload }) => {

    switch (type) {
        case LIST_PRODUCT_CATEGORIES: 
        return {
            ...state,
            productCategories: payload
        }
        case GET_PRODUCT_CATEGORY:
            return {
                ...state,
                productCategory: payload
            }
        default:
            return state;
    }
}
