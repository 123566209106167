import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const InventoryCount = () => {

    const [transfer, setTransfer] = useState(true)
    const [actionDrop, setActionDrop] = useState(false)

    const [serch, setSearch] = useState(false)

    const transferInToogle = () => {
        setTransfer(true)
    }
    const transferOutToogle = () => {
        setTransfer(false)
    }
    const actionDropToogle = () => {
        setActionDrop(!actionDrop)
    }
    const serchDropToogle = () => {
        setSearch(!serch)
    }

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-style1 p-1">
                        <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                        </li>

                        <li className="breadcrumb-item active">
                            <Link to="">Manage Inventory Count</Link>
                        </li>
                    </ol>
                </nav>

                <nav className="navbar navbar-expand-lg">
                    <div className="navbar-nav me-auto">
                        <button onClick={serchDropToogle} className="btn btn-primary me-1 collapsed toggle dropdown-toggle"
                            data-bs-toggle="collapse" data-bs-target="#collapseExample"
                            aria-expanded="false" aria-controls="collapseExample">
                            Search Filter
                        </button>
                    </div>

                    <ul className="navbar-nav ms-lg-auto">
                        <li className="nav-item">
                            <Link className="nav-link btn mx-1 btn-success" to="add">
                                <span><i className="bx bxs-plus-circle" />
                                    New Inventory Count </span>
                            </Link>
                        </li>

                    </ul>
                </nav>
                {
                    serch ?
                        <div className="toggle-box-filter dropdown hide search-form p-3">
                            <b className="caret2" />
                            <form className="container-fluid py-4" action="/index.php?r=inventoryCount/index" method="get">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group d-flex  mb-2">
                                            <h5 htmlFor="InventoryCount_id" className="col-sm-3">Count ID</h5>
                                            <input maxLength={20} placeholder="Count ID" name="InventoryCount[id]" id="InventoryCount_id" type="text" className="form-control" />
                                        </div>
                                        <div className="form-group d-flex  mb-2">
                                            <h5 htmlFor="InventoryCount_store_id" className="col-sm-3">Store</h5>
                                            {/* Simplified store dropdown */}
                                            <select data-placeholder="Select Store" name="InventoryCount[store_id]" className="form-control">
                                                <option value="">Select Store</option>
                                                <option value="21559">Mrfix EXTRA</option>
                                                <option value="22972">Mr Fix - Kilmarnock</option>
                                                <option value="22973">Mr Fix - Milton Keynes</option>
                                                <option value="23174">Mr Fix - Corstorphine</option>
                                            </select>
                                        </div>
                                        <div className="form-group d-flex  mb-2">
                                            <h5 htmlFor="InventoryCount_employee_id" className="col-sm-3">Employee</h5>
                                            {/* Simplified employee dropdown */}
                                            <select data-placeholder="Select Employee" name="InventoryCount[employee_id]" className="form-control">
                                                <option value="">Select Employee</option>
                                                <option value="1">Sufyan</option>
                                                <option value="2">Saif</option>
                                                <option value="3">Nadeem</option>
                                                <option value="4">Hussnain</option>
                                            </select>
                                        </div>
                                        <div className="form-group d-flex  mb-2">
                                            <h5 htmlFor="InventoryCount_product_name" className="col-sm-3">Item Name</h5>
                                            <input maxLength={20} placeholder="Enter item name" name="InventoryCount[product_name]" id="InventoryCount_product_name" type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group d-flex  mb-2">
                                            <h5 htmlFor="InventoryCount_status" className="col-sm-3">Status</h5>
                                            {/* Simplified status dropdown */}
                                            <select data-placeholder="Select Status" id="InventoryCount_status" name="InventoryCount[status]" className="form-control">
                                                <option value="">Select Status</option>
                                                <option value="Paused">Paused</option>
                                                <option value="Counted">Counted</option>
                                            </select>
                                        </div>
                                        <div className="form-group d-flex  mb-2">
                                            <h5 htmlFor="InventoryCount_created_on_0" className="col-sm-3">From Date</h5>
                                            <div className="wdp-input-wrap">
                                                <input className="form-control" data-date-include-clear-icon data-date-format="DD MMM, YYYY" data-date-allow-clear="yes" autoComplete="off" name="InventoryCount[created_on][0]" id="InventoryCount_created_on_0" type="date" />
                                                <a href="javascript:void(0)" className="wdp-clear-icon" style={{ display: 'none' }}>×</a>
                                            </div>
                                        </div>
                                        <div className="form-group d-flex  mb-2">
                                            <h5 htmlFor="InventoryCount_created_on_1" className="col-sm-3">To Date</h5>
                                            <div className="wdp-input-wrap">
                                                <input className="form-control" data-date-include-clear-icon data-date-format="DD MMM, YYYY" data-date-allow-clear="yes" autoComplete="off" name="InventoryCount[created_on][1]" id="InventoryCount_created_on_1" type="date" />
                                                <a href="javascript:void(0)" className="wdp-clear-icon" style={{ display: 'none' }}>×</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="form-group d-flex  mb-2 text-right">
                                    <button className="btn btn-primary" type="submit">Search <i className="icon-search icon-white" /></button>
                                </div>
                            </form>
                        </div> :
                        ''
                }
            </div>



            <div className="row" id="basic-table">
                <div className="col-12">
                    <div className="card">




                        <div className="card-body">
                            <div className='border d-flex justify-content-between justify-content-center'>
                                <div>
                                    <h5 className='px-2 pb-2 my-2'>Manage Inventory Count</h5>

                                </div>

                                <select className="browser-default px-2 py-1 custom-select my-2 mx-2">
                                    <option selected>50</option>
                                    <option value={1}>100</option>
                                    <option value={2}>250</option>
                                    <option value={3}>500</option>
                                </select>

                            </div>

                            <div className="table-responsive container-full">

                                <table className="table table-bordered w-full" style={{ whiteSpace: "nowrap" }}>
                                    <thead>
                                        <tr className='bg-light'>
                                            <th scope="col">Count ID</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">
                                                Store</th>
                                            <th scope="col">Count Name</th>
                                            <th scope="col">Employee</th>
                                            <th scope="col">Items</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">In Stock</th>
                                            <th scope="col">Counted</th>
                                            <th scope="col">Item
                                                Variance</th>
                                            <th scope="col">Cost
                                                Variance</th>
                                            <th scope="col">Inventory
                                                Adjustment Report</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr className="odd"  >
                                            <td className=''>IC-022</td>
                                            <td>	16 Aug, 2023 (11:19 am)</td>
                                            <td>Mrfix EXTRA</td>
                                            <td >Mrfix EXTRA - 16 Aug, 2023 (11:19 AM)</td>
                                            <td style={{ whiteSpace: "nowrap" }}>Sufyan</td>
                                            <td style={{ whiteSpace: "nowrap" }}>Iphone 5G Mr Fix Premium Screen Replacement White

                                                , Iphone 5G Mr Fix Premium Screen Replacement Black</td>
                                            <td><div className="nav-link btn btn-warning" >
                                                <span>
                                                    Pasued </span>
                                            </div></td>
                                            <td>
                                                822
                                            </td>
                                            <td >822</td>
                                            <td >0</td>
                                            <td >£0.00</td>
                                            <td ></td>
                                         
                                            <td>
                                                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="bx bx-dots-vertical-rounded"></i>
                                                </button>
                                                <ul className="dropdown-menu bg-white">
                                                    <li><Link to='view'
                                                        className="dropdown-item">Update</Link>
                                                    </li>
                                                    <li><Link to='view'
                                                        className="dropdown-item">Delete</Link>
                                                    </li>

                                                </ul>
                                            </td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>

                        </div>

                        <div className="card-footer">

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default InventoryCount