import axios from "axios";
import { alertMessage, base_url, check_response_status } from "../utils";
import { GET_PRODUCT_SIZE, LIST_PRODUCT_SIZES } from "./types";

// list  productSizes
export const listProductSizes = () => async dispatch => {

    axios.get(`${base_url}productSizes`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                console.log(' productSizes', response.data);
                dispatch({
                    type: LIST_PRODUCT_SIZES,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//get  productSize
export const getProductSize = (id) => async dispatch => {
    axios.get(`${base_url}productSizes/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                // console.log(' productSizes', response.data);
                dispatch({
                    type: GET_PRODUCT_SIZE,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

// add  productSize
export const addProductSizeAction = (productSize) => async dispatch => {

    axios.post(`${base_url}productSizes`,
    productSize,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log(' productSize', response);
            if(response.status == 201) {
                dispatch(alertMessage(response.data));
                dispatch(listProductSizes());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//update Device Color
export const updateProductSizeAction = (id, productSize) => async dispatch => {
    axios.put(`${base_url}productSizes/${id}`,
    productSize,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log(' productSize', response);
            if(response.status == 200) {
                dispatch(alertMessage({flag: 'success', message: "Device Color Upadated Successfully."}));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

export const deleteProductSizeAction = (id) => async dispatch => {
    console.log(' productSize', id)
    axios.delete(`${base_url}productSizes/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            console.log('delete', response);
            if(response.status == 200) {
                dispatch(alertMessage({flag: 'success', message: response.data.message}));
                dispatch(listProductSizes());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}