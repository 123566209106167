import { GET_PRODUCT_SUPPLIER, LIST_PRODUCT_SUPPLIERS } from "../actions/types";

const initialState = {
    productSuppliers: [],
    error: null,
    productSupplier: null
};
export default (state = initialState, { type, payload }) => {

    switch (type) {
        case LIST_PRODUCT_SUPPLIERS: 
        return {
            ...state,
            productSuppliers: payload
        }
        case GET_PRODUCT_SUPPLIER:
            return {
                ...state,
                productSupplier: payload
            }
        default:
            return state;
    }
}
