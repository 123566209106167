import axios from "axios";
import { alertMessage, base_url, check_response_status } from "../utils";
import { GET_TAX_CLASS, LIST_TAX_CLASSES } from "./types";

// list tax classes
export const listTaxClasses = () => async dispatch => {

    axios.get(`${base_url}taxes`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                // console.log('taxes', response.data);
                dispatch({
                    type: LIST_TAX_CLASSES,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//get tax class
export const getTaxClass = (id) => async dispatch => {
    axios.get(`${base_url}taxes/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                // console.log('taxes', response.data);
                dispatch({
                    type: GET_TAX_CLASS,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

// add tax class
export const addTaxClassAction = (classes, percentage) => async dispatch => {

    axios.post(`${base_url}taxes`,
        {class: classes, percentage},
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log('tax class', response);
            if(response.status == 201) {
                dispatch(alertMessage(response.data));
                dispatch(listTaxClasses());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//update Tax class
export const updateTaxClassAction = (id, tax_class) => async dispatch => {
    axios.put(`${base_url}taxes/${id}`,
    tax_class,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log('tax class', response);
            if(response.status == 200) {
                dispatch(alertMessage({flag: 'success', message: "Tax Class Upadated Successfully."}));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

export const deleteTaxClassAction = (id) => async dispatch => {
    // console.log('tax class', id)
    axios.delete(`${base_url}taxes/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            // console.log('taxes', response);
            if(response.status == 200) {
                dispatch(alertMessage({flag: 'success', message: response.data.message}));
                dispatch(listTaxClasses());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}