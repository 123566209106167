import { GET_DEVICE_COLOR, LIST_DEVICE_COLORS } from "../actions/types";

const initialState = {
    deviceColors: [],
    error: null,
    deviceColor: null
};
export default (state = initialState, { type, payload }) => {

    switch (type) {
        case LIST_DEVICE_COLORS: 
        return {
            ...state,
            deviceColors: payload
        }
        case GET_DEVICE_COLOR:
            return {
                ...state,
                deviceColor: payload
            }
        default:
            return state;
    }
}
