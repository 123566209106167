import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';

const Pagination = ({ updateRecord }) => {
    const count = useSelector((state) => state.global.count);
    const [pageCount, setPageCount] = useState(0);
    const itemsPerPage = 20;

    useEffect(() => {
        if (count) {
            setPageCount(Math.ceil(count / itemsPerPage));
        }
    }, [count])

    const handlePageClick = (event) => {
        updateRecord((event.selected * itemsPerPage) % count);
    };

    return (
        <ReactPaginate
            breakLabel="..."
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< Previous"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            activeClassName="active"
            nextClassName='page-item'
            nextLinkClassName='page-link'
            previousClassName='page-item'
            previousLinkClassName='page-link'
        />
    );
}

export default Pagination;