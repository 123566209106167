import {
    GET_ORDER,
    LIST_ORDERS,
    LIST_ORDER_STATUS,
    LIST_INVOICE_STATUS,
    LIST_SELL_ORDERS,
    GET_SELL_ORDER,
    LIST_BUY_DEVICES,
    GET_BUY_DEVICE
} from "../actions/types";

const initialState = {
    sellOrders: [],
    sellOrder: {},
    buyOrders: [],
    buyOrder: {},
    error: null,
    orderStatus: [],
    invoiceStatus: [],
    buyDevices: [],
    buyDevice: {}
};
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case LIST_BUY_DEVICES:
            return {
                ...state,
                buyDevices: payload
            }
        case GET_BUY_DEVICE:
            return {
                ...state,
                buyDevice: payload
            }
        case LIST_SELL_ORDERS:
            return {
                ...state,
                sellOrders: payload
            }
        case GET_SELL_ORDER:
            return {
                ...state,
                sellOrder: payload
            }
        case LIST_ORDER_STATUS:
            return {
                ...state,
                orderStatus: payload
            }
        case LIST_INVOICE_STATUS:
            return {
                ...state,
                invoiceStatus: payload
            }
        default:
            return state;
    }
}