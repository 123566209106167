import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { addDeviceSeriesAction } from "../../actions/deviceSeriesActions";
import { listManufacturers } from "../../actions/manufacturerActions";
import Select from 'react-select';

const AddDeviceSeries = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const manufacturers = useSelector(state => state.manufacturer.manufacturers);

    const [name, setName] = useState("");
    const [manufacturerOptions, setManufacturerOptions] = useState([]);

    const [manufacturer, setManufacturer] = useState("");

    useLayoutEffect(() => {
        dispatch(listManufacturers());
    }, []);

    useEffect(() => {
        const opts = [];
        manufacturers && manufacturers.map(manufacturer => {
            if (!manufacturer.isDeleted) {
                opts.push({ obj: manufacturer, value: manufacturer._id, label: manufacturer.name });
            }
        });
        setManufacturerOptions(opts);
    }, [manufacturers]);

    const AddDeviceSeries = (e) => {
        e.preventDefault();
        const deviceSeries = {
            name,
            manufactures: manufacturer.obj
        }
        dispatch(addDeviceSeriesAction(deviceSeries));
        navigate('/admin/device-series');
    }

    return (
        <>
        <div className="container-xxl flex-grow-1 cotainer-p-y">
            <div className="breadcrumbs-top py-4">
                {/* <h5 className="content-header-title float-left pr-1 mb-0">
                    Add New Product Condition
                </h5> */}
                <div className="breadcrumb-wrapper d-none d-sm-block">
                    <ol className="breadcrumb p-0 mb-0 pl-1">
                        <li className="breadcrumb-item">
                            <a href="javascript:void(0);">
                                <i className="bx bx-home-alt" />
                            </a>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/admin/device-series">Device Series</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <a href="javascript:void(0);">Add New</a>
                        </li>
                    </ol>
                </div>
            </div>
            <section id="basic-horizontal-layouts">
                <div className="row match-height">
                    <div className="col-md-6 col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">
                                <h5 className="">
                                    Add New Product Condition
                                </h5>
                                </div>
                            </div>
                            <div className="card-body">
                                <form className="form form-horizontal" onSubmit={AddDeviceSeries}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label>
                                                    Name
                                                </label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input
                                                    type="text"
                                                    id="name"
                                                    className="form-control mb-4"
                                                    name="name"
                                                    placeholder="Name"
                                                    required
                                                    value={name}
                                                    onChange={(e) => { setName(e.target.value) }}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                    <label>
                                                        Manufacturer
                                                    </label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <Select
                                                        required
                                                        value={manufacturer}
                                                        onChange={(e) => setManufacturer(e)}
                                                        options={manufacturerOptions}
                                                        className="mb-4"
                                                    />
                                                </div>
                                            <div className="col-sm-12 d-flex justify-content-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary me-1"
                                                    onClick={() => navigate(-1)}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary "
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        </>
    );
};

export default AddDeviceSeries;