import { combineReducers } from "redux";
import authReducer from "./authReducer";
import globalReducer from "./globalReducer";
import manufacturerReducer from "./manufacturerReducer";
import deviceColorsReducer from "./deviceColorsReducer";
import taxClassReducer from "./taxClassReducer";
import deviceReducer from "./deviceReducer";
import repairCategoryReducer from "./repairCategoryReducer";
import repairServiceReducer from "./repairServiceReducer"
import problemReducer from "./problemReducer";
import orderReducer from "./orderReducer";
import storeReducer from "./storeReducer";
import productCategoryReducer from "./productCategoryReducer";
import blogReducer from "./blogReducer";
import seoPageReducer from "./seoPageReducer";
import productConditionReducer from "./productConditionReducer";
import productNetworkReducer from "./productNetworkReducer";
import productPhysicalLocationReducer from "./productPhysicalLocationReducer";
import productSupplierReducer from "./productSupplierReducer";
import inquiryReducer from "./inquiryReducer";
import rolesReducer from "./rolesReducer"
import employeeReducer from "./employeeReducer"
import productsReducer from "./productsReducer";
import tradeInReducer from "./tradeInReducer";
import productSizeReducer from "./productSizeReducer";
import buySellOrdersReducer from "./buySellOrdersReducer";
import deviceSeriesReducer from "./deviceSeriesReducer"
import ticketReducer from "./ticketReducer";
import productTypeReducer from "./productTypeReducer";

export default combineReducers({
    auth: authReducer,
    global: globalReducer,
    manufacturer: manufacturerReducer,
    deviceColor: deviceColorsReducer,
    taxClass: taxClassReducer,
    device: deviceReducer,
    repairCategory: repairCategoryReducer,
    repairService: repairServiceReducer,
    problem: problemReducer,
    order: orderReducer,
    store: storeReducer,
    productCategory: productCategoryReducer,
    blog: blogReducer,
    page: seoPageReducer,
    productCondition: productConditionReducer,
    productNetwork: productNetworkReducer,
    productPhysicalLocation: productPhysicalLocationReducer,
    productSupplier: productSupplierReducer,
    inquiry: inquiryReducer,
    userRole: rolesReducer,
    employee: employeeReducer,
    product: productsReducer,
    tradein: tradeInReducer,
    productSize: productSizeReducer,
    buySellOrder: buySellOrdersReducer,
    deviceSeries: deviceSeriesReducer,
    productType:productTypeReducer,
    ticket: ticketReducer,
  });