import axios from "axios";
import { alertMessage, base_url, check_response_status } from "../utils";
import { LIST_EMPLOYEES , GET_EMPLOYEE, ADD_CUSTOMER } from "./types";

// list  users
export const listEmployees = (type) => async dispatch => {

    axios.get(`${base_url}users?userType=${type}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                console.log('employee', response.data);
                dispatch({
                    type: LIST_EMPLOYEES,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//get  inquiry
export const getEmployee = (id) => async dispatch => {
    axios.get(`${base_url}users/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 200) {
                console.log(' users', response.data);
                dispatch({
                    type: GET_EMPLOYEE,
                    payload: response.data
                });
            }
            else if(response.status == 201) {
                dispatch(alertMessage(response.data));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

// add  Employee
export const addEmployeeAction = (employee) => async dispatch => {
    // console.log('addemployeeaction', employee)
    axios.post(`${base_url}users`,
    employee,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if(response.status == 201) {
                dispatch(alertMessage(response.data));
                dispatch(listEmployees());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

//update Device Color
export const updateEmployeeAction = (id, employe) => async dispatch => {
    console.log("yyyyyyyy", employe)

    axios.put(`${base_url}users/${id}`,
    employe,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
           
            if(response.status == 200) {
                dispatch(alertMessage({flag: 'success', message: "Employee data Upadated Successfully."}));
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

export const deleteEmployeeAction = (id) => async dispatch => {
    // console.log(' inquiry', id)
    axios.delete(`${base_url}users/${id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
         
            if(response.status == 200) {
                dispatch(alertMessage({flag: 'success', message: response.data.message}));
                dispatch(listEmployees());
            }
            else if (response.status == 400) {
                dispatch(alertMessage({flag: 'danger', message: response.data.message.toString()}));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}

export const addCustomerAction = (employee) => async dispatch => {
    axios.post(`${base_url}users`,
        employee,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 201) {
                dispatch({
                    type: ADD_CUSTOMER,
                    payload: response.data
                });
            }
            else if (response.status == 400) {
                dispatch(alertMessage({ flag: 'danger', message: response.data.message.toString() }));
            } else {
                dispatch(check_response_status(response))
            }
        })
        .catch(err => {
            // console.log('err', err);
            dispatch(check_response_status(err.response));
        });
}