import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { emptyAlertMessage, uploadImage } from "../../actions/globalActions";
import { updateDeviceSeriesAction, getDeviceSeries } from "../../actions/deviceSeriesActions";
import Alert from "../elements/Alert";
import { listManufacturers } from "../../actions/manufacturerActions";
import Select from 'react-select';


const UpdateDeviceSeries = () => {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    let { id } = useParams();

    const manufacturers = useSelector(state => state.manufacturer.manufacturers);
    const deviceSeries = useSelector(state => state.deviceSeries.deviceSeries);

    const [name, setName] = useState("");
    const [manufacturerOptions, setManufacturerOptions] = useState([]);
    const [manufacturer, setManufacturer] = useState("");

    useLayoutEffect(() => {
        dispatch(emptyAlertMessage());
        dispatch(listManufacturers());
    }, []);

    useEffect(() => {
        if (manufacturers) {
            const opts = [];
            manufacturers.map(manufacturer => {
                if (!manufacturer.isDeleted) {
                    opts.push({ obj: manufacturer, value: manufacturer._id, label: manufacturer.name });
                }
            });
            setManufacturerOptions(opts);
        }
    }, [manufacturers]);

    useEffect(() => {
        if (deviceSeries) {
            setName(deviceSeries.name);
            setManufacturer({ obj: deviceSeries.manufactures, value: deviceSeries.manufactures?._id, label: deviceSeries.manufactures?.name });
        }
    }, [deviceSeries])

    useEffect(() => {
        id && dispatch(getDeviceSeries(id));
    }, [id]);

    const UpdateDeviceSeries = (e) => {
        e.preventDefault();
        const deviceSeries = {
            name,
            manufactures: manufacturer.obj
        }
        dispatch(updateDeviceSeriesAction(id, deviceSeries));
    }
    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="breadcrumbs-top py-4">
                    {/* <h5 className="content-header-title float-left pr-1 mb-0">
                    Update Product Condition
                </h5> */}
                    <div className="breadcrumb-wrapper d-none d-sm-block">
                        <ol className="breadcrumb breadcrumb-style1 p-0 mb-0 pl-1">
                            <li className="breadcrumb-item">
                                <a href="javascript:void(0);">
                                    <i className="bx bx-home-alt" />
                                </a>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/admin/product-conditions">Product Conditions</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <a href="javascript:void(0);">Update</a>
                            </li>
                        </ol>
                    </div>
                </div>
                <section id="basic-horizontal-layouts">
                    <div className="row match-height">
                        <div className="col-md-6 col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h5 className="content-header-title float-left pr-1 mb-0">
                                            Update Product Condition
                                        </h5>
                                    </div>
                                    <Alert />
                                </div>
                                <div className="card-body">
                                    <form className="form form-horizontal" onSubmit={UpdateDeviceSeries}>
                                        <div className="form-body">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label>
                                                        Name
                                                    </label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <input
                                                        type="text"
                                                        id="class"
                                                        className="form-control mb-4"
                                                        name="class"
                                                        placeholder="Tax Class"
                                                        required
                                                        value={name}
                                                        onChange={(e) => { setName(e.target.value) }}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <label>
                                                        Manufacturer
                                                    </label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <Select
                                                        required
                                                        value={manufacturer}
                                                        onChange={(e) => setManufacturer(e)}
                                                        options={manufacturerOptions}
                                                        className="mb-4"
                                                    />
                                                </div>
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-1"
                                                        onClick={() => navigate(-1)}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary "
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default UpdateDeviceSeries;
